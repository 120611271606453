import { Box, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useMemo, useState } from 'react';
import './App.css';
import { AppSettingsProvider } from './Context/AppSettingsContext';
import { UnsavedChangesProvider } from './Context/UnsavedChangesContext';
import AppRoutes from './Routes';
import MLAppBar from './components/AppBar/MLAppBar';
import MLSideBar from './components/AppBar/MLSideBar';
import { TimeoutLogic } from './components/TimeOut/TimeoutLogic';
import { useAuth } from './utils/auth/AuthService';
import { NotificationsProvider } from './utils/components/AppBar/Notifications/NotificationsContext';
import { CaseSearchProvider } from './utils/hooks/CaseSearch/useCaseSearch.hook';
import getAppTheme from './utils/theme/getAppTheme';
import { IncidentProvider } from './views/DVCS/IncidentContext';
import { ConversationProvider } from './views/SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';

LicenseInfo.setLicenseKey(
  '83110911e323e03a1b20fca6f91e8744Tz0xMTAyNzQsRT0xNzc0NDgzMTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
);

function App() {
  const auth = useAuth();
  const [mode, setMode] = useState('light');
  // const [appEnvironmentIsUAT, setAppEnvironmentIsUAT] = useState(true);
  const theme = getAppTheme(mode);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { VITE_API_URL, VITE_URL } = import.meta.env;
  const location = window.location;
  const isCaseView = location.pathname?.toLowerCase() === `${VITE_URL}caseview`;

  // useEffect(() => {
  //   fetch(VITE_API_URL + 'isappenvironmentuat', {
  //     method: 'GET',
  //   })
  //     .then(res => {
  //       return res.json();
  //     })
  //     .then(data => {
  //       console.log(data);
  //       setAppEnvironmentIsUAT(data);
  //     })
  //     .catch(e => {
  //       //alert(e);
  //     });
  // }, []);

  useEffect(() => {
    if (auth.isAuthenticated && auth.ready) {
      setMode(auth.user.isDarkMode ? 'dark' : 'light');
    }
  }, [auth?.user?.isDarkMode, auth.isAuthenticated, auth.ready]);

  const authenticated = useMemo(() => {
    return auth.isAuthenticated && auth.ready;
  }, [auth.isAuthenticated, auth.ready]);

  // Only render app bars if user is logged in
  return authenticated ? (
    <Box
      sx={{
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UnsavedChangesProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />

          <AppSettingsProvider>
            <NotificationsProvider>
              <ConversationProvider>
                <CaseSearchProvider>
                  <IncidentProvider>
                    <MLAppBar />
                    <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
                      {isDesktop && !isCaseView && <MLSideBar />}

                      <AppRoutes
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          color: theme.palette.text.primary,
                          overflow: 'auto',
                          width: '100%',
                          // height: `calc(100% - ${dimensions.app_bar_height})`,
                          height: '100%',
                        }}
                      />
                    </Box>
                  </IncidentProvider>
                </CaseSearchProvider>
              </ConversationProvider>
            </NotificationsProvider>
          </AppSettingsProvider>

          {(() => {
            if (auth.ready && auth.user) {
              return <TimeoutLogic />;
            }
          })()}
        </ThemeProvider>
      </UnsavedChangesProvider>
      {/* {appEnvironmentIsUAT && (
        <Box
          sx={{
            width: '100%',
            height: `calc(100% - ${dimensions.app_bar_height}`,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10000000,
            opacity: 0.05,
            color: 'white',
            overflow: 'hidden',
            pointerEvents: 'none',
          }}
        >
          <h1
            style={{
              position: 'absolute',
              fontSize: 175,
              pointerEvents: 'none',
            }}
          >
            <Stack spacing={2}>
              {[...Array(30)].map((_, outerIndex) => (
                <Stack direction='row' spacing={2}>
                  {[...Array(30)].map((_, innerIndex) => (
                    <b
                      style={{
                        transform: `rotate(-35deg) translate(-${outerIndex * 275 + 100}px, -100px)`,
                      }}
                    >
                      UAT
                    </b>
                  ))}
                </Stack>
              ))}
            </Stack>
          </h1>
        </Box>
      )} */}
    </Box>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <AppRoutes sx={{ overflow: 'auto', height: '100%' }} />
    </ThemeProvider>
  );
}

export default App;
