import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  Tooltip,
  Alert,
  Snackbar,
  AlertColor,
} from '@mui/material';
import {
  useGridApiContext,
  GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import authHeader from '../../../services/auth-header.jsx';
import UserFileEditView from './UserFileEditView.js';
import FileUploadDropzone from '../../../utils/components/FileUploadDropzone.js';
import { createSvgIcon } from '@mui/material/utils';
import { TailSpin } from 'react-loader-spinner';
import CondensedDataGrid from '../../../components/CondensedDataGrid.jsx';
import { useAuth } from '../../../utils/auth/AuthService.js';
import { FileAPI } from '../../../utils/functions/FileAPI.js';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';

interface UserFilesProps {
  userSeq: string | number;
}

const UserFiles: React.FC<UserFilesProps> = ({ userSeq }) => {
  const { user } = useAuth();
  const [fileTypeOptions, setFileTypeOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userFiles, setUserFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const { VITE_API_URL } = import.meta.env;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditView, setShowEditView] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [clickTimeout, setClickTimeout] = useState(null);
  const [userDocumnetSnackbar, setUserDocumnetSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const ExportIcon = createSvgIcon(
    <path d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z' />,
    'SaveAlt'
  );
  const [showSelectionAlert, setShowSelectionAlert] = useState(false);
  const guid = '00000000-0000-0000-0000-000000000000';

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  const columns = [
    // {
    //   field: 'photoSeq',
    //   headerName: 'PhotoSeq',
    //   minWidth: 150,
    //   hide: true,
    //   flex: 1,
    //   editable: false,
    // },
    {
      field: 'originalFileName',
      headerName: 'File Name',
      minWidth: 300,
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Button
            variant='text'
            color='primary'
            onClick={() =>
              FileAPI.openFileInNewTab(
                `${import.meta.env.VITE_API_URL}OpenDoc?photoSeq=${params.row.photoSeq}`,
                undefined, 
                user?.token || '', 
                'GET'
              )
            }
          >
            {params.row.originalFileName}
          </Button>
        );
      },
    },
    {
      field: 'photoLabel',
      headerName: 'Certificate Type',
      minWidth: 180,
      flex: 1,
      editable: false,
    },

    {
      field: 'uploadedBy',
      headerName: 'Uploaded By',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: value => {
        return value ? value.optionName : '';
      },
    },
    {
      field: 'uploadedOn',
      headerName: 'Uploaded On',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 100,
      renderCell: params => {
        const [anchorEl, setAnchorEl] = useState(null);

        const onDeleteClick = (e, row) => {
          e.stopPropagation();
          setShowConfirmation(true);
          setItemToDelete(row);
        };

        const onInfoClick = e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        };

        const onInfoClose = () => {
          setTimeout(() => setAnchorEl(null), 100);
        };

        const showInfo = Boolean(anchorEl);

        return (
          <div>
            <IconButton
              onClick={e => onEditClick(e, params.row)}
              size='small'
              color='primary'
              style={{
                justifyContent: 'left',
                marginRight: '1rem',
              }}
            >
              <EditIcon />
            </IconButton>
            {/* System-Admin and support agent role and user uploaded the file got access to void. */}
            <IconButton
              onClick={e => onDeleteClick(e, params.row)}
              style={{
                justifyContent: 'left',
                marginRight: '1rem',
                visibility:
                  String(params.row?.uploadedBy?.optionSeq)?.toLowerCase() ===
                    String(user?.userSeq)?.toLowerCase() || user?.roleCheck(['451', '8f7'])
                    ? 'visible'
                    : 'hidden',
              }}
              size='small'
              color='error'
            >
              <DeleteIcon />
            </IconButton>

            <Tooltip title={params.row?.notes ?? ''} arrow>
              <IconButton
                onClick={onInfoClick}
                size='small'
                style={{
                  justifyContent: 'left',
                  marginRight: '1rem',
                  visibility: params.row?.notes !== '' ? 'visible' : 'hidden',
                }}
                aria-describedby={showInfo ? 'simple-popover' : undefined}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <Popover
              id={showInfo ? 'simple-popover' : undefined}
              open={showInfo}
              anchorEl={anchorEl}
              onClose={onInfoClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ marginRight: '2rem' }}
              disableAutoFocus
              disableEnforceFocus
            >
              <div style={{ padding: '10px' }}>{params.row?.notes}</div>
            </Popover>
          </div>
        );
      },
    },
  ];

  const downloadFiles = async (selectedRows: any) => {
    const fileSeqs = [];
    if (!selectedRows || selectedRows.size === 0) {
      setShowSelectionAlert(true);
      return;
    }
    for (var row of selectedRows) {
      fileSeqs.push(Array.isArray(selectedRows) ? row['key'] : row[0]);
    }

    let formData = new FormData();
    formData.append('fileSeqsString', JSON.stringify(fileSeqs));

    setDownloading(true);

    try {
      const response = await fetch(`${VITE_API_URL}downloaduserfiles`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: formData,
      });

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      }

      if (response.status >= 400) {
        const errorText = await response.text();
        throw new Error(errorText || 'Download failed');
      }

      const blob = await response.blob();

      const isSingleFile = selectedRows.length === 1 || selectedRows.size === 1;

      let filename = 'UserFiles.zip';

      if (isSingleFile) {
        const disposition = response.headers.get('content-disposition');
        const filenameMatch = disposition?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        filename = filenameMatch ? filenameMatch[1].replace(/['"]/g, '') : 'file';
      }

      saveAs(blob, filename);
    } catch (error) {
      console.error('Download error:', error);
      alert(error || 'An unexpected error occurred');
    }

    setDownloading(false);
  };

  const CustomExportButton = (props: any) => {
    const apiRef = useGridApiContext();
    const { hideMenu } = props;

    return downloading ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TailSpin color='#00BFFF' height={20} width={20} />
        <i style={{ color: '#00BFFF', paddingLeft: 10 }}>
          I'm preparing the download for you, this may take a bit of time....
        </i>
      </div>
    ) : (
      <Button
        {...buttonBaseProps}
        onClick={() => {
          const selectedRows = apiRef.current.getSelectedRows();
          downloadFiles(selectedRows);
          hideMenu?.();
        }}
      >
        Download Selection
      </Button>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <CustomExportButton />
        <Button color='primary' startIcon={<AddIcon />} onClick={() => setIsOpen(true)}>
          Upload New File
        </Button>
      </GridToolbarContainer>
    );
  }

  const onEditClick = (e: MouseEvent, row: any) => {
    e.stopPropagation();
    setItemToEdit(row);
    setShowEditView(true);
  };

  const handleConfirmDelete = () => {
    if (!itemToDelete) return;
    setShowConfirmation(false);
    let formData = new FormData();
    formData.append('photoSeq', itemToDelete?.photoSeq ?? '');

    fetch(VITE_API_URL + 'VoidCertificate', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        fetchUserCertificates();
      })
      .catch(e => {
        alert(e);
      });
  };

  const handleRowClick = (params : any) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleRowDoubleClick(params);
    } else {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setClickTimeout(null);
      }, 250); // 250ms to differentiate between single and double click
      setClickTimeout(timer);
    }
  };

  const handleRowDoubleClick = (selectedRow: any) => {
    // Extract rows that are currently selected
    if (selectedRow) {
      downloadFiles([{ key: selectedRow.id, value: selectedRow.row }]);
    }
  };

  const handleEditSaveActionComplete = (status: string) => {
    setShowEditView(false);
    if (status === 'success') {
      setUserDocumnetSnackbar({
        open: true,
        severity: 'success',
        message: `file was updated successfully`,
      });
      fetchUserCertificates();
    } else {
      setUserDocumnetSnackbar({
        open: true,
        severity: 'error',
        message: 'An error occurred, unable to update the file',
      });
    }
  };

  const handleCloseUserDocumntSnackbar = () => {
    setUserDocumnetSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = () => {
        const bytes = new Uint8Array(reader.result as ArrayBuffer);
        const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
        const base64 = btoa(binary);
        resolve(base64);
      };

      reader.onerror = error => reject(error);
    });
  };

  const handleSaveFiles = async (files: File[], fileType?: Option) => {
    if (!files.length) return [];

    try {
      // Upload each file asynchronously
      const uploadPromises = files.map(async singleFile => {
        try {
          // Convert the file to base64
          const base64String = await convertFileToBase64(singleFile);

          const saveData = {
            assignmentTypeSeq: fileType?.optionSeq ?? guid,
            photo: base64String,
            userSeq: userSeq,
            logInUserSeq: user?.userSeq,
            caseSeq: '',
            fileName: singleFile.name,
            entityName: '',
            photoInfo: '',
            photoSeq: guid,
            uploadType: '',
            isResourceFile: '',
            isUsercertificate: 'true',
            recoverySeal: '',
            photoTypeSeq: fileType?.optionSeq ?? guid,
            certificateTypeSeq: fileType?.optionSeq ?? guid,
            photoLabel: '',
            resourceTypeSeq: '',
            photoMimeType: singleFile.type,
            filePath: '',
            isEsignature: '',
          };

          const saveEndpoint = 'SaveUserCertificate';

          const response = await axios.post(
            `${import.meta.env.VITE_API_URL}${saveEndpoint}`,
            saveData,
            { headers: authHeader() }
          );

          if (response?.data?.status === 0) {
            return { fileName: singleFile.name, success: true };
          } else {
            throw new Error(`Failed to upload ${singleFile.name}`);
          }
        } catch (uploadError) {
          console.error(`Error uploading file ${singleFile.name}:`, uploadError);
          return { fileName: singleFile.name, success: false, message: uploadError };
        }
      });

      // Wait for all uploads to complete
      const uploadResults = await Promise.all(uploadPromises);

      const allSuccessful = uploadResults.every(result => result.success);

      if (allSuccessful) {
        setIsOpen(false);
        fetchUserCertificates();
      } else {
        console.warn('Some uploads failed:', uploadResults);
      }

      return uploadResults;
    } catch (error) {
      console.error('Unexpected error during upload:', error);
      return files.map(file => ({
        fileName: file.name,
        success: false,
        message: 'Unexpected error during upload:', error,
      }));
    }
  };

  const fetchUserCertificateTypeOptions = () => {
    fetch(VITE_API_URL + 'getusercertificatetypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setFileTypeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchUserCertificates = async () => {
    setLoadingFiles(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}GetUserCertificates?userSeq=${userSeq}`,
        { headers: authHeader() }
      );

      if (response.data) {
        setUserFiles(response.data);
      } else {
        console.error('Invalid response structure:', response);
      }
    } catch (error) {
      console.error('Error fetching user certificates:', error);
    }
    setLoadingFiles(false);
  };

  useEffect(() => {
    fetchUserCertificateTypeOptions();
    fetchUserCertificates();
  }, []);

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Confirm Void File'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to void this file?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


  return (
    <>
      <Grid container spacing={2}>
        {showSelectionAlert && (
          <Grid item xs={12}>
            <Alert
              severity='warning'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => setShowSelectionAlert(false)}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              Please select at least one file to download.
            </Alert>
          </Grid>
        )}

        <Snackbar
          open={userDocumnetSnackbar.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ position: 'absolute', top: 5 }}
          autoHideDuration={5000}
          onClose={handleCloseUserDocumntSnackbar}
        >
          <Alert
            onClose={handleCloseUserDocumntSnackbar}
            severity={userDocumnetSnackbar.severity as AlertColor}
            variant='filled'
            sx={{ width: '100%' }}
          >
            <Typography variant='body1' fontWeight={500}>
              {userDocumnetSnackbar.message}
            </Typography>
          </Alert>
        </Snackbar>

        <Grid item xs={12}>
          <CondensedDataGrid
            columnsVisibility={{
              photoSeq: false,
            }}
            loading={loadingFiles}
            listresults={userFiles}
            columnsInput={columns}
            gridheight={userFiles?.length > 0 ? 'auto' : 200}
            idcolumn='photoSeq'
            rowsperpage={10}
            gridcolor='#4b21a6'
            tooltip='A list of all user related files'
            title=''
            checkboxSelection={true}
            toolbarComponent={CustomToolbar}
            onRowClick={(params : any) => handleRowClick(params)}
            disableRowSelectionOnClick={false}
          />
        </Grid>
      </Grid>
      {showEditView && (
        <Grid item xs={12}>
          <UserFileEditView
            itemToEdit={itemToEdit}
            fileTypeOptions={fileTypeOptions}
            onClose={() => setShowEditView(false)}
            onSaveActionComplete={handleEditSaveActionComplete}
          />
        </Grid>
      )}

      {showConfirmation && (
        <ConfirmationModal />
      )}

      <FileUploadDropzone
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fileTypeRequired={true}
        fileTypeOptions={fileTypeOptions}
        handleSaveFiles={handleSaveFiles}
      />
    </>
  );
};

export default UserFiles;
