import { Alert, Button, Card, CardContent, CardHeader, Collapse, Stack } from '@mui/material';
import { useState } from 'react';
//@ts-ignore
import MLDropzone from '../../utils/components/MLDropzone';

interface BulkFileUploadProps {
  isLab: boolean;
  useFastAPI: boolean;
}

export default function BulkFileUpload({ isLab = false }: BulkFileUploadProps) {
  const [open, setOpen] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleSuccessfulUpload = () => {
    setShowSuccessAlert(true);
  };

  return (
    <Card>
      <CardHeader
        title='Bulk Upload Case Documents'
        subheader='Upload multiple case documents to MedexLab at once'
      />
      <CardContent>
        <Stack spacing={2}>
          <Collapse in={showSuccessAlert}>
            <Alert severity='success' onClose={() => setShowSuccessAlert(false)}>
              All accepted files uploaded successfully!
            </Alert>
          </Collapse>

          <Button variant='contained' color='primary' onClick={() => setOpen(true)}>
            Upload Documents
          </Button>

          <MLDropzone
            isOpen={open}
            setIsOpen={setOpen}
            documentTypeRequired={true}
            onSuccessUpload={handleSuccessfulUpload}
            isLab={isLab}

            // DO NOT UNCOMMENT THE LINE BELOW UNTIL FASTAPI ENDPOINTS ARE WORKING CORRECTLY
            // AS OF 2/17/25 IT IS NOT DONE. WHOEVER UNCOMMENTS SHALL BEAR THE FULL CONSEQUENCES. YOU HAVE BEEN WARNED.
            // - William Wu
            // useFastAPI={true}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
