import { Add, Info, Warning } from '@mui/icons-material';
import ChatIcon from '@mui/icons-material/Chat';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  DataGridPremium,
  GridColDef,
  GridToolbarContainer,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';

import {
  CreateAccount_DefaultBlankNewUser,
  createAccount,
} from '../../../utils/functions/createAccount';
import { UserProfile } from '../../DVCS/CallCenterUserDialog';
import { AccountRequestViewModel } from '../../Support/AccountRequestView';
import { NewAccountRequestDialog } from '../../Support/NewAccountRequestDialog';
import { useConversation } from '../../SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';

// Import dayjs with UTC plugin
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ConversationUser } from '../../SysAdmin/SysAdminModules/UserFeedback/Conversations/Dialogs/NewConversationDialog';
import { ConversationTypes } from '../../SysAdmin/SysAdminModules/UserFeedback/Conversations/conversation.types';

// Enable the UTC plugin
dayjs.extend(utc);

export function YourAccountRequestView() {
  const apiRef = useGridApiRef();
  const { sendMessage, createChat } = useConversation();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [requests, setRequests] = useState<AccountRequestViewModel[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDenyDialog, setOpenDenyDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<AccountRequestViewModel | null>(null);
  const [suggestedUsername, setSuggestedUsername] = useState<string | null>(null);
  const [newUserData, setNewUserData] = useState<UserProfile>(CreateAccount_DefaultBlankNewUser);
  const [columnVisibility, setColumnVisibility] = useState({
    action: true,
  });

  const [newAccountFormOpen, setNewAccountFormOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'info' | 'warning'
  >('info');

  const [deactivationFormOpen, setDeactivationFormOpen] = useState(false);

  const generateUsername = (fullName: string) => {
    const nameParts = fullName.split(' ');
    if (nameParts.length >= 2) {
      const lastName = nameParts[nameParts.length - 1];
      const firstInitial = nameParts[0][0];
      return `${lastName}${firstInitial}`.toLowerCase();
    }
    return '';
  };

  const handleView = (request: AccountRequestViewModel) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
  };

  const handleCloseDeny = () => {
    setOpenDenyDialog(false);
    setSelectedRequest(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAccountFormClose = (status?: { success: boolean; message: string }) => {
    if (status) {
      setSnackbarMessage(status.message);
      setSnackbarSeverity(status.success ? 'success' : 'error');
      setSnackbarOpen(true);

      // Refresh the requests list if successful
      if (status.success) {
        fetchRequests();
      }
    }
    setNewAccountFormOpen(false);
  };

  const handleDeactivationFormClose = (status?: { success: boolean; message: string }) => {
    if (status) {
      setSnackbarMessage(status.message);
      setSnackbarSeverity(status.success ? 'success' : 'error');
      setSnackbarOpen(true);

      // Refresh the requests list if successful
      if (status.success) {
        fetchRequests();
      }
    }
    setDeactivationFormOpen(false);
  };

  const fetchRequests = async () => {
    setLoading(true);
    setError(null);

    try {
      // Using the new endpoint with the current user's userSeq
      const response = await fetch(
        `${import.meta.env.VITE_FASTAPI_URL}account-requests/your-account-requests?userSeq=${
          user?.userSeq
        }`,
        {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching account requests: ${response.statusText}`);
      }

      const data: AccountRequestViewModel[] = await response.json();
      setRequests(data);
    } catch (err: any) {
      console.error(err);
      setError(err.message || 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.userSeq) {
      fetchRequests();
    }
  }, [user]);

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 90,
      renderCell: params => (
        <>
          {params.value === 'PENDING' && (
            <Chip label='PENDING' variant='filled' color='warning' size='small' />
          )}
          {params.value === 'DENIED' && (
            <Chip label='DENIED' variant='outlined' color='error' size='small' />
          )}
          {params.value === 'APPROVED' && (
            <Chip label='APPROVED' variant='outlined' color='success' size='small' />
          )}
        </>
      ),
    },
    {
      field: 'requestedOn',
      headerName: 'Requested On',
      flex: 1,
      minWidth: 150,
      renderCell: params => {
        if (!params.value) return '-';
        // Format the date using dayjs with UTC conversion to local time
        return dayjs.utc(params.value).local().format('MMM D, YYYY, h:mm A');
      },
    },
    {
      field: 'requestedBy',
      headerName: 'Requested By',
      flex: 1,
      minWidth: 180,
      renderCell: params => params.value || '-',
    },
    {
      field: 'type',
      headerName: 'Request Type',
      flex: 1,
      minWidth: 90,
      renderCell: params => params.value || '-',
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      flex: 1,
      minWidth: 180,
      renderCell: params => params.value || '-',
    },
    {
      field: 'employeeEmail',
      headerName: 'Employee Email',
      flex: 1,
      minWidth: 220,
      renderCell: params => params.value || '-',
    },
    {
      field: 'roles',
      headerName: 'Roles',
      flex: 1,
      minWidth: 200,
      renderCell: params => {
        const roles = params.value;
        if (!Array.isArray(roles)) return '-';
        const filteredRoles = roles.filter(
          r => r.optionSeq !== 'A62EB010-88D5-440A-BE2C-E94BCEC73502'
        );
        return (
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
            }}
          >
            {filteredRoles
              .filter(role => role.optionName !== 'MEDEXLAB-ACCESS')
              .map((role, index) => (
                <Chip
                  key={index}
                  label={role.optionName}
                  sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                      display: 'flex',
                      alignItems: 'center',
                      whiteSpace: 'normal',
                    },
                  }}
                />
              ))}
          </Box>
        );
      },
    },
    {
      field: 'jurisdictions',
      headerName: 'Jurisdictions',
      flex: 1,
      minWidth: 200,
      renderCell: params =>
        params.value && Array.isArray(params.value)
          ? params.value.map(jdx => jdx.jdxName).join(', ')
          : '-',
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 200,
      renderCell: params => (
        <Stack
          spacing={1}
          direction='row'
          sx={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={e => {
              e.stopPropagation(); // Prevent the row click from being triggered
              handleView(params.row);
            }}
          >
            View
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      {error && (
        <Alert severity='error' sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={3}>
        <DataGridPremium
          apiRef={apiRef}
          rows={requests}
          columns={columns}
          columnVisibilityModel={columnVisibility}
          loading={loading}
          getRowId={row => row.accountRequestSeq}
          density='compact'
          autoHeight
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 100}
          slots={{
            toolbar: () => (
              <CustomGridToolbar
                onNewRequest={() => setNewAccountFormOpen(true)}
                onDeactivationRequest={() => setDeactivationFormOpen(true)}
              />
            ),
          }}
          onRowClick={params => {
            handleView(params.row);
          }}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              // Make row background the paper color:
              backgroundColor: theme => theme.palette.background.paper,
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal !important',
              wordWrap: 'break-word !important',
              lineHeight: '1.43 !important',
              padding: '8px !important',
            },
            // If you previously forced a Paper background for the headers, remove it:
            // so that the header & toolbar remain the default color.
            '& .super-app-theme--header': {
              // You can remove background overrides or explicitly set them back to default:
              backgroundColor: 'inherit',
            },
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    field: 'status',
                    operator: 'equals',
                    value: 'PENDING',
                  },
                ],
              },
            },
          }}
        />
      </Paper>

      <ViewRequestDialog
        open={openDialog}
        onClose={handleClose}
        selectedRequest={selectedRequest}
        suggestedUsername={suggestedUsername}
      />

      {/* New Account Request Dialog */}
      <NewAccountRequestDialog open={newAccountFormOpen} onClose={handleAccountFormClose} />

      {/* Deactivation Request Dialog */}
      <DeactivationRequestDialog
        open={deactivationFormOpen}
        onClose={handleDeactivationFormClose}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

interface CustomGridToolbarProps {
  onNewRequest: () => void;
  onDeactivationRequest: () => void;
}

const CustomGridToolbar = ({ onNewRequest, onDeactivationRequest }: CustomGridToolbarProps) => {
  const apiRef = useGridApiContext();

  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: string | null) => {
    if (newStatus !== null) {
      if (newStatus === 'ALL') {
        apiRef.current.setFilterModel({
          items: [],
        });
      } else {
        apiRef.current.setFilterModel({
          items: [
            {
              field: 'status',
              operator: 'equals',
              value: newStatus,
            },
          ],
        });
      }
    }
  };

  const getCurrentStatus = () => {
    const filterModel = apiRef.current.state.filter;
    if (!filterModel.filterModel.items || filterModel.filterModel.items.length === 0) {
      return 'ALL';
    }
    const statusFilter = filterModel.filterModel.items.find(item => item.field === 'status');
    return statusFilter ? statusFilter.value : 'ALL';
  };

  return (
    <GridToolbarContainer
      sx={{
        backgroundColor: theme => theme.palette.background.default,
      }}
    >
      {' '}
      <Box
        component='div'
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
        }}
      >
        <Box component='div' sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <ToggleButtonGroup
            value={getCurrentStatus()}
            exclusive
            onChange={handleStatusChange}
            size='small'
            aria-label='status filter'
          >
            <ToggleButton value='PENDING'>PENDING</ToggleButton>
            <ToggleButton value='APPROVED'>APPROVED</ToggleButton>
            <ToggleButton value='DENIED'>DENIED</ToggleButton>
            <ToggleButton value='ALL'>ALL</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Stack direction='row' spacing={1}>
          {/* Add New Request Button */}
          <Button
            variant='contained'
            color='primary'
            startIcon={<Add />}
            onClick={onNewRequest}
            size='small'
          >
            New Account Request
          </Button>
          <Button
            variant='contained'
            color='error'
            startIcon={<Add />}
            onClick={onDeactivationRequest}
            size='small'
          >
            Deactivation Request
          </Button>
        </Stack>
      </Box>
    </GridToolbarContainer>
  );
};

interface ViewRequestDialogProps {
  open: boolean;
  onClose: () => void;
  selectedRequest: AccountRequestViewModel | null;
  suggestedUsername: string | null;
}

const ViewRequestDialog = ({
  open,
  onClose,
  selectedRequest,
  suggestedUsername,
}: ViewRequestDialogProps) => {
  if (!selectedRequest) return null;

  const { user } = useAuth();
  const { handleSelectChat, chats, setDialogOpen } = useConversation();

  // Determine if this is a deactivation request
  const isDeactivationRequest = selectedRequest.type === 'Account deactivation';

  // State for user details (for deactivation requests)
  const [targetUserDetails, setTargetUserDetails] = useState<any>(null);

  // Fetch user details for deactivation requests
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!isDeactivationRequest || !selectedRequest.existingEmployeeUserSeq || !user?.accessToken)
        return;

      try {
        const response = await fetch(
          `${import.meta.env.VITE_FASTAPI_URL}account-requests/employee-details?userSeq=${
            selectedRequest.existingEmployeeUserSeq
          }`,
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          }
        );

        if (response.ok) {
          const userData = await response.json();
          setTargetUserDetails(userData);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [isDeactivationRequest, selectedRequest.existingEmployeeUserSeq, user?.accessToken]);

  const onSendChatClick = (conversationSeq: string | null) => {
    if (!conversationSeq) return;

    const chatRecord =
      chats?.find(c => c.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()) || false;
    if (!chatRecord) return;

    handleSelectChat(chatRecord);
    setDialogOpen(true);
  };

  interface InfoSectionProps {
    title: string;
    content: React.ReactNode;
  }

  const InfoSection = ({ title, content }: InfoSectionProps) => (
    <Box component='div' sx={{ py: 1 }}>
      <Typography variant='subtitle2' color='text.secondary' sx={{ mb: 0.5 }}>
        {title}
      </Typography>
      <Typography variant='body1'>{content}</Typography>
    </Box>
  );

  // Deactivation request view
  if (isDeactivationRequest) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='md'
        fullWidth
        disableEscapeKeyDown
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Box component='div'>
              <Typography variant='h6' gutterBottom={false}>
                Deactivation Request
              </Typography>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
                <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                  Request Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InfoSection title='Requested By' content={selectedRequest.requestedBy} />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoSection
                      title='Request Date'
                      content={
                        selectedRequest.requestedOn
                          ? dayjs
                              .utc(selectedRequest.requestedOn)
                              .local()
                              .format('MMM D, YYYY, h:mm A')
                          : '-'
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoSection
                      title='Conversation'
                      content={
                        selectedRequest.conversationSeq ? (
                          <Button
                            variant='outlined'
                            size='small'
                            onClick={() => onSendChatClick(selectedRequest.conversationSeq)}
                            startIcon={<ChatIcon />}
                          >
                            View Conversation
                          </Button>
                        ) : (
                          'No conversation linked'
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* User details section */}
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 2 }}>
                <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                  User to Deactivate
                </Typography>

                {targetUserDetails ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <InfoSection
                        title='Name'
                        content={`${targetUserDetails.firstName} ${targetUserDetails.lastName}`.trim()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InfoSection title='User ID' content={targetUserDetails.username} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InfoSection title='Email' content={targetUserDetails.email} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InfoSection title='Phone' content={targetUserDetails.phoneNumber || 'N/A'} />
                    </Grid>

                    {targetUserDetails.lastLoginDateTime && (
                      <Grid item xs={12} md={6}>
                        <InfoSection
                          title='Last Login'
                          content={dayjs
                            .utc(targetUserDetails.lastLoginDateTime)
                            .local()
                            .format('MMM D, YYYY, h:mm A')}
                        />
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Typography variant='body2'>Loading user details...</Typography>
                )}
              </Paper>
            </Grid>

            {/* Reason section */}
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 2 }}>
                <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                  Reason for Deactivation
                </Typography>
                <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
                  {selectedRequest.notes || 'No reason provided'}
                </Typography>
              </Paper>
            </Grid>

            {selectedRequest?.status === 'PENDING' && (
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ p: 2 }}>
                  <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                    Status
                  </Typography>
                  <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
                    <>
                      <Chip label='PENDING' variant='filled' color='warning' size='small' />
                    </>
                  </Typography>
                </Paper>
              </Grid>
            )}
            {selectedRequest?.status === 'APPROVED' && (
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ p: 2 }}>
                  <Stack spacing={1}>
                    <Stack direction='row' spacing={1} justifyContent='start' alignContent='start'>
                      <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                        Status:
                      </Typography>
                      <Chip label='APPROVED' variant='filled' color='success' size='small' />
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            )}

            {selectedRequest?.status === 'DENIED' && (
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ p: 2 }}>
                  <Stack spacing={1}>
                    <Stack direction='row' spacing={1} justifyContent='start' alignContent='start'>
                      <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                        Status:
                      </Typography>
                      <Chip label='DENIED' variant='filled' color='error' size='small' />
                    </Stack>
                    <Stack direction='row' spacing={1} justifyContent='start' alignItems='center'>
                      <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                        Reason:
                      </Typography>
                      <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
                        {selectedRequest?.denialReason}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  // New account request view
  const { employee, address, roles, jurisdictions, requestedBy, requestedOn } = selectedRequest;

  interface PermissionWithTooltipProps {
    role: {
      optionSeq: string;
      optionName: string;
      isPermission: boolean;
      description?: string;
    };
  }

  const PermissionWithTooltip = ({ role }: PermissionWithTooltipProps) => {
    return (
      <Tooltip
        arrow
        title={
          <Box component='div'>
            <Typography fontWeight='bold'>{role.optionName}</Typography>
            <Typography>{role.description}</Typography>
          </Box>
        }
      >
        <li>{role.optionName}</li>
      </Tooltip>
    );
  };

  // Username input for new accounts
  const [username, setUsername] = useState(suggestedUsername || '');
  const [usernameError, setUsernameError] = useState('');
  const [checkingUsername, setCheckingUsername] = useState(false);

  // Check username availability
  const checkUsernameAvailability = async (username: string) => {
    if (!username || !user?.accessToken) return;

    setCheckingUsername(true);
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_FASTAPI_URL
        }account-requests/check-userid-availability?userid=${username}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (!data.is_available) {
          setUsernameError('This username is already taken');
        } else {
          setUsernameError('');
        }
      }
    } catch (error) {
      console.error('Error checking username:', error);
    } finally {
      setCheckingUsername(false);
    }
  };

  // Debounce username check
  useEffect(() => {
    const timer = setTimeout(() => {
      if (username) {
        checkUsernameAvailability(username);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [username]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      disableEscapeKeyDown
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box component='div'>
            <Typography variant='h6' gutterBottom={false}>
              View New Account Request
            </Typography>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Requestor Details
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InfoSection title='Requested By' content={requestedBy} />
                </Grid>
                <Grid item xs={4}>
                  <InfoSection
                    title='Contact'
                    content={
                      <Stack spacing={0}>
                        <Typography variant='body1'>{employee.email}</Typography>
                        <Typography variant='body1'>{employee.phoneNumber}</Typography>
                        {selectedRequest.conversationSeq && (
                          <Button
                            variant='outlined'
                            sx={{ mt: 1 }}
                            onClick={() => onSendChatClick(selectedRequest.conversationSeq)}
                            startIcon={<ChatIcon />}
                          >
                            Send Message
                          </Button>
                        )}
                      </Stack>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoSection
                    title='Requested On'
                    content={
                      requestedOn
                        ? dayjs.utc(requestedOn).local().format('MMM D, YYYY, h:mm A')
                        : '-'
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Employee Details Section */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Employee Details
              </Typography>

              <InfoSection
                title='Full Name'
                content={`${employee.firstName} ${employee.middleName || ''} ${
                  employee.lastName
                }`.trim()}
              />
              <InfoSection
                title='Contact'
                content={
                  <>
                    {employee.email}
                    <br />
                    {employee.phoneNumber}
                  </>
                }
              />
              {employee.supervisor && (
                <InfoSection
                  title='Supervisor'
                  content={
                    <>
                      {`${employee.supervisor.firstName} ${employee.supervisor.lastName} (${employee.supervisor.userName})`}
                    </>
                  }
                />
              )}
            </Paper>
          </Grid>

          {/* Address Section */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Address Information
              </Typography>
              {address.addressType && (
                <InfoSection title='Type' content={address.addressType.optionName} />
              )}
              <InfoSection
                title='Address'
                content={
                  <>
                    {address.addressLine1}
                    {address.addressLine2 && (
                      <>
                        <br />
                        {address.addressLine2}
                      </>
                    )}
                    <br />
                    {`${address.addressCity}, ${address.addressZip}`.trim()}
                  </>
                }
              />
            </Paper>
          </Grid>

          {/* Access Details Section */}
          <Grid item xs={12}>
            <Paper variant='outlined' sx={{ p: 2 }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Access Details
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={12} md={4}>
                  <InfoSection
                    title='Roles'
                    content={
                      <ul>
                        {roles
                          .filter(r => r.optionName !== 'MEDEXLAB-ACCESS')
                          .filter(r => !r.isPermission)
                          .map((role, index) => (
                            <li key={role.optionSeq || index}>{role.optionName}</li>
                          ))}
                      </ul>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoSection
                    title='Permissions'
                    content={
                      <ul>
                        {roles
                          .filter(r => r.optionName !== 'MEDEXLAB-ACCESS')
                          .filter(r => r.isPermission)
                          .map((role, index) => (
                            <PermissionWithTooltip key={role.optionSeq || index} role={role} />
                          ))}
                      </ul>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoSection
                    title='Jurisdictions'
                    content={jurisdictions.map(j => j.jdxName).join(', ')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InfoSection
                    title='Notes from requestor'
                    content={selectedRequest?.notes || '-'}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Alert icon={<Info />} severity='info' sx={{ mt: 2 }}>
          Once approved, the user will receive an email containing login instructions.
        </Alert>
      </DialogContent>
    </Dialog>
  );
};

interface DeactivationRequestDialogProps {
  open: boolean;
  onClose: (status?: { success: boolean; message: string }) => void;
}
export const DeactivationRequestDialog = ({ open, onClose }: DeactivationRequestDialogProps) => {
  const { user } = useAuth();
  const { createChat, availableUsers } = useConversation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<ConversationUser | null>(null);
  const [reason, setReason] = useState('');
  const [userDetails, setUserDetails] = useState<any>(null);

  // Fetch user details when a user is selected
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!selectedUser || !user?.accessToken) {
        setUserDetails(null);
        return;
      }

      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}user/${selectedUser.userSeq}`,
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          }
        );

        if (response.ok) {
          const userData = await response.json();
          setUserDetails(userData);
        } else {
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [selectedUser, user?.accessToken]);

  // Submit deactivation request
  const handleSubmit = async () => {
    if (!selectedUser) {
      setError('Please select a user to deactivate');
      return;
    }

    if (!reason.trim()) {
      setError('Please provide a reason for deactivation');
      return;
    }

    if (!user?.userSeq || !user?.accessToken) {
      setError('User authentication is required');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Submit the deactivation request
      const response = await fetch(
        `${import.meta.env.VITE_FASTAPI_URL}account-requests/deactivation`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            requestedByUserSeq: user.userSeq,
            targetUserSeq: selectedUser.userSeq,
            reason: reason,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error submitting deactivation request: ${response.statusText}`);
      }

      const deactivationResponse = await response.json();

      // Create a conversation for this deactivation request
      const newChat = await createChat({
        topic: `Deactivation request: ${selectedUser.personFirstName} ${selectedUser.personLastName}`,
        messages: [
          {
            messageContent: `
              <strong>Deactivation Request</strong><br/>
              <table>
                <tr><td><strong>User:</strong></td><td>${selectedUser.personFirstName} ${
              selectedUser.personLastName
            } (${selectedUser.userName})</td></tr>
                <tr><td><strong>Reason:</strong></td><td>${reason}</td></tr>
                <tr><td><strong>Requested by:</strong></td><td>${user.userFirstName} ${
              user.userLastName
            }</td></tr>
                <tr><td><strong>Date:</strong></td><td>${new Date().toLocaleString()}</td></tr>
              </table>
            `,
            sender: { userSeq: user.userSeq },
            readBy: [{ userSeq: user.userSeq }],
            attachments: [],
          },
        ],
        creator: { userSeq: user.userSeq },
        type: { conversationTypeSeq: ConversationTypes.New_Account_Request }, // Reuse the existing type
        isOpen: true,
        isActive: true,
        participants: [{ userSeq: user.userSeq }],
      });

      // Link the conversation to the account request
      const linked_response = await fetch(
        `${import.meta.env.VITE_FASTAPI_URL}account-requests/link-to-conversation`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            accountRequestSeq: deactivationResponse.accountRequestSeq,
            conversationSeq: newChat.conversationSeq,
          }),
        }
      );

      if (!linked_response.ok) {
        console.warn('Failed to link conversation to deactivation request');
      }

      onClose({ success: true, message: 'Deactivation request submitted successfully' });
    } catch (err: any) {
      console.error(err);
      setError(err.message || 'Error submitting deactivation request');
    } finally {
      setLoading(false);
    }
  };

  // Reset form on close
  const handleClose = () => {
    setSelectedUser(null);
    setReason('');
    setError(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth disableEscapeKeyDown>
      <DialogTitle>
        <Typography variant='h6'>Request Account Deactivation</Typography>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity='error' sx={{ mb: 2, mt: 1 }}>
            {error}
          </Alert>
        )}

        <Autocomplete
          options={availableUsers.filter(
            u => u.userSeq !== user?.userSeq && !u.userName.includes('Support')
          )}
          value={selectedUser}
          onChange={(_, newValue) => {
            setError(null);
            setSelectedUser(newValue);
          }}
          getOptionLabel={(option: ConversationUser) =>
            `${option.personFirstName} ${option.personLastName} (${option.userName})`
          }
          isOptionEqualToValue={(option: ConversationUser, value: ConversationUser) =>
            option.userSeq?.toLowerCase() === value.userSeq?.toLowerCase()
          }
          renderInput={params => (
            <TextField
              {...params}
              required
              label='User to deactivate'
              variant='outlined'
              error={!!error && !selectedUser}
              helperText={!selectedUser && error ? 'Please select a user' : ''}
              fullWidth
              autoFocus
              sx={{ mt: 1 }}
            />
          )}
        />

        {/* Selected user details */}
        {selectedUser && (
          <Box component='div' sx={{ mt: 2 }}>
            <Typography variant='subtitle2' gutterBottom>
              User details
            </Typography>
            <Paper variant='outlined' sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant='body2' color='text.secondary'>
                    Full Name
                  </Typography>
                  <Typography variant='body1'>
                    {`${selectedUser.personFirstName} ${selectedUser.personLastName}`.trim()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body2' color='text.secondary'>
                    User ID
                  </Typography>
                  <Typography variant='body1'>{selectedUser.userName}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}

        <Typography variant='subtitle1' fontWeight='bold' gutterBottom sx={{ mt: 2 }}>
          Reason for Deactivation
        </Typography>
        <TextField
          label='Reason for Deactivation'
          value={reason}
          onChange={e => setReason(e.target.value)}
          fullWidth
          multiline
          rows={4}
          required
          error={!!error && !reason.trim()}
          helperText={!reason.trim() && error ? 'Please provide a reason' : ''}
          placeholder='Please provide a detailed reason for the deactivation request'
        />

        <Alert severity='warning' sx={{ mt: 2 }}>
          <AlertTitle>Important Note</AlertTitle>
          This request will be reviewed by an administrator before any action is taken. The user
          account will remain active until the request is approved.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='inherit'>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          disabled={!selectedUser || !reason.trim() || loading}
          startIcon={loading && <CircularProgress size={20} color='inherit' />}
        >
          Submit Request
        </Button>
      </DialogActions>
    </Dialog>
  );
};
