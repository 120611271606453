import { Box, Button, FormGroup, FormControlLabel, Checkbox, Card, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { UserAccount, Jurisdiction } from './UserAccount.type';

interface UserAccountJurisdictionsProps {
  jurisdictionOptions: Jurisdiction[];
}

const UserAccountJurisdictions: React.FC<UserAccountJurisdictionsProps> = ({
  jurisdictionOptions,
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<{
    jurisdictions: Jurisdiction[];
  }>();

  const handleCheckboxChange = (selectedJdx: Jurisdiction) => {
    const isSelected = values.jurisdictions.some(
      jdx => jdx.jdxSeq?.toLowerCase() === selectedJdx.jdxSeq?.toLowerCase()
    );

    setFieldValue(
      'jurisdictions',
      isSelected
        ? values.jurisdictions.filter(
            jdx => jdx.jdxSeq?.toLowerCase() !== selectedJdx.jdxSeq?.toLowerCase()
          )
        : [...values.jurisdictions, selectedJdx]
    );
  };

  const handleSelectAll = () => {
    setFieldValue(
      'jurisdictions',
      values.jurisdictions.length === jurisdictionOptions.length ? [] : [...jurisdictionOptions]
    );
  };

  return (
    <Box component={'div'}>
      <Card
        sx={{
          p: '16px',
        }}
      >
        <Button variant='contained' onClick={handleSelectAll} sx={{ mb: 1 }}>
          {values.jurisdictions.length === jurisdictionOptions.length
            ? 'Deselect All'
            : 'Select All'}
        </Button>
        <Grid container>
          {jurisdictionOptions.map((jdx, index) => (
            <Grid item xs={12} lg={3} key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.jurisdictions.some(
                      selectedJdx => selectedJdx.jdxSeq?.toLowerCase() === jdx.jdxSeq?.toLowerCase()
                    )}
                    onChange={() => handleCheckboxChange(jdx)}
                  />
                }
                label={jdx.jdxName}
              />
            </Grid>
          ))}
        </Grid>
      </Card>
    </Box>
  );
};

export default UserAccountJurisdictions;
