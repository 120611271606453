import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Button,
  useMediaQuery,
  useTheme,
  Box,
  Alert,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import UserAccountMain from './UserAccountMain';
import UserAccountJurisdictions from './UserAccountJurisdictions';
import UserAccountRoles from './UserAccountRoles';
import LoginHistory from './LoginHistory';
import UserFiles from './UserFiles';
import Notes from './Notes';
import { UserAccount, Jurisdiction, Role } from './UserAccount.type';
import { sortOptionsAlphabetically } from '../../../utils/functions/sortOptionsAlphabetically';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import { useOptionsAPI } from '../../../utils/api/useOptions.hook';
import { formatPhoneNumber } from '../../../utils/functions/formatPhoneNumber';
import { getAllJurisdiction } from '../../../services/utility-api';
import { LoadingButton } from '@mui/lab';

interface UserAccountDialogProps {
  open: boolean;
  onClose: () => void;
  selectedUser: UserAccount | null;
  onSaveActionComplete?: (status: 'success' | 'failure') => void;
}

interface RoleOption extends Option {
  optionCode: string;
  isPermission: boolean;
  isVisibleWhenRequestingNewAccount: boolean;
  description: string;
}

const UserAccountDialog: React.FC<UserAccountDialogProps> = ({
  open,
  onClose,
  selectedUser,
  onSaveActionComplete,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [jurisdictionOptions, setJurisdictionOptions] = useState<Jurisdiction[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<Option[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<Option[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const { user } = useAuth();
  const hasSystemAdminRole = user?.roleCheck(['451']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const { VITE_API_URL } = import.meta.env;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessages, setAlertMessages] = useState<string[]>([]);

  const { options: unfilteredRoleOptions } = useOptionsAPI<RoleOption>('getroleoptions');
  const roleOptions: Role[] = unfilteredRoleOptions
  ?.filter(r => {
    if (!r.isActive) return false;

    // If NOT SysAdmin, exclude these roles (sysadmin, secadmin, lmsadmin, support agent, mro review)
    if (!hasSystemAdminRole && (r.optionCode === '451' || r.optionCode === 'SEC' || r.optionCode === '055' || r.optionCode === '8f7' || r.optionCode === 'MRO')) {
      return false;
    }

    return true;
  })
  ?.map(role => ({
    roleSeq: role.optionSeq ?? '',
    roleName: role.optionName ?? '',
    roleCode: role.optionCode ?? '',
    isPermission: role.isPermission
  })) || [];

  const initialValues = {
    ...selectedUser,
    organization: selectedUser?.['organization'] ?? null,
    department: selectedUser?.['department'] ?? null,
    address: selectedUser?.['address'] ?? [],
    contactItem: selectedUser?.['contactItem'] ?? [],
  };

  const validationSchema = Yup.object({
    personFirstName: Yup.string().required('First Name is required'),
    personLastName: Yup.string().required('Last Name is required'),
    initials: Yup.string().required('Initals are required'),
    userName: Yup.string().required('User ID is required'),
    primaryEmail: Yup.string().email('Invalid email format').required('Email is required'),
    primaryMobileNo: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', 'Please enter a valid phone number', value => {
        try {
          formatPhoneNumber(value ?? '');
          return true;
        } catch (error) {
          return false;
        }
      }),
    organization: Yup.object().nullable().required('Organization is required'),
    department: Yup.object().nullable().required('Department is required'),
    address: Yup.array()
      .of(
        Yup.object().shape({
          addressLine1: Yup.string().required('Address line 1 is required'),
          city: Yup.string().required('City is required'),
        })
      )
      .min(1, 'Address is a required field'),
    // contactItem: Yup.array()
    // .of(
    //   Yup.object().shape({
    //     contactItemTypeSeq: Yup.string().required('Contact type is required'),
    //     contactItemDetails: Yup.string().required('Contact Details is required'),
    //   })
    // )
    // .min(1, 'Contact is a required field'),
  });

  const handleValidateAndSubmit = async (validateForm: any, handleSubmit: any) => {
    const errors = await validateForm();

    if (Object.keys(errors).length > 0) {
      const errorMsgs = Object.values(errors).flatMap(error => {
        if (typeof error === 'string') return [error];
        if (typeof error === 'object' && error !== null) {
          return Object.values(error).flat();
        }
        return [];
      });

      setAlertMessages(errorMsgs);
      setAlertOpen(true);
    } else {
      handleSubmit(); 
    }
  };

  const handleSaveChanges = async (values: UserAccount) => {
    let formData = new FormData();
    formData.append('user', JSON.stringify(values));
    // formData.append('loginUserSeq', user?.userSeq);
    setLoadingSubmit(true);
    if (user && user.token) {
      fetch(VITE_API_URL + 'user/saveuser', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          onSaveActionComplete?.('success');
        })
        .catch(e => {
          console.error(e);
          onSaveActionComplete?.('failure');
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    } else {
      console.log('user or token is null');
    }
  };

  const fetchJursidictionOptions = async () => {
    try {
      if (hasSystemAdminRole) {
        const response = await getAllJurisdiction();
        const transformedResponse = response?.map((jurisdiction: any) => ({
          jdxSeq: jurisdiction.optionSeq,
          jdxName: jurisdiction.optionName,
        }));
        setJurisdictionOptions(sortOptionsAlphabetically(transformedResponse ?? [], 'jdxName'));
      } else {
        setJurisdictionOptions(sortOptionsAlphabetically(user?.jdxAccessList ?? [], 'jdxName'));
      }
    } catch (error) {
      console.error('Error fetching jurisdiction options:', error);
      setJurisdictionOptions([]);
    }
  };

  const fetchOrganizationOptions = () => {
    fetch(VITE_API_URL + 'getorganizationoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setOrganizationOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchDepartmentOptions = () => {
    fetch(VITE_API_URL + 'getdepartmentoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setDepartmentOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    fetchJursidictionOptions();
    fetchOrganizationOptions();
    fetchDepartmentOptions();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      PaperProps={{
        style: {
          minWidth: isMobile ? '95%' : isMobile ? '80%' : '60%',
          margin: '0 auto',
          minHeight: '400px', // Stabilized height
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSaveChanges}
        enableReinitialize 
      >
        {({ values, handleSubmit, setFieldValue, errors, handleChange, validateForm }) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle>
              {values?.personFirstName} {values?.personLastName}
            </DialogTitle>
            <DialogContent>
              {alertOpen && (
                <Alert severity='error' onClose={() => setAlertOpen(false)} sx={{ width: '100%' }}>
                  {alertMessages.map((msg, index) => (
                    <div key={index}>
                    <strong>{index + 1}.</strong> {msg}
                  </div>
                  ))}
                </Alert>
              )}
              
              <Tabs value={activeTab}  onChange={(e, newValue) => setActiveTab(newValue)} centered >
                <Tab label='Main' />
                <Tab label='Jurisdictions' />
                <Tab label='Roles' />
                <Tab label='Login History' />
                <Tab label='Files' />
                <Tab label='Notes' />
              </Tabs>

              <Box component={'div'} sx={{ mt: 3, pointerEvents: !values.isActive ? 'none' : 'auto' }}>
                {activeTab === 0 && (
                  <UserAccountMain
                    organizationOptions={organizationOptions}
                    departmentOptions={departmentOptions}
                  />
                )}
                {activeTab === 1 && (
                  <UserAccountJurisdictions jurisdictionOptions={jurisdictionOptions} />
                )}
                {activeTab === 2 && <UserAccountRoles roleOptions={roleOptions} />}
                {activeTab === 3 && <LoginHistory userSeq={values?.userSeq ?? ''} />}
                {activeTab === 4 && <UserFiles userSeq={values?.userSeq} />}
                {activeTab === 5 && <Notes userSeq={values?.userSeq} />}
              </Box>
            </DialogContent>
            <DialogActions sx={{
                justifyContent: 'flex-end',
                p: 2, 
              }}>
              <LoadingButton
                loading={loadingSubmit}
                hidden={activeTab === 3 || activeTab === 4 || activeTab === 5}
                color='primary'
                variant='contained'
                onClick={() => handleValidateAndSubmit(validateForm, handleSubmit)}
              >
                Save
              </LoadingButton>
              <Button onClick={onClose} color='secondary'>
                Close
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserAccountDialog;
