import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid-premium';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAuth } from '../../../utils/auth/AuthService';
import DialogCrudDataGrid, {
  getDateValue,
  handleAutocompleteChange,
  handleCheckboxChange,
  handleDateChange,
  handleTextFieldChange,
} from '../../../utils/components/DialogCrudDataGrid';
import { ActionList } from '../CaseViewDateTimeField';

export default function DNAProfileDataGrid() {
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext<CMSCase>();

  const dnaProfiles = formik.values?.caseLabTesting?.dnaProfiles ?? [];

  const getDNAProfiles = async () => {
    try {
      const caseSeq = formik.values?.caseSummary?.caseSeq;
      const response = await fetch(`${VITE_API_URL}getdnaprofiles?caseSeq=${caseSeq}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user?.token,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else if (response.status === 204) {
        return [];
      }
      const data = await response.json();
      formik.setFieldValue('caseLabTesting.dnaProfiles', data ?? []);
    } catch (error) {
      console.error('There was a problem fetching DNA profile data: ', error);
    }
  };

  const CreateDNAProfile = async (newRow: any) => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user?.userSeq || '');
    await fetch(VITE_API_URL + 'creatednaprofile', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getDNAProfiles();
  };

  const UpdateDNAProfile = async (updatedRow: any) => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user?.userSeq || '');

    await fetch(VITE_API_URL + 'updatednaprofile', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getDNAProfiles();
  };

  const DeleteDNAProfile = async (id: any) => {
    await fetch(VITE_API_URL + `deletednaprofile/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getDNAProfiles();
  };

  const dnaProfileColumns: GridColDef[] = [
    {
      flex: 1,
      minWidth: 150,
      field: 'locus',
      headerName: 'Locus',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'allele1',
      headerName: 'Allele1',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'allele2',
      headerName: 'Allele2',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'profileSummary',
      headerName: 'Profile Summary',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'submissionStatus',
      headerName: 'Submission Status',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
  ];

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState<DNAProfile>({
        id: '',
        locus: '',
        allele1: '',
        allele2: '',
        profileSummary: '',
        submissionStatus: '',
      });

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            value={localData?.locus ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'locus', localData, setLocalData, onChange)
            }
            label='Locus'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <TextField
            value={localData?.allele1 ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'allele1', localData, setLocalData, onChange)
            }
            label='Allele1'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <TextField
            value={localData?.allele2 ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'allele2', localData, setLocalData, onChange)
            }
            label='Allele2'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} lg={8}>
          <TextField
            value={localData?.profileSummary ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'profileSummary', localData, setLocalData, onChange)
            }
            label='Profile Summary'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <TextField
            value={localData?.submissionStatus ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'submissionStatus', localData, setLocalData, onChange)
            }
            label='Submission Status'
            fullWidth
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogCrudDataGrid
      rows={dnaProfiles}
      columns={dnaProfileColumns}
      title='DNA Profile'
      idColumn='id'
      createFunction={CreateDNAProfile}
      deleteFunction={DeleteDNAProfile}
      updateFunction={UpdateDNAProfile}
      dialogContent={dialogContent}
    />
  );
}
