import { ChevronLeftRounded } from '@mui/icons-material';
import { Box, Button, Divider, Paper, Stack, Typography, useTheme } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CaseViewFieldsContainer from '../../../../../components/CaseView/CaseViewFieldsContainer';
import CaseViewTextField from '../../../../../components/CaseView/CaseViewTextField';
import LMSCaseLoading from '../../../../../components/LMS/LMSCaseLoading';
import LMSCaseViewHeader from '../../../../../components/LMS/LMSCaseViewHeader';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { useDebouncedValidate } from '../../../../../utils/functions/debouncedFormikValidation';
import ELabAMTestResultsView from './ELabAMTestResults';
import ELabDonorSamplesView from './ELabDonorSamples';

export default function ELabAMCaseView() {
  const [loading, setLoading] = useState(true);
  const [accessRestricted, setAccessRestricted] = useState(false);

  const { state } = useLocation();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;

  const formik = useFormik({
    initialValues: null,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      console.log(values);
    },
    initialStatus: {
      moduleVisibilityStatus: {
        casesummary: {
          enabled: true,
          visible: true,
        },
        samplelist: {
          enabled: true,
          visible: true,
        },
        labtestresultlist: {
          enabled: true,
          visible: true,
        },
      },
    },
  });

  // This changes formik's default behavior from validating on every input
  // to validation 200ms after the last input, which should improve
  // performance both locally and in the deployed versions
  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  const GetELabPMCaseData = async () => {
    const userHasRole = await user.hasRole('MDXLMS-ACCESS');

    console.log(userHasRole);

    if (userHasRole) {
      let formData = new FormData();
      formData.append('elab_case_number', state.elabcasenumber);

      setLoading(true);

      // GET CASE DATA
      // await fetch(VITE_API_URL + 'getelabpmcasedata', {
      await fetch(VITE_FASTAPI_URL + 'elab/elab-pm-casedata', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You are unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          if (res.status == 204) {
            formik.setValues(null);
          }
          return res.json();
        })
        .then(data => {
          formik.setValues(data);
        })
        .catch(e => {
          //handle error
        });

      setLoading(false);
    } else {
      setAccessRestricted(true);
      setLoading(false);
    }
  };

  const AccessRestricted = () => {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            p: 3,
            pb: 0,
            backgroundColor: theme.palette.background.default,
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 20,
          }}
        >
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            {/* CaseID */}
            <Typography variant='h5' component='h1'>
              <b>****** ****** ({state.elabcasenumber})</b>
            </Typography>
          </Box>

          <Divider sx={{ borderColor: '#555', m: 0, mt: 3 }} />
        </Box>

        <Box sx={{ p: 3, width: '100%' }}>
          <Typography variant='h3' component='h2' sx={{ m: 2 }}>
            Access Denied
          </Typography>
          <br />

          <Typography variant='body1' sx={{ m: 2 }}>
            <i>You do not have permission to view this case.</i>
          </Typography>
          <br />

          <Button
            variant='outlined'
            sx={{ m: 2, mt: 4 }}
            size='large'
            onClick={() => {
              navigate('/home');
            }}
          >
            Return To Home
          </Button>
        </Box>
      </Box>
    );
  };

  const showBackButton = state?.showBackButton;

  useEffect(() => {
    GetELabPMCaseData();
  }, [state.elabcasenumber]);

  return (
    <Stack justifyContent='start' alignItems='start'>
      {showBackButton && (
        <Box component={Paper} sx={{ width: '100%', py: 1 }} elevation={2}>
          <Button
            variant='text'
            startIcon={<ChevronLeftRounded />}
            onClick={() => {
              console.log('History Length:', window.history.length);
              console.log('Current Location:', window.location.href);
              // logs above to track just in case if same issue arises with browser stack
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        {loading ? (
          <LMSCaseLoading />
        ) : accessRestricted ? (
          <AccessRestricted />
        ) : (
          <FormikProvider value={formik}>
            <Form style={{ width: '100%', height: '100%', overflow: 'auto' }}>
              <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
                <Box sx={{ p: 3.1 }}>
                  <LMSCaseViewHeader antemortem />

                  <Typography variant='subtitle1' color='gray'>
                    <strong>DEMOGRAPHICS </strong>{' '}
                  </Typography>
                  <Box sx={{ p: 2.1 }}>
                    {/* <ElabPMCaseSummary /> */}
                    <CaseViewFieldsContainer>
                      <CaseViewTextField
                        xs={12}
                        md={4}
                        fieldName='casesummary.donorfirstname'
                        label='First Name'
                      />

                      <CaseViewTextField
                        xs={12}
                        md={4}
                        fieldName='casesummary.donormiddlename'
                        label='Middle Name'
                      />

                      <CaseViewTextField
                        xs={12}
                        md={4}
                        fieldName='casesummary.donorlastname'
                        label='Last Name'
                      />
                    </CaseViewFieldsContainer>
                  </Box>
                  <Typography variant='subtitle1' color='gray'>
                    <strong>DONOR SPECIMEN SAMPLES </strong>
                  </Typography>
                  <Box sx={{ p: 2.1 }}>
                    <ELabDonorSamplesView />
                  </Box>
                  <Typography variant='subtitle1' color='gray'>
                    <strong>ELAB TEST RESULTS</strong>
                  </Typography>
                  <Box sx={{ p: 2.1 }}>
                    <ELabAMTestResultsView />
                  </Box>
                </Box>
              </Box>
            </Form>
          </FormikProvider>
        )}
      </Box>
    </Stack>
  );
}
