import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Grid, Box, Button, IconButton, Divider } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import ReferenceMasterTableFilter from '../ReferenceMasterTableFilter';
import axios from 'axios';
import AddEdit from './AddEdit';
import { SlideProps } from '@mui/material/Slide';
import {
  deleteData,
  activeData,
  getAdminData,
  getAllJurisdiction,
} from '../../../../../services/utility-api';
import authHeader from '../../../../../services/auth-header';
import AccessDenied from '../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../components/AdministrationModules/Breadcrumb';
import DataGridComponent from '../../../../../components/AdministrationModules/DataGridComponent';
import { useAuth } from '../../../../../utils/auth/AuthService';

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ReportHeaderDetailsView = () => {
  const { user } = useAuth();
  const referenceMasterTableName = 'ReportHeaderDetails';
  const authorizedToEdit = user?.roleCheck(['8f7']) || false;
  const [isAddEditOn, setIsAddEditOn] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] = useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState<{
    icon?: string;
    seq?: string;
    status?: string;
  }>({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [rowsToShow, setRowsToShow] = useState<any[]>([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState<any[]>([]);
  const [filteredJurisdictionOptions, setFilteredJurisdictionOptions] = useState<any[]>([]);
  const MENU_OBJECT = {
    Home: '/support',
    'Reference Data': '/support',
    ReportHeaderDetails: '',
  };

  const [isLoading, setIsLoading] = useState(false);

  const [columns, setColumns] = useState([
    {
      field: 'jurisdiction',
      headerName: referenceMasterTableName
        .split('')
        .map(alpha => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim(),
      minWidth: 200,
      align: 'left',
      flex: 2,
      valueFormatter: (value: any) => {
        return value ? value.optionName : '';
      },
    },
    {
      field: 'reportHeaderDetailsOrder',
      headerName: 'List Order',
      minWidth: 200,
      align: 'left',
      flex: 2,
    },
  ]);

  let isStatusColumnAdded = false;
  columns?.forEach(column => {
    if (column.field == 'status') {
      isStatusColumnAdded = true;
    }
  });
  if (!isStatusColumnAdded) {
    setColumns(prev => [
      ...prev,
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        align: 'left',
        flex: 1,
        renderCell: (cellValues: any) => {
          const handleClick = async (row: any) => {
            setOpenActiveInActiveDialogBox(true);
            const newIconData = row.status === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,
              seq: row[
                referenceMasterTableName[0].toLowerCase() +
                  referenceMasterTableName.slice(1) +
                  'Seq'
              ],
              status: row.status,
            });
          };

          return Number(cellValues.row.order) > 90000 ? (
            <></>
          ) : (
            <IconButton
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleClick(cellValues.row);
              }}
              color='primary'
              size='small'
            >
              {cellValues.row.status === 'Active' ? (
                <Tooltip title='Inactive'>
                  <LockIcon />
                </Tooltip>
              ) : (
                <Tooltip title='Active'>
                  <DoneIcon />
                </Tooltip>
              )}
            </IconButton>
          );
        },
      },
    ]);
  }

  const editRow = (params: any) => {
    if (authorizedToEdit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          filterJurisdictionOptions(params.row?.jurisdiction?.optionSeq);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        }); //.unwrap();
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };

  const filterJurisdictionOptions = (optionSeq: string | null) => {
    if (optionSeq) {
      const currentJurisdiction = jurisdictionOptions.find(
        j => j.optionSeq?.toLowerCase() === optionSeq?.toLowerCase()
      );
      setFilteredJurisdictionOptions(currentJurisdiction ? [currentJurisdiction] : []);
    } else {
      const usedJurisdictions = rows.map(item => item.jurisdiction?.optionSeq?.toLowerCase());

      const filteredJurisdictions = jurisdictionOptions.filter(
        j => !usedJurisdictions.includes(j.optionSeq?.toLowerCase())
      );
      setFilteredJurisdictionOptions(filteredJurisdictions);
    }
  };

  const getAllData = async () => {
    setIsLoading(true);
    try {
      // const response = await axios.get(
      //   `${import.meta.env.VITE_FASTAPI_URL}master-reference-data?table_name=${referenceMasterTableName}`,
      //   {
      //     headers: authHeader(),
      //   }
      // );

      const response = await getAdminData(referenceMasterTableName);

      const jurisdictionOptions = await getAllJurisdiction();
      setJurisdictionOptions(jurisdictionOptions);

      const data = response.data.data.map((row: Record<string, any>) => {
        const newObj: Record<string, any> = {};
        Object.keys(row).map(key => {
          newObj[key] = row[key];
        });
        newObj.id =
          newObj[
            referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
          ];
        return newObj;
      });

      let maxOrder = 0;
      for (let i = 0; i < data.length; i++) {
        if (
          Number(data[i].reportHeaderDetailsOrder) > maxOrder &&
          Number(data[i].reportHeaderDetailsOrder) < 90000
        )
          maxOrder = Number(data[i].reportHeaderDetailsOrder);
      }
      setMaxOrder(maxOrder);
      setRows(data);
      setRowsToShow(data.filter((row: any) => row['status'].toLowerCase() == 'active'));
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  if (!authorizedToEdit) {
    return (
      <Grid container spacing={2} sx={{ mt: '10px', padding: '16px' }}>
        <Grid item xs={12} md={12}>
          <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
        </Grid>
        <Grid item xs={12} md={12}>
          <AccessDenied />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={2} sx={{ padding: '16px' }}>
        <Grid item xs={12}>
          <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3}} />
        </Grid>
        <Grid item xs={12}>
          <Box component={'div'} sx={{ tr: 'hover', cursor: 'pointer' }}>
            <ReferenceMasterTableFilter
              rows={rows}
              isJdx={true}
              isShowSearch = {false}
              jurisdictionOptions={jurisdictionOptions}
              setRowsToShow={setRowsToShow}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataGridComponent
            loading={isLoading}
            listresults={rowsToShow}
            columnsInput={columns}
            rowsperpage={10}
            onRowClick={editRow}
            gridheight={rowsToShow?.length > 0 ? 'auto' : 200}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant='contained'
            onClick={() => {
              setIsAddEditOn(false);
              setTimeout(() => {
                setEditObj({});
                filterJurisdictionOptions(null);
                setIsAddEditOn(true);
              }, 200);
            }}
            disabled={!authorizedToEdit}
          >
            Add Report Header Details
          </Button>
        </Grid>
        
        <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 1, pb: 0 }} />
        {isAddEditOn && editObj && (
          <Box component={'div'}>
            <AddEdit
              editObj={editObj}
              referenceMasterTableName={referenceMasterTableName}
              maxOrder={maxOrder}
              jurisdictionOptions={filteredJurisdictionOptions}
              setIsAddEditOn={setIsAddEditOn}
              getAllData={getAllData}
            />
          </Box>
        )}
        </Grid>

        <div className='modal'>
          <Dialog
            open={openActiveInActiveDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenActiveInActiveDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                Are you sure you want to{' '}
                {activeInactiveClickData?.status === 'Active' ? 'InActive ' : 'Active'} this report
                header details?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>No</Button>
              <Button onClick={changeActiveInActiveClick}>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>

        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
        {/* </MainCard> */}
      </Grid>
    );
  }
};

export default ReportHeaderDetailsView;
