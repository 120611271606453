import axios from 'axios';

const { VITE_API_URL } = import.meta.env;
type DownloadFileBlobParams = {
  fileSeq: string;
  caseid: string;
  accessToken: string;
  endpoint?: string;
};

async function downloadFileBlob({
  caseid,
  fileSeq,
  accessToken,
  endpoint,
}: DownloadFileBlobParams): Promise<{ blob: Blob; filename: string }> {
  const path = endpoint || 'downloadfiles';
  const url = `${VITE_API_URL}${path}`;

  const formData = new FormData();
  formData.append('caseId', caseid);
  formData.append('fileSeqsString', JSON.stringify([fileSeq]));

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        responseType: 'blob', // Corrected from 'resposeType' to 'responseType'
        Authorization: 'Bearer ' + accessToken,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Status: ${response.status}`);
    }

    const blob = await response.blob();

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'file';

    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    return {
      blob,
      filename,
    };
  } catch (error) {
    console.error('Error downloading file', error);
    throw error;
  }
}

const openDocumentInNewTab = async (documentSeq: string, accessToken: string) => {
  try {
    const response = await axios.get(
      `${VITE_API_URL}OpenResourceDocument?documentSeq=${documentSeq}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );

    const fileData = response?.data?.data?.[0];
    const base64String = fileData?.documentString;
    const filename = fileData?.originalFileName;
    const contentType = fileData?.documentMimeType;
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });

    // Create an object URL from the blob
    const blobUrl = URL.createObjectURL(blob);

    // Open the document in a new tab with a custom HTML structure
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(`
        <html>
          <head>
            <title>${filename}</title>
            <style>
              body {
                margin: 0;
                paddingTop: 20px;
                font-family: Arial, sans-serif;
                background-color: #f4f4f4;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              iframe {
                border: none;
                width: 60%; /* Makes the PDF not take the entire width */
                height: 90vh; /* Ensures the PDF takes up most of the height */
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                margin-top: 20px;
                overflow: hidden;
              }
              #download-btn {
                padding: 10px 20px;
                margin-top: 20px;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                text-align: center;
                font-size: 16px;
              }
              #download-btn:hover {
                background-color: #0056b3;
              }
            </style>
          </head>
          <body>
            <button id="download-btn">Download ${filename}</button>
            <iframe src="${blobUrl}#zoom=page-fit&view=FitH&toolbar=0" type="application/pdf" title="${filename}"></iframe>
            <script>
              document.getElementById('download-btn').onclick = function() {
                const a = document.createElement('a');
                a.href = '${blobUrl}';
                a.download = '${filename}';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              };
              window.onbeforeunload = function() {
                URL.revokeObjectURL('${blobUrl}');
              };
            </script>
          </body>
        </html>
      `);
      newWindow.document.close();
    } else {
      console.error('Failed to open new window');
      URL.revokeObjectURL(blobUrl); // Clean up if window couldn't be opened
    }
  } catch (error) {
    alert('Error : ' + error);
    console.log('error', error);
  }
};

 const openFileInNewTab = async (
  endpointUrl: string,
  payload?: any,
  accessToken?: string,
  method: 'GET' | 'POST' = 'GET'
): Promise<void> => {
  try {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    let response;
    if (method === 'POST') {
      response = await axios.post(endpointUrl, payload, axiosConfig);
    } else {
      response = await axios.get(endpointUrl, axiosConfig);
    }

    const fileData = response?.data?.data?.[0]; // adjust this based on API response
    if (!fileData) {
      alert('No file found.');
      return;
    }

    const base64String = fileData.documentString || fileData.photo;
    const filename = fileData.originalFileName || fileData.fileName || 'download';
    const contentType = fileData.documentMimeType || fileData.photoMimeType || 'application/octet-stream';

    // Decode base64 string into a Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });

    // Create a URL and open in new tab
    const blobUrl = URL.createObjectURL(blob);

    // Open the blob URL in a new tab
    const newWindow = window.open(blobUrl, '_blank');

    if (newWindow) {
      const isPdf = contentType === 'application/pdf';
  
    const content = isPdf
      ? `<iframe src="${blobUrl}#zoom=page-fit&view=FitH&toolbar=0" type="application/pdf" title="${filename}"></iframe>`
      : `<img src="${blobUrl}" alt="${filename}" id="preview-image" />`;

      newWindow.document.write(`
        <html>
          <head>
            <title>${filename}</title>
            <style>
              body {
                margin: 0;
                paddingTop: 20px;
                font-family: Arial, sans-serif;
                background-color: #f4f4f4;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              iframe {
                border: none;
                width: 60%; /* Makes the PDF not take the entire width */
                height: 90vh; /* Ensures the PDF takes up most of the height */
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                margin-top: 20px;
                overflow: hidden;
              }
                img {
                margin-top: 20px;
                max-width: 90%;
                max-height: 90%;
                height: auto;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                object-fit: contain;
              }
              #download-btn {
                padding: 10px 20px;
                margin-top: 20px;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                text-align: center;
                font-size: 16px;
              }
              #download-btn:hover {
                background-color: #0056b3;
              }
            </style>
          </head>
          <body>
            <button id="download-btn">Download ${filename}</button>
            ${content}
            <script>
              document.getElementById('download-btn').onclick = function() {
                const a = document.createElement('a');
                a.href = '${blobUrl}';
                a.download = '${filename}';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              };
              window.onbeforeunload = function() {
                URL.revokeObjectURL('${blobUrl}');
              };
            </script>
          </body>
        </html>
      `);
      newWindow.document.close();
    } else {
      console.error('Failed to open new window');
      URL.revokeObjectURL(blobUrl); // Clean up if window couldn't be opened
    }
  } catch (error) {
    console.error('Failed to open document:', error);
    alert('Failed to open document. Please try again.');
  }
};

export const FileAPI = {
  downloadFileBlob,
  openDocumentInNewTab,
  openFileInNewTab,
};
