import { useEffect } from 'react';

export function Dev_ThrowErrorView() {
  function throw_an_error_function() {
    throw new Error('This is a test');
  }
  useEffect(() => {
    throw_an_error_function();
  }, []);

  return <p>Test</p>;
}
