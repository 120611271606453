import CaseViewAutocomplete from '../../../../../components/CaseView/CaseViewAutocomplete';
import CaseViewFieldsContainer from '../../../../../components/CaseView/CaseViewFieldsContainer';
import CaseViewTextField from '../../../../../components/CaseView/CaseViewTextField';

export default function ElabPMCaseSummary() {
  // These should likely be replaced with API calls, perhaps a new GetOptions
  // controller can be created for LMS cases
  const manners = ['Accident', 'Homicide', 'Natural', 'Pending', 'Suicide'].sort();
  const individualtype = [
    'Alias',
    'Deceased',
    'Law Enforcement',
    'Mass Fatality',
    'Missing',
    'Suspect',
    'Victim',
  ].sort();

  return (
    <CaseViewFieldsContainer>
      <CaseViewTextField xs={12} md={4} fieldName='casesummary.donorfirstname' label='First Name' />

      <CaseViewTextField
        xs={12}
        md={4}
        fieldName='casesummary.donormiddlename'
        label='Middle Name'
      />

      <CaseViewTextField xs={12} md={4} fieldName='casesummary.donorlastname' label='Last Name' />

      <CaseViewTextField xs={12} md={4} fieldName='casesummary.race' label='Race' />

      <CaseViewTextField xs={12} md={4} fieldName='casesummary.gender' label='Gender' />

      <CaseViewTextField xs={12} md={4} fieldName='casesummary.dateofbirth' label='Date of Birth' />

      <CaseViewAutocomplete
        xs={12}
        md={4}
        fieldName='casesummary.manner'
        options={manners}
        label='Death Classification'
      />

      <CaseViewAutocomplete
        xs={12}
        md={4}
        fieldName='casesummary.individualtype'
        options={individualtype}
        label='Individual Type'
      />

      <CaseViewTextField
        xs={12}
        fieldName='casesummary.notes'
        label='Internal Comments'
        multiline
        rows={5}
      />
    </CaseViewFieldsContainer>
  );
}
