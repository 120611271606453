import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { dateColumnType } from '../../../../../utils/components/CrudDataGrid/DateColumn';
import ReportDataGrid from '../../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ELabAMTestResultsView() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const formik = useFormikContext();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    // {
    //   field: 'samplenumber',
    //   headerName: 'Sample Number',
    //   flex: 1,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    // Not sure if its assumed that caseid in medexlab is going to be the same
    // {
    //   field: 'caseid',
    //   headerName: 'MedexLab CaseID',
    //   flex: 1,
    //   minWidth: 150,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'specimen',
      headerName: 'Specimen',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },

    {
      field: 'analyte',
      headerName: 'Analyte',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'testmethod',
      headerName: 'Test Method',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    // {
    //   field: 'quantitativelimit',
    //   headerName: 'Quantitative Limit',
    //   flex: 1,
    //   minWidth: 150,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'unitofmeasurement',
      headerName: 'Units',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    // {
    //   field: 'quantitativeresult',
    //   headerName: 'RESULT',
    //   flex: 1,
    //   minWidth: 150,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'interpretation',
      headerName: 'INTERPRETATION',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'testdate',
      headerName: 'Test Date',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return 'undefined';
        }
        return new Date(value);
      },
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'labreferencenumber',
      headerName: 'Lab Reference Number',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'validationdate',
      headerName: 'Validation Date',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'labnotes',
      headerName: 'Lab Notes',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  useEffect(() => {
    if (formik.values != null) {
      setlistresults(formik.values?.labtestresultlist);
    }
    console.log('labresults', formik.values?.labtestresultlist);
  }, [formik.values]);

  return (
    <ReportDataGrid
      loading={loading}
      idcolumn='id'
      listresults={listresults ?? []}
      columnsInput={columns}
      customButtons={[<GridToolbarQuickFilter key='filter' />]}
      title='ELAB TEST RESULTS'
    />
  );
}
