import {
  Grid,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  Autocomplete,
  Checkbox,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Divider,
  Box,
  Slide,
} from '@mui/material';
import { useFormikContext, ErrorMessage, Form, FormikValues } from 'formik';
import { UserAccount } from './UserAccount.type';
import AddressData from '../../../components/AdministrationModules/AddressData/AddressView';
import ContactData from '../../../components/AdministrationModules/ContactData/ContactView';
import React, { useEffect, useState } from 'react';
import { CancelRounded, CheckBox, CheckBoxOutlineBlankOutlined, Info } from '@mui/icons-material';
import {
  SPECIAL_PRIVILEGE,
  PHYSICIAN_CERTIFICATION_PATHOLOGY,
  OCSME_CERTIFICATION,
} from '../constants';
import { UnlockAccountButton } from '../../../components/AdministrationModules/UnlockAccountButton';
import authHeader from '../../../services/auth-header';
import ApiNotification from '../../../components/DialogBox';
import { formatPhoneNumber } from '../../../utils/functions/formatPhoneNumber';
import axios from 'axios';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ActionList } from '../../../components/CaseView/CaseViewDateTimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { SlideProps } from '@mui/material/Slide';

interface UserAccountMainProps {
  organizationOptions: Option[];
  departmentOptions: Option[];
}

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const UserAccountMain: React.FC<UserAccountMainProps> = ({
  organizationOptions,
  departmentOptions,
}) => {
  const [confirmationPassword, setConfirmationPassword] = useState(false);
  const [confirmationSecurityQuestion, setConfirmationSecurityQuestion] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState('');
  const [securityQuestionModal, setSecurityQuestionModal] = useState(false);
  const [openActiveInActiveDialog, setOpenActiveInActiveDialog] = useState(false);
  const [securityMessage, setSecurityMessage] = useState('');
  const id = '00000000-0000-0000-0000-000000000000';
  const { values, setFieldValue, errors, handleChange } = useFormikContext<FormikValues>();

  const isOptionEqualToValue = (option: Option, value: Option) =>
    option?.optionSeq?.toLowerCase() === value?.optionSeq?.toLowerCase();

  const getOptionLabel = (option: Option) => option?.optionName ?? '';

  const multiRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    { selected }: { selected: boolean }
  ) => (
    <li {...props}>
      <Checkbox
        icon={<CheckBoxOutlineBlankOutlined fontSize='small' />}
        checkedIcon={<CheckBox fontSize='small' />}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option?.name}
    </li>
  );

  const multiRenderTags = (values: any[], getTagProps: any) =>
    values.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        sx={{
          height: 'auto',
          '& .MuiChip-label': { display: 'flex', alignItems: 'center' },
          '& .MuiChip-deleteIcon': { fontSize: '16px' },
        }}
        label={option?.name}
        deleteIcon={<CancelRounded />}
      />
    ));

  const getDateValue = (date: any): Dayjs | null => {
    if (date == null || date === '' || (isDayjs(date) && !date.isValid())) return null;
    return dayjs(date);
  };

  const handlePassword = async () => {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}user/generatetemporarypassword`,
      {
        userSeq: values?.userSeq,
        securityQuestionSeq: '00000000-0000-0000-0000-000000000000',
        securityAnswer: '',
      },
      { headers: authHeader() }
    );

    if (response.data.status === 0) {
      setPasswordModal(true);
      setPasswordMessage(response.data.message);
    }
  };

  const handleSecurityQuestion = async () => {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}ResetSecurityQuestion`,
      { userSeq: values?.userSeq },
      { headers: authHeader() }
    );
    if (response.data.status === 0) {
      setSecurityQuestionModal(true);
      setSecurityMessage(response.data.message);
    }
  };

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            required
            name='personFirstName'
            label='First Name'
            value={values.personFirstName}
            onChange={handleChange}
            error={Boolean(errors.personFirstName)}
          />
          <ErrorMessage
            name='personFirstName'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            required
            label='Last Name'
            name='personLastName'
            value={values.personLastName}
            onChange={handleChange}
            error={Boolean(errors.personLastName)}
          />
          <ErrorMessage
            name='personLastName'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label='Middle Name'
            name='personMiddleName'
            value={values.personMiddleName}
            onChange={handleChange}
            error={Boolean(errors.personMiddleName)}
          />
          <ErrorMessage
            name='personMiddleName'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label='Suffix'
            name='suffix'
            value={values.suffix}
            onChange={handleChange}
            error={Boolean(errors.suffix)}
          />
          <ErrorMessage
            name='suffix'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            required
            name='userName'
            label='User ID'
            value={values.userName}
            onChange={handleChange}
            error={Boolean(errors.userName)}
          />
          <ErrorMessage
            name='userName'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            required
            label='Email'
            name='primaryEmail'
            value={values.primaryEmail}
            onChange={handleChange}
            error={Boolean(errors.primaryEmail)}
          />
          <ErrorMessage
            name='primaryEmail'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            required
            label='Phone Number'
            name='primaryMobileNo'
            value={formatPhoneNumber(values.primaryMobileNo, true)}
            onChange={e => {
              const inputValue = e.target.value;
              try {
                const formattedNumber = formatPhoneNumber(inputValue);
                setFieldValue('primaryMobileNo', formattedNumber);
              } catch (error) {
                setFieldValue('primaryMobileNo', inputValue); // Keep raw input if invalid
              }
            }}
            error={Boolean(errors.primaryMobileNo)}
          />
          <ErrorMessage
            name='primaryMobileNo'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            required
            label='Initials'
            name='initials'
            value={values.initials}
            onChange={handleChange}
            error={Boolean(errors.initials)}
          />
          <ErrorMessage
            name='initials'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            id='organization'
            options={organizationOptions ?? []}
            value={values?.organization ?? null}
            onChange={(_, newValue) => {
              setFieldValue('organization', newValue as Option);
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={params => (
              <TextField
                {...params}
                id='organization'
                name='organization'
                label='Organization'
                variant='outlined'
                required
                error={Boolean(errors.organization)}
              />
            )}
          />
          <ErrorMessage
            name='organization'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            options={departmentOptions ?? []}
            value={values?.department ?? null}
            onChange={(_, newValue) => {
              setFieldValue('department', newValue as Option);
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={params => (
              <TextField
                {...params}
                label='Department'
                variant='outlined'
                required
                error={Boolean(errors.department)}
              />
            )}
          />
          <ErrorMessage
            name='department'
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            multiple
            options={SPECIAL_PRIVILEGE ?? []}
            value={values?.specialPrivilege?.map(
              (sp: any) => SPECIAL_PRIVILEGE.find(option => option.value === sp.value) || sp
            )}
            onChange={(_, newValue) => {
              setFieldValue(
                'specialPrivilege',
                newValue.map(v => ({ value: v.value }))
              );
            }}
            limitTags={2}
            getOptionLabel={option => option.label || option.value}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            renderOption={(props, option, state) => multiRenderOption(props, option, state)}
            renderTags={(v, getTagProps) => multiRenderTags(v, getTagProps)}
            renderInput={params => (
              <TextField {...params} fullWidth label='Special Privilege' variant='outlined' />
            )}
          />
        </Grid>

        <Grid item sm={12} lg={4}>
          <Button
            fullWidth
            variant='contained'
            onClick={() => setConfirmationSecurityQuestion(true)}
          >
            Reset Security Questions
          </Button>
        </Grid>
        <Grid item sm={12} lg={4}>
          <Button fullWidth variant='contained' onClick={() => setConfirmationPassword(true)}>
            Generate Temporary Password
          </Button>
        </Grid>

        <Grid item sm={12} lg={4}>
          <UnlockAccountButton userid={values?.userName} />
        </Grid>

        <Grid item sm={12} xs={12} md={12} lg={12}>
          <AddressData
            title='Address'
            isMultipleOn={true}
            disabled={!values.isActive}
            addresses={values?.address || []}
            contactInfoSeq={values.contactInfoSeq || id}
            handleChange={(data: any) =>
              handleChange({
                target: {
                  value: data,
                  name: 'address',
                },
              })
            }
          />
        </Grid>

        <Grid item sm={12} xs={12} md={12} lg={12}>
          <ContactData
            contactItem={values.contactItem}
            handleChange={(data: any) =>
              handleChange({
                target: {
                  value: data,
                  name: 'contactItem',
                },
              })
            }
          />
        </Grid>

        <Grid item container spacing={2}>
          <Grid item sm={12}>
            <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
              <strong>Pathologist Certification</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              fullWidth
              multiple
              options={PHYSICIAN_CERTIFICATION_PATHOLOGY ?? []}
              value={values?.pathologyCertification?.map(
                (pc: any) =>
                  PHYSICIAN_CERTIFICATION_PATHOLOGY.find(option => option.value === pc.value) || pc
              )}
              onChange={(_, newValue) => {
                setFieldValue(
                  'pathologyCertification',
                  newValue.map(v => ({ value: v.value }))
                );
              }}
              limitTags={2}
              getOptionLabel={option => option.label || option.value}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              renderOption={(props, option, state) => multiRenderOption(props, option, state)}
              renderTags={(v, getTagProps) => multiRenderTags(v, getTagProps)}
              renderInput={params => (
                <TextField {...params} fullWidth label='Pathology' variant='outlined' />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name='mdLicenseExpiryDate'
                value={getDateValue(values?.mdLicenseExpiryDate)}
                onChange={data => setFieldValue('mdLicenseExpiryDate', data)}
                label='Expiry Date'
                slots={{
                  actionBar: ActionList,
                }}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label='MD License #'
              name='mdLicenseNo'
              value={values.mdLicenseNo}
              onChange={handleChange}
              error={Boolean(errors.mdLicenseNo)}
            />
            <ErrorMessage
              name='mdLicenseNo'
              render={msg => <FormHelperText error>{msg}</FormHelperText>}
            />
          </Grid>
          {/* <Grid item lg={6} xs={12} md={12} sm={12}>
            <Autocomplete
              fullWidth
              options={OCSME_CERTIFICATION ?? []}
              value={values?.ocsmeCertification}
              onChange={(_, newValue) => {
                setFieldValue('ocsmeCertification', newValue);
              }}
              limitTags={2}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              renderOption={(props, option, state) => multiRenderOption(props, option, state)}
              renderTags={(v, getTagProps) => multiRenderTags(v, getTagProps)}
              renderInput={params => (
                <TextField {...params} fullWidth label='OCSME Certification' variant='outlined' />
              )}
            />
          </Grid>
          <Grid item lg={6} xs={12} md={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name='ocsmeExpiryDate'
                value={getDateValue(values?.ocsmeExpiryDate)}
                onChange={data => setFieldValue('ocsmeExpiryDate', data)}
                label='Expiry Date'
                slots={{
                  actionBar: ActionList,
                }}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid> */}
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
              <strong>Investigator Certification</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label='ABMDI Certification'
              name='abmdiCertificateNo'
              value={values.abmdiCertificateNo}
              onChange={handleChange}
              error={Boolean(errors.abmdiCertificateNo)}
            />
            <ErrorMessage
              name='abmdiCertificateNo'
              render={msg => <FormHelperText error>{msg}</FormHelperText>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label='Board Certified Fellow'
              name='boardcertificatefellowNo'
              value={values.boardcertificatefellowNo}
              onChange={handleChange}
              error={Boolean(errors.boardcertificatefellowNo)}
            />
            <ErrorMessage
              name='boardcertificatefellowNo'
              render={msg => <FormHelperText error>{msg}</FormHelperText>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name='abmdiCertificateExpiryDate'
                value={getDateValue(values?.abmdiCertificateExpiryDate)}
                onChange={data => setFieldValue('abmdiCertificateExpiryDate', data)}
                label='Expiry Date'
                slots={{
                  actionBar: ActionList,
                }}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box component={'div'} sx={{ pointerEvents: 'auto' }}>
          <FormControlLabel
            control={
              <Switch
                checked={values.isActive}
                onChange={() => setOpenActiveInActiveDialog(true)}
              />
            }
            label={values.isActive ? 'Active' : 'Inactive'}
          />
          </Box>
        </Grid>

        {confirmationPassword ? (
          <div>
            <Dialog open={confirmationPassword} onClose={() => setConfirmationPassword(false)}>
              <DialogTitle>Confirm?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to generate temporary password?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmationPassword(false)}>No</Button>
                <Button
                  onClick={() => {
                    setConfirmationPassword(false);
                    handlePassword();
                  }}
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : null}
        {confirmationSecurityQuestion ? (
          <div>
            <Dialog
              open={confirmationSecurityQuestion}
              onClose={() => setConfirmationSecurityQuestion(false)}
            >
              <DialogTitle>Confirm?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to reset security question?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmationSecurityQuestion(false)}>No</Button>
                <Button
                  onClick={() => {
                    setConfirmationSecurityQuestion(false);
                    handleSecurityQuestion();
                  }}
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : null}

        {securityQuestionModal ? (
          <ApiNotification
            openApiDialogBox={securityQuestionModal}
            closeDialogBox={() => setSecurityQuestionModal(false)}
            dialogContents={securityMessage || ''}
          />
        ) : null}
        {passwordModal ? (
          <ApiNotification
            openApiDialogBox={passwordModal}
            closeDialogBox={() => setPasswordModal(false)}
            dialogContents={passwordMessage || ''}
          />
        ) : null}

        <div className='modal'>
                <Dialog
                  open={openActiveInActiveDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setOpenActiveInActiveDialog(false)}
                  aria-describedby='alert-dialog-slide-description'
                >
                  <DialogTitle></DialogTitle>
                  <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                      Are you sure you want to{' '}
                      {values?.isActive ? 'InActive ' : 'Active'} this user
                      account?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenActiveInActiveDialog(false)}>No</Button>
                    <Button onClick={() => {setOpenActiveInActiveDialog(false); setFieldValue('isActive', !values.isActive);}}>Yes</Button>
                  </DialogActions>
                </Dialog>
              </div>
      </Grid>
    </Form>
  );
};

export default UserAccountMain;
