import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Alert,
  Snackbar,
  AlertColor,
} from '@mui/material';
import axios from 'axios';
import authHeader from '../../../services/auth-header';
import { useAuth } from '../../../utils/auth/AuthService';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ActionList } from '../../../components/CaseView/CaseViewDateTimeField';
import NotesRichTextEditor from '../../../utils/components/NotesRichTextEditor';
import DataGridComponent from '../../../components/AdministrationModules/DataGridComponent';
import { format } from 'date-fns';

interface NotesProps {
  userSeq: string;
}

const Notes: React.FC<NotesProps> = ({ userSeq }) => {
  const { user } = useAuth();
  const [notes, setNotes] = useState('');
  const [rows, setRows] = useState([]);
  const [dateTime, setDateTime] = useState<string | null>(null);
  const [isAddEditOn, setIsAddEditOn] = useState(false);
  const [showSelectionAlert, setShowSelectionAlert] = useState(false);
  const [userNotesSnackbar, setUserNotesSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const columns = [
    {
      field: 'supplementalNote',
      headerName: 'Notes',
      type: 'text',
      minWidth: 200,
      align: 'left',
      flex: 2,
      renderCell: (params: any) => (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            display: 'flex',
            alignItems: 'flex-start',
            overflow: 'hidden',
            width: '100%',
          }}
          dangerouslySetInnerHTML={{ __html: params.value || '' }}
        />
      ),
    },
    {
      field: 'name',
      headerName: 'User Name',
      renderCell: () => {
        return <span>{user?.userFirstName + ' ' + user?.userLastName}</span>;
      },
      type: 'text',
      minWidth: 200,
      align: 'left',
      flex: 1,
    },

    {
      field: 'supplementalDateTime',
      headerName: 'Date Time',
      minWidth: 150,
      flex: 1,
      align: 'left',
      valueFormatter: (value: any) => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
  ];

  const handleClose = () => {
    setIsAddEditOn(false);
  };

  const handleAddClick = () => {
    setIsAddEditOn(true);
    setDateTime(dayjs().format('YYYY-MM-DDTHH:mm:ss'));
    setNotes('');
  };

  const handleDateChange = (date: Dayjs | null) => {
    const localDate = date?.isValid() ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
    setDateTime(localDate);
  };

  const handleCloseUserNotesSnackbar = () => {
    setUserNotesSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleSubmit = async () => {
    try {
      const NotesData = {
        supplementalNote: notes,
        name: `${user?.userLastName} ${user?.userFirstName}`,
        supplementalDateTime: dateTime,
        editUserId: userSeq,
        logedInUserId: user?.userSeq,
      };
      const response = await axios.post(`${import.meta.env.VITE_API_URL}SaveUserNote`, NotesData, {
        headers: authHeader(),
      });

      if (response.data.status === 0) {
        fetchNotes();
        setUserNotesSnackbar({
          open: true,
          severity: 'success',
          message: 'Notes was saved successfully!',
        });
      } else {
        // Handle API returning failure status
        setUserNotesSnackbar({
          open: true,
          severity: 'error',
          message: response.data.message || 'An error occurred, unable to save the notes.',
        });
      }
    } catch (error: any) {
      // Handle network or server errors
      console.error('Error saving note:', error);

      setUserNotesSnackbar({
        open: true,
        severity: 'error',
        message: error.response?.data?.message || 'An unexpected error occurred.',
      });
    } finally {
      handleClose();
    }
  };

  const handleNotesChange = (content: any) => {
    setNotes(content);
    const cleanedContent = content
      .replace(/<p>(&nbsp;|\s|<br\s*\/?>)*<\/p>/gi, '') // Remove all empty paragraphs
      .trim();

    const isEmpty = cleanedContent === '';

    if (!isEmpty && showSelectionAlert) {
      setShowSelectionAlert(false);
    } else if (isEmpty && !showSelectionAlert) {
      setShowSelectionAlert(true);
    }
  };

  const fetchNotes = async () => {
    const getNotes = await axios.get(
      `${import.meta.env.VITE_API_URL}GetUserNote?EdituserSeq=${userSeq}&LogedInUserSeq=${
        user?.userSeq
      }`,
      { headers: authHeader() }
    );
    const dataGridUpdate = getNotes.data.data?.map((row, index) => ({
      id: `${index}`,
      supplementalNote: `${row.supplementalNote}`,
      name: `${row.name}`,
      supplementalDateTime: `${new Date(row.supplementalDateTime).toLocaleString()}`,
    }));
    setRows(dataGridUpdate);
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <Grid container spacing={2}>
      <Snackbar
        open={userNotesSnackbar.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ position: 'absolute', top: 5 }}
        autoHideDuration={5000}
        onClose={handleCloseUserNotesSnackbar}
      >
        <Alert
          onClose={handleCloseUserNotesSnackbar}
          severity={userNotesSnackbar.severity as AlertColor}
          variant='filled'
          sx={{ width: '100%' }}
        >
          <Typography variant='body1' fontWeight={500}>
            {userNotesSnackbar.message}
          </Typography>
        </Alert>
      </Snackbar>

      <Grid item xs={12}>
        <DataGridComponent
          // loading={isLoading}
          listresults={rows}
          columnsInput={columns}
          rowsperpage={20}
          gridheight={rows?.length > 0 ? 'auto' : 200}
          idcolumn={'id'}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant='contained'
          onClick={() => {
            handleAddClick();
          }}
        >
          Add New Note
        </Button>
      </Grid>

      {isAddEditOn && (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#555', m: 0, mt: 1, pb: 0 }} />
          </Grid>
          <Grid item lg={4} xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                name='startDatecall'
                label='Date'
                value={dayjs(dateTime) ?? null}
                onChange={handleDateChange}
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                ampm={false}
                slots={{
                  actionBar: ActionList,
                }}
                slotProps={{
                  field: { clearable: true },
                  textField: { id: 'startDatecall' },
                }}
                sx={{
                  minWidth: '100%',
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <NotesRichTextEditor
              value={notes}
              onChange={handleNotesChange}
              placeholder='Enter Notes...'
            />
            {showSelectionAlert && (
              <Typography variant='subtitle1' color={'red'}>
                <strong>Please enter notes</strong>
              </Typography>
            )}
          </Grid>

          <Grid container item xs={12} justifyContent='flex-start'>
            <Box component={'div'}>
              <Button variant='contained' sx={{ minWidth: '100px' }} onClick={e => handleSubmit()}>
                Submit
              </Button>

              <Button
                color='error'
                variant='outlined'
                onClick={handleClose}
                sx={{ minWidth: '100px', marginLeft: '1rem' }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Notes;
