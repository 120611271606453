// Constants for meAction options
export const ME_Action = {
  Accepted: '08737538-FE47-450A-AA2A-5D37E95066EA',
  Release: '98147AAB-5A4E-45D5-82AE-3B535490DD04',
  StorageOnly: 'FBCD2E47-F946-4968-A978-97F4361C7EB5',
  Transfer: 'FA7AB1B8-93BA-4080-8EAC-470CF39C4324',
  Facilitated_External_Exam: '04E71EEB-2D4A-4F6E-9CB4-2D600CFC71B5',
};

export const TimeZone = 'America/New_York';

// Constants for liveryOption options
export const Livery_To_Transport_Body = 'E1E62391-C8F8-4674-A4BE-0CE1A4C62C93';
export const Livery_Not_Required = 'B013DF78-CC9E-4D94-B348-69BDE12934B5';
export const Investigator_To_Transport_Body = '0D6DD5E8-A673-490E-81D6-1ADFE15D1ED1';
export const Law_Enforcement_To_Transport_Body = '9015EDD5-9B6E-44D9-9A15-7E4872DC6916';

export const NoLiveryReason_Hospital = 'Hospital';
export const NoLiveryReason_Other = 'Other';

export const Race_Other = 'D20603C2-69A3-40EA-B126-1AA27459F080';
export const Occupation_Other = '53F93CF2-EA7F-4AB8-A919-632DD8FA539A';
export const NoPronouncement_Other = 'A935243E-4CF5-4EB7-9819-7F02218A8445';

export const NoticeMethod_Telephone = '847CEB6A-3F16-4806-9FAF-6EADECC7DD9C';
export const NoticeMethod_Other = 'D0DC738E-FFBE-4898-8579-5BF7F4EB3917';
export const ObjectionReason_Other = 'B61C27A6-4C12-48A4-8BAA-E9F0E19CB899';
export const TransferReason_Other = '372873FE-0757-4F92-A1DC-975401E137E2';
export const PronouncerLocation_Other = '5BEED1BC-D6F3-42DF-8BEE-7E9ECBF36FA4';
export const PronouncerAgency_Other = '90ED1E1F-F465-498A-BE11-BC6836407EAA';
export const authorizingRelease_Other = '8B79A31F-02BE-4FFD-BA1B-58D7183874B7';

export const Module_Name = {
  Medical_Imaging: 'Medical Imaging',
  Scene: 'Scene',
  Autopsy_EE: 'Autopsy_EE',
};

export const AddressTypeOptions = {
  MEDICALFACILITY: '3c7e6ede-5365-42d0-a1f1-47a743092127',
  LAWENFORCEMENT: 'ccbb0e1c-e87b-4338-89b5-89543fbaabe8',
  NURSINGHOME: '6a78b211-9c2f-44c9-897b-f3a7087f59aa',
  FUNERALHOME: '6fb47ea3-ab7e-46a6-be44-3913e70e6720',
  OTHER: 'e18c8e0e-7582-4bcd-ba54-082ec3f351aa'
};

export const CaseReporterTypeOptions = {
  MEDICALFACILITY: '875C36E0-A559-4552-BA14-2C0F8368A632',
  LAWENFORCEMENT: '85115ED1-039F-4B89-B15C-CFCB4F6046D3',
  NURSINGHOME: '3B758606-75D6-4068-A66D-21176A9F1C5F',
  FUNERALHOME: 'DC11F527-4CB4-4DF8-9B20-22F1F2731C6E',
  CHARGEABLEREQUEST: '8918DC13-59DD-45EF-87B0-B268D97C8156',
  OTHER: '25439ADE-F997-451A-9122-2C904DAD52D9'
};

export const CaseReporterAgencyTypeOptions = {
  LAWENFORCEMENT: '22CD6EC5-93C6-4344-A859-E3DAF99797BD',
  MEDICALFACILITY: '348BD197-50A3-4DAF-82D5-862C00E51BCB',
  NURSINGHOME: '9DA66840-2A60-4E1C-87BC-EE0D02AE199C',
  FUNERALHOME: '6172B365-983D-4B30-97B0-FB5221A314E0',
};

export const DecedentAddressTypeOptions = {
  Unknown: 'EEA37E74-0A50-419E-9DE9-372D21D1F890', 
  Homeless: 'F8362705-4D9D-4FC9-AFE9-486051FBC3CC', 
  Residence: 'F701E8A5-0BEB-44C5-8167-DB4C9185CFAC', 
  NursingHome: '6A78B211-9C2F-44C9-897B-F3A7087F59AA'
};

export const Photo_Types = {
  Medical_Imaging: [
    'F9A3903B-0A4E-47FE-B1C4-257058739AF3',
    'C500141B-E744-40D1-937D-8202D17DDF03',
    '25603565-9672-448A-BD11-92F99DB18E30',
    '958A0C42-2386-4677-B8EE-D8081200E9CA',
  ],
  Scene: ['17C5573E-E638-4A48-89CC-70EE11EC5F58'],
  Autopsy_EE: ['FEBEA0FA-CE32-4F41-A52E-4978D65F5E13', '359AACA7-806D-4703-92F0-10F1BA64F079'],
};

export const LMS_FileTypes = {
  Medical_Review: 'C0061647-436D-418A-9F96-C32CAD6BD4E7',
};


export const LabTestingDocumentTypes = [
  '8616894A-5114-443C-A1F9-059F975E9F0B', //Toxicology Report
  'DB8E963F-53CD-484E-B750-640600F76747', //Toxicology Request Form
  '81040409-8886-408D-911F-677E12302BB3', //Tox Request
  '65E6CAB8-1769-4961-90CC-561F40022387', //Histology Request Form
  'FEFFDB8C-7703-4F4E-914D-3653FDAACA8F',  //Lab Requests/Results
  '3264C833-C7DB-4379-ACF5-4F719425D9DD', //Ancillary Report
  '44186E5D-DECA-43AF-9A50-06C588671A73'  //Ancillary Request
];

export const excludedLabelSetTypeOptions = [
  '9AFD94F4-4D18-4854-AE0F-06D00156B84B', //Homicide
  '55AAB87C-2AEE-4168-8181-11D765BB9731', //Pediatric
  '839624F3-9CE9-46B0-BE72-4CD522E9EEF6', //Sexual Assault
  '68FDA680-6CD8-4649-87AF-CE56ED0F4537', //Basic
  '85938D21-3E59-47CE-9F1B-DA914F8A2A5E', //Rape
];

export const objExamtypeOptions = [
  '5A02F78B-40E5-41AC-AE59-158A1487F889',  //Hold
  'B060F36B-073F-4EB6-89CC-2C5280A6C3CE',  //External Exam with Imaging
  '12BE4E9C-BCDA-46D0-A3FB-8C82B8474948',  //External Exam
];

export const labelNameAbbreviations = {
  'External Examination': 'EE',
  'External Examination Spl': 'EE Spl',
};

export const payeeNameOptions = [
  { optionName: 'Registered', value: 'R' },
  { optionName: 'UnRegistered', value: 'U' },
];

export const AssignmentTypes = {
  Records: {
    optionSeq: '4F66A4B5-858E-459A-8F37-33E224A45C18',
    optionName: 'Records',
  },
  Investigations: {
    optionSeq: '4F66A4B5-858E-459A-8F37-33E224A45C02',
    optionName: 'Investigations',
  },
  Pathology: {
    optionSeq: '4F66A4B5-858E-459A-8F37-33E224A45C05',
    optionName: 'Pathology',
  },
  Morgue: {
    optionSeq: '4F66A4B5-858E-459A-8F37-33E224A45C04',
    optionName: 'Morgue',
  },
  Identifications: {
    optionSeq: '4F66A4B5-858E-459A-8F37-33E224A45C09',
    optionName: 'Identifications',
  },
};

export const ObjectionResolution = {
  Objection_Withdrawn: 'B1099A9F-8426-4AE9-8A70-56D90613DFDA',
};
