import {
  Box,
  Button,
  Pagination,
  PaginationItem,
  Paper,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import ExpandedDataGrid from './ExpandedDataGrid';

// function CustomPagination() {
//   const apiRef = useGridApiContext();
//   const page = gridPageSelector(apiRef);
//   const pageCount = gridPageCountSelector(apiRef);

//   return (
//     <Pagination
//       color='primary'
//       variant='outlined'
//       shape='rounded'
//       page={page + 1}
//       count={pageCount}
//       // @ts-expect-error
//       renderItem={props2 => (
//         <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
//       )}
//       onChange={(event, value) => apiRef.current.setPage(value - 1)}
//       sx={{ mr: 3 }}
//     />
//   );
// }

function CustomPagination({ paginationModel, setPaginationModel, pageCount }) {
  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={paginationModel.page + 1}
      count={pageCount}
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => setPaginationModel(prev => ({ ...prev, page: value - 1 }))}
      sx={{ mr: 3 }}
    />
  );
}

export default function CondensedDataGrid({
  loading = false,
  listresults = [],
  columnsInput = [],
  gridheight = '400',
  idcolumn = 'id',
  rowsperpage = 5,
  columnsVisibility = {},
  gridcolor = '#0288d1',
  tooltip = '',
  title = '',
  checkboxSelection = false,
  toolbarComponent = null,
  onRowClick = () => {},
  sortModel = [],
  disableRowSelectionOnClick = true,
}) {
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [updatedExpandedColumns, setUpdatedExpandedColumns] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: rowsperpage,
    page: 0,
  });

  const theme = useTheme();

  /**
   * MUI DataGrid bug: Using getRowHeight={() => "auto"} causes infinite row resize jittering.
   * Fix: Set resizeThrottleMs to 1000000ms (16.6min) by default, but allow 500ms of
   * unrestricted resizing when page dimensions actually change.
   * This preserves auto-height functionality while preventing unwanted resize loops.
   */
  const [resizeThrottle, setResizeThrottle] = useState(1000000);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setResizeThrottle(-1);
      setTimeout(() => setResizeThrottle(1000000), 500);
    });

    resizeObserver.observe(document.documentElement);
    return () => resizeObserver.disconnect();
  }, [paginationModel]);

  const preprocessColumns = () => {
    if (listresults.length > 0) {
      var count = 1;
      for (var row of listresults) {
        row.rownumber = count;
        count += 1;
      }
    }

    for (var column of columnsInput) {
      column.align = 'left';
      column.headerAlign = 'left';
      column.flex = column?.flex || 1;

      if (column.width != null) {
        column.minWidth = column.width;
      }

      if (column.field == 'caseid' || column.field == 'caseID') {
        column.renderCell = params => {
          return (
            <Button
              variant='text'
              color='primary'
              sx={{ fontFamily: 'DataGrid, sans-serif', fontWeight: 700 }}
              onClick={event => {
                handleCaseClick(event, params);
              }}
            >
              {params.formattedValue}
            </Button>
          );
        };
      }

      if (column.field == 'id' || column.field == 'ID') {
        column.hideable = false;
      }
    }

    var exColumnsTemp = columnsInput.slice();
    exColumnsTemp.unshift({
      field: 'rownumber',
      headerName: '',
      width: 65,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      disableColumnMenu: true,
      disableExport: true,
    });

    setUpdatedColumns(columnsInput);
    setUpdatedExpandedColumns(exColumnsTemp);
  };

  useEffect(() => {
    preprocessColumns();
  }, [listresults]);

  return loading ? (
    <Stack spacing={0}>
      <Skeleton
        variant='rounded'
        animation='wave'
        height={30}
        sx={{ borderRadius: '10px 10px 3px 3px' }}
      />
      <Skeleton variant='text' animation='wave' sx={{ fontSize: '2rem' }} />
      <Skeleton
        variant='rounded'
        animation='wave'
        height={gridheight - 40}
        sx={{ borderRadius: '3px 3px 10px 10px' }}
      />
    </Stack>
  ) : expanded ? (
    <ExpandedDataGrid
      listresults={listresults}
      columns={updatedExpandedColumns}
      setExpanded={setExpanded}
      gridcolor={gridcolor}
      idcolumn={idcolumn}
      columnsVisibility={columnsVisibility}
    />
  ) : (
    <Paper sx={{ backgroundColor: gridcolor, width: '100%' }} elevation={3}>
      <Box
        sx={{
          // height: '2.5rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* <Typography
          variant='h1'
          sx={{
            pl: 2,
            color: 'white',
            fontSize: '14pt',
          }}
        >
          {title}
        </Typography>
        <Tooltip
          sx={{
            color: 'white',
          }}
          size='small'
          title={tooltip}
        >
          <InfoIcon sx={{ color: 'white', ml: 'auto', mr: 1 }} />
        </Tooltip>
        <IconButton onClick={() => setExpanded(true)} sx={{ mr: 2 }}>
          <OpenInFullIcon sx={{ color: 'white' }} fontSize='small' />
        </IconButton> */}
      </Box>

      <Box sx={{ height: 'max-content' }}>
        <DataGridPremium
          resizeThrottleMs={resizeThrottle}  
          getRowId={row => row[idcolumn]}
          columns={updatedColumns}
          rows={listresults}
          pagination
          disableColumnMenu
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          checkboxSelection={checkboxSelection}
          onRowClick={onRowClick}
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '0px 0px 4px 4px',
            height: gridheight,
            '& .MuiDataGrid-cell': {
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
            },
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '6px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '6px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '10px' },
          }}
          getRowHeight={() => 'auto'}  
          columnHeaderHeight={32}
          initialState={{
            columns: {
              columnVisibilityModel: columnsVisibility,
            },
          }}
          slots={{
            toolbar: toolbarComponent,
            pagination: CustomPagination,
            noRowsOverlay: () => <></>,
          }}
          slotProps={{
            pagination: {
              pageCount: Math.ceil(listresults.length / rowsperpage),
              paginationModel,
              setPaginationModel,
            },
          }}
          hideFooter={listresults.length <= rowsperpage}
          sortModel={sortModel}
        />
      </Box>
    </Paper>
  );
}
