import DeleteIcon from '@mui/icons-material/Delete';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { AttachFile, Description, PictureAsPdf } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { TailSpin } from 'react-loader-spinner';
import { Alert } from 'reactstrap';
import { LabTestingDocumentTypes } from '../../utils/constants/constants';
import { useAuth } from '../auth/AuthService';

const baseStyle = {
  minWidth: 550,
  minHeight: 400,
  border: '3px dashed #ccc',
  borderColor: '#ccc',
  borderRadius: 1,
  padding: 30,
  backgroundColor: '#eee',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function MLDropzone({
  isOpen,
  setIsOpen,
  documentTypeRequired,
  caseNumber,
  caseSeq,
  onSuccessUpload = () => {},
  isLab = false,
  useFastAPI = false,
}) {
  const { user } = useAuth();
  const [userSeq, setuserSeq] = useState(user?.userSeq);
  const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;
  const API_BASE_URL = useFastAPI ? VITE_FASTAPI_URL : VITE_API_URL;
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [allDocumentType, setAllDocumentType] = useState([]);
  const [documentTypeOption, setDocumentTypeOption] = useState(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const handleClose = () => {
    setIsOpen(false);
    setRejectedFiles([]);
    setAcceptedFiles([]);
    setDocumentTypeOption(null);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const dialogTitle = () => (
    <>
      <div style={{ paddingTop: '1rem' }}>
        {rejectedFiles.length > 0 && (
          <Alert color='danger' isOpen={showErrorAlert} toggle={dismissAlert}>
            {rejectedFiles.map((file, index) => (
              <div style={{ fontSize: '14px' }} key={index}>
                {`${index + 1}. ${file.name.split('.')[0]} : ${file.rejectReason}`}
              </div>
            ))}
          </Alert>
        )}
      </div>
    </>
  );

  const getAPIEndpoint = key => {
    const endpoints = {
      checkCaseId: useFastAPI ? 'check_case_ids' : 'checkcaseid',
      documentTypes: useFastAPI ? 'get_document_types' : 'getdocumenttypes',
      fileUploads: useFastAPI ? 'file_uploads' : 'fileuploads',
    };
    return endpoints[key];
  };

  const dismissAlert = () => {
    setShowErrorAlert(false);
    setRejectedFiles([]);
  };

  const handlePreviewIcon = fileObject => {
    const { type } = fileObject;
    const iconProps = {
      // className: classes.image,
    };

    if (!fileObject.accepted) {
      iconProps.style = {
        border: '2px solid red',
      };
    }

    switch (type) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description {...iconProps} />;
      case 'application/pdf':
        return <PictureAsPdf {...iconProps} />;
      case 'video/mp4':
        return <OndemandVideoIcon {...iconProps} />;
      case 'model/vnd.usdz+zip':
        return <AttachFile {...iconProps} />; // Placeholder for USDZ file format
      case 'model/gltf-binary':
      case 'model/gltf+json':
        return <AttachFile {...iconProps} />; // Placeholder for glTF file format
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  const handleDrop = async (acceptedFiles, rejectedFiles) => {
    const newRejectedFiles = [];
    const caseIds = [];

    // Check each file to see if it has a prohibited extension
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const extension = file.name.split('.').pop();

      if (!['pdf', 'doc', 'docx', 'wpd'].includes(extension.toLowerCase())) {
        file.rejectReason = 'This document has a filetype that is not allowed.';
        file.accepted = false;
        newRejectedFiles.push(file);
        acceptedFiles.splice(i, 1);
        i--; // Adjust index to account for removed item
      }
    }

    // Perform validation on the DocumentType
    acceptedFiles.forEach(file => {
      const [caseId, documentType] = file.name.split('_');

      caseIds.push(caseId?.split('.')[0]);

      if (documentTypeOption) {
        file.accepted = true;
        file.documentTypeName = documentTypeOption.photoTypeName;
        file.documentTypeSeq = documentTypeOption.photoTypeSeq;
      } else {
        const isValidDocumentType = isLab
          ? ['ToxReq', 'ToxRpt'].includes(documentType?.split('.')[0])
          : ['aut', 'vie', 'ToxReq', 'ToxRpt', 'MRF', 'AF'].includes(documentType?.split('.')[0]);

        if (isValidDocumentType) {
          file.accepted = true;
          const documentTypeName = getDocumentTypeName(documentType);
          file.documentTypeName = documentTypeName;
          file.documentTypeSeq = allDocumentType.find(
            documentType => documentType.photoTypeName === documentTypeName
          )?.photoTypeSeq;
        } else {
          file.rejectReason = 'File was not processed due to invalid document type.';
          file.accepted = false;
          newRejectedFiles.push(file);
        }
      }
    });

    if (caseSeq) {
      acceptedFiles.forEach(file => {
        file.caseNumber = caseNumber;
        file.caseSeq = caseSeq;
      });
    } else {
      // Perform validation on the CaseId
      const validatedCaseIds = await validateCaseId(caseIds);

      if (caseIds.length > 0) {
        for (const file of acceptedFiles.filter(file => file.accepted)) {
          const caseId = file.name.split('_')[0]?.split('.')[0];
          const matchedCase = validatedCaseIds?.find(caseData => caseData.caseId === caseId);
          if (matchedCase) {
            const jdxCode = caseId.split('-')[0];
            const hasJdxAccess = user.jdxAccessList.some(jdx => jdx.jdxCode === jdxCode);

            if (hasJdxAccess) {
              file.accepted = true;
              file.caseNumber = matchedCase.caseId;
              file.caseSeq = matchedCase.caseSeq;
            } else {
              file.accepted = false;
              file.rejectReason = 'You do not have access to this jurisdiction case.';
              newRejectedFiles.push(file);
            }
          } else {
            file.accepted = false;
            file.rejectReason = 'File was not processed due to CASEID not found.';
            newRejectedFiles.push(file);
          }
        }
      }
    }
    setAcceptedFiles(acceptedFiles);
    setRejectedFiles(newRejectedFiles);

    if (newRejectedFiles.length > 0) {
      setShowErrorAlert(true);
    }
  };

  const handleFilesSave = files => {
    if (uploadInProgress) {
      return;
    }
    setUploadInProgress(true);

    const acceptedFiles = files.filter(file => file.accepted);
    if (acceptedFiles.length === 0) {
      setUploadInProgress(false);
      return false;
    }

    const formDataUploadFiles = new FormData();
    acceptedFiles.forEach((file, index) => {
      formDataUploadFiles.append(`FILEPARAMS[${index}].CASENO`, file.caseNumber);
      formDataUploadFiles.append(`FILEPARAMS[${index}].CASESEQ`, file.caseSeq);
      formDataUploadFiles.append(`FILEPARAMS[${index}].FILERELATEDTO`, file.documentTypeSeq);
      formDataUploadFiles.append(`FILEPARAMS[${index}].FILERELATEDTONAME`, file.documentTypeName);
      formDataUploadFiles.append(`FILEPARAMS[${index}].FILE`, file);
    });

    formDataUploadFiles.append('USERSEQ', userSeq);

    const uploadingFilesList = acceptedFiles.map(file => file.name);
    setUploadingFiles(uploadingFilesList);

    if (user && user.token) {
      // obtains the corresponding fastapi or dotnet core version of file uploads endpoint
      fetch(`${API_BASE_URL}${getAPIEndpoint('fileUploads')}`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formDataUploadFiles,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          if (data.status === 0) {
            setShowErrorAlert(false);
            setIsOpen(false);
            setDocumentTypeOption(null);
            setAcceptedFiles([]);
            setRejectedFiles([]);
          } else {
            // Handle failed files
            const failedFiles = data.data || [];
            acceptedFiles.map(file => {
              const isFailed = failedFiles.some(failedFile => failedFile === file.name);
              if (isFailed) {
                file.accepted = false;
                file.rejectReason = 'File was not processed';
              }
              return file;
            });

            const updatedRejectedFiles = acceptedFiles.filter(file =>
              failedFiles.includes(file.name)
            );
            setRejectedFiles(updatedRejectedFiles);
            setShowErrorAlert(true);
            setIsOpen(true);
          }
        })
        .catch(e => {
          alert(e);
        })
        .finally(() => {
          setUploadingFiles([]);
          setUploadInProgress(false);
          const successFiles = acceptedFiles.filter(file => file.accepted);
          if (successFiles.length > 0) {
            onSuccessUpload();
          }
        });
    } else {
      console.log('user or token is null');
      setUploadingFiles([]);
      setUploadInProgress(false);
    }
    return false;
  };

  const getDocumentTypeName = documentType => {
    switch (documentType?.split('.')[0]) {
      case 'aut':
        return 'Autopsy Report-Final';
      case 'vie':
        return 'External Examination Report';
      case 'ToxReq':
        return 'Tox Request';
      case 'ToxRpt':
        return 'Toxicology Report';
      case 'MRF':
        return 'Morgue Release Form';
      case 'AF':
        return 'Transcription Files';
      default:
        return '';
    }
  };

  const filteredDocumentTypes = allDocumentType.filter(photoType =>
    LabTestingDocumentTypes.includes(photoType.photoTypeSeq?.toUpperCase())
  );

  const validateCaseId = async caseIds => {
    try {
      const response = await fetch(`${API_BASE_URL}${getAPIEndpoint('checkCaseId')}`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ CaseIds: caseIds }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleDocumentTypeChange = (event, data) => {
    setDocumentTypeOption(data);
  };

  const getDocumenttypes = async () => {
    await fetch(`${API_BASE_URL}${getAPIEndpoint('documentTypes')}`, {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAllDocumentType(data);
      })
      .catch(e => {});
  };

  useEffect(() => {
    getDocumenttypes();
    setRejectedFiles([]);
    setAcceptedFiles([]);
  }, []);

  var { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'application/pdf', // Accept specific MIME type for pdf file
    maxFiles: 50,
    maxSize: 2000000000,
    onDrop: (acceptedFiles, event) => {
      if (documentTypeRequired && !documentTypeOption) {
        // Prevent the drop if document type is required and not selected
        event.preventDefault();
        return;
      }
      handleDrop(acceptedFiles);
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          minWidth: isMobileScreen ? '90%' : isSmallScreen ? '80%' : '50%',
          maxWidth: isMobileScreen ? '90%' : isSmallScreen ? '80%' : '50%',
          margin: '0 auto',
        },
      }}
    >
      <DialogTitle>{dialogTitle()}</DialogTitle>
      <DialogContent>
        <Grid style={{ margin: '1rem 0 1rem 0' }}>
          <Autocomplete
            id='documentType'
            size='small'
            options={isLab ? filteredDocumentTypes : allDocumentType}
            value={documentTypeOption}
            getOptionLabel={option => option.photoTypeName}
            onChange={(event, data) => handleDocumentTypeChange(event, data)}
            fullWidth
            sx={{ maxWidth: '25rem' }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.photoTypeName}</li>
            )}
            renderInput={params => (
              <TextField {...params} label='Document Type' placeholder='Document Type' />
            )}
          />
          {documentTypeRequired && !documentTypeOption && (
            <Typography variant='body2' color='error' style={{ marginTop: 8 }}>
              Please select a document type.
            </Typography>
          )}
        </Grid>
        <Box {...getRootProps({ style: { ...style, minHeight: '100px' } })}>
          <Box sx={{ display: 'block', textAlign: 'center' }}>
            <DriveFolderUploadIcon
              fontSize='large'
              sx={{ color: '#aaa', height: 50, width: 50, marginBottom: '1rem' }}
            />
            <input {...getInputProps()} />
            <Typography variant='h6' sx={{ color: '#aaa' }}>
              Drag & drop files here, or click to select files
            </Typography>
            <Typography variant='subtitle' color='gray'>
              (Only *.pdf, *.docx, *.doc files will be accepted)
            </Typography>
          </Box>
        </Box>

        {acceptedFiles.length > 0 || rejectedFiles.length > 0 ? (
          <List>
            {acceptedFiles.map((fileObject, index) => (
              <ListItem key={fileObject.path}>
                {uploadingFiles.includes(fileObject.name) ? ( // Check if the file is currently being uploaded
                  <TailSpin color='#00BFFF' height={20} width={20} />
                ) : (
                  handlePreviewIcon(fileObject)
                )}
                <ListItemText style={{ marginLeft: '1rem' }} primary={fileObject.path} />
                <DeleteIcon
                  color='secondary'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const updatedAcceptedFiles = acceptedFiles.filter(
                      file => file.path !== fileObject.path
                    );
                    setAcceptedFiles(updatedAcceptedFiles);
                  }}
                />
              </ListItem>
            ))}
          </List>
        ) : null}
      </DialogContent>

      <DialogActions style={{ justifyContent: 'right', margin: '1rem' }}>
        <Button
          variant='contained'
          onClick={() => handleFilesSave(acceptedFiles)}
          autoFocus
          disabled={acceptedFiles && acceptedFiles?.length === 0}
        >
          Upload
        </Button>
        <Button onClick={handleClose} color='error'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
