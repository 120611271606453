import { Delete, Download, PanoramaPhotosphere, ZoomIn, ZoomOut } from '@mui/icons-material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import HomeIcon from '@mui/icons-material/Home';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Snackbar,
  Alert,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Viewer360 from '../../components/360DegreeViewer';
import { ResponseStatus } from '../../types/MedexResponse.type';
import { useAuth } from '../auth/AuthService';
import { GuidEmptySeq } from '../constants/GuidEmptySeq';
import GalleryImage from './GalleryImage';

const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;

const isEquirectangular = image => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      const { width, height } = img;
      resolve(width / height === 2);
    };
    img.onerror = () => resolve(false); // Handle potential loading errors
  });
};

const CustomGallery = ({ images, selectedImageIndex, onImageVoided }) => {
  const auth = useAuth();
  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(appTheme.breakpoints.up('md'));
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [galleryImages, setGalleryImages] = useState(images);
  const [selectedIndex, setSelectedIndex] = useState(selectedImageIndex);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [analyzeImageModalOpen, setAnalyzeImageModalOpen] = useState(false);
  const [analyzeImageModalContent, setAnalyzeImageModalContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [currentImageToVoid, setCurrentImageToVoid] = useState(null);
  const [voidConfirmOpen, setVoidConfirmOpen] = useState(false);
  const [isVoiding, setIsVoiding] = useState(false);

  const [is360, setIs360] = useState(true);
  const [is360DegreeViewerOpen, setIs360DegreeViewerOpen] = useState(false);

  const [originalImage, setOriginalImage] = useState(null);
  const [loadingOriginalImage, setLoadingOriginalImage] = useState(true);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', 
  });
  const [loadingDownloadImage, setLoadingDownloadImage] = useState(false);

  const formik = useFormikContext();

  const handleSnackbarClose = (event, reason) => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleOpen360ImageViewer = () => {
    fetchOriginalImage(galleryImages[selectedIndex].photoSeq);
    setIs360DegreeViewerOpen(true);
  };

  const checkImage = async image => {
    const result = await isEquirectangular(image);
    setIs360(result);
  };

  useEffect(() => {
    setIs360(checkImage(galleryImages[selectedIndex].thumbnail));
  }, [selectedIndex]);

  const renderCustomControls = () => {
    const currentImage = galleryImages[selectedIndex] || null;
    const uploadedByUserSeq = currentImage?.uploadedByUserSeq || GuidEmptySeq;

    const authorizedToVoid =
      uploadedByUserSeq?.toLowerCase() === auth.user?.userSeq?.toLowerCase() ||
      auth.user.roleCheck(['f45']); // role code for CASE-SUPER-ADMIN

    const iconStyle = { color: 'white' }; // since background is dark, used white color for icons

    return (
      <Stack direction='row' justifyContent='center'>
        <Tooltip title='Download' arrow>
          <IconButton onClick={() => handleDownloadClick(currentImage.photoSeq)}>
            {loadingDownloadImage ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              <Download color='primary' />
            )}
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleZoomInClick}>
          <ZoomIn style={iconStyle} />
        </IconButton>
        <IconButton onClick={handleZoomOutClick}>
          <ZoomOut style={iconStyle} />
        </IconButton>
        <IconButton onClick={handleRotateLeftClick}>
          <RotateLeftIcon style={iconStyle} />
        </IconButton>
        <IconButton onClick={handleRotateRightClick}>
          <RotateRightIcon style={iconStyle} />
        </IconButton>
        <Tooltip title='Reset View' arrow>
          <IconButton onClick={handleHomeClick}>
            <HomeIcon style={iconStyle} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Void' arrow>
          <IconButton
            sx={{ ml: 2 }}
            color={authorizedToVoid ? 'error' : 'default'}
            disabled={!authorizedToVoid}
            onClick={() => handleVoidClick(currentImage)}
          >
            <Delete />
          </IconButton>
        </Tooltip>
        {auth?.user?.roleCheck(['d70']) && (
          <Tooltip title='Analyze Image' arrow>
            <IconButton sx={{ ml: 4 }} onClick={handleAnalyzeImageClick}>
              <AutoFixHighIcon style={iconStyle} />
            </IconButton>
          </Tooltip>
        )}
        {is360 && (
          <Tooltip title='Open 360 Degree View' arrow>
            <IconButton sx={{ ml: 4 }} onClick={handleOpen360ImageViewer}>
              <PanoramaPhotosphere style={iconStyle} />
            </IconButton>
          </Tooltip>
        )}
        {/* "Razzle Dazzle" the Dialog for X-ray analysis results */}
        <Dialog
          open={analyzeImageModalOpen}
          onClose={handleCloseDialog}
          maxWidth={false}
          sx={{
            width: '70vw', // Expands the dialog to 90% of the viewport width
            maxWidth: 'none', // Ensures it's not limited
            zIndex: theme => theme.zIndex.modal + 1, // Ensure it's above other modals
            margin: 'auto', // Ensures centering
            left: '50%', // Moves it to the center
            transform: 'translateX(-50%)', // Corrects centering issue
          }}
        >
          {loading ? (
            <>
              <DialogTitle style={{ cursor: 'move' }} id='analyze-image'>
                Analyzing Image... ✨
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 2,
                  }}
                >
                  <CircularProgress size={50} />
                  <Typography variant='body1' sx={{ mt: 2 }}>
                    Please wait. Processing the image
                  </Typography>
                  <Typography variant='body2' sx={{ mt: 1 }}>
                    <strong>This feature is in development</strong>, a preview is being generated
                  </Typography>
                </Box>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogContent
                sx={{
                  p: 3,
                  bgcolor: 'black',
                  color: 'white',
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {/* Left column: the base64 image */}
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: 2,
                    }}
                  >
                    <img
                      src={currentImage.midsize} // e.g. "data:image/png;base64,iVBORw0KGgoAAAANS..."
                      alt='Analyzed'
                      style={{
                        width: '100%',
                        maxWidth: '800px',
                        height: 'auto',
                        borderRadius: 4,
                      }}
                    />
                  </Box>

                  {/* Right column: analysis text */}
                  <Box sx={{ flex: 1, p: 2 }}>
                    <DialogContentText>
                      MDXAI Analysis <br />
                      (This is an <strong>experimental feature</strong>) <br /> <br />
                    </DialogContentText>
                    <DialogContentText component='div'>
                      <div
                        dangerouslySetInnerHTML={{ __html: analyzeImageModalContent }}
                        style={{ whiteSpace: 'pre-wrap' }}
                      />
                    </DialogContentText>
                    <Box sx={{ mt: 2 }}>
                      <TextField fullWidth placeholder='Ask anything ' variant='outlined' />
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions sx={{ bgcolor: 'black', color: 'white' }}>
                <Button variant='contained' color='primary' onClick={handleCloseDialog}>
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
        {/* 360 Degree Photo Viewer */}
        <Dialog
          open={is360DegreeViewerOpen}
          onClose={() => setIs360DegreeViewerOpen(false)}
          aria-labelledby='360-degree-photo-viewer-dialog'
          sx={{
            zIndex: theme => theme.zIndex.modal + 2,
          }}
          PaperProps={{
            style: {
              minWidth: '80%',
              minHeight: '80%',
            },
          }}
        >
          <DialogActions>
            <Button onClick={() => setIs360DegreeViewerOpen(false)}>Close</Button>
          </DialogActions>
          <DialogContent>
            {loadingOriginalImage ? (
              <Typography>Loading Image...</Typography>
            ) : (
              <Viewer360 image={originalImage} />
            )}
          </DialogContent>
        </Dialog>
      </Stack>
    );
  };
  const handleMouseDown = event => {
    event.preventDefault();
    setDragging(true);
    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = event => {
    if (dragging) {
      const offsetX = event.clientX - dragStart.x;
      const offsetY = event.clientY - dragStart.y;
      setDragOffset({ x: offsetX, y: offsetY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const handleTouchStart = event => {
    event.preventDefault();
    const touch = event.touches[0];
    setDragging(true);
    setDragStart({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchMove = event => {
    event.preventDefault();
    if (!dragging) return;
    const touch = event.touches[0];
    const offsetX = touch.clientX - dragStart.x;
    const offsetY = touch.clientY - dragStart.y;
    setDragOffset({ x: offsetX, y: offsetY });
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  const handleDoubleClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = (newImages[selectedIndex].zoomLevel || 1) === 1 ? 2 : 1;
      return newImages;
    });
  };

  const handleZoomInClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = Math.max(
        1,
        (newImages[selectedIndex].zoomLevel || 1) + 0.1
      );
      return newImages;
    });
  };

  const handleZoomOutClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = Math.max(
        0.1,
        (newImages[selectedIndex].zoomLevel || 1) - 0.1
      );
      return newImages;
    });
  };

  const handleHomeClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = 1;
      newImages[selectedIndex].rotationAngle = 0;
      return newImages;
    });
    setDragOffset({ x: 0, y: 0 });
  };

  const handleDownloadClick = async (photoSeq) => {

    if (!photoSeq) {
      console.error('No photoSeq provided for download.');
      return;
    }

    let formData = new FormData();
    formData.append('PhotoSeq', photoSeq);
    setLoadingDownloadImage(true);

    try {
      const res = await fetch(VITE_API_URL + 'casephotos/retrieve/original/single', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + auth.user?.token,
        },
        body: formData,
      });

      if (res.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      } else if (res.status >= 400) {
        throw new Error('An error occurred');
      }

      const data = await res.json();

      // Use the helper function
      const blob = base64ToBlob(data.imageString, 'image/jpeg');
      const url = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = data?.originalFileName ||  data?.photoLabel || 'image_download.jpg';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      window.URL.revokeObjectURL(url);
      setSnackbar({
        open: true,
        message: 'Image downloaded successfully!',
        severity: 'success',
      });
    } catch (e) {
      console.error('Failed to download the image:', e);
      setSnackbar({
        open: true,
        message: e.message || 'Failed to download the image! Please try again.',
        severity: 'error',
      });
    } finally {
      setLoadingDownloadImage(false); 
    }
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  };

  const handleRotateLeftClick = () => {
    const currentImage = galleryImages[selectedIndex];

    if (currentImage) {
      currentImage.rotationAngle = (currentImage.rotationAngle || 0) - 90;
      setGalleryImages([...galleryImages]);
    }
  };

  const handleRotateRightClick = () => {
    const currentImage = galleryImages[selectedIndex];

    if (currentImage) {
      currentImage.rotationAngle = (currentImage.rotationAngle || 0) + 90;
      setGalleryImages([...galleryImages]);
    }
  };

  const handleAnalyzeImageClick = async () => {
    const currentImage = galleryImages[selectedIndex];
    if (currentImage) {
      try {
        setLoading(true);
        setAnalyzeImageModalOpen(true);
        setProgress(0);

        const progressInterval = setInterval(() => {
          setProgress(oldProgress => {
            if (oldProgress >= 100) {
              clearInterval(progressInterval);
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
        }, 200);

        const formData = new FormData();
        formData.append('content', 'Please describe this image to me in detail.');

        const dataURL = currentImage.midsize;

        const imageResponse = await fetch(dataURL);
        const blob = await imageResponse.blob();

        formData.append('images', blob, 'image.jpg');

        const response = await fetch('https://mdxlab.io/mdxai/analyze_image', {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();
        // delay for 6 seconds --> makes the loading/fetching look more legit -will
        // await new Promise(resolve => setTimeout(resolve, 6000));

        if (response.ok) {
          setAnalyzeImageModalContent(`
           ${data.content}
          `);
        } else {
          setAnalyzeImageModalContent('Error: Analysis feature is still under development.');
        }
      } catch (error) {
        console.error('error at image analysis:', error);
        setAnalyzeImageModalContent('Error: Unable to process the request.');
      } finally {
        clearInterval(progress);
        setLoading(false);
      }
    }
  };

  const handleCloseDialog = () => setAnalyzeImageModalOpen(false);

  const handleVoidClick = image => {
    if (image) {
      setCurrentImageToVoid(image);
      setVoidConfirmOpen(true);
    }
  };

  const handleVoidConfirm = async () => {
    if (currentImageToVoid) {
      setIsVoiding(true);
      try {
        const response = await fetch(VITE_API_URL + 'casephotos/void', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            photoSeq: currentImageToVoid.photoSeq,
          }),
        });

        const data = await response.json();

        if (data.status === ResponseStatus.SUCCESS) {
          if (onImageVoided && typeof onImageVoided === 'function') {
            onImageVoided(currentImageToVoid.photoSeq, true, 'Image successfully voided');
          }
        } else {
          if (onImageVoided && typeof onImageVoided === 'function') {
            onImageVoided(
              currentImageToVoid.photoSeq,
              false,
              'Failed to void image: ' + data.message
            );
          }
        }
      } catch (error) {
        console.error('Error voiding image:', error);
        if (onImageVoided && typeof onImageVoided === 'function') {
          onImageVoided(
            currentImageToVoid.photoSeq,
            false,
            'Error voiding image: ' + error.message
          );
        }
      } finally {
        setIsVoiding(false);
        setVoidConfirmOpen(false);
        setCurrentImageToVoid(null);
      }
    }
  };

  const handleVoidCancel = () => {
    setVoidConfirmOpen(false);
    setCurrentImageToVoid(null);
  };

  const handleOnChange = index => {
    setSelectedIndex(index);
  };

  const handleScroll = event => {
    event.preventDefault();
    setScrollAmount(prev => prev + event.deltaY);
  };

  const fetchOriginalImage = async photoSeq => {
    setLoadingOriginalImage(true);

    let formData = new FormData();
    formData.append('PhotoSeq', photoSeq);
    formData.append('UserSeq', auth.user.userSeq);

    await fetch(VITE_API_URL + 'casephotos/retrieve/original/single', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + auth.user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setOriginalImage('data:image/jpeg;base64,' + data.imageString);
        setLoadingOriginalImage(false);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    // Check if the accumulated scroll amount crosses the threshold
    if (Math.abs(scrollAmount) > 100) {
      // Adjust this value for sensitivity
      if (scrollAmount > 0) {
        setSelectedIndex(prevIndex => (prevIndex + 1) % galleryImages.length);
      } else {
        setSelectedIndex(
          prevIndex => (prevIndex - 1 + galleryImages.length) % galleryImages.length
        );
      }
      // Reset scroll amount after updating index
      setScrollAmount(0);
    }
  }, [scrollAmount, galleryImages.length]);

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {galleryImages.length > 0 ? (
        <div style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }}>
          <Gallery
            items={galleryImages}
            startIndex={selectedIndex}
            showIndex={true}
            renderCustomControls={renderCustomControls}
            lazyLoad={true}
            useTranslate3D={true}
            onSlide={handleOnChange}
            renderItem={item => {
              const currentIndex = galleryImages.indexOf(item);
              return (
                <div
                  style={{
                    paddingTop: isMobile ? '100%' : isLaptop ? '45%' : '50%',
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: dragging ? 'grabbing' : 'grab',
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onDoubleClick={handleDoubleClick}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                >
                  <GalleryImage
                    key={item.photoSeq}
                    item={item}
                    dragOffset={dragOffset}
                    selectedIndex={selectedIndex}
                    currentIndex={currentIndex}
                  />
                  {/* <div
                    style={{
                      position: 'absolute',
                      bottom: '30px',
                      left: '110px',
                      color: 'white',
                      padding: '5px',
                    }}
                  >
                    {item.originalFileName}
                  </div> */}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '110px',
                      color: 'white',
                      padding: '5px',
                    }}
                  >
                    {item.photoLabel}
                  </div>
                </div>
              );
            }}
          />
        </div>
      ) : (
        <div>No images to display.</div>
      )}

      {/* Void Confirmation Dialog */}
      <Dialog
        open={voidConfirmOpen}
        onClose={handleVoidCancel}
        aria-labelledby='void-confirm-dialog-title'
        aria-describedby='void-confirm-dialog-description/'
      >
        <DialogTitle id='void-confirm-dialog-title'>
          {currentImageToVoid ? `Void "${currentImageToVoid.photoLabel}"?` : 'Void Image'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='void-confirm-dialog-description' sx={{ fontWeight: 'bold' }}>
            This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVoidCancel} color='primary' autoFocus disabled={isVoiding}>
            Cancel
          </Button>
          <Button onClick={handleVoidConfirm} color='error' disabled={isVoiding}>
            {isVoiding ? <CircularProgress size={24} /> : 'Void'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomGallery;
