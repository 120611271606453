import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../utils/auth/AuthService';

interface GalleryImageProps {
  item: any;
  dragOffset: {
    x: number;
    y: number;
  };
  selectedIndex: number;
  currentIndex: number;
}

const GalleryImage: React.FC<GalleryImageProps> = ({ item, dragOffset, selectedIndex, currentIndex}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();


  const fetchOriginalImage = async (signal : AbortSignal) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('PhotoSeq', item.photoSeq);
    formData.append('UserSeq', user.userSeq);

    try {
      const res = await fetch(VITE_API_URL + 'casephotos/retrieve/original/single', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user?.token,
        },
        body: formData,
        signal,
      });

      if (signal.aborted) {
        console.log('Fetch request was aborted before processing the response.');
        return; // Exit early, do not process the response
      }

      if (res.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      } else if (res.status >= 400) {
        throw new Error('An error occurred');
      }

      const data = await res.json();
      setImageSrc(`data:image/jpeg;base64,${data.imageString}`);
    } catch (e) {
    //   alert(e);
      console.log('fetch original image failed:' + e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    

    if (!item.midsize && selectedIndex === currentIndex) {
      fetchOriginalImage(signal);
    }

    return () => {
      controller.abort();
      setImageSrc(null);
    };
  }, [item.photoSeq, item.midsize, selectedIndex, currentIndex]);



  return (
    <div>
      <img
        key={item.photoSeq}
        src={item.midsize ? item.midsize : imageSrc || item.thumbnail}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          transform: `translate(${dragOffset.x}px, ${dragOffset.y}px) rotate(${
            item.rotationAngle || 0
          }deg) scale(${item.zoomLevel || 1})`,
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        }}
        loading='lazy'
      />

      {/* Optional Loading Overlay */}
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#fff',
            padding: '6px 12px',
            borderRadius: '4px',
            fontSize: '14px',
          }}
        >
          Loading...
        </div>
      )}
    </div>
  );
};

export default GalleryImage;
