import {
  Autocomplete,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import CaseNotes from '../../../components/CaseNotes';
import CaseDocuments from '../../../components/CaseRecords/CaseDocuments';
import CaseViewDateTimeField from '../../../components/CaseView/CaseViewDateTimeField';
import { DeathCertificatePreviewView } from '../../../components/CaseView/DeathCertificatePreviewView';
import PathologyOptionsAutocomplete from '../../../components/CaseView/PathologyView/PathologyOptionsAutocomplete';
import { useUnsavedChanges } from '../../../Context/UnsavedChangesContext';
import { useAuth } from '../../../utils/auth/AuthService';
import { AssignmentTypes, ME_Action } from '../../../utils/constants/constants';
import useStickyState from '../../../utils/functions/useStickyState';
import PathologyHUD from './PathologyModule/PathologyHUD';

export default function PathologyView() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useStickyState(
    0,
    'case.pathology.tab',
    location.state.cmscaseid
  );
  const [mannerOfDeathOptions, setMannerOfDeathOptions] = useState([]);
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const theme = useTheme();
  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
    '0b24365b-1159-4359-b302-9df8946bd62f', // Forensic Technician
  ]);
  const authorizedToRelease = user.roleCheck([
    'RCA', // Release approval
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
  ]);
  const [isDatasheetUploaded, setIsDatasheetUploaded] = useState(false);
  const { setUnsavedChanges } = useUnsavedChanges();

  const fetchIsDatasheetUploaded = async () => {
    await fetch(
      VITE_API_URL + 'checkifdatasheetuploaded?caseSeq=' + formik.values?.caseSummary.caseSeq,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        },
      }
    )
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setIsDatasheetUploaded(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    // INITIALIZE MANNER OF DEATH OPTIONS
    fetch(VITE_API_URL + 'getmannerofdeathoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setMannerOfDeathOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    fetchIsDatasheetUploaded();
  }, []);

  const formik = useFormikContext();

  return (
    <>
      <form>
        {/* <Typography
          variant='subtitle1'
          color='gray'
          sx={{ fontSize: '.775rem' }}
        >
          <strong>PATHOLOGY</strong>
        </Typography> <br /> */}
        <TextField
          label=''
          // @ts-ignore
          value={formik.values?.caseInvestigations?.synopsis ?? '--'}
          InputProps={{
            readOnly: true,
          }}
          variant='outlined'
          fullWidth
          multiline
          maxRows={6}
          disabled
          sx={{
            fontSize: '0.8rem',
            '& .MuiInputBase-root': {
              fontSize: '0.8rem',
            },
            '& .Mui-disabled': {
              // WebkitTextFillColor: '#000', // Ensure text remains black when disabled
            },
          }}
        />{' '}
        <br /> <br />
        {formik.values?.caseInvestigations?.meAction?.optionSeq === ME_Action.Accepted && (
          <PathologyHUD />
        )}
        <Grid container spacing={1}>
          {formik.values?.caseInvestigations?.meAction?.optionSeq === ME_Action.Release && (
            <>
              <Grid item xs={6} md={6}>
                <Typography
                  variant='subtitle1'
                  color='gray'
                  sx={{ fontSize: '0.775rem' }} // Adjust the font size as needed
                >
                  ME Release Approval:
                </Typography>

                <RadioGroup
                  name='casePathology.meApproval'
                  value={formik.values?.casePathology?.meApproval ?? null}
                  onChange={event => {
                    formik.handleChange(event);
                    setUnsavedChanges();
                    if (event.target.value === 'Approved') {
                      formik.setFieldValue('casePathology.rejectedReason', '');
                      formik.setFieldValue('casePathology.meApprovedBy', {
                        optionSeq: user?.userSeq || null,
                        optionName: `${user?.userLastName || ''}${
                          user?.userLastName && user?.userFirstName ? ', ' : ''
                        }${user?.userFirstName || ''}`,
                      });

                      // formik.setFieldValue(
                      //   'caseInvestigations.pocAssignedDateTime',
                      //   formik.values?.caseInvestigations?.pocAssignedDateTime ??
                      //     new Date().toISOString()
                      // );
                    }
                  }}
                  row
                >
                  <FormControlLabel
                    disabled={
                      !formik.status.editing || !authorizedToRelease || !isDatasheetUploaded
                    }
                    value='Approved'
                    control={<Radio />}
                    label={<Typography variant='body2'>Confirmed</Typography>}
                  />
                  <FormControlLabel
                    disabled={
                      !formik.status.editing || !authorizedToRelease || !isDatasheetUploaded
                    }
                    value='Rejected'
                    control={<Radio />}
                    label={<Typography variant='body2'>Rejected</Typography>}
                  />
                </RadioGroup>
              </Grid>

              {formik.values?.casePathology?.meApproval === 'Approved' && (
                <Grid item xs={12} md={3}>
                  <Typography variant='body1' style={{ display: 'inline' }}>
                    {'Confirmed By: '}
                  </Typography>
                  <Typography variant='body2' style={{ display: 'inline' }}>
                    {formik.values?.casePathology?.meApprovedBy?.optionName}
                  </Typography>
                </Grid>
              )}

              {formik.values?.casePathology?.meApproval === 'Rejected' && (
                <Grid item xs={12} md={9}>
                  <TextField
                    disabled={!formik.status.editing || !authorizedToRelease}
                    id='casePathology.rejectedReason'
                    name='casePathology.rejectedReason'
                    label='Reason For Rejection'
                    placeholder='Reason For Rejection'
                    variant='outlined'
                    size='small'
                    fullWidth
                    value={
                      formik.values?.casePathology != null
                        ? formik.values?.casePathology?.rejectedReason
                        : null
                    }
                    onChange={e => {
                      formik.handleChange(e);
                      setUnsavedChanges();
                    }}
                    error={formik.touched.rejectedReason && Boolean(formik.errors.rejectedReason)}
                    helperText={formik.touched.rejectedReason && formik.errors.rejectedReason}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        {/* <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} /> */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            marginBottom: 2,
            position: 'sticky',
            top: 0,
            zIndex: 1001,
            backgroundColor: theme.palette.background.default,
          }}
          variant='scrollable'
          scrollButtons='auto'
          allowScrollButtonsMobile
        >
          <Tab label='Pathology' />
          <Tab label='Documents' />
          {user?.roleCheck(['2d0']) && <Tab label='DC Preview' />}

          {/* <Tab label='Report Designer (coming later)' /> */}
        </Tabs>
        {selectedTab === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Autocomplete
                disabled={!formik.status.editing || !authorizedToEdit}
                id='casePathology.mannerOfDeath'
                name='casePathology.mannerOfDeath'
                size='small'
                options={mannerOfDeathOptions}
                isOptionEqualToValue={(option, value) =>
                  option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                }
                value={formik.values?.casePathology.mannerOfDeath || null}
                onChange={(e, value) => {
                  formik.setFieldValue('casePathology.mannerOfDeath', value || null);
                  setUnsavedChanges();
                }}
                error={
                  (formik.touched?.mannerOfDeath ?? false) &&
                  Boolean(formik.errors?.mannerOfDeath ?? false)
                }
                helperText={
                  (formik.touched?.mannerOfDeath ?? false) &&
                  (formik.errors?.mannerOfDeath ?? false)
                }
                getOptionLabel={option => option.optionName}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      <div>
                        {'Manner Of Death '}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                disabled={!formik.status.editing || !authorizedToEdit}
                fullWidth
                id='casePathology.causeOfDeath'
                name='casePathology.causeOfDeath'
                label={
                  <div>
                    {'Cause Of Death '}
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                size='small'
                variant='outlined'
                value={formik.values != null ? formik.values?.casePathology.causeOfDeath : null}
                onChange={e => {
                  formik.handleChange(e);
                  setUnsavedChanges();
                }}
                error={
                  (formik.touched?.causeOfDeath ?? false) &&
                  Boolean(formik.errors?.causeOfDeath ?? false)
                }
                helperText={
                  (formik.touched?.causeOfDeath ?? false) && (formik.errors?.causeOfDeath ?? false)
                }
              />
            </Grid>

            <CaseViewDateTimeField
              xs={12}
              md={4}
              label='Date of Death'
              authorizedToEdit={authorizedToEdit}
              fieldName='caseDemographics.dateOfDeath'
            />
            <Grid item xs={12} md={6}>
              <TextField
                disabled={!formik.status.editing || !authorizedToEdit}
                fullWidth
                id='casePathology.contributory'
                name='casePathology.contributory'
                label='Contributory'
                size='small'
                multiline
                minRows={3}
                maxRows={10}
                variant='outlined'
                value={formik.values != null ? formik.values?.casePathology.contributory : null}
                onChange={e => {
                  formik.handleChange(e);
                  setUnsavedChanges();
                }}
                error={
                  (formik.touched?.contributory ?? false) &&
                  Boolean(formik.errors?.contributory ?? false)
                }
                helperText={
                  (formik.touched?.contributory ?? false) && (formik.errors?.contributory ?? false)
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                disabled={!formik.status.editing || !authorizedToEdit}
                fullWidth
                id='casePathology.howInjuryOccurred'
                name='casePathology.howInjuryOccurred'
                label='How Injury Occurred'
                variant='outlined'
                size='small'
                multiline
                minRows={3}
                maxRows={10}
                value={formik.values != null ? formik.values?.casePathology.howInjuryOccurred : ''}
                onChange={e => {
                  formik.handleChange(e);
                  setUnsavedChanges();
                }}
                error={
                  (formik.touched?.casePathology?.howInjuryOccurred ?? false) &&
                  Boolean(formik.errors?.casePathology?.howInjuryOccurred ?? false)
                }
                helperText={
                  (formik.touched?.casePathology?.howInjuryOccurred ?? false) &&
                  formik.errors?.casePathology?.howInjuryOccurred
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl component='fieldset'>
                <FormLabel>Drug Toxicity Confirmed</FormLabel>
                <RadioGroup
                  name='casePathology.drugToxicityConfirmed'
                  value={
                    formik.values != null
                      ? formik.values?.casePathology?.drugToxicityConfirmed
                      : null
                  }
                  onChange={e => {
                    formik.handleChange(e);
                    setUnsavedChanges();
                  }}
                  row
                >
                  <FormControlLabel
                    disabled={!formik.status.editing || !authorizedToEdit}
                    value={true}
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    disabled={!formik.status.editing || !authorizedToEdit}
                    value={false}
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl component='fieldset'>
                <FormLabel>Infectious Disease Confirmed</FormLabel>
                <RadioGroup
                  name='casePathology.infectiousDiseaseConfirmed'
                  value={
                    formik.values != null
                      ? formik.values?.casePathology?.infectiousDiseaseConfirmed
                      : null
                  }
                  onChange={e => {
                    formik.handleChange(e);
                    setUnsavedChanges();
                  }}
                  row
                >
                  <FormControlLabel
                    disabled={!formik.status.editing || !authorizedToEdit}
                    value={true}
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    disabled={!formik.status.editing || !authorizedToEdit}
                    value={false}
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
                {(formik.values?.casePathology.infectiousDiseaseConfirmed === true ||
                  formik.values?.casePathology.infectiousDiseaseConfirmed === 'true') && (
                  <TextField
                    fullWidth
                    id='casePathology.otherInfectiousDiseaseConfirmed'
                    name='casePathology.otherInfectiousDiseaseConfirmed'
                    label='Infectious Disease Name'
                    size='small'
                    variant='outlined'
                    value={formik.values?.casePathology?.otherInfectiousDiseaseConfirmed || ''}
                    onChange={e => {
                      formik.handleChange(e);
                      setUnsavedChanges();
                    }}
                    disabled={!formik.status.editing || !authorizedToEdit}
                  />
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl component='fieldset'>
                <FormLabel>Opioid Death Confirmed</FormLabel>
                <RadioGroup
                  name='casePathology.opioidConfirmed'
                  value={
                    formik.values != null ? formik.values?.casePathology?.opioidConfirmed : null
                  }
                  onChange={e => {
                    formik.handleChange(e);
                    setUnsavedChanges();
                  }}
                  row
                >
                  <FormControlLabel
                    disabled={!formik.status.editing || !authorizedToEdit}
                    value={true}
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    disabled={!formik.status.editing || !authorizedToEdit}
                    value={false}
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display:
                  formik.values?.casePathology?.mannerOfDeath?.optionSeq?.toLowerCase() ===
                    'f202409a-4520-4d49-8b8e-aca95685996a' ||
                  formik.values?.casePathology?.mannerOfDeath?.optionSeq?.toLowerCase() ===
                    '7f317add-dfa8-4398-8ac6-00b22bb9bcdc' ||
                  formik.values?.casePathology?.mannerOfDeath?.optionSeq?.toLowerCase() ===
                    '9242c34b-70da-4f0f-af67-f71f1fecb8f9'
                    ? 'block'
                    : 'none',
              }}
            >
              <Stack spacing={2}>
                <Divider sx={{ borderColor: '#555' }} />
                <PathologyOptionsAutocomplete
                  title='Condition Of Body'
                  fieldName='conditionOfBody'
                  apiEndpoint='getconditionofbodyoptions'
                />

                <PathologyOptionsAutocomplete
                  title='Unnatural Death Categories'
                  fieldName='unnaturalDeath'
                  apiEndpoint='getunnaturaldeathoptions'
                />

                <PathologyOptionsAutocomplete
                  title='Circumstances Related To Death'
                  fieldName='circumstancesRelatedToDeath'
                  apiEndpoint='getcircumstancesrelatedtodeathoptions'
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
              <Typography variant='subtitle1' color='gray'>
                <strong>CASE NOTES</strong>
              </Typography>
              <br />
              <CaseNotes department={AssignmentTypes.Pathology} />
            </Grid>
          </Grid>
        )}
        {selectedTab === 1 && (
          <Grid item xs={12}>
            <CaseDocuments caseinfo={formik.values?.caseSummary} />
          </Grid>
        )}
        {selectedTab === 2 && <DeathCertificatePreviewView />}
      </form>
    </>
  );
}
