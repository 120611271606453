import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ConversationTypes } from '../../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/conversation.types';
import { useConversation } from '../../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';
import { useAuth } from '../auth/AuthService';

const { VITE_API_URL } = import.meta.env;

interface ErrorFallbackProps {
  error: any;
  errorInfo?: React.ErrorInfo | null;
  resetErrorBoundary?: () => void;
}

export function ErrorFallback_View({ error, errorInfo, resetErrorBoundary }: ErrorFallbackProps) {
  const location = useLocation();
  const auth = useAuth();
  const [sendingError, setSendingError] = useState<string | null>(null);
  const { createChat } = useConversation();
  const sendErrorReport = async () => {
    if (import.meta.env.MODE === 'development') return;

    const timestamp = new Date().toISOString();
    const formData = new FormData();

    formData.append('topic', `Error ${auth.user?.userName} ${timestamp}`);
    formData.append('conversationTypeSeq', ConversationTypes.Error_Report);

    const errorMessage = `
This is an automatically generated error report.
Timestamp: ${timestamp}

Error Details:
${
  error instanceof Error
    ? `
Type: ${error.name}
Message: ${error.message}
Stack Trace:
${error.stack}
`
    : `Unstructured Error:
${JSON.stringify(error, null, 2)}`
}

<strong>Component Stack:</strong>
${errorInfo?.componentStack}

Client Information:
User Agent: ${window.navigator.userAgent}
Screen Resolution: ${window.screen.width}x${window.screen.height}
Window Size: ${window.innerWidth}x${window.innerHeight}
Device Pixel Ratio: ${window.devicePixelRatio}

Route Information:
Path: ${location.pathname}
State: ${JSON.stringify(location.state)}

This report was generated automatically when the error occurred.
No action is required from the user.`;

    formData.append('initialMessage', errorMessage);

    try {
      await createChat(
        {
          topic: `Error ${auth.user?.userName} ${timestamp}`,
          messages: [
            {
              messageContent: errorMessage,
              sender: { userSeq: auth.user?.userSeq },
              readBy: [{ userSeq: auth.user?.userSeq }],
              attachments: [],
            },
          ],
          creator: {
            userSeq: auth.user?.userSeq,
          },
          type: {
            conversationTypeSeq: ConversationTypes.Error_Report,
          },
          isOpen: true,
          isActive: true,
          participants: [{ userSeq: auth.user?.userSeq }],
        },
        {
          caseId: location.state?.cmscaseid || null,
          pathname: location.pathname,
        }
      );

      console.log('Error report conversation created');
    } catch (e) {
      setSendingError(
        e instanceof Error ? e.message : 'An unknown error occurred while sending the error report'
      );
    }
  };

  useEffect(() => {
    sendErrorReport();
  }, []);

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        minHeight: '100%',
        textAlign: 'center',
      }}
    >
      <Typography variant='h4' gutterBottom sx={{ fontWeight: 'bold' }}>
        Uh oh :(
      </Typography>
      <Typography variant='body1' gutterBottom>
        Something went wrong:
      </Typography>
      <pre
        style={{
          color: 'red',
          textAlign: 'left',
          overflowX: 'auto',
          maxWidth: '100%',
          whiteSpace: 'pre-wrap',
        }}
      >
        {error instanceof Error && (
          <Stack>
            <strong>{error.name} Details:</strong>
            <p>Message: {error.message}</p>
            <div>
              <strong>Stack:</strong>
              {error.stack?.split('\n').map((line, i) => (
                <div key={i} style={{ marginLeft: i > 0 ? '20px' : '0' }}>
                  {line}
                </div>
              ))}
            </div>
          </Stack>
        )}
        {errorInfo && (
          <Stack>
            <strong>Component Stack:</strong>
            <div>
              {errorInfo?.componentStack?.split('\n').map((line, i) => (
                <div key={i} style={{ marginLeft: i > 0 ? '20px' : '0' }}>
                  {line}
                </div>
              ))}
            </div>
          </Stack>
        )}
        <Stack>
          <strong>Location Details:</strong>
          <p>Pathname: {location.pathname}</p>
          <p>State: {JSON.stringify(location.state)}</p>
        </Stack>
        <Stack>
          <strong>Browser Details:</strong>
          <p>User Agent: {window.navigator.userAgent}</p>
          <strong>Device Details:</strong>
          <p>
            Width: {window.screen.width}, Height: {window.screen.height}
          </p>
        </Stack>
      </pre>
      {sendingError && (
        <Typography color='error' gutterBottom>
          Error sending report: {sendingError}
        </Typography>
      )}
    </Box>
  );
}
