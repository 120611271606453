import React, { useEffect, useState } from 'react';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Tabs,
  Tab,
  Box,
  Chip,
  Snackbar,
  Alert,
  AlertColor,
  IconButton,
  Slide,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CustomHeader from '../../utils/components/CustomHeader';
import axios from 'axios';
import DataGridComponent from '../../components/AdministrationModules/DataGridComponent';
import { UserAccount } from '../../../src/views/Support/UserAccountModule/UserAccount.type';
import UserAccountDialog from './UserAccountModule/UserAccountDialog';
import { useAuth } from '../../utils/auth/AuthService';
import { formatPhoneNumber } from '../../utils/functions/formatPhoneNumber';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import { SlideProps } from '@mui/material/Slide';

const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function MDXUserAccounts() {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<UserAccount[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserAccount[]>([]);
  const [showActive, setShowActive] = useState<boolean>(true); // For active/inactive toggle
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserAccount | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const { user } = useAuth();
  const [userAccountSnackbar, setUserAccountSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] = useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState<{
    isActive?: boolean;
    userSeq?: string;
    status?: string;
  }>({});

  const handleCloseUserAccountSnackbar = () => {
    setUserAccountSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleSaveActionComplete = (status: string) => {
    handleCloseModal();
    if (status === 'success') {
      setUserAccountSnackbar({
        open: true,
        severity: 'success',
        message: `User account was updated successfully`,
      });
      fetchUsers();
    } else {
      setUserAccountSnackbar({
        open: true,
        severity: 'error',
        message: 'An error occurred, unable to update the user account',
      });
    }
  };

  const handleOpenModal = (selectedUser: UserAccount) => {
    setSelectedUser(selectedUser);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setOpenModal(false);
  };

  const handleActiveInActiveClick = async () => {
    try {
      const response = await fetch(
        `${VITE_API_URL}user/active-inactive-user?userSeq=${activeInactiveClickData.userSeq}&isActive=${activeInactiveClickData.isActive}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update user status');
      }

      setOpenActiveInActiveDialogBox(false);
      setUserAccountSnackbar({
        open: true,
        severity: 'success',
        message: `User account was ${
          activeInactiveClickData.isActive ? 'activated' : 'inactivated'
        } successfully`,
      });
      fetchUsers();
    } catch (error) {
      console.error('Error:', error);
      setUserAccountSnackbar({
        open: true,
        severity: 'error',
        message: `An error occurred, unable to  ${
          activeInactiveClickData.isActive ? 'activate' : 'inactivate'
        } the user account`,
      });
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);

      // const response = await fetch(`${VITE_FASTAPI_URL}users/get-user-accounts`, {   --python endpoint
      const response = await fetch(`${VITE_API_URL}user/getallusers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch user accounts');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all users from the API
  useEffect(() => {
    fetchUsers();
  }, []);

  // Filter users based on active/inactive status and search filter
  useEffect(() => {
    const filtered = users.filter(user => {
      const isActiveMatch = showActive ? user.isActive === true : user.isActive === false;
      const searchTerm = searchFilter.toLowerCase();
      const matchesSearch =
        searchFilter.trim() === '' ||
        user.personLastName?.toLowerCase().includes(searchTerm) ||
        user.personFirstName?.toLowerCase().includes(searchTerm) ||
        user.roles?.join(', ')?.toLowerCase().includes(searchTerm) ||
        user.jurisdictions?.join(', ')?.toLowerCase().includes(searchTerm); // Add jurisdictions here
      return isActiveMatch && matchesSearch;
    });

    setFilteredUsers(filtered);
  }, [users, showActive, searchFilter]);

  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Username',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Button variant='text' color='primary' onClick={() => handleOpenModal(params.row)}>
          {params.value}
        </Button>
      ),
    },
    { field: 'personLastName', headerName: 'Last Name', flex: 1 },
    { field: 'personFirstName', headerName: 'First Name', flex: 1 },
    { field: 'primaryEmail', headerName: 'Email', flex: 1 },
    {
      field: 'primaryMobileNo',
      headerName: 'Mobile No',
      flex: 1,
      valueGetter: (value: string | null) => formatPhoneNumber(value ?? '', true),
    },
    {
      field: 'jurisdictions',
      headerName: 'Jurisdictions',
      flex: 2,
      renderCell: params => {
        const jurisdictions = (params.value || []).map(
          (jurisdiction: { jdxName: string }) => jurisdiction.jdxName
        );
        return (
          <Box
            component={'div'}
            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: '4px', mb: '4px' }}
          >
            {jurisdictions.map((jdxName: string, index: number) => (
              <Chip
                key={index}
                label={jdxName}
                // variant='outlined'
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'normal',
                  },
                }}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: 'roles',
      headerName: 'Roles',
      flex: 2,
      renderCell: params => {
        const roles = (params.value || []).map((role: { roleName: string }) => role.roleName);
        return (
          <Box
            component={'div'}
            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: '4px', mb: '4px' }}
          >
            {roles.map((roleName: string, index: number) => (
              <Chip
                key={index}
                label={roleName}
                // variant='outlined'
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'normal',
                  },
                }}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      align: 'left',
      flex: 1,
      renderCell: params => {
        const onActiveInActiveClick = async (row: any) => {
          setOpenActiveInActiveDialogBox(true);
          const isActive = row.status === 'Active' ? false : true;
          setActiveInactiveClickData({
            isActive: isActive,
            userSeq: row['userSeq'],
            status: row.status,
          });
        };
        return (
          <IconButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onActiveInActiveClick(params.row);
            }}
            color='primary'
            size='small'
          >
            {params.row.status === 'Active' ? (
              <Tooltip title='Inactive'>
                <LockIcon />
              </Tooltip>
            ) : (
              <Tooltip title='Active'>
                <DoneIcon />
              </Tooltip>
            )}
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <Snackbar
        open={userAccountSnackbar.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ position: 'absolute', top: 5 }}
        autoHideDuration={5000}
        onClose={handleCloseUserAccountSnackbar}
      >
        <Alert
          onClose={handleCloseUserAccountSnackbar}
          severity={userAccountSnackbar.severity as AlertColor}
          variant='filled'
          sx={{ width: '100%' }}
        >
          <Typography variant='body1' fontWeight={500}>
            {userAccountSnackbar.message}
          </Typography>
        </Alert>
      </Snackbar>

      <CustomHeader title='User Accounts' description='View all Current User accounts and roles' />

      {/* Radio button for Active/Inactive */}
      <FormControl>
        <FormLabel id='active-status'>Users:</FormLabel>
        <RadioGroup
          row
          aria-labelledby='active-status'
          name='active-status-group'
          value={showActive ? 'active' : 'inactive'}
          onChange={event => setShowActive(event.target.value === 'active')}
        >
          <FormControlLabel value='active' control={<Radio />} label='Active' />
          <FormControlLabel value='inactive' control={<Radio />} label='Inactive' />
        </RadioGroup>
      </FormControl>

      {/* Search Filter */}
      <TextField
        label='Filter by Last Name, First Name, Jurisdiction or Role'
        variant='outlined'
        fullWidth
        value={searchFilter}
        onChange={e => setSearchFilter(e.target.value)}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      />

      {/* Conditional rendering with loading state */}
      {loading ? (
        <div>Loading users...</div>
      ) : (
        // ) : filteredUsers.length === 0 ? (
        //   <div>No users found matching the current filters.</div>
        <div>
          <DataGridComponent
            loading={loading}
            idcolumn={'userSeq'}
            listresults={filteredUsers}
            columnsInput={columns}
            rowsperpage={100}
            onRowClick={params => handleOpenModal(params.row)}
            gridheight={filteredUsers.length > 0 ? 'auto' : 200}
          />
        </div>
      )}
      {openModal && (
        <UserAccountDialog
          open={openModal}
          onClose={handleCloseModal}
          selectedUser={selectedUser ?? null}
          onSaveActionComplete={handleSaveActionComplete}
        />
      )}

      <div className='modal'>
        <Dialog
          open={openActiveInActiveDialogBox}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenActiveInActiveDialogBox(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Are you sure you want to{' '}
              {activeInactiveClickData?.status === 'Active' ? 'InActive ' : 'Active'} this user
              account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>No</Button>
            <Button onClick={handleActiveInActiveClick}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
