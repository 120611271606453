import { Info, Warning } from '@mui/icons-material';
import ChatIcon from '@mui/icons-material/Chat';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import {
  DataGridPremium,
  GridColDef,
  GridToolbarContainer,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import CustomHeader from '../../utils/components/CustomHeader';
import UsernameChecker from '../../utils/components/UserIDChecker';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Jurisdiction } from '../../types/Jurisdiction.interface';
import {
  CreateAccount_DefaultBlankNewUser,
  createAccount,
} from '../../utils/functions/createAccount';
import { UserProfile } from '../DVCS/CallCenterUserDialog';
import { useConversation } from '../SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';
dayjs.extend(utc);

export interface AddressViewModel {
  addressType?: Option;
  addressLine1?: string;
  addressLine2?: string;
  addressAptSuite?: string;
  addressFloor?: string;
  addressCity?: string;
  addressZip?: string;
  stateSeq: string;
  countrySeq: string;
}

export interface SupervisorViewModel {
  userSeq: string;
  userName: string;
  firstName: string;
  lastName: string;
}

export interface EmployeeViewModel {
  firstName: string;
  lastName: string;
  middleName?: string;
  phoneNumber: string;
  email: string;
  supervisor?: SupervisorViewModel;
}

interface RoleViewModel {
  optionSeq: string;
  optionName: string;
  isActive: boolean;
  isPermission: boolean;
  description?: string;
}

interface Role extends Option {
  isPermission: boolean;
}

export type AccountRequestStaus = 'PENDING' | 'APPROVED' | 'DENIED';

export interface AccountRequestViewModel {
  accountRequestSeq: string;
  denialReason: string | null;
  existingEmployeeUserSeq?: string | null;
  type: string;
  roles: RoleViewModel[];
  jurisdictions: Jurisdiction[];
  requestedBy: string;
  requestedOn: string;
  notes?: string | null;
  address: AddressViewModel;
  employee: EmployeeViewModel;
  status: AccountRequestStaus;
  conversationSeq: string | null;
}
export default function AccountRequestView() {
  const apiRef = useGridApiRef();
  const { sendMessage } = useConversation();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [requests, setRequests] = useState<AccountRequestViewModel[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeactivationDialog, setOpenDeactivationDialog] = useState(false);

  const [selectedRequest, setSelectedRequest] = useState<AccountRequestViewModel | null>(null);
  const [action, setAction] = useState<'view' | 'approve' | 'deny'>('view');
  const [suggestedUsername, setSuggestedUsername] = useState<string | null>(null);
  const [newUserData, setNewUserData] = useState<UserProfile>(CreateAccount_DefaultBlankNewUser);
  const [denialReason, setDenialReason] = useState('');
  const [denialError, setDenialError] = useState('');
  const [columnVisibility, setColumnVisibility] = useState({
    action: true,
  });

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'info' | 'warning'
  >('success');

  const generateUsername = (fullName: string) => {
    const nameParts = fullName.split(' ');
    if (nameParts.length >= 2) {
      const lastName = nameParts[nameParts.length - 1];
      const firstInitial = nameParts[0][0];
      return `${lastName}${firstInitial}`.toLowerCase();
    }
    return '';
  };

  const handleView = (request: AccountRequestViewModel) => {
    setSelectedRequest(request);
    setAction('view');
    if (request.type === 'Deactivation') {
      setOpenDeactivationDialog(true);
    } else {
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
    setAction('view');
    setDenialReason('');
    setDenialError('');
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value as 'view' | 'approve' | 'deny');
  };

  const fetchRequests = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${import.meta.env.VITE_FASTAPI_URL}account-requests/all`, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching account requests: ${response.statusText}`);
      }

      const data: AccountRequestViewModel[] = await response.json();
      setRequests(data);
    } catch (err: any) {
      console.error(err);
      setError(err.message || 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAction = async () => {
    if (action === 'approve') {
      await handleConfirmApprove();
    } else if (action === 'deny') {
      if (!denialReason.trim()) {
        setDenialError('Please provide a reason for denial');
        return;
      }
      await handleDenyConfirm(denialReason);
    } else {
      handleClose();
    }
  };

  const handleConfirmApprove = async () => {
    try {
      const new_account: UserProfile = { ...CreateAccount_DefaultBlankNewUser };
      new_account.userName = suggestedUsername || '';
      new_account.primaryEmail = selectedRequest?.employee?.email || '';
      new_account.primaryMobileNo = selectedRequest?.employee?.phoneNumber || '';
      new_account.userFirstName = selectedRequest?.employee?.firstName || '';
      new_account.userMiddleName = selectedRequest?.employee?.middleName || '';
      new_account.userLastName = selectedRequest?.employee?.lastName || '';
      new_account.roleseq = selectedRequest?.roles?.map(r => `${r.optionSeq} || True`) || [];
      new_account.countySeqandDashboard =
        selectedRequest?.jurisdictions?.map(
          jdx => `${jdx.jdxSeq.toUpperCase()} || True || False`
        ) || [];
      new_account.userSeq = '00000000-0000-0000-0000-000000000000';
      new_account.userAddress = [
        {
          ...new_account.userAddress[0],
          addressTypeSeq: selectedRequest?.address?.addressType?.optionSeq || '',
          addressLine1: selectedRequest?.address?.addressLine1 || '',
          addressLine2: selectedRequest?.address?.addressLine2 || '',
          city: selectedRequest?.address?.addressCity || '',
          zip: selectedRequest?.address?.addressZip || '',
          stateSeq: selectedRequest?.address?.stateSeq || '',
          countrySeq: selectedRequest?.address?.countrySeq || '',
          creationDateTime: new Date().toISOString(),
        },
      ];
      new_account.userContacts = [
        {
          ...new_account.userContacts[0],
          contactItemSeq: '00000000-0000-0000-0000-000000000000',
          contactItemTypeSeq: '176240f7-30a9-40dd-af0c-304226e1bfea',
          contactItemDetails: selectedRequest?.employee?.phoneNumber || '',
          contactOrder: '1',
          isActive: true,
          contactItemIsPrimary: false,
          // @ts-ignore
          status: 'Active',
        },
      ];
      setNewUserData(new_account);
      const response = await createAccount({
        new_user: new_account,
        approving_user_seq: user?.userSeq || '',
        access_token: user?.accessToken || '',
      });

      const { data: created_user, status } = await response.json();

      await fetch(`${import.meta.env.VITE_API_URL}user/generatetemporarypassword`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          securityAnswer: '',
          securityQuestionSeq: '00000000-0000-0000-0000-000000000000',
          userSeq: created_user.userSeq,
        }),
      });

      const account_approved_response = await fetch(
        `${import.meta.env.VITE_FASTAPI_URL}account-requests/approve`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            accountRequestSeq: selectedRequest?.accountRequestSeq,
            actionByUserSeq: user?.userSeq,
          }),
        }
      );

      if (account_approved_response.ok && selectedRequest?.conversationSeq) {
        await sendMessage({
          chatMessage: {
            messageContent: `This account request has been approved. <br/>UserID: <strong>${created_user.userName}</strong><br/>Login instructions have been sent to <strong>${created_user.primaryEmail}</strong>`,
            sender: { userSeq: user?.userSeq },
            readBy: [{ userSeq: user?.userSeq }],
            attachments: [],
          },
          conversationSeq: selectedRequest?.conversationSeq,
        });
      }

      setNewUserData(created_user);

      // Display success snackbar
      setSnackbarMessage(
        `Account for ${selectedRequest?.employee.firstName} ${selectedRequest?.employee.lastName} has been approved`
      );
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      // Refetch requests to update the list
      await fetchRequests();

      handleClose();
    } catch (error) {
      console.error('Error approving account request:', error);
      setSnackbarMessage('Failed to approve account request');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDenyConfirm = async (denialReason: string) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_FASTAPI_URL}account-requests/deny`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accountRequestSeq: selectedRequest?.accountRequestSeq,
          actionByUserSeq: user?.userSeq,
          denialReason: denialReason,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to deny account request');
      }

      if (selectedRequest?.conversationSeq) {
        await sendMessage({
          chatMessage: {
            messageContent: `This account request has been denied.\nReason: ${denialReason}`,
            sender: { userSeq: user?.userSeq },
            readBy: [{ userSeq: user?.userSeq }],
            attachments: [],
          },
          conversationSeq: selectedRequest?.conversationSeq,
        });
      }

      // Display success snackbar
      setSnackbarMessage(
        `Account request for ${selectedRequest?.employee.firstName} ${selectedRequest?.employee.lastName} has been denied`
      );
      setSnackbarSeverity('info');
      setSnackbarOpen(true);

      // Fetch updated data
      await fetchRequests();

      handleClose();
    } catch (error) {
      console.error('Error denying account request:', error);
      setError('Failed to deny account request. Please try again.');
      setSnackbarMessage('Failed to deny account request');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleApproveDeactivationConfirm = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_FASTAPI_URL}account-requests/approve-deactivation`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            accountRequestSeq: selectedRequest?.accountRequestSeq,
            actionByUserSeq: user?.userSeq,
            denialReason: null,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to deny account request');
      }

      if (selectedRequest?.conversationSeq) {
        await sendMessage({
          chatMessage: {
            messageContent: `This account has been deactivated.`,
            sender: { userSeq: user?.userSeq },
            readBy: [{ userSeq: user?.userSeq }],
            attachments: [],
          },
          conversationSeq: selectedRequest?.conversationSeq,
        });
      }

      // Display success snackbar
      setSnackbarMessage(` Account deactivated succesfully`);
      setSnackbarSeverity('info');
      setSnackbarOpen(true);

      // Fetch updated data
      await fetchRequests();

      handleClose();
    } catch (error) {
      console.error('Error approving account deactivation request:', error);
      setError('Failed to approve account deactivation request. Please try again.');
      setSnackbarMessage('Failed to approve account deactivation request:');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 90,
      renderCell: params => (
        <>
          {params.value === 'PENDING' && (
            <Chip label='PENDING' variant='filled' color='warning' size='small' />
          )}
          {params.value === 'DENIED' && (
            <Chip label='DENIED' variant='outlined' color='error' size='small' />
          )}
          {params.value === 'APPROVED' && (
            <Chip label='APPROVED' variant='outlined' color='success' size='small' />
          )}
        </>
      ),
    },
    {
      field: 'requestedOn',
      headerName: 'Requested On',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 150,
      renderCell: params => {
        if (!params.value) return '-';
        // Format the date using dayjs with UTC conversion to local time
        return dayjs.utc(params.value).local().format('MMM D, YYYY, h:mm A');
      },
    },
    {
      field: 'requestedBy',
      headerName: 'Requested By',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 180,
      renderCell: params => params.value || '-',
    },
    {
      field: 'type',
      headerName: 'Request Type',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 90,
      renderCell: params => params.value || '-',
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 180,
      renderCell: params => params.value || '-',
    },
    {
      field: 'employeeEmail',
      headerName: 'Employee Email',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 220,
      renderCell: params => params.value || '-',
    },
    {
      field: 'roles',
      headerName: 'Roles',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 200,
      renderCell: params => {
        const roles = params.value;
        if (!Array.isArray(roles)) return '-';
        const filteredRoles = roles.filter(
          r => r.optionSeq !== 'A62EB010-88D5-440A-BE2C-E94BCEC73502'
        );
        return (
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
            }}
          >
            {filteredRoles.map((role, index) => (
              <Chip
                key={index}
                label={role.optionName}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'normal',
                  },
                }}
              />
            ))}
          </Box>
        );
      },
    },

    {
      field: 'jurisdictions',
      headerName: 'Jurisdictions',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 200,
      renderCell: params =>
        params.value && Array.isArray(params.value)
          ? params.value.map(jdx => jdx.jdxName).join(', ')
          : '-',
    },

    {
      field: 'action',
      headerName: '',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 150,
      renderCell: params => (
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={e => {
            e.stopPropagation(); // Prevent the row click from being triggered
            handleView(params.row);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchRequests();
  }, [user]);

  useEffect(() => {
    if (selectedRequest) {
      setNewUserData(CreateAccount_DefaultBlankNewUser);
      setSuggestedUsername(
        generateUsername(
          `${selectedRequest.employee.firstName} ${selectedRequest.employee.lastName}`
        )
      );
    } else {
      setSuggestedUsername(null);
      setNewUserData(CreateAccount_DefaultBlankNewUser);
    }
  }, [selectedRequest]);

  return (
    <>
      <CustomHeader
        title='Account Requests'
        description='View and manage account change requests'
      />

      {error && (
        <Alert severity='error' sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={3}>
        <DataGridPremium
          apiRef={apiRef}
          rows={requests}
          columns={columns}
          columnVisibilityModel={columnVisibility}
          loading={loading}
          getRowId={row => row.accountRequestSeq}
          density='compact'
          autoHeight
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 100}
          slots={{
            toolbar: CustomGridToolbar,
          }}
          onRowClick={params => {
            handleView(params.row);
          }}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              // Make row background the paper color:
              backgroundColor: theme => theme.palette.background.paper,
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal !important',
              wordWrap: 'break-word !important',
              lineHeight: '1.43 !important',
              padding: '8px !important',
            },
            // If you previously forced a Paper background for the headers, remove it:
            // so that the header & toolbar remain the default color.
            '& .super-app-theme--header': {
              // You can remove background overrides or explicitly set them back to default:
              backgroundColor: 'inherit',
            },
          }}
        />
      </Paper>

      <DeactivationDialog
        open={openDeactivationDialog}
        onClose={handleClose}
        selectedRequest={selectedRequest}
        action={action}
        onActionChange={handleActionChange}
        denialReason={denialReason}
        denialError={denialError}
        onDenialReasonChange={value => {
          setDenialReason(value);
          if (value.trim()) setDenialError('');
        }}
        onConfirm={handleApproveDeactivationConfirm}
      />

      <UnifiedDialog
        open={openDialog}
        onClose={handleClose}
        selectedRequest={selectedRequest}
        suggestedUsername={suggestedUsername}
        action={action}
        onActionChange={handleActionChange}
        denialReason={denialReason}
        denialError={denialError}
        onDenialReasonChange={value => {
          setDenialReason(value);
          if (value.trim()) setDenialError('');
        }}
        onConfirm={handleConfirmAction}
      >
        {action === 'approve' && (
          <UsernameChecker
            initialUsername={suggestedUsername || ''}
            accessToken={user?.accessToken || ''}
            apiUrl={import.meta.env.VITE_FASTAPI_URL}
            onChange={(username, isAvailable) => {
              setSuggestedUsername(username);
              setNewUserData(prev => ({
                ...prev,
                userName: isAvailable ? username : '',
              }));
            }}
            disabled={!selectedRequest}
          />
        )}
      </UnifiedDialog>

      {/* Success/Error Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

const CustomGridToolbar = () => {
  const apiRef = useGridApiContext();

  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: string | null) => {
    if (newStatus !== null) {
      if (newStatus === 'ALL') {
        apiRef.current.setFilterModel({
          items: [],
        });
      } else {
        apiRef.current.setFilterModel({
          items: [
            {
              field: 'status',
              operator: 'equals',
              value: newStatus,
            },
          ],
        });
      }
    }
  };

  const getCurrentStatus = () => {
    const filterModel = apiRef.current.state.filter;
    if (!filterModel.filterModel.items || filterModel.filterModel.items.length === 0) {
      return 'ALL';
    }
    const statusFilter = filterModel.filterModel.items.find(item => item.field === 'status');
    return statusFilter ? statusFilter.value : 'ALL';
  };

  return (
    <GridToolbarContainer
      sx={{
        backgroundColor: theme => theme.palette.background.default,
      }}
    >
      <Box
        component='div'
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
        }}
      >
        <Box component='div' sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <ToggleButtonGroup
            value={getCurrentStatus()}
            exclusive
            onChange={handleStatusChange}
            size='small'
            aria-label='status filter'
          >
            <ToggleButton value='PENDING'>PENDING</ToggleButton>
            <ToggleButton value='APPROVED'>APPROVED</ToggleButton>
            <ToggleButton value='DENIED'>DENIED</ToggleButton>
            <ToggleButton value='ALL'>ALL</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

interface UnifiedDialogProps {
  open: boolean;
  onClose: () => void;
  selectedRequest: AccountRequestViewModel | null;
  suggestedUsername: string | null;
  action: 'view' | 'approve' | 'deny';
  onActionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  denialReason: string;
  denialError: string;
  onDenialReasonChange: (value: string) => void;
  onConfirm: () => void;
  children: React.ReactNode;
}

const UnifiedDialog = ({
  open,
  onClose,
  selectedRequest,
  suggestedUsername,
  action,
  onActionChange,
  denialReason,
  denialError,
  onDenialReasonChange,
  onConfirm,
  children,
}: UnifiedDialogProps) => {
  if (!selectedRequest) return null;

  const {
    employee,
    address,
    roles,
    jurisdictions,
    requestedBy,
    type,
    requestedOn,
    status,
    conversationSeq,
  } = selectedRequest;

  const { handleSelectChat, chats, setDialogOpen } = useConversation();

  const onSendChatClick = (conversationSeq: string | null) => {
    if (!conversationSeq) return;

    const chatRecord =
      chats?.find(c => c.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()) || false;
    if (!chatRecord) return;

    handleSelectChat(chatRecord);
    setDialogOpen(true);
  };

  interface InfoSectionProps {
    title: string;
    content: React.ReactNode;
  }

  const InfoSection = ({ title, content }: InfoSectionProps) => (
    <Box component='div' sx={{ py: 1 }}>
      <Typography variant='subtitle2' color='text.secondary' sx={{ mb: 0.5 }}>
        {title}
      </Typography>
      <Typography variant='body1'>{content}</Typography>
    </Box>
  );

  const { user } = useAuth();
  const [supervisorDetails, setSupervisorDetails] = useState<any>(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!selectedRequest?.employee?.supervisor?.userSeq) return;

      try {
        const response = await fetch(
          `${import.meta.env.VITE_FASTAPI_URL}account-requests/employee-details?userSeq=${
            selectedRequest?.employee?.supervisor?.userSeq
          }`,
          {
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        );

        if (response.ok) {
          const userData = await response.json();
          setSupervisorDetails(userData);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [selectedRequest]);

  const isPending = status === 'PENDING';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      disableEscapeKeyDown
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box component='div'>
            <Typography variant='h6' gutterBottom={false}>
              View Account Request
            </Typography>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Requestor Details
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InfoSection title='Requested By' content={requestedBy} />
                </Grid>
                <Grid item xs={4}>
                  <InfoSection
                    title='Contact'
                    content={
                      <Stack spacing={0}>
                        <Typography variant='body1'>{employee.email}</Typography>
                        <Typography variant='body1'>{employee.phoneNumber}</Typography>
                        {conversationSeq && (
                          <Button
                            variant='outlined'
                            sx={{ mt: 1 }}
                            onClick={() => onSendChatClick(conversationSeq)}
                            startIcon={<ChatIcon />}
                          >
                            Send Message
                          </Button>
                        )}
                      </Stack>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoSection
                    title='Requested On'
                    content={
                      requestedOn
                        ? dayjs.utc(requestedOn).local().format('MMM D, YYYY, h:mm A')
                        : '-'
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Employee Details Section */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Employee Details
              </Typography>

              <InfoSection
                title='Full Name'
                content={`${employee.firstName} ${employee.middleName || ''} ${
                  employee.lastName
                }`.trim()}
              />
              <InfoSection
                title='Contact'
                content={
                  <>
                    {employee.email}
                    <br />
                    {employee.phoneNumber}
                  </>
                }
              />
              {employee.supervisor && (
                <InfoSection
                  title='Supervisor'
                  content={
                    <>
                      {supervisorDetails
                        ? `${supervisorDetails.firstName} ${supervisorDetails.lastName} (${supervisorDetails.username})`
                        : 'Loading..'}
                    </>
                  }
                />
              )}
            </Paper>
          </Grid>

          {/* Address Section */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Address Information
              </Typography>
              {address.addressType && (
                <InfoSection title='Type' content={address.addressType.optionName} />
              )}
              <InfoSection
                title='Address'
                content={
                  <>
                    {address.addressLine1}
                    {address.addressLine2 && (
                      <>
                        <br />
                        {address.addressLine2}
                      </>
                    )}
                    <br />
                    {`${address.addressCity}, ${address.addressZip}`.trim()}
                  </>
                }
              />
            </Paper>
          </Grid>

          {/* Access Details Section */}
          <Grid item xs={12}>
            <Paper variant='outlined' sx={{ p: 2 }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Access Details
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={12} md={4}>
                  <InfoSection
                    title='Roles'
                    content={
                      <ul>
                        {roles
                          .filter(r => r.optionName !== 'MEDEXLAB-ACCESS')
                          .filter(r => !r.isPermission)
                          .map((role, index) => (
                            <li key={role.optionSeq || index}>{role.optionName}</li>
                          ))}
                      </ul>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoSection
                    title='Permissions'
                    content={
                      <ul>
                        {roles
                          .filter(r => r.optionName !== 'MEDEXLAB-ACCESS')
                          .filter(r => r.isPermission)
                          .map((role, index) => (
                            <li key={role.optionSeq || index}>{role.optionName}</li>
                          ))}
                      </ul>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoSection
                    title='Jurisdictions'
                    content={jurisdictions.map(j => j.jdxName).join(', ')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InfoSection
                    title='Notes from requestor'
                    content={selectedRequest?.notes || '-'}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Actions Section - only visible for PENDING requests */}
          {isPending && (
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 2 }}>
                <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                  Actions
                </Typography>
                <FormControl component='fieldset'>
                  <RadioGroup
                    aria-label='action'
                    name='action'
                    value={action}
                    onChange={onActionChange}
                    row
                  >
                    <FormControlLabel value='view' control={<Radio />} label='None' />
                    <FormControlLabel value='approve' control={<Radio />} label='Approve' />
                    <FormControlLabel value='deny' control={<Radio />} label='Deny' />
                  </RadioGroup>
                </FormControl>

                {action === 'approve' && (
                  <Box component='div' sx={{ mt: 2 }}>
                    <Typography variant='subtitle2' gutterBottom>
                      User ID
                    </Typography>
                    {children}
                    <Alert icon={<Info />} severity='info' sx={{ mt: 2 }}>
                      Once approved, the user will receive an email containing login instructions.
                    </Alert>
                  </Box>
                )}

                {action === 'deny' && (
                  <Box component='div' sx={{ mt: 2 }}>
                    <TextField
                      label='Reason for Denial'
                      multiline
                      rows={4}
                      value={denialReason}
                      onChange={e => onDenialReasonChange(e.target.value)}
                      error={!!denialError}
                      helperText={denialError}
                      fullWidth
                      required
                      autoComplete='off'
                    />
                    <Alert severity='warning' sx={{ mt: 2 }}>
                      This action cannot be undone. The requestor will be notified of the denial and
                      the provided reason.
                    </Alert>
                  </Box>
                )}
              </Paper>
            </Grid>
          )}

          {selectedRequest?.status === 'APPROVED' && (
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 2 }}>
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} justifyContent='start' alignContent='start'>
                    <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                      Status:
                    </Typography>
                    <Chip label='APPROVED' variant='filled' color='success' size='small' />
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          )}

          {selectedRequest?.status === 'DENIED' && (
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 2 }}>
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} justifyContent='start' alignContent='start'>
                    <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                      Status:
                    </Typography>
                    <Chip label='DENIED' variant='filled' color='error' size='small' />
                  </Stack>
                  <Stack direction='row' spacing={1} justifyContent='start' alignItems='center'>
                    <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                      Reason:
                    </Typography>
                    <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
                      {selectedRequest?.denialReason || 'None provided'}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='inherit'>
          {isPending && action !== 'view' ? 'Cancel' : 'Close'}
        </Button>
        {isPending && action !== 'view' && (
          <Button
            onClick={onConfirm}
            variant='contained'
            color={action === 'approve' ? 'primary' : 'error'}
          >
            {action === 'approve' ? 'Confirm Approval' : 'Confirm Denial'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface DeactivationDialogProps {
  open: boolean;
  onClose: () => void;
  selectedRequest: AccountRequestViewModel | null;
  action: 'view' | 'approve' | 'deny';
  onActionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  denialReason: string;
  denialError: string;
  onDenialReasonChange: (value: string) => void;
  onConfirm: () => void;
}

const DeactivationDialog = ({
  open,
  onClose,
  selectedRequest,
  action,
  onActionChange,
  denialReason,
  denialError,
  onDenialReasonChange,
  onConfirm,
}: DeactivationDialogProps) => {
  if (!selectedRequest) return null;
  const { user } = useAuth();
  const {
    employee,
    requestedBy,
    requestedOn,
    status,
    notes,
    conversationSeq,
    existingEmployeeUserSeq,
  } = selectedRequest;
  const [employeeDetails, setEmployeeDetails] = useState<any>(null);

  const { handleSelectChat, chats, setDialogOpen } = useConversation();

  const onSendChatClick = (conversationSeq: string | null) => {
    if (!conversationSeq) return;

    const chatRecord =
      chats?.find(c => c.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()) || false;
    if (!chatRecord) return;

    handleSelectChat(chatRecord);
    setDialogOpen(true);
  };

  // Fetch user details for deactivation requests
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!existingEmployeeUserSeq) return;

      try {
        const response = await fetch(
          `${
            import.meta.env.VITE_FASTAPI_URL
          }account-requests/employee-details?userSeq=${existingEmployeeUserSeq}`,
          {
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        );

        if (response.ok) {
          const userData = await response.json();
          setEmployeeDetails(userData);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [selectedRequest]);

  interface InfoSectionProps {
    title: string;
    content: React.ReactNode;
  }

  const InfoSection = ({ title, content }: InfoSectionProps) => (
    <Box component='div' sx={{ py: 1 }}>
      <Typography variant='subtitle2' color='text.secondary' sx={{ mb: 0.5 }}>
        {title}
      </Typography>
      <Typography variant='body1'>{content}</Typography>
    </Box>
  );

  const isPending = status === 'PENDING';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      disableEscapeKeyDown
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box component='div'>
            <Typography variant='h6' gutterBottom={false}>
              Account Deactivation Request
            </Typography>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Requestor Details
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InfoSection title='Requested By' content={requestedBy} />
                </Grid>
                <Grid item xs={4}>
                  <InfoSection
                    title='Contact'
                    content={
                      <Stack spacing={0}>
                        {conversationSeq && (
                          <Button
                            variant='outlined'
                            sx={{ mt: 1 }}
                            onClick={() => onSendChatClick(conversationSeq)}
                            startIcon={<ChatIcon />}
                          >
                            Send Message
                          </Button>
                        )}
                      </Stack>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoSection
                    title='Requested On'
                    content={
                      requestedOn
                        ? dayjs.utc(requestedOn).local().format('MMM D, YYYY, h:mm A')
                        : '-'
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Employee Details Section */}
          <Grid item xs={12} md={12}>
            <Paper variant='outlined' sx={{ p: 2, height: '100%' }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Employee To Deactivate
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InfoSection
                    title='Full Name'
                    content={`${employee.firstName} ${employee.middleName || ''} ${
                      employee.lastName
                    }`.trim()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InfoSection title='UserID' content={`${employeeDetails?.username}`} />
                </Grid>
                <Grid item xs={6}>
                  <InfoSection
                    title='Contact'
                    content={
                      <>
                        {employee.email}
                        <br />
                        {employee.phoneNumber}
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Reason for Deactivation Section */}
          <Grid item xs={12}>
            <Paper variant='outlined' sx={{ p: 2 }}>
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                Deactivation Details
              </Typography>
              <InfoSection
                title='Reason for Deactivation'
                content={notes || 'No reason provided'}
              />
            </Paper>
          </Grid>

          {/* Actions Section - only visible for PENDING requests */}
          {isPending && (
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 2 }}>
                <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                  Actions
                </Typography>
                <FormControl component='fieldset'>
                  <RadioGroup
                    aria-label='action'
                    name='action'
                    value={action}
                    onChange={onActionChange}
                    row
                  >
                    <FormControlLabel value='view' control={<Radio />} label='None' />
                    <FormControlLabel
                      value='approve'
                      control={<Radio />}
                      label='Approve Deactivation'
                    />
                    <FormControlLabel value='deny' control={<Radio />} label='Deny Deactivation' />
                  </RadioGroup>
                </FormControl>

                {action === 'approve' && (
                  <Box component='div' sx={{ mt: 2 }}>
                    <Alert icon={<Warning />} severity='warning' sx={{ mt: 2 }}>
                      This will deactivate the user account. The user will no longer be able to log
                      in to the system.
                    </Alert>
                  </Box>
                )}

                {action === 'deny' && (
                  <Box component='div' sx={{ mt: 2 }}>
                    <TextField
                      label='Reason for Denial'
                      multiline
                      rows={4}
                      value={denialReason}
                      onChange={e => onDenialReasonChange(e.target.value)}
                      error={!!denialError}
                      helperText={denialError}
                      fullWidth
                      required
                      autoComplete='off'
                    />
                    <Alert severity='info' sx={{ mt: 2 }}>
                      Denying this request will keep the user account active.
                    </Alert>
                  </Box>
                )}
              </Paper>
            </Grid>
          )}

          {selectedRequest?.status === 'APPROVED' && (
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 2 }}>
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} justifyContent='start' alignContent='start'>
                    <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                      Status:
                    </Typography>
                    <Chip label='APPROVED' variant='filled' color='success' size='small' />
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          )}

          {selectedRequest?.status === 'DENIED' && (
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 2 }}>
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} justifyContent='start' alignContent='start'>
                    <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                      Status:
                    </Typography>
                    <Chip label='DENIED' variant='filled' color='error' size='small' />
                  </Stack>
                  <Stack direction='row' spacing={1} justifyContent='start' alignItems='center'>
                    <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                      Reason:
                    </Typography>
                    <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
                      {selectedRequest?.denialReason || 'None provided'}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='inherit'>
          {isPending && action !== 'view' ? 'Cancel' : 'Close'}
        </Button>
        {isPending && action !== 'view' && (
          <Button
            onClick={onConfirm}
            variant='contained'
            color={action === 'approve' ? 'primary' : 'error'}
          >
            {action === 'approve' ? 'Confirm Deactivation' : 'Deny Deactivation'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
