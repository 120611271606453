// CatalogView.tsx
import { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import { useAuth } from '../../../utils/auth/AuthService';
import CharacterLimitTextField from '../../../utils/components/CharacterLimitTextField';
import DialogCrudDataGrid, {
  handleTextFieldChange,
} from '../../../utils/components/DialogCrudDataGrid';
import CurrencyTextField from '../../../components/CurrencyTextField';

export default function CatalogView() {
  const { user } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [testCatalog, setTestCatalog] = useState([]);
  const [specimenCatalog, setSpecimenCatalog] = useState([]);
  const { VITE_FASTAPI_URL } = import.meta.env;

  useEffect(() => {
    if (user === null) return;

    if (tabValue === 0) {
      fetchTestCatalog();
    } else {
      fetchSpecimenCatalog();
    }
  }, [user, tabValue]);

  const fetchTestCatalog = async () => {
    setLoading(true);
    try {
      // @ts-ignore
      const response = await fetch(`${VITE_FASTAPI_URL}lms-lab-test-catalog`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch test catalog');
      const data = await response.json();
      const formattedData = data.map(item => ({
        ...item,
        id: item.LMSLabTestCatalogSeq,
        // Ensure Price is a number for proper formatting
        Price: item.Price !== null ? Number(item.Price) : null,
      }));
      setTestCatalog(formattedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSpecimenCatalog = async () => {
    setLoading(true);
    try {
      // @ts-ignore
      const response = await fetch(`${VITE_FASTAPI_URL}lms-specimen-catalog`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch specimen catalog');
      const data = await response.json();
      const formattedData = data.map(item => ({
        ...item,
        id: item.LMSSpecimenCatalogSeq,
      }));
      setSpecimenCatalog(formattedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Test Catalog CRUD operations
  const createTest = async newTest => {
    try {
      const formData = new FormData();
      formData.append('test_name', newTest.LabTestName);
      formData.append('description', newTest.LabTestDescription || '');
      if (newTest.Price !== null && newTest.Price !== undefined && newTest.Price !== '') {
        // Ensure price has max 2 decimal places before sending to API
        const formattedPrice = parseFloat(newTest.Price.toString()).toFixed(2);
        formData.append('price', formattedPrice);
      }
      // @ts-ignore
      formData.append('created_by_user_seq', user.userSeq.toString());

      // @ts-ignore
      const response = await fetch(`${VITE_FASTAPI_URL}lms-lab-test-catalog`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to create test');
      await fetchTestCatalog();
      console.log('Test created successfully');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateTest = async updatedTest => {
    try {
      const formData = new FormData();
      formData.append('test_name', updatedTest.LabTestName);
      formData.append('description', updatedTest.LabTestDescription || '');
      if (
        updatedTest.Price !== null &&
        updatedTest.Price !== undefined &&
        updatedTest.Price !== ''
      ) {
        // Ensure price has max 2 decimal places before sending to API
        const formattedPrice = parseFloat(updatedTest.Price.toString()).toFixed(2);
        formData.append('price', formattedPrice);
      }

      // @ts-ignore
      const response = await fetch(`${VITE_FASTAPI_URL}lms-lab-test-catalog/${updatedTest.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to update test');
      await fetchTestCatalog();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deleteTest = async id => {
    try {
      // @ts-ignore
      const response = await fetch(`${VITE_FASTAPI_URL}lms-lab-test-catalog/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to delete test');
      await fetchTestCatalog();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // Specimen Catalog CRUD operations
  const createSpecimen = async newSpecimen => {
    try {
      const formData = new FormData();
      formData.append('specimen_name', newSpecimen.SpecimenCatalogName);
      formData.append('description', newSpecimen.SpecimenCatalogDescription || '');
      // @ts-ignore
      formData.append('created_by_user_seq', user.userSeq.toString());

      // @ts-ignore
      const response = await fetch(`${VITE_FASTAPI_URL}lms-specimen-catalog`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to create specimen');
      await fetchSpecimenCatalog();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateSpecimen = async updatedSpecimen => {
    try {
      const formData = new FormData();
      formData.append('specimen_name', updatedSpecimen.SpecimenCatalogName);
      formData.append('description', updatedSpecimen.SpecimenCatalogDescription || '');

      // @ts-ignore
      const response = await fetch(
        `${VITE_FASTAPI_URL}lms-specimen-catalog/${updatedSpecimen.id}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) throw new Error('Failed to update specimen');
      await fetchSpecimenCatalog();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deleteSpecimen = async id => {
    try {
      // @ts-ignore
      const response = await fetch(`${VITE_FASTAPI_URL}lms-specimen-catalog/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to delete specimen');
      await fetchSpecimenCatalog();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // @ts-ignore
  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const testColumns = [
    {
      field: 'LMSLabTestCatalogSeq',
      headerName: 'ID',
      width: 100,
      editable: false,
      hide: true,
    },

    {
      field: 'LabTestName',
      headerName: 'Test Name',
      flex: 0.75,
      minWidth: 150, // Reduced from 200
      editable: false,
      headerClassName: 'header-theme',
    },
    {
      field: 'LabTestDescription',
      headerName: 'Description',
      flex: 1.25, // Reduced from 2
      minWidth: 250, // Reduced from 350
      editable: false,
      headerClassName: 'header-theme',
    },
    {
      field: 'Price',
      headerName: 'Price (USD)',
      width: 160,
      editable: false,
      headerClassName: 'header-theme',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params: any) => {
        if (params.value === null || params.value === undefined || isNaN(params.value)) {
          return '';
        }

        const formattedValue = Number(params.value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return (
          <div
            style={{
              width: '100%',
              textAlign: 'right',
              fontFamily: 'monospace',
              fontSize: '1.02rem',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: 'CreatedOnDate',
      headerName: 'Created On',
      width: 180,
      editable: false,
      headerClassName: 'header-theme',
      valueFormatter: params => {
        return params.value ? new Date(params.value).toLocaleDateString() : '';
      },
    },
  ];

  const specimenColumns = [
    {
      field: 'LMSSpecimenCatalogSeq',
      headerName: 'ID',
      width: 100,
      editable: false,
      hide: true,
    },
    {
      field: 'SpecimenCatalogName',
      headerName: 'Specimen Name',
      flex: 1,
      minWidth: 200,
      editable: false,
      headerClassName: 'header-theme',
    },
    {
      field: 'SpecimenCatalogDescription',
      headerName: 'Description',
      flex: 2,
      minWidth: 350,
      editable: false,
      headerClassName: 'header-theme',
    },
    {
      field: 'CreatedOnDate',
      headerName: 'Created On',
      width: 180,
      editable: false,
      headerClassName: 'header-theme',
      // @ts-ignore
      valueFormatter: params => {
        return params.value ? new Date(params.value).toLocaleDateString() : '';
      },
    },
  ];

  // Dialog content components
  // @ts-ignore
  const TestDialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(() => {
      return { ...data };
    });

    useEffect(() => {
      setLocalData(prev => ({
        ...prev,
        ...data,
      }));
    }, [data]);

    // @ts-ignore
    const handlePriceChange = numValue => {
      const updatedData = {
        ...localData,
        Price: numValue,
      };
      setLocalData(updatedData);
      onChange(updatedData);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CharacterLimitTextField
            value={localData?.LabTestName || ''}
            onChange={event =>
              handleTextFieldChange(event, 'LabTestName', localData, setLocalData, onChange)
            }
            label='Test Name'
            fullWidth
            margin='dense'
            characterLimit={255}
            TextFieldProps={{ required: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <CharacterLimitTextField
            value={localData?.LabTestDescription || ''}
            onChange={event =>
              handleTextFieldChange(event, 'LabTestDescription', localData, setLocalData, onChange)
            }
            label='Description'
            fullWidth
            multiline
            rows={3}
            margin='dense'
            characterLimit={1000}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrencyTextField
            value={localData?.Price}
            onChange={handlePriceChange}
            label='Price'
            fullWidth
            margin='dense'
            currencySymbol='$'
            currencyLabel='USD'
            decimalPlaces={2}
            placeholder='0.00'
          />
        </Grid>
      </Grid>
    );
  };

  // @ts-ignore
  const SpecimenDialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(() => {
      return { ...data };
    });

    useEffect(() => {
      setLocalData(prev => ({
        ...prev,
        ...data,
      }));
    }, [data]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CharacterLimitTextField
            value={localData?.SpecimenCatalogName || ''}
            onChange={event =>
              handleTextFieldChange(event, 'SpecimenCatalogName', localData, setLocalData, onChange)
            }
            label='Specimen Name'
            fullWidth
            margin='dense'
            characterLimit={255}
            TextFieldProps={{ required: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <CharacterLimitTextField
            value={localData?.SpecimenCatalogDescription || ''}
            onChange={event =>
              handleTextFieldChange(
                event,
                'SpecimenCatalogDescription',
                localData,
                setLocalData,
                onChange
              )
            }
            label='Description'
            fullWidth
            multiline
            rows={3}
            margin='dense'
            characterLimit={1000}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      component='div'
      sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', pb: 2 }}
    >
      <Box
        component='div'
        sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}
      >
        <Tabs value={tabValue} onChange={handleTabChange} aria-label='catalog tabs'>
          <Tab label='Lab Test Catalog' />
          <Tab label='Specimen Catalog' />
        </Tabs>
      </Box>

      {/* Test Catalog Tab */}
      <Box
        component='div'
        hidden={tabValue !== 0}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 48px)',
          pt: 2,
        }}
      >
        {tabValue === 0 && (
          <DialogCrudDataGrid
            idColumn='LMSLabTestCatalogSeq'
            columns={testColumns}
            loading={loading}
            rows={testCatalog}
            title='Lab Test'
            rowsperpage={20}
            createFunction={createTest}
            updateFunction={updateTest}
            deleteFunction={deleteTest}
            dialogContent={TestDialogContent}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  LMSLabTestCatalogSeq: false,
                  CreatedOnDate: false,
                },
              },
              sorting: {
                sortModel: [{ field: 'LabTestName', sort: 'asc' }],
              },
            }}
          />
        )}
      </Box>

      {/* Specimen Catalog Tab */}
      <Box
        component='div'
        hidden={tabValue !== 1}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 48px)',
          pt: 2,
        }}
      >
        {tabValue === 1 && (
          <DialogCrudDataGrid
            idColumn='LMSSpecimenCatalogSeq'
            columns={specimenColumns}
            loading={loading}
            rows={specimenCatalog}
            title='Specimen'
            rowsperpage={20}
            createFunction={createSpecimen}
            updateFunction={updateSpecimen}
            deleteFunction={deleteSpecimen}
            dialogContent={SpecimenDialogContent}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  LMSSpecimenCatalogSeq: false,
                  CreatedOnDate: false,
                },
              },
              sorting: {
                sortModel: [{ field: 'SpecimenCatalogName', sort: 'asc' }],
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
}
