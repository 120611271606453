import { AssignmentInd, LocalShippingRounded, People } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import CustomDrawer from '../../utils/components/CustomDrawer';
import CustomHeader from '../../utils/components/CustomHeader';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import { AllRoles } from '../../utils/constants/AllRoles';
import { LiveryCheckinView } from '../Livery/LiveryCheckInView';
import { YourAccountRequestView } from './YourProfileModules/YourAccountRequestsView';
import YourCasesView from './YourProfileModules/YourCasesView';
import YourReportsView from './YourProfileModules/YourReportsView';

export default function YourCasesAndReportsView() {
  const auth = useAuth();
  const hasAccessToLivery = auth.user?.roleCheck([AllRoles.Livery]) ?? false;
  const hasAccessToAccountRequests = auth.user?.roleCheck(['bbe']) ?? false;

  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [tabIndexMap, setTabIndexMap] = useState<{
    cases: number;
    reports: number;
    accountRequests?: number;
    livery?: number;
  }>({
    cases: 0,
    reports: 1,
    accountRequests: 2,
    livery: 3,
  });

  // Build tab structure based on permissions
  useEffect(() => {
    const newTabIndexMap: {
      cases: number;
      reports: number;
      accountRequests?: number;
      livery?: number;
    } = { cases: 0, reports: 1 };
    let currentIndex = 2;

    if (hasAccessToAccountRequests) {
      newTabIndexMap.accountRequests = currentIndex++;
    }

    if (hasAccessToLivery) {
      newTabIndexMap.livery = currentIndex;
    }

    setTabIndexMap(newTabIndexMap);
  }, [hasAccessToAccountRequests, hasAccessToLivery]);

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  // Dynamically build drawer contents based on permissions
  const drawerContents = [
    { text: 'Your Cases', icon: <People /> },
    { text: 'Your Reports', icon: <AssessmentIcon /> },
  ];

  // Only add account requests tab if user has permission
  if (hasAccessToAccountRequests) {
    drawerContents.push({ text: 'Account Requests', icon: <AssignmentInd /> });
  }

  // Only add livery tab if user has permission
  if (hasAccessToLivery) {
    drawerContents.push({ text: 'Your Transport', icon: <LocalShippingRounded /> });
  }

  return (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {/* Mobile and Desktop Drawer*/}
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      {/* Page Contents */}
      <Box component='section' sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <hr />
        {/* Cases Tab */}
        {/* @ts-ignore */}
        <CustomTabPanel value={currentTab} index={tabIndexMap.cases} sx={{ margin: 0 }}>
          <CustomHeader
            title='Your Case History'
            description='View all your cases, details and statistics'
            handleMenuClick={handleMenuClick}
          />
          <YourCasesView />
        </CustomTabPanel>

        {/* Reports Tab */}
        {/* @ts-ignore */}
        <CustomTabPanel value={currentTab} index={tabIndexMap.reports} sx={{ margin: 0 }}>
          <CustomHeader
            title='Reports'
            description='Create quick assessments based on your User data'
            handleMenuClick={handleMenuClick}
          />
          <YourReportsView />
        </CustomTabPanel>

        {/* Account Requests Tab - Only rendered if user has access */}
        {hasAccessToAccountRequests && tabIndexMap.accountRequests !== undefined && (
          // @ts-ignore
          <CustomTabPanel value={currentTab} index={tabIndexMap.accountRequests} sx={{ margin: 0 }}>
            <CustomHeader
              title='Your Account Requests'
              description={`Manage account requests you've submitted`}
              handleMenuClick={handleMenuClick}
            />
            <YourAccountRequestView />
          </CustomTabPanel>
        )}

        {/* Livery Tab - Only rendered if user has access */}
        {hasAccessToLivery && tabIndexMap.livery !== undefined && (
          // @ts-ignore
          <CustomTabPanel value={currentTab} index={tabIndexMap.livery} sx={{ margin: 0 }}>
            <CustomHeader
              title='Your Transport'
              description='Manage transport assignments'
              handleMenuClick={handleMenuClick}
            />
            <LiveryCheckinView />
          </CustomTabPanel>
        )}
      </Box>
    </Box>
  );
}
