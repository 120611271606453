import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../../utils/auth/AuthService';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';

// todp:  modify this page to enumerate all the batches and allow the user to click on a batch to view the samples in that batch
export default function ELabPMAgenciesView() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;

  const columns = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 150,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'agencyname',
      headerName: 'Agency Name',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'type',
      headerName: 'Agency/Department Type',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      minWidth: 350,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  useEffect(() => {
    if (user === null) return;

    setLoading(true);

    if (user && user.token) {
      fetch(VITE_FASTAPI_URL + 'elab/pm_agencies', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setLoading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }
  }, [user]);

  return (
    <ReportDataGrid
      idcolumn='id'
      loading={loading}
      listresults={listresults}
      columnsInput={columns}
      title='PM Agencies'
    />
  );
}