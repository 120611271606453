import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import { forwardRef, useEffect, useState } from 'react';
import { useConversation } from './ConversationsContext';

type AddParticipantsMenuProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: HTMLElement | null;
};

type User = {
  userSeq: string;
  personFirstName: string;
  personLastName: string;
  userName: string;
};

export const AddParticipantsMenu = forwardRef<HTMLDivElement, AddParticipantsMenuProps>(
  ({ open, setOpen, anchorEl }, ref) => {
    const theme = useTheme();
    const {
      selectedChat,
      availableUsers,
      supportAgentName,
      creatingConversation,
      addParticipantsToChat,
    } = useConversation();
    const [historyOption, setHistoryOption] = useState('none');
    const [daysCount, setDaysCount] = useState('1');
    const [maxDays, setMaxDays] = useState(0);
    const [showError, setShowError] = useState(false);
    const [selectedParticipants, setSelectedParticipants] = useState<User[]>([]);
    const [isAdding, setIsAdding] = useState(false);

    // Filter out existing participants
    const availableNewUsers = availableUsers
      .filter(
        user =>
          !selectedChat?.participants?.some(
            p => p.userSeq?.toLowerCase() === user.userSeq?.toLowerCase() && p.isActive
          )
      )
      .filter(user => user.userSeq !== 'Support')
      .sort((a, b) => {
        // Support agent comes first
        if (a.userSeq === 'Support') return -1;
        if (b.userSeq === 'Support') return 1;

        // Sort remaining users by name
        return `${a.personFirstName} ${a.personLastName}`.localeCompare(
          `${b.personFirstName} ${b.personLastName}`
        );
      });

    useEffect(() => {
      if (selectedChat?.createdAtTimestamp) {
        const createdDate = parseISO(selectedChat.createdAtTimestamp);
        const daysSinceCreation = differenceInDays(new Date(), createdDate);
        setMaxDays(daysSinceCreation);
      }
    }, [selectedChat]);

    const handleClose = () => {
      setOpen(false);
      // Reset state
      setSelectedParticipants([]);
      setHistoryOption('none');
      setDaysCount('1');
      setShowError(false);
      setIsAdding(false);
    };

    const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (/^\d*$/.test(value)) {
        const numValue = parseInt(value || '0', 10);
        setDaysCount(value);
        setShowError(numValue > maxDays);
        if (numValue <= maxDays) {
          setShowError(false);
        }
      }
    };

    const handleAddParticipants = async () => {
      if (!selectedChat || selectedParticipants.length === 0) return;

      setIsAdding(true);
      try {
        let historyTimestamp: string | undefined;

        // Calculate history timestamp based on selected option
        if (historyOption === 'all') {
          historyTimestamp = selectedChat.createdAtTimestamp;
        } else if (historyOption === 'days' && !showError) {
          const daysAgo = new Date();
          daysAgo.setDate(daysAgo.getDate() - parseInt(daysCount));
          historyTimestamp = daysAgo.toISOString();
        }
        // 'none' option doesn't set a timestamp

        await addParticipantsToChat({
          conversationSeq: selectedChat.conversationSeq,
          participants: selectedParticipants.map(participant => ({
            userSeq: participant.userSeq,
            canViewMessageHistoryUpToTimestamp: historyTimestamp,
            silent: false,
          })),
        });

        handleClose();
      } catch (error) {
        console.error('Failed to add participants:', error);
        // You might want to show an error message to the user here
      } finally {
        setIsAdding(false);
      }
    };

    return (
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'add-participant-button',
          sx: {
            p: 1,
            minWidth: 350,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack spacing={1}>
          <Typography variant='body1' sx={{ pt: 1.5 }}>
            Add
          </Typography>

          <Autocomplete
            multiple
            options={availableNewUsers}
            value={selectedParticipants}
            onChange={(_, newValue) => setSelectedParticipants(newValue)}
            disabled={isAdding || creatingConversation}
            autoHighlight
            getOptionLabel={option =>
              option.userSeq === 'Support'
                ? supportAgentName || ''
                : `${option.personFirstName} ${option.personLastName} (${option.userName})`
            }
            renderInput={params => (
              <TextField
                {...params}
                placeholder={
                  availableNewUsers.length === 0 ? 'No users available' : 'Enter a name or UserID'
                }
                size='small'
              />
            )}
            size='small'
            sx={{ minWidth: 300 }}
          />

          <FormControl size='small'>
            <RadioGroup
              value={historyOption}
              onChange={e => setHistoryOption(e.target.value)}
              sx={{
                '& .MuiFormControlLabel-root': {
                  my: -0.5,
                  '& .MuiTypography-root': {
                    fontSize: '0.875rem',
                    lineHeight: 1.2,
                  },
                },
              }}
            >
              <FormControlLabel
                value='none'
                control={<Radio size='small' />}
                label="Don't include chat history"
              />
              {/* <FormControlLabel
                value='days'
                control={<Radio size='small' />}
                sx={{
                  marginBottom: showError ? '16px' : 'inherit',
                }}
                label={
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <span>Include history from past</span>
                    <TextField
                      size='small'
                      value={daysCount}
                      onChange={handleDaysChange}
                      onClick={e => e.stopPropagation()}
                      disabled={historyOption !== 'days'}
                      error={showError}
                      inputProps={{
                        style: {
                          padding: '2px 8px',
                          width: '30px',
                        },
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>days</InputAdornment>,
                      }}
                      helperText={showError ? `Max ${maxDays} days` : ''}
                      FormHelperTextProps={{
                        sx: {
                          m: 0,
                          color: theme.palette.error.main,
                          position: 'absolute',
                          top: '100%',
                        },
                      }}
                    />
                  </Stack>
                }
              /> */}
              <FormControlLabel
                value='all'
                control={<Radio size='small' />}
                label='Include all chat history'
              />
            </RadioGroup>
          </FormControl>

          <Stack direction='row' spacing={1} justifyContent='flex-end' sx={{ mt: 1 }}>
            <Button
              size='large'
              onClick={handleClose}
              disabled={isAdding}
              sx={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
            <Button
              size='large'
              variant='contained'
              disabled={
                selectedParticipants.length === 0 ||
                isAdding ||
                (historyOption === 'days' && showError)
              }
              onClick={handleAddParticipants}
              sx={{ textTransform: 'none' }}
            >
              {isAdding ? 'Adding...' : 'Add'}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    );
  }
);
