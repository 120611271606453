import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Divider,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';

interface ItemToEdit {
  photoSeq: string;
  notes?: string;
  photoType?: Option;
  uploadedBy?: Option;
}

interface Props {
  itemToEdit: ItemToEdit | null;
  fileTypeOptions: Option[];
  onClose: () => void;
  onSaveActionComplete?: (status: 'success' | 'failure') => void;
}

const UserFileEditView: React.FC<Props> = ({
  itemToEdit,
  fileTypeOptions,
  onClose,
  onSaveActionComplete,
}) => {
  const [fileType, setFileType] = useState<Option | null>(null);
  const [notes, setNotes] = useState('');
  const [editAccess, setEditAccess] = useState<boolean>(false);
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const [showSelectionAlert, setShowSelectionAlert] = useState(false);

  const handleFileTypeChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Option | null
  ) => {
    setFileType(value);
    if (value) {
      setShowSelectionAlert(false);
    } else {
      setShowSelectionAlert(true);
    }
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const handleEditSubmit = async () => {

    if (!itemToEdit || !fileType) {
      return;
    }

    let formData = new FormData();
    formData.append('photoSeq', itemToEdit?.photoSeq);
    formData.append('photoType.PhotoTypeSeq', fileType?.optionSeq ?? '');
    formData.append('photoType.PhotoTypeName', fileType?.optionName ?? '');
    formData.append('notes', notes);

    fetch(VITE_API_URL + 'updateusercertificate', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        onSaveActionComplete?.('success');
      })
      .catch(e => {
        onSaveActionComplete?.('failure');
      })
      .finally(() => {
        onClose();
      });
  };

  useEffect(() => {
    if (itemToEdit) {
      setFileType(itemToEdit?.photoType || null);
      setNotes(itemToEdit?.notes || '');
      {
        /* System-Admin and support agent role and user uploaded the file got access to edit. */
      }
      const editAccess =
        String(itemToEdit?.uploadedBy?.optionSeq)?.toLowerCase() ===
          String(user?.userSeq)?.toLowerCase() ||
        user?.roleCheck(['451', '8f7']) ||
        false;
      setEditAccess(editAccess);
    }
  }, [itemToEdit]);

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 1, pb: 0 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>Edit File</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Autocomplete
          id='fileType'
          size='small'
          disabled={!editAccess}
          options={fileTypeOptions}
          value={fileType ?? null}
          getOptionLabel={option => option.optionName ?? ''}
          onChange={(event, option) => handleFileTypeChange(event, option)}
          fullWidth
          renderOption={(props, option, { selected }) => <li {...props}>{option.optionName}</li>}
          renderInput={params => (
            <TextField {...params} required label='File Type' placeholder='File Type' error={Boolean(showSelectionAlert)} helperText = {showSelectionAlert && 'File type required'} />
          )}
          
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label='Note'
          name='note'
          type='text'
          disabled={!editAccess}
          multiline
          value={notes}
          onChange={handleNoteChange}
          fullWidth
          InputProps={{
            inputProps: {
              minRows: 1,
            },
          }}
        />
      </Grid>

      <Grid container item xs={12} justifyContent='flex-start'>
        <Box component={'div'}>
          <Button variant='contained' sx={{ minWidth: '100px' }} onClick={e => handleEditSubmit()}>
            Submit
          </Button>

          <Button
            color='error'
            variant='outlined'
            onClick={onClose}
            sx={{ minWidth: '100px', marginLeft: '1rem' }}
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
export default UserFileEditView;
