import { UserProfile } from '../../views/DVCS/CallCenterUserDialog';

type CreateAccountParams = {
  new_user: UserProfile;
  approving_user_seq: string;
  access_token: string;
};

const { VITE_API_URL } = import.meta.env;
export const createAccount = async ({
  new_user,
  approving_user_seq,
  access_token,
}: CreateAccountParams) => {
  return await fetch(
    `${VITE_API_URL}SaveUpdateUser?LogInuserSeq=${approving_user_seq}&isIncludeSecurityQuestion=false`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(new_user),
    }
  );
};

export const CreateAccount_DefaultBlankNewUser: UserProfile = {
  // @ts-ignore
  abmdiCertificateExpiryDate: null,
  abmdiCertificateNO: '',
  barcode: '',
  boardcertificatefellowNo: '',
  // countySeqandDashboard: ['a4f61e5a-30ec-433f-ad5c-892fab64957f || True || False'],
  countySeqandDashboard: [],
  departmentSeq: '4f66a4b5-858e-459a-8f37-33e224a45c99',
  eSignature: null,
  enable2FA: false,
  initials: '',
  // @ts-ignore
  mdLicenseExpiryDate: null,
  // @ts-ignore
  mdLicenseNo: null,
  // @ts-ignore
  ocsmeCertificateExpiryDate: null,
  // @ts-ignore
  operationMode: null,
  organizationseq: 'a6dbc293-7045-4cc1-bc79-36d772a57743',
  primaryEmail: '',
  primaryMobileNo: '',
  roleseq: [
    '9bb433a7-1cf5-48e1-969e-b41bcdfd3b4b || True', // DVCS,
    'A62EB010-88D5-440A-BE2C-E94BCEC73502 || True', // MEDEXLAB-ACCESS
  ],
  securityQuestion: null,
  userAddress: [
    {
      addressSeq: '00000000-0000-0000-0000-000000000000',
      addressTypeSeq: '',
      addressLine1: '',
      city: '',
      placeSeq: null,
      stateSeq: '',
      // @ts-ignore
      countrySeq: null,
      countySeq: null,
      nursingHomeSeq: null,
      funeralHomeSeq: null,
      lawEnforcementSeq: null,
      isWithinServiceArea: true,
      // @ts-ignore
      creationDateTime: '2025-02-10T18:08:50.749Z',

      status: 'Active',
      geoX: null,
      geoY: null,
      isCopyAddress: false,
      floor: '',
      isPrimary: false,
      otherState: '',
      zip: '',
      addressLine2: '',
      apartmentNo: '',
      crossStreet: '',
      otherAddressType: '',
      contactInfoSeq: '00000000-0000-0000-0000-000000000000',
      isActive: true,
    },
  ],
  userContacts: [
    {
      contactItemSeq: '00000000-0000-0000-0000-000000000000',
      contactItemTypeSeq: '176240f7-30a9-40dd-af0c-304226e1bfea',
      contactItemDetails: '',
      contactOrder: '1',
      isActive: true,
      contactItemIsPrimary: false,
      // @ts-ignore
      status: 'Active',
    },
  ],
  userFirstName: '',
  userLastName: '',
  userMiddleName: '',
  userName: '',
  userSeq: '00000000-0000-0000-0000-000000000000',
  userSuffix: '',
  userTitle: '',
  IsWithinServiceArea: true,
  isAnatomicPathologyCert: false,
  isAutopsyCertCert: false,
  isAutopsyReportFinal: false,
  isClinicalPathologyCert: false,
  isDeathInvestigationCert: false,
  isDeleteTissueNumber: false,
  isExamCompleteDateEditable: false,
  isExternalExamCert: false,
  isForensicPathologyCert: false,
  isHideDropdownPopUp: false,
  isHighProfile: false,
  isModifyIdUser: false,
  isUnconfirmCaseRelease: false,
  isVoidTissueNumber: false,
};
