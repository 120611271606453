export default function authHeader() {
  const access_token = localStorage.getItem('access_token');

  if (access_token) {
    return {
      Authorization: 'Bearer ' + access_token,
      'Content-Type': 'application/json',
    }; // for Spring Boot back-end
  } else {
    return {};
  }
}

