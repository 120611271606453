import LockIcon from '@mui/icons-material/Lock';
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import { useAuth } from '../../utils/auth/AuthService';
import { CaseViewOptionsAutocomplete } from '../../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../../utils/components/FormikTextField';
import { AllRoles } from '../../utils/constants/AllRoles';
import { ME_Action, Occupation_Other } from '../../utils/constants/constants';
import { GuidEmptySeq } from '../../utils/constants/GuidEmptySeq';
import options from '../../utils/constants/options';
import { Option } from '../../views/Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import AddressData from '../AdministrationModules/AddressComponents/AddressData';
import { CaseViewAge } from './CaseViewAge';
import CaseViewAutocomplete from './CaseViewAutocomplete';
import CaseViewDateField from './CaseViewDateField';
import CaseViewDateTimeField, { ActionList } from './CaseViewDateTimeField';
import { CaseViewSameAsAddressField } from './CaseViewSameAsAddressField';
import CaseViewTextField from './CaseViewTextField';

const { VITE_API_URL } = import.meta.env;

export function DeathCertificatePreviewView() {
  const { user } = useAuth();
  const formik = useFormikContext<any>();
  const editing = formik.status.editing;
  const { setUnsavedChanges } = useUnsavedChanges();

  // const authorizedToEdit =
  //   user?.roleCheck([
  //     '2D5', // Investigator
  //     '451', // System Admin
  //     'MES', // Pathologist
  //     'c97', // Administrative Assistant,
  //     '08b', // HISTORIC-DATAENTRYONLY
  //   ]) || false;

  const authorizedToEdit = false;

  const discovery = formik.values?.caseInvestigations?.discovery || [];
  const [caseReporter, setCaseReporter] = useState<any>({});

  const hasConfirmedIdentification = formik.values?.caseDemographics?.identifications?.some(
    (identification: any) => identification.isAccept === true
  );
  const hasAccessToConfirmID =
    user?.roleCheck([AllRoles.Systems_Admin, AllRoles.Confirm_Id]) ?? false;

  const filteredInformantList =
    formik.values?.caseDemographics?.informantList?.filter(
      (informant: any) => informant.isActive === 1
    ) ?? [];

  const [genderOptions, setGenderOptions] = useState([]);
  const [raceOptions, setRaceOptions] = useState([]);
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [funeralHomeOptions, setFuneralHomeOptions] = useState([]);
  const [funeralHomeOptionsLoading, setFuneralHomeOptionsLoading] = useState(false);
  const [funeralHomeAddress, setFuneralHomeAddress] = useState<any>(null);
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [showCheckbox, setShowCheckbox] = useState(true);
  const [foundOnsetIllnessByType, setFoundOnsetIllnessByType] = useState(null);
  const hasInjury = foundOnsetIllnessByType === 'O';
  const [mannerOfDeathOptions, setMannerOfDeathOptions] = useState([]);
  const [pregnancystageOptions, setPregnancyStageOptions] = useState([]);
  const [meOptions, setMEOptions] = useState([]);
  const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
  const [pastMedicalHistoryOptions, setPastMedicalHistoryOptions] = useState([]);

  const fetchOccupationOptions = async () => {
    fetch(VITE_API_URL + 'getoccupationoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setOccupationOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchPregnancyStageOptions = async () => {
    fetch(VITE_API_URL + 'getpregnancystageoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPregnancyStageOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchRaceOptions = async () => {
    fetch(VITE_API_URL + 'getraceoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setRaceOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchPastMedicalHistoryOptions = () => {
    fetch(VITE_API_URL + 'getmedicalconditiontypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPastMedicalHistoryOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchFuneralHomeOptions = async () => {
    setFuneralHomeOptionsLoading(true);
    await fetch(VITE_API_URL + 'getfuneralhomeoptions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setFuneralHomeOptions(data);
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setFuneralHomeOptionsLoading(false);
      });
  };

  const fetchGenderOptions = async () => {
    fetch(VITE_API_URL + 'getgenderoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setGenderOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchIdentifierRelship = async () => {
    fetch(VITE_API_URL + 'getidentifierrelationshipoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setRelationshipOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchInformantAddress = async (personSeq: any) => {
    const response = await fetch(VITE_API_URL + 'GetInformantAddress?PersonSeq=' + personSeq, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };

  const loadReporterContact = async (caseReporter: any) => {
    try {
      const fetchedAddress = await fetchInformantAddress(caseReporter.personSeq);
      const updatedCaseReporter = {
        ...caseReporter,
        informantAddress: fetchedAddress,
      };
      setCaseReporter(updatedCaseReporter);

      const updatedInformantList = formik.values.caseDemographics.informantList.map(
        (informant: any) => {
          if (informant.personSeq === caseReporter.personSeq) {
            return updatedCaseReporter;
          }
          return informant;
        }
      );
      formik.setFieldValue('caseDemographics.informantList', updatedInformantList);
    } catch (error) {
      // Handle the error
    }
  };

  const handleCaseReporterChange = (field: any, value: any) => {
    console.log(field);
    console.log(value);

    const personSeq = formik.values?.caseInvestigations?.caseReporterSeq;
    if (personSeq) {
      const updatedInformantList = formik.values?.caseDemographics?.informantList?.map(
        (informant: any) => {
          if (informant?.personSeq?.toLowerCase() === personSeq?.toLowerCase()) {
            let updatedInformant = { ...informant, isChgInd: 1 };
            if (field === 'contactItemDetails') {
              if (
                !updatedInformant?.informantAddress?.contactItem ||
                !updatedInformant?.informantAddress?.contactItem?.length
              ) {
                updatedInformant.informantAddress.contactItem = [
                  {
                    contactItemDetails: value,
                    contactItemTypeSeq: '176240F7-30A9-40DD-AF0C-304226E1BFEA',
                  },
                ];
              } else {
                updatedInformant.informantAddress.contactItem[0].contactItemDetails = value;
              }
            } else {
              updatedInformant[field] = value;
            }

            if (field === 'relship') {
              updatedInformant.nextOfKin = value?.isNOKRelship ? 1 : 0;
            }
            setCaseReporter(updatedInformant);
            return updatedInformant;
          }
          return informant;
        }
      );
      formik.setFieldValue('caseDemographics.informantList', updatedInformantList);
    }
  };

  const fetchFuneralHomeInfo = async () => {
    const qryParams = {
      FuneralHomeSeq: formik.values?.caseMortuary?.funeralHome?.optionSeq,
    };

    const url = `${VITE_API_URL}getfuneralhomeaddress?${new URLSearchParams(qryParams)}`;

    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (res.status >= 400) {
        throw new Error('An error occurred');
      }

      const data = await res.json();
      setFuneralHomeAddress(data[0] || null);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMaritalStatusOptions = async () => {
    fetch(VITE_API_URL + 'getmaritalstatusoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setMaritalStatusOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchMEs = async () => {
    const queryParams = new URLSearchParams();
    if (formik.values?.caseSummary?.jdxSeq) {
      queryParams.append('jdxList', formik.values?.caseSummary?.jdxSeq);
    }

    await fetch(`${VITE_API_URL}get-pathologist-options?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMEOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  function onCheck(event: { target: { checked: any } }) {
    const isChecked = event.target.checked;

    formik.setFieldValue(
      'caseInvestigations.discovery.isCopyDecedentAddrToFoundOnsetIllnessAddr',
      isChecked
    );

    const foundOnsetIllnessAddress = discovery?.foundOnsetIllnessAddress?.[0] || [];

    if (isChecked) {
      const decedentAddress = formik.values.caseDemographics?.decedentAddress?.[0] || [];

      formik.setFieldValue('caseInvestigations.discovery.foundOnsetIllnessAddress', [
        {
          ...decedentAddress,
          addressSeq: foundOnsetIllnessAddress?.addressSeq,
          contactInfoSeq: foundOnsetIllnessAddress?.contactInfoSeq,
        },
      ]);
    } else {
      const updatedDecedentAddress = {
        ...foundOnsetIllnessAddress,
        addressTypeSeq: null,
        addressLine1: '',
        city: '',
        placeSeq: null,
        countySeq: null,
        nursingHomeSeq: null,
        funeralHomeSeq: null,
        lawEnforcementSeq: null,
        floor: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
      };

      formik.setFieldValue('caseInvestigations.discovery.foundOnsetIllnessAddress', [
        updatedDecedentAddress,
      ]);
    }
  }

  const handleAddressChange = (data: any) => {
    formik.setFieldValue('caseInvestigations.discovery.foundOnsetIllnessAddress', data);
  };

  useEffect(() => {
    fetch(VITE_API_URL + 'getmannerofdeathoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setMannerOfDeathOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
    fetchRaceOptions();
    fetchGenderOptions();
    fetchIdentifierRelship();
    fetchOccupationOptions();
    fetchPregnancyStageOptions();
    fetchFuneralHomeOptions();
    fetchMEs();
    fetchMaritalStatusOptions();
    fetchPastMedicalHistoryOptions();
  }, []);

  useEffect(() => {
    fetchFuneralHomeInfo();
  }, [formik.values?.caseMortuary?.funeralHome?.optionSeq]);

  useEffect(() => {
    //caller details
    const reporter = filteredInformantList?.find(
      (informant: any) =>
        informant.isActive === 1 &&
        informant.personSeq?.toLowerCase() ===
          formik.values?.caseInvestigations?.caseReporterSeq?.toLowerCase()
    );

    if (reporter) {
      setCaseReporter(reporter);
      if (!reporter.informantAddress) {
        loadReporterContact(reporter);
      }
    }
  }, [formik.values?.caseDemographics?.informantList]);

  const decedentFather =
    formik.values?.caseDemographics?.informantList?.find(
      (inf: any) =>
        inf?.relship?.optionSeq?.toUpperCase() === 'F8877D55-E844-4C53-B1ED-AD4DAF180AD4'
    ) || null;

  const organDonationNetworkNotified =
    formik.values?.caseInvestigations?.organDonation !== null &&
    formik.values?.caseInvestigations?.organDonation?.organDonorOrganization !== null;

  const selected_me_action =
    formik?.values?.caseInvestigations?.meAction?.optionSeq?.toUpperCase() || '';

  const computed_certifier_type = useMemo(() => {
    if (selected_me_action === ME_Action.Accepted) {
      return 'Assigned Exam Pathologist';
    }

    if (selected_me_action === ME_Action.Release) {
      return 'Release Physician';
    }
  }, [selected_me_action]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Grid item xs={12} pb={2}>
          <Typography variant='subtitle1' color='gray'>
            <strong>1. LEGAL NAME OF DECEDENT</strong>
          </Typography>
        </Grid>
      </Grid>
      <CaseViewTextField
        xs={12 as any}
        sm={12 as any}
        md={3.5 as any}
        authorizedToEdit={authorizedToEdit && !hasConfirmedIdentification}
        fieldName='caseDemographics.decedentPerson.firstName'
        label={
          <>
            1a. Decedent First Name
            {hasConfirmedIdentification ? (
              <span style={{ color: 'green' }}>
                <i>
                  ({<LockIcon sx={{ fontSize: 15, marginRight: 0.5, color: 'green' }} />} Confirmed){' '}
                </i>
              </span>
            ) : (
              ''
            )}
          </>
        }
      />
      <CaseViewTextField
        xs={12 as any}
        sm={12 as any}
        md={3.5 as any}
        authorizedToEdit={authorizedToEdit && !hasConfirmedIdentification}
        fieldName='caseDemographics.decedentPerson.middleName'
        label={
          <>
            1a. Decedent Middle Name
            {hasConfirmedIdentification ? (
              <span style={{ color: 'green' }}>
                <i>
                  {' '}
                  ({
                    <LockIcon sx={{ fontSize: 15, marginRight: 0.5, color: 'green' }} />
                  } Confirmed){' '}
                </i>
              </span>
            ) : (
              ''
            )}
          </>
        }
      />
      <CaseViewTextField
        xs={12 as any}
        sm={12 as any}
        md={3.5 as any}
        authorizedToEdit={authorizedToEdit && !hasConfirmedIdentification}
        fieldName='caseDemographics.decedentPerson.lastName'
        label={
          <>
            1a. Decedent Last Name
            {hasConfirmedIdentification ? (
              <span style={{ color: 'green' }}>
                <i>
                  {' '}
                  ({
                    <LockIcon sx={{ fontSize: 15, marginRight: 0.5, color: 'green' }} />
                  } Confirmed){' '}
                </i>
              </span>
            ) : (
              ''
            )}
          </>
        }
      />
      <CaseViewTextField
        xs={12 as any}
        sm={12 as any}
        md={1.5 as any}
        authorizedToEdit={authorizedToEdit && !hasConfirmedIdentification}
        fieldName='caseDemographics.decedentPerson.suffix'
        label={
          <>
            1a. Suffix
            {hasConfirmedIdentification ? (
              <span style={{ color: 'green' }}>
                <i> ({<LockIcon sx={{ fontSize: 15, color: 'green' }} />}) </i>
              </span>
            ) : (
              ''
            )}
          </>
        }
      />
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FormikTextField
          label='1b. Also Known As (AKA), If Any (First, Middle, Last, Suffix)'
          formikField='caseInvestigations.decedentAka'
          authorizedToEdit={authorizedToEdit}
          minRows={3}
          maxRows={10}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Autocomplete
          disabled={!formik.status.editing || !authorizedToEdit}
          id='caseDemographics.gender'
          options={genderOptions}
          value={formik.values?.caseDemographics?.gender}
          getOptionLabel={option => option.optionName}
          onChange={(e, value) => {
            formik.setFieldValue('caseDemographics.gender', value);
            setUnsavedChanges();
          }}
          fullWidth
          renderInput={params => <TextField {...params} label='2. Sex' placeholder='Sex' />}
        />
      </Grid>

      <CaseViewTextField
        xs={12 as any}
        sm={3 as any}
        md={3 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.ssn'
        label='3. Social Security Number'
      />

      <Grid item xs={12} sm={3} md={3}>
        <CaseViewAge label='4a. Age' />
      </Grid>

      <CaseViewDateField
        xs={12}
        sm={3}
        md={3}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.dateOfBirth'
        label='5. Date of Birth (Mo/Day/Yr)'
        lg={undefined}
        xl={undefined}
        sx={undefined}
      />

      <CaseViewTextField
        xs={12 as any}
        sm={12 as any}
        md={12 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label='6. Birthplace (City & State/Foreign Country)'
      />

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Grid item xs={12} pb={2}>
          <Typography variant='subtitle1' color='gray'>
            <strong>
              7. DECEDENT RESIDENCE
              {!formik.status.editing &&
                Boolean(formik.values.caseDemographics?.useBodyLocationAddressAsDecedentAddress) &&
                ` (same as Location of Body)`}
            </strong>
          </Typography>
        </Grid>

        <Grid item xs={12} pb={2}>
          <CaseViewSameAsAddressField />
        </Grid>
        <Grid
          component={Collapse}
          in={
            (formik.status.editing &&
              !Boolean(formik.values.caseDemographics?.useBodyLocationAddressAsDecedentAddress)) ||
            !formik.status.editing
          }
          item
          xs={12}
        >
          <AddressData
            handleChange={(data: any) =>
              formik?.handleChange({
                target: {
                  value: data,
                  name: 'caseDemographics.decedentAddress',
                },
              })
            }
            addresses={formik.values?.caseDemographics?.decedentAddress || []}
            title=''
            decedentAddressTypes={true}
            disabled={!formik.status.editing}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      </Grid>

      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={!formik.status.editing || !authorizedToEdit}
          id='caseDemographics.veteranStatus'
          options={options}
          value={
            formik.values != null
              ? options.find(
                  option => option.value === formik.values?.caseDemographics?.veteranStatus
                ) || null
              : null
          }
          onChange={(e, value) => {
            formik.setFieldValue('caseDemographics.veteranStatus', value ? value.value : null);
            setUnsavedChanges();
          }}
          getOptionLabel={option => option.optionName}
          renderInput={params => <TextField {...params} label='8a. Ever in US Armed Forces?' />}
        />
      </Grid>
      <CaseViewTextField
        xs={12 as any}
        sm={6 as any}
        md={6 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label='8b. If Yes, Name of War'
      />

      <Grid item xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='8c. War Service From'
            disabled={true}
            onChange={date => {
              // const localDate = date?.isValid() ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
              // setSelectedIncidentGroup(prevState => ({
              //   ...prevState,
              //   incidentDateTime: localDate,
              // }));
            }}
            // disableFuture={true}
            slots={{
              actionBar: ActionList,
            }}
            slotProps={{
              field: { clearable: true },
              textField: { id: 'warServiceFromDate' },
            }}
            sx={{
              minWidth: '100%',
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='8c. War Service To'
            disabled={true}
            onChange={date => {
              // const localDate = date?.isValid() ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
              // setSelectedIncidentGroup(prevState => ({
              //   ...prevState,
              //   incidentDateTime: localDate,
              // }));
            }}
            // disableFuture={true}
            slots={{
              actionBar: ActionList,
            }}
            slotProps={{
              field: { clearable: true },
              textField: { id: 'warServiceToDate' },
            }}
            sx={{
              minWidth: '100%',
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      </Grid>

      <CaseViewAutocomplete
        xs={12}
        sm={6}
        md={6}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.maritalStatus'
        options={maritalStatusOptions}
        label='9. Domestic Status at Time of Death'
        lg={undefined}
        xl={undefined}
      />

      <CaseViewTextField
        xs={12 as any}
        sm={6 as any}
        md={6 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label='10. Name of Survivng Spouse/Partner (Name given at birth or on birth certificate)'
      />

      <FormikTextField
        gridProps={{ xs: 12, sm: 4, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        disabled={true}
        value={decedentFather?.personFirstName || ''}
        label={`11. Father's First Name`}
        formikField={''}
      />

      <FormikTextField
        gridProps={{ xs: 12, sm: 4, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        disabled={true}
        value={decedentFather?.personMiddleName || ''}
        label={`11. Father's Middle Name`}
        formikField={''}
      />
      <FormikTextField
        gridProps={{ xs: 12, sm: 4, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        disabled={true}
        value={decedentFather?.personLastName || ''}
        label={`11. Father's Last Name`}
        formikField={''}
      />

      <CaseViewTextField
        xs={12 as any}
        sm={4 as any}
        md={4 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label={`12. Mothers's First Name`}
      />
      <CaseViewTextField
        xs={12 as any}
        sm={4 as any}
        md={4 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label={`12. Mothers's Middle Name`}
      />
      <CaseViewTextField
        xs={12 as any}
        sm={4 as any}
        md={4 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label={`12. Mothers's Maiden Last Name`}
      />

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Grid item xs={12} pb={2}>
          <Typography variant='subtitle1' color='gray'>
            <strong>13. INFORMANT</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <Box
          component='div'
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <TextField
            id='caseReporter.personFirstName'
            fullWidth={true}
            disabled={!formik.status.editing || !authorizedToEdit}
            value={caseReporter.personFirstName || ''}
            onChange={e => {
              handleCaseReporterChange('personFirstName', e.target.value);
            }}
            onBlur={setUnsavedChanges}
            label='13a. Informant First Name'
          />
          {(!formik?.status?.editing || !authorizedToEdit) && (
            <Box
              component='div'
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <TextField
          id='caseReporter.personMiddleName'
          fullWidth={true}
          disabled={!formik.status.editing || !authorizedToEdit}
          value={caseReporter.personMiddleName || ''}
          onChange={e => {
            handleCaseReporterChange('personMiddleName', e.target.value);
          }}
          onBlur={setUnsavedChanges}
          label='13a. Informant Middle Name'
        />
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <TextField
          id='caseReporter.personLastName'
          fullWidth={true}
          disabled={!formik.status.editing || !authorizedToEdit}
          value={caseReporter?.personLastName || ''}
          onChange={e => {
            handleCaseReporterChange('personLastName', e.target.value);
          }}
          onBlur={setUnsavedChanges}
          label='13a. Informant Last Name'
        />
        {(!formik?.status?.editing || !authorizedToEdit) && (
          <Box
            component='div'
            onClick={() =>
              formik.setStatus({
                ...formik.status,
                disabledClickCount: formik.status?.disabledClickCount + 1,
              })
            }
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: 'pointer',
            }}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <TextField
          id='caseReporter.suffix'
          fullWidth={true}
          disabled={!formik.status.editing || !authorizedToEdit}
          value={caseReporter.suffix || ''}
          onChange={e => {
            handleCaseReporterChange('suffix', e.target.value);
          }}
          onBlur={setUnsavedChanges}
          label='13a. Informant Suffix'
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Box
          component='div'
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <Autocomplete
            disabled={!formik.status.editing || !authorizedToEdit}
            id='caseReporter.relship'
            options={relationshipOptions}
            value={caseReporter.relship || null}
            onChange={(e, value) => {
              handleCaseReporterChange('relship', value ? value : null);
              setUnsavedChanges();
            }}
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='13b. Relationship to Decedent' />}
          />
          {(!formik?.status?.editing || !authorizedToEdit) && (
            <Box
              component='div'
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <FormikTextField
        gridProps={{ xs: 12, md: 12 }}
        authorizedToEdit={authorizedToEdit}
        disabled={true}
        formikField=''
        label='13c. Mailing Address (Street and Number, City, State, Zip Code)'
      />
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      </Grid>

      <CaseViewTextField
        xs={12 as any}
        sm={2 as any}
        md={2 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label='14. Method of Disposition'
      />
      <CaseViewTextField
        xs={12 as any}
        sm={6 as any}
        md={6 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label='15. Place of Disposition (name of cemetery, crematory, other)'
      />
      <CaseViewTextField
        xs={12 as any}
        sm={4 as any}
        md={4 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.placeholder'
        disabled={true}
        label='16. Location (City & State/Foreign Country)'
      />
      <Grid item xs={12} md={4}>
        <Autocomplete
          disabled={!formik.status.editing || !authorizedToEdit}
          id='caseMortuary.funeralHome'
          options={funeralHomeOptions}
          value={formik.values?.caseMortuary.funeralHome || null}
          onChange={(e, value) => {
            formik.setFieldValue('caseMortuary.funeralHome', value || null);
            formik.setFieldValue(
              'caseMortuary.releasedBy',
              formik.values?.caseMortuary?.releasedBy ||
                `${user?.userLastName || ''}${
                  user?.userLastName && user?.userFirstName ? ', ' : ''
                }${user?.userFirstName || ''}`
            );
            setUnsavedChanges();
          }}
          getOptionLabel={option => option.optionName}
          isOptionEqualToValue={(o, v) => o.optionSeq.toLowerCase() === v.optionSeq.toLowerCase()}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {funeralHomeOptionsLoading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label='17. Funeral Home Name'
            />
          )}
        />
      </Grid>

      {formik.values?.caseMortuary?.funeralHome?.optionSeq?.toUpperCase() ===
        'FA80A11F-DDBB-42B5-B237-43676D2712FC' && (
        <FormikTextField
          gridProps={{ xs: 12, md: 3 }}
          authorizedToEdit={authorizedToEdit}
          formikField='caseMortuary.otherFuneralHome'
          label='17. Other Funeral Home'
        />
      )}

      <FormikTextField
        gridProps={{ xs: 12, md: 8 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.funeralhomeaddressplaceholder'
        disabled={true}
        value={`${funeralHomeAddress?.addressLine1 || ''} ${
          funeralHomeAddress?.addressLine2 || ''
        } ${funeralHomeAddress?.city || ''} ${funeralHomeAddress?.state || ''} ${
          funeralHomeAddress?.zip || ''
        }`}
        label='17. Funeral Home Address'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 8 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='18. Electronic Signature of Funeral Director'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='19. NJ License Number'
      />

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      </Grid>
      {/* 
      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='Decedent Education'
      /> */}

      <Grid item xs={12} md={6}>
        <CaseViewOptionsAutocomplete
          label='20. Decedent Education'
          optionsEndpoint='getmaideducationleveloptions'
          formikField='caseMAIDDetails.educationType'
          authorizedToEdit={authorizedToEdit}
          textFieldProps={{ variant: 'outlined' }}
        />
      </Grid>

      <FormikTextField
        gridProps={{ xs: 12, md: 3 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='21. Decedent of Hispanic Origin?'
      />

      <CaseViewAutocomplete
        xs={12 as any}
        sm={3 as any}
        md={3 as any}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.race'
        options={raceOptions}
        label='22. Decedent Race'
        lg={undefined}
        xl={undefined}
      />
      <CaseViewAutocomplete
        xs={12}
        sm={6}
        md={6}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.occupation'
        options={occupationOptions}
        label='23. Occupation of Decedent (Type of work done most of life, even if retired)'
        lg={undefined}
        xl={undefined}
      />

      {formik.values?.caseDemographics?.occupation != null &&
        formik.values?.caseDemographics?.occupation?.optionSeq === Occupation_Other && (
          <CaseViewTextField
            xs={12 as any}
            sm={6 as any}
            md={6 as any}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.otherOccupation'
            label='23. Other Occupation'
          />
        )}

      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='24. Kind of Business/Industry'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 12 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='25. Name and Address of Last Employer'
      />

      <CaseViewDateTimeField
        xs={12}
        md={6}
        label='26-27. (NOT DONE) Date and Time Pronounced Dead (Mo/Day/Yr) (24-hr)'
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.dateOfDeath'
        sm={undefined}
        lg={undefined}
        xl={undefined}
        sx={undefined}
      />

      <CaseViewDateTimeField
        xs={12}
        md={6}
        label='28. (NOT DONE) Name of Person Pronouncing Death'
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.dateOfDeath'
        sm={undefined}
        lg={undefined}
        xl={undefined}
        sx={undefined}
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 3 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        value=''
        label='29. License Number'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 3 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        value=''
        label='30. Date Signed (Mo/Day/Yr)'
      />

      <CaseViewDateTimeField
        xs={12}
        md={6}
        label='31-32. Date and Time of Death (Mo/Day/Yy) (24-hr)'
        authorizedToEdit={authorizedToEdit}
        fieldName='caseDemographics.dateOfDeath'
        sm={undefined}
        lg={undefined}
        xl={undefined}
        sx={undefined}
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder.wasMedicalExaminerContacted'
        disabled={true}
        label='33. Was Medical Examiner Contacted?'
        value={dayjs(formik.values?.caseInvestigations?.meNotified) !== null ? 'Yes' : ''}
      />

      <Grid item xs={12}>
        <Box
          component='div'
          display={{ xs: 'block', md: 'flex' }}
          alignItems='center'
          gap={{ md: 3 }}
        >
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <b>{`34. Place of Death / Location Where ${
              foundOnsetIllnessByType === 'I'
                ? 'Illness Observed'
                : foundOnsetIllnessByType === 'O'
                ? 'Injury Observed'
                : 'Found'
            }`}</b>
          </Typography>

          {showCheckbox && (
            <FormControlLabel
              disabled={!editing || !authorizedToEdit}
              checked={discovery?.isCopyDecedentAddrToFoundOnsetIllnessAddr ?? false}
              control={<Checkbox sx={{ transform: 'scale(0.8)' }} />} // Optional: Scale the checkbox size
              // @ts-ignore
              onChange={onCheck}
              label='Copy address from decedent residence'
              sx={{
                flexShrink: 0,
                '& .MuiFormControlLabel-label': { fontSize: '0.7rem' }, // Adjust label font size
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <AddressData
          handleChange={(data: any) => handleAddressChange(data)}
          addresses={discovery?.foundOnsetIllnessAddress || []}
          title=''
          showAllTypes={true}
          disabled={
            !editing || !authorizedToEdit || discovery?.isCopyDecedentAddrToFoundOnsetIllnessAddr
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Grid item xs={12} pb={2}>
          <Typography variant='subtitle1' color='gray'>
            <strong>35. CAUSE OF DEATH</strong>
          </Typography>
          <Typography variant='subtitle2' color='gray'>
            <strong>
              35.a PART I - IMMEDIATE CAUSE - final disease or condition resulting in death.
              Subsequently list conditions, if any, leading to the casue listed on Line a. Enter the
              UNDERLYING CAUSE (disease or injury that initiated the events resulting in death)
              LAST.
            </strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <TextField
          disabled={!formik.status.editing || !authorizedToEdit}
          fullWidth
          id='casePathology.causeOfDeath'
          name='casePathology.causeOfDeath'
          label={
            <div>
              {'35a. Immediate Cause Of Death '}
              <span style={{ color: 'red' }}>*</span>
            </div>
          }
          size='small'
          variant='outlined'
          value={formik.values != null ? formik.values?.casePathology.causeOfDeath : null}
          onChange={e => {
            formik.handleChange(e);
            setUnsavedChanges();
          }}
          error={
            (formik.touched?.causeOfDeath ?? false) && Boolean(formik.errors?.causeOfDeath ?? false)
          }
        />
      </Grid>
      <FormikTextField
        gridProps={{ xs: 12, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='35a. Interval Between Onset and Death'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 8 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='35b. Due to (or as a consequence of)'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='35b. Interval Between Onset and Death'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 8 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='35c. Due to (or as a consequence of)'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='35c. Interval Between Onset and Death'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 8 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='35d. Due to (or as a consequence of)'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='35d. Interval Between Onset and Death'
      />

      <Grid item xs={12} md={12}>
        <TextField
          disabled={!formik.status.editing || !authorizedToEdit}
          fullWidth
          id='casePathology.contributory'
          name='casePathology.contributory'
          label='36b PART II - Enter other significant conditions contributing to death but not resulting in underlying cause given in PART I.'
          size='small'
          multiline
          minRows={3}
          maxRows={10}
          variant='outlined'
          value={formik.values != null ? formik.values?.casePathology.contributory : null}
          onChange={e => {
            formik.handleChange(e);
            setUnsavedChanges();
          }}
          error={
            (formik.touched?.contributory ?? false) && Boolean(formik.errors?.contributory ?? false)
          }
        />
      </Grid>

      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        label='37. Was an Autopsy Performed?'
        value={
          formik?.values?.caseInvestigations?.meAction?.optionSeq?.toUpperCase() ===
          ME_Action.Accepted
            ? 'Yes'
            : ''
        }
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        value=''
        label='38. Were Autopsy Findings Availiable to Complete Cause of Death?'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='39-40. Date and Time of Injury (Mo/Day/Yr)'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 4.5 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='41. Place of Injury (e.g. home, construction site, restaurant)'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 1.5 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        value=''
        label='42. Injury at work?'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='43a. Location of Injury (Number and Street, Zip Code)'
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 2 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='43b. Municipality'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 2 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='43c. County'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 2 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        value=''
        label='43d. State'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 8 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='44. Describe How Injury Occured'
        multiline
        minRows={2}
        maxRows={10}
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='45. If Transportation Injury'
        multiline
        minRows={2}
        maxRows={10}
      />

      <Grid item xs={12} md={3}>
        <Autocomplete
          disabled={!formik.status.editing || !authorizedToEdit}
          id='casePathology.mannerOfDeath'
          size='small'
          options={mannerOfDeathOptions}
          isOptionEqualToValue={(option, value) =>
            option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
          }
          value={formik.values?.casePathology.mannerOfDeath || null}
          onChange={(e, value) => {
            formik.setFieldValue('casePathology.mannerOfDeath', value || null);
            setUnsavedChanges();
          }}
          getOptionLabel={option => option.optionName}
          renderInput={params => (
            <TextField
              {...params}
              label={
                <div>
                  {'46. Manner Of Death '}
                  <span style={{ color: 'red' }}>*</span>
                </div>
              }
            />
          )}
        />
      </Grid>

      <FormikTextField
        gridProps={{ xs: 12, md: 3 }}
        value={
          formik.values?.caseInvestigations?.pastMedicalHistory?.findIndex(
            (o: Option) => o.optionSeq === GuidEmptySeq
          ) > -1
            ? 'Yes'
            : ''
        }
        label='Did Decedent Have Diabetes?'
        authorizedToEdit={false}
        formikField={''}
      />

      <FormikTextField
        gridProps={{ xs: 12, md: 3 }}
        value={
          formik.values?.caseInvestigations?.pastMedicalHistory?.findIndex(
            (o: Option) => o.optionSeq === GuidEmptySeq
          ) > -1
            ? 'Yes'
            : ''
        }
        label='Did Tobacco Use Contribute to Death?'
        authorizedToEdit={false}
        formikField={''}
      />

      <Grid item xs={12} sm={3} md={3}>
        <Autocomplete
          disabled={true}
          id='caseDemographics.pregnancyStatus'
          options={pregnancystageOptions}
          value={
            formik.values != null
              ? pregnancystageOptions.find(
                  // @ts-ignore
                  option => option.optionSeq === formik.values?.caseDemographics?.pregnancyStatus
                ) || null
              : null
          }
          onChange={(e, value) => {
            formik.setFieldValue(
              'caseDemographics.pregnancyStatus',
              // @ts-ignore
              value ? value.optionSeq : null
            );
            setUnsavedChanges();
          }}
          getOptionLabel={(
            option // @ts-ignore
          ) => option.optionName}
          renderInput={params => <TextField {...params} label='49. If Female, Pregnancy State' />}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Grid item xs={12} pb={2}>
          <Typography variant='subtitle1' color='gray'>
            <strong>50-54. CERTIFIER</strong>
          </Typography>
        </Grid>
      </Grid>

      <FormikTextField
        gridProps={{ xs: 12, md: 4 }}
        authorizedToEdit={authorizedToEdit}
        formikField='caseMortuary.placeholder'
        disabled={true}
        label='50. Certifier Type'
        value={computed_certifier_type}
      />
      <CaseViewAutocomplete
        xs={12}
        md={8}
        authorizedToEdit={authorizedToEdit}
        fieldName='caseCertification.deathCertificateSignedAndFiledUser'
        options={meOptions}
        label='51. Certifier Name'
        sm={undefined}
        lg={undefined}
        xl={undefined}
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 12 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='51. Certifier Address, and Zip Code of Certifier'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='52. Electronic Signature of Certifier'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 3 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        value=''
        label='52. License Number'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 3 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='54. Date Certified (Mo/Day/Yr)'
      />
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      </Grid>

      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='55. Electronic Signature of Local Registrar'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 2 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='56. District No.'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 2 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='57. Date Received'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 2 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        value=''
        label='DC Number'
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 2 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        value={
          new Date(Date.now()).toLocaleDateString() +
          ' ' +
          new Date(Date.now()).toLocaleTimeString()
        }
        label='Date/Time '
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 6 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        disabled={true}
        label='Organ Donor Network Notified'
        value={organDonationNetworkNotified ? 'Yes' : ''}
      />
      <FormikTextField
        gridProps={{ xs: 12, md: 2 }}
        authorizedToEdit={authorizedToEdit}
        formikField=''
        value=''
        disabled={true}
        label='Died on Active Duty?'
      />
    </Grid>
  );
}
