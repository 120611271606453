import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import ReportCard from '../../../components/Reports/ReportCard';
import { useAuth } from '../../../utils/auth/AuthService';
import JurisdictionAutocomplete from '../../../components/JurisdictionAutocomplete';
import { Jurisdiction } from '../../../types/Jurisdiction.interface';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRange } from '@mui/x-date-pickers-pro/models';

interface CaseTurnaroundFormValues {
  reportedDateRange: [Dayjs | null, Dayjs | null];
  selectedYear: number | null;
  selectedJdxs: Jurisdiction[];
  clockStart: string;
}

const shortcutsItems = [
  // {
  //   label: 'This Week',
  //   getValue: () => {
  //     const today = dayjs();
  //     return [today.startOf('week'), today.endOf('week')];
  //   },
  // },
  // {
  //   label: 'Last Week',
  //   getValue: () => {
  //     const today = dayjs();
  //     const prevWeek = today.subtract(7, 'day');
  //     return [prevWeek.startOf('week'), prevWeek.endOf('week')];
  //   },
  // },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs();
      const endOfLastMonth = today.startOf('month').subtract(1, 'day');
      return [endOfLastMonth.startOf('month'), endOfLastMonth];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, 'day'), today];
    },
  },
  {
    label: 'Last 6 Months',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(6, 'month'), today];
    },
  },
  {
    label: 'Last 3 Years',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(3, 'year'), today];
    },
  },
  {
    label: 'Last 5 Years',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(5, 'year'), today];
    },
  },
  {
    label: 'Reset',
    getValue: () => [null, null],
  },
];

export default function CaseTurnAroundTimeView() {
  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;
  const [averageTAT, setAverageTAT] = useState('');
  const [yearOptions, setYearOptions] = useState<number[]>([]);

  useEffect(() => {
    if (user) {
      const currentYear = new Date().getFullYear();
      // Create a list of 16 years (current year and the 15 previous)
      setYearOptions([...Array(16).keys()].map(i => currentYear - i));
    }
  }, [user]);

  const initialValues: CaseTurnaroundFormValues = {
    reportedDateRange: [null, null],
    selectedYear: user ? new Date().getFullYear() : null,
    selectedJdxs: user ? user.jdxAccessList : [],
    clockStart: 'exam',
  };

  const validate = (values: CaseTurnaroundFormValues) => {
    const errors: Partial<Record<keyof CaseTurnaroundFormValues, string>> = {};
    // Either a year must be selected, or both start & end dates must be provided
    if (!values.selectedYear && (!values.reportedDateRange[0] || !values.reportedDateRange[1])) {
      errors.reportedDateRange =
        'Call taken range must be within 5 years or a year must be selected.';
    } else if (!values.selectedYear && values.reportedDateRange[0] && values.reportedDateRange[1]) {
      const start = dayjs(values.reportedDateRange[0]);
      const end = dayjs(values.reportedDateRange[1]);
      if (!start.isValid() || !end.isValid() || end.diff(start, 'year', true) > 5) {
        errors.reportedDateRange =
          'Call taken range must be within 5 years or a year must be selected.';
      }
    }
    if (!values.selectedJdxs || values.selectedJdxs.length === 0) {
      errors.selectedJdxs = 'Please select at least one jurisdiction.';
    }
    return errors;
  };

  const onSubmit = async (values: CaseTurnaroundFormValues, formData: FormData) => {
    formData.append(
      'SEARCHFROMDATE',
      values.selectedYear ? '' : values.reportedDateRange[0]?.toISOString() ?? ''
    );
    formData.append(
      'SEARCHTODATE',
      values.selectedYear ? '' : values.reportedDateRange[1]?.toISOString() ?? ''
    );
    formData.append('CASEYEAR', values.selectedYear?.toString() || '');
    formData.append('SELECTEDJDXLIST', JSON.stringify(values.selectedJdxs));
    formData.append('CLOCKSTART', values.clockStart);

    if (!user?.token) {
      throw new Error('User token unavailable');
    }
    const response = await fetch(`${VITE_API_URL}TurnaroundTime`, {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + user.token },
      body: formData,
    });
    if (response.status === 401) throw new Error('You are unauthorized to use this tool');
    if (response.status >= 400) throw new Error('An error occurred');
    const data = await response.json();

    // Compute average turnaround time:
    let total = 0,
      count = 0;
    data.forEach((item: any) => {
      total += Number(item.turnaroundTime);
      count++;
    });
    const avg = count > 0 ? Math.round(total / count) : 0;
    setAverageTAT(avg.toString());
    return data;
  };

  const renderForm = (formik: any) => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JurisdictionAutocomplete
          selectedJurisdictions={formik.values?.selectedJdxs ?? []}
          setSelectedJurisdictions={(_, data) => formik.setFieldValue('selectedJdxs', data)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          id='selectedYear'
          options={yearOptions}
          value={formik.values.selectedYear}
          size='small'
          getOptionLabel={(option: number) => option.toString()}
          onChange={(event, data) => formik.setFieldValue('selectedYear', data)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<MdCheckBoxOutlineBlank />}
                checkedIcon={<MdOutlineCheckBox />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.toString()}
            </li>
          )}
          fullWidth
          renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            name='reportedDateRange'
            value={formik.values?.reportedDateRange as DateRange<any>}
            onChange={data => formik.setFieldValue('reportedDateRange', data)}
            localeText={{ start: 'Reported From', end: 'Reported To' }}
            disabled={formik.values?.caseYear}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Start Clock when:</FormLabel>
          <RadioGroup
            row
            name='clockStart'
            value={formik.values.clockStart}
            onChange={formik.handleChange}
          >
            <FormControlLabel value='exam' control={<Radio />} label='Exam Completed' />
            <FormControlLabel value='notified' control={<Radio />} label='Call Taken' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 105,
      editable: false,
      renderCell: (params: any) => (
        <NavLink
          to={
            import.meta.env.MODE === 'production'
              ? '/medexlab/caseview?id=' + params.formattedValue
              : '/caseview?id=' + params.formattedValue
          }
          color='primary'
        >
          <b>{params.formattedValue}</b>
        </NavLink>
      ),
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'jurisdictionName',
      headerName: 'Jurisdiction',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'me',
      headerName: 'Medical Examiner',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'examEnd',
      headerName: 'Exam End',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseSigned',
      headerName: 'Case Signed and Filed',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'turnaroundTime',
      headerName: 'Turnaround Time (Days)',
      flex: 1,
      minWidth: 80,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  return (
    <ReportCard<CaseTurnaroundFormValues>
      title='Case Turnaround Time Report'
      description='This report measures how long it takes on average for a CMS case to complete. This is measured EITHER from when the exam is completed to when the case is signed/certified by the pathologist OR from when the call comes in to when the case is certified by the pathologist. Please select your preferred clock start.'
      columns={columns}
      idColumn='caseid'
      chatContent={
        <Paper
          elevation={3}
          sx={{
            p: '10px',
            px: '20px',
            borderRadius: '20px',
            width: 'max-content',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            The average turnaround time for your selection is <b>{averageTAT} days.</b> I've listed
            the detailed case records below; you can export the list to CSV format.
          </div>
        </Paper>
      }
      initialValues={initialValues}
      validate={validate}
      renderForm={renderForm}
      onSubmit={onSubmit}
    />
  );
}
