import { Box, Grid } from '@mui/material';
import BulkFileUpload from '../../../../components/CaseRecords/BulkFileUploadModule';
import { useAuth } from '../../../../utils/auth/AuthService.tsx';

export default function ToolsView() {
  const { user } = useAuth();

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <BulkFileUpload />
        </Grid>
      </Grid>
    </Box>
  );
}
