import React from 'react';
import { ErrorFallback_View } from '../components/ErrorFallback_View';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

export class CustomErrorBoundary extends React.Component<
  { children: React.ReactNode },
  ErrorBoundaryState
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ errorInfo });
    console.log('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorFallback_View
          error={this.state.error}
          errorInfo={this.state.errorInfo}
          resetErrorBoundary={() =>
            this.setState({ hasError: false, error: null, errorInfo: null })
          }
        />
      );
    }
    return this.props.children;
  }
}
