import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ActionList } from '../../../../components/CaseView/CaseViewDateTimeField';
import { useAuth } from '../../../../utils/auth/AuthService';
import CharacterLimitTextField from '../../../../utils/components/CharacterLimitTextField';
import { dateColumnType } from '../../../../utils/components/CrudDataGrid/DateColumn';
import DialogCrudDataGrid, {
  getDateValue,
  handleDateChange,
  handleTextFieldChange,
} from '../../../../utils/components/DialogCrudDataGrid';

export default function ElabInventoryManagement() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;

  const inventoryColumns = [
    {
      field: 'InventoryItemSeq', // the id of the columns
      headerName: '',
      flex: 1,
      minWidth: 125,
      maxWidth: 125,
      hidden: false,
      editable: false,
    },
    {
      field: 'itemName',
      headerName: 'Item Name',
      minWidth: 175,
      maxWidth: 200,
      flex: 1,
      editable: false,
      //   renderCell: params => {
      //     return (
      //       <NavLink
      //         //variant="contained"
      //         color='primary'
      //         onClick={event => {
      //           handleELabCaseClick(event, params);
      //         }}
      //       >
      //         {params.formattedValue}
      //       </NavLink>
      //     );
      //   },
    },
    // {
    //   field: 'traceid',
    //   headerName: 'Trace ID',
    //   flex: 1,
    //   // minWidth: 150,
    //   // maxWidth: 170,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'inventoryItemExpirationDateTime',
      headerName: 'Expiration Date',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
    },
    // {
    //   field: 'inventoryType',
    //   headerName: 'Inventory Type',
    //   flex: 1,
    //   // minWidth: 150,
    //   // maxWidth: 170,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'storageLocationName',
      headerName: 'Storage Location',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    // { not sure if the Active is the same as isActive we have
    //   field: 'active',
    //   headerName: 'Active',
    //   flex: 1,
    //   // minWidth: 150,
    //   // maxWidth: 170,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'isInventoryItemPublished',
      headerName: 'Published',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      field: 'receivedDateTime',
      headerName: 'Received Date',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      ...dateColumnType,
      editable: false,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
    },
    {
      field: 'receivedByUserName',
      headerName: 'Received By',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    {
      field: 'openedDateTime',
      headerName: 'Opened Date',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
    },
    {
      field: 'comment',
      headerName: 'Comments',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
  ];

  useEffect(() => {
    if (user === null) return;

    setLoading(true);

    if (user && user.token) {
      fetchLmsInventory();
    }
  }, [user]);

  const fetchLmsInventory = async () => {
    try {
      const response = await fetch(`${VITE_FASTAPI_URL}elab/lms_inventory`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch inventory');
      const data = await response.json();
      setlistresults(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const createLmsInventoryItem = async newItem => {
    try {
      const response = await fetch(`${VITE_FASTAPI_URL}elab/lms_inventory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) throw new Error('Failed to create inventory item');
      await fetchLmsInventory(); // Refresh data after creation
    } catch (error) {
      console.error(error);
    }
  };

  const updateLmsInventoryItem = async updatedItem => {
    try {
      const response = await fetch(
        `${VITE_FASTAPI_URL}elab/lms_inventory/${updatedItem.InventoryItemSeq}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(updatedItem),
        }
      );

      if (!response.ok) throw new Error('Failed to update inventory item');
      fetchLmsInventory(); // Refresh data
    } catch (error) {
      console.error(error);
    }
  };

  const deleteLmsInventoryItem = async id => {
    try {
      const response = await fetch(`${VITE_FASTAPI_URL}elab/lms_inventory/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (!response.ok) throw new Error('Failed to delete inventory item');
      fetchLmsInventory(); // Refresh data
    } catch (error) {
      console.error(error);
    }
  };

  const InventoryDialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(() => {
      if (mode === 'add') {
        return {
          ...data,
          createdByUserSeq: user?.userSeq || null,
          updatedByUserSeq: user?.userSeq || null,
        };
      } else {
        // mode === 'edit'
        return {
          ...data,
          updatedByUserSeq: user?.userSeq || data.updatedByUserSeq,
        };
      }
    });

    useEffect(() => {
      setLocalData(prev => {
        if (mode === 'add') {
          return {
            ...data,
            createdByUserSeq: user?.userSeq || prev.createdByUserSeq,
            updatedByUserSeq: user?.userSeq || prev.updatedByUserSeq,
          };
        } else {
          return {
            ...prev,
            ...data,
            updatedByUserSeq: user?.userSeq || prev.updatedByUserSeq,
          };
        }
      });
    }, [data, mode, user]);

    return (
      <Grid container spacing={1}>
        {/* Item Name */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.itemName}
            onChange={event =>
              handleTextFieldChange(event, 'itemName', localData, setLocalData, onChange)
            }
            label='Item Name'
            fullWidth
            margin='dense'
            characterLimit={50}
            TextFieldProps={{ required: true }}
          />
        </Grid>

        {/* Trace ID */}
        {/* <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.traceid}
            onChange={event =>
              handleTextFieldChange(event, 'traceid', localData, setLocalData, onChange)
            }
            label='Trace ID'
            fullWidth
            margin='dense'
            characterLimit={255}
          />
        </Grid> */}

        {/* Expiration Date */}
        <Grid item xs={12} md={6} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Expiration Date'
              value={getDateValue(localData?.inventoryItemExpirationDateTime)}
              onChange={value =>
                handleDateChange(
                  value,
                  'inventoryItemExpirationDateTime',
                  localData,
                  setLocalData,
                  onChange
                )
              }
              sx={{ width: '100%' }}
              slots={{ actionBar: ActionList }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>

        {/* Inventory Type */}
        {/* <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.inventoryType}
            onChange={event =>
              handleTextFieldChange(event, 'inventoryType', localData, setLocalData, onChange)
            }
            label='Inventory Type'
            fullWidth
            margin='dense'
            characterLimit={255}
          />
        </Grid> */}

        {/* Storage Location */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.storageLocationName}
            onChange={event =>
              handleTextFieldChange(event, 'storageLocationName', localData, setLocalData, onChange)
            }
            label='Storage Location'
            fullWidth
            margin='dense'
            characterLimit={50}
          />
        </Grid>

        {/* Active Checkbox */}
        {/* <Grid item xs={12} md={6} xl={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(localData?.active)}
                onChange={event => onChange({ ...localData, active: event.target.checked })}
              />
            }
            label='Active'
          />
        </Grid> */}

        {/* Published Checkbox */}
        <Grid item xs={12} md={6} xl={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(localData?.isInventoryItemPublished)}
                onChange={event =>
                  onChange({ ...localData, isInventoryItemPublished: event.target.checked })
                }
              />
            }
            label='Published'
          />
        </Grid>

        {/* Received Date */}
        <Grid item xs={12} md={6} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Received Date'
              value={getDateValue(localData?.receivedDateTime)}
              onChange={value =>
                handleDateChange(value, 'receivedDateTime', localData, setLocalData, onChange)
              }
              sx={{ width: '100%' }}
              slots={{ actionBar: ActionList }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>

        {/* Received By */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.receivedByUserName}
            onChange={event =>
              handleTextFieldChange(event, 'receivedByUserName', localData, setLocalData, onChange)
            }
            label='Received By'
            fullWidth
            margin='dense'
            characterLimit={50}
          />
        </Grid>

        {/* Opened Date */}
        <Grid item xs={12} md={6} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Opened Date'
              value={getDateValue(localData?.openedDateTime)}
              onChange={value =>
                handleDateChange(value, 'openedDateTime', localData, setLocalData, onChange)
              }
              sx={{ width: '100%' }}
              slots={{ actionBar: ActionList }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>

        {/* Comments */}
        <Grid item xs={12}>
          <CharacterLimitTextField
            value={localData?.comment}
            onChange={event =>
              handleTextFieldChange(event, 'comment', localData, setLocalData, onChange)
            }
            label='Comments'
            fullWidth
            multiline
            rows={4}
            margin='dense'
            characterLimit={500}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogCrudDataGrid
      idColumn='InventoryItemSeq'
      columns={inventoryColumns}
      loading={loading}
      rows={listresults}
      title='Inventory'
      rowsperpage={20}
      createFunction={createLmsInventoryItem}
      updateFunction={updateLmsInventoryItem}
      deleteFunction={deleteLmsInventoryItem}
      dialogContent={InventoryDialogContent}
      initialState={{
        columns: {
          columnVisibilityModel: {
            InventoryItemSeq: false,
          },
        },
      }}
    />
  );
}
