import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { Button, Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';

export default function ElabAMReadyForReview() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { VITE_FASTAPI_URL } = import.meta.env;
  const navigate = useNavigate();

  const columns = [
    {
      field: 'ID',
      headerName: '',
      flex: 1,
      minWidth: 125,
      maxWidth: 125,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'CASENUMBER',
      headerName: 'Tox LIMS Number',
      minWidth: 175,
      maxWidth: 200,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: params => (
        <Button
          color='primary'
          onClick={event => {
            handleELabCaseClick(event, params);
          }}
        >
          <b>{params.formattedValue}</b>
        </Button>
      ),
    },
    {
      field: 'PENDING',
      headerName: 'Pending',
      flex: 1,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'Status',
      headerName: 'Testing Status',
      flex: 1,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'REVIEWDT',
      headerName: 'Reviewed Date',
      flex: 1,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'AMENDEDDT',
      headerName: 'Amended Date',
      flex: 1,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'certifier',
      headerName: 'Certifying Reviewer',
      flex: 1,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'amender',
      headerName: 'Certifying Amender',
      flex: 1,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'REVIEWED',
      headerName: 'Is Reviewed',
      flex: 1,
      type: 'boolean',
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: cell =>
        cell.value ? <CheckIcon color='success' /> : <CloseIcon color='error' />,
    },
    {
      field: 'AMENDED',
      headerName: 'Is Amended',
      flex: 1,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: cell =>
        cell.value ? <CheckIcon color='success' /> : <CloseIcon color='error' />,
    },
  ];

  const handleELabCaseClick = (event, data) => {
    navigate('/elabamcaseview', {
      state: {
        elabcasenumber: data.formattedValue,
        previousPage: window.location.pathname,
        showBackButton: true,
      },
    });
  };

  const fetchCases = async () => {
    setLoading(true);
    try {
      if (!user?.token) {
        throw new Error('User token unavailable');
      }

      const formData = new FormData();
      formData.append('CASETYPE', 'am');

      const response = await fetch(`${VITE_FASTAPI_URL}elab/reports/ready-for-review`, {
        method: 'POST',
        headers: { Authorization: 'Bearer ' + user.token },
        body: formData,
      });
      if (response.status === 401) throw new Error('You are unauthorized to use this tool');
      if (response.status >= 400) throw new Error('An error occurred');

      const jsonData = await response.json();

      console.log(jsonData);

      setData(jsonData);
    } catch (error) {
      console.error('Error fetching cases', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCases();
  }, []);

  return (
    <ReportDataGrid
      idcolumn='ID'
      loading={loading}
      listresults={data}
      columnsInput={columns}
      casecount
      rowsperpage={20}
      customButtons={[<GridToolbarQuickFilter key='filter' />]}
    />
  );
}
