import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  InputAdornment,
  TextFieldProps,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface CurrencyTextFieldProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  value: number | null;
  onChange: (value: number | null) => void;
  currencySymbol?: string;
  currencyLabel?: string;
  decimalPlaces?: number;
  allowNegative?: boolean;
  placeholder?: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
  formatOnBlur?: boolean;
  maxValue?: number; // New property for maximum allowed value
  onMaxValueExceeded?: (value: number) => void; // Optional callback when max value is exceeded
}

/**
 * A custom TextField component for handling money/price inputs.
 * Inspired by AutoNumeric and other popular currency input libraries.
 * Strictly enforces decimal limits during typing.
 * Includes a clear button to reset the input.
 */
const CurrencyTextField: React.FC<CurrencyTextFieldProps> = ({
  value,
  onChange,
  currencySymbol = '$',
  currencyLabel = 'USD',
  decimalPlaces = 2,
  allowNegative = false,
  placeholder = '0.00',
  thousandSeparator = ',',
  decimalSeparator = '.',
  formatOnBlur = true,
  maxValue = 999999.99, // Default max value to match common DB schema limits
  onMaxValueExceeded,
  InputProps,
  ...textFieldProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // Internal state for handling the raw input string during typing
  const [displayValue, setDisplayValue] = useState<string>('');
  // Track if the input has focus to avoid formatting while typing
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  // Format number value for display with thousand separators and decimal places
  const formatForDisplay = (num: number | null): string => {
    if (num === null || num === undefined || isNaN(num)) {
      return '';
    }

    // Use toLocaleString for formatting but handle custom separators
    const formatted = num.toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      useGrouping: true,
    });

    // If using custom separators that differ from en-US locale defaults,
    // replace them as needed (en-US uses ',' for thousands and '.' for decimal)
    if (thousandSeparator !== ',' || decimalSeparator !== '.') {
      return formatted
        .replace(/,/g, '__TEMP__') // Temporarily replace commas
        .replace(/\./g, decimalSeparator) // Replace decimal points
        .replace(/__TEMP__/g, thousandSeparator); // Replace commas
    }

    return formatted;
  };

  // Parse display value to get numeric value
  const parseDisplayValue = (displayVal: string): number | null => {
    if (!displayVal) return null;

    // Remove currency symbol, thousand separators and normalize decimal separator
    const normalized = displayVal
      .replace(new RegExp(`[${currencySymbol}${thousandSeparator}\\s]`, 'g'), '')
      .replace(decimalSeparator, '.');

    // Handle empty string
    if (!normalized || normalized === '-') return null;

    const num = parseFloat(normalized);
    return isNaN(num) ? null : num;
  };

  // Initialize display value when the value prop changes
  useEffect(() => {
    // Only update display if input doesn't have focus or if value is explicitly set to null
    if (!hasFocus || value === null) {
      setDisplayValue(value === null ? '' : formatForDisplay(value));
    }
  }, [value, hasFocus]);

  // Validate if user input would form a valid money value
  const isValidInput = (input: string): boolean => {
    if (input === '' || (input === '-' && allowNegative)) {
      return true;
    }

    // Remove existing formatting to check just the numeric content
    const strippedValue = input
      .replace(new RegExp(`[${currencySymbol}${thousandSeparator}\\s]`, 'g'), '')
      .replace(decimalSeparator, '.');

    // Check if value exceeds maximum (for validation during typing)
    const numValue = parseFloat(strippedValue);
    if (!isNaN(numValue) && maxValue !== undefined && numValue > maxValue) {
      return false;
    }

    // Strictly enforce decimal limit during typing
    const parts = strippedValue.split('.');
    if (parts.length > 1 && parts[1].length > decimalPlaces) {
      return false;
    }

    // Pattern for valid numeric input with decimal limit
    const pattern = allowNegative
      ? new RegExp(`^-?\\d*\\.?\\d{0,${decimalPlaces}}$`)
      : new RegExp(`^\\d*\\.?\\d{0,${decimalPlaces}}$`);

    // Make sure we only have one decimal point
    const decimalCount = input.split(decimalSeparator).length - 1;

    return pattern.test(strippedValue) && decimalCount <= 1;
  };

  // Handle input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInput = event.target.value;

    // Allow typing if the current input could form a valid money value
    if (isValidInput(newInput)) {
      setDisplayValue(newInput);

      // Only parse number and call onChange if we have a complete number
      if (newInput === '' || newInput === '-') {
        onChange(null);
      } else {
        // Convert to number for the parent component
        const numValue = parseDisplayValue(newInput);
        onChange(numValue);
      }
    } else {
      // Check if the value exceeds the maximum
      const numValue = parseDisplayValue(newInput);
      if (numValue !== null && maxValue !== undefined && numValue > maxValue) {
        // Call the onMaxValueExceeded callback if provided
        if (onMaxValueExceeded) {
          onMaxValueExceeded(numValue);
        }
        return;
      }

      // If invalid input contains more decimals than allowed, truncate it
      const parts = newInput.split(decimalSeparator);
      if (parts.length > 1 && parts[1].length > decimalPlaces) {
        const truncated = parts[0] + decimalSeparator + parts[1].substring(0, decimalPlaces);
        if (isValidInput(truncated)) {
          setDisplayValue(truncated);
          const numValue = parseDisplayValue(truncated);
          onChange(numValue);
        }
      }
    }
  };

  // Format the value when the input loses focus
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(false);

    if (formatOnBlur && displayValue) {
      const numValue = parseDisplayValue(displayValue);
      if (numValue !== null) {
        setDisplayValue(formatForDisplay(numValue));
      }
    }

    // Call original onBlur handler if provided
    if (textFieldProps.onBlur) {
      textFieldProps.onBlur(event);
    }
  };

  // Handle focus events
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(true);

    // Call original onFocus handler if provided
    if (textFieldProps.onFocus) {
      textFieldProps.onFocus(event);
    }
  };

  // Special handling for certain key presses
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle decimal separator
    if (event.key === '.' || event.key === ',') {
      // If we already have a decimal separator, prevent adding another
      if (displayValue.includes(decimalSeparator)) {
        event.preventDefault();
      } else if (event.key !== decimalSeparator) {
        // If the pressed key is not the configured decimal separator,
        // prevent default and manually insert the correct one
        event.preventDefault();
        const selectionStart = event.currentTarget.selectionStart || 0;
        const selectionEnd = event.currentTarget.selectionEnd || 0;
        const newValue =
          displayValue.substring(0, selectionStart) +
          decimalSeparator +
          displayValue.substring(selectionEnd);

        setDisplayValue(newValue);

        // Update cursor position after React renders
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.selectionStart = selectionStart + 1;
            inputRef.current.selectionEnd = selectionStart + 1;
          }
        }, 0);
      }
    }

    // Handle negative sign
    if (event.key === '-' && !allowNegative) {
      event.preventDefault();
    }

    // If there's an onKeyDown in the props, call it
    if (textFieldProps.onKeyDown) {
      textFieldProps.onKeyDown(event);
    }
  };

  // Handle paste events
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData('text');

    // If pasted content wouldn't result in valid input
    if (!isValidInput(pastedText)) {
      event.preventDefault();

      // Try to sanitize and truncate the pasted value
      const sanitized = pastedText.replace(
        new RegExp(`[^0-9${decimalSeparator}${allowNegative ? '-' : ''}]`, 'g'),
        ''
      );

      const parts = sanitized.split(decimalSeparator);
      const truncated =
        parts.length > 1
          ? parts[0] + decimalSeparator + parts[1].substring(0, decimalPlaces)
          : sanitized;

      if (isValidInput(truncated)) {
        // Insert the sanitized value instead
        const selectionStart = event.currentTarget.selectionStart || 0;
        const selectionEnd = event.currentTarget.selectionEnd || 0;
        const newValue =
          displayValue.substring(0, selectionStart) +
          truncated +
          displayValue.substring(selectionEnd);

        setDisplayValue(newValue);
        const numValue = parseDisplayValue(newValue);
        onChange(numValue);

        // Update cursor position after React renders
        setTimeout(() => {
          if (inputRef.current) {
            const newPosition = selectionStart + truncated.length;
            inputRef.current.selectionStart = newPosition;
            inputRef.current.selectionEnd = newPosition;
          }
        }, 0);
      }
    }

    // If there's an onPaste in the props, call it
    if (textFieldProps.onPaste) {
      textFieldProps.onPaste(event);
    }
  };

  // Handle clear button click
  const handleClearClick = () => {
    setDisplayValue('');
    onChange(null);
    // Focus the input after clearing
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Check if current value exceeds maximum
  const currentValue = parseDisplayValue(displayValue);
  const isOverMaxValue = currentValue !== null && currentValue > maxValue;

  return (
    <TextField
      {...textFieldProps}
      inputRef={inputRef}
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      type='text'
      placeholder={placeholder}
      error={textFieldProps.error || isOverMaxValue}
      helperText={
        isOverMaxValue
          ? `Maximum value is ${formatForDisplay(maxValue)}`
          : textFieldProps.helperText
      }
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position='start'>
            <Box component='div' display='flex' alignItems='center'>
              <Typography variant='body2' color='text.secondary' sx={{ mr: 0.5 }}>
                {currencyLabel} {currencySymbol}
              </Typography>
            </Box>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton aria-label='clear input' onClick={handleClearClick} edge='end' size='small'>
              <ClearIcon fontSize='small' />
            </IconButton>
          </InputAdornment>
        ),
        inputProps: {
          ...InputProps?.inputProps,
          inputMode: 'decimal',
        },
      }}
    />
  );
};

export default CurrencyTextField;
