import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Divider,
  Hidden,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';

export default function LMSCaseViewHeader({ handleMenuClick, antemortem = false }) {
  const theme = useTheme();
  const formik = useFormikContext();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const caseNumber = formik.values?.casesummary?.elabcasenumber ?? '';
  const donorLastName = formik.values?.casesummary?.donorlastname ?? '';
  const donorFirstName = formik.values?.casesummary?.donorfirstname ?? '';
  const mdxLabCaseId = formik.values?.casesummary?.mdxlabcaseid ?? '';

  return (
    <Box
      sx={{
        p: 3,
        pb: 0,
        backgroundColor: theme.palette.background.default,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 20,
      }}
    >
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        <Stack>
          {/* Show donor name with or without parentheses based on `antemortem` */}
          <Typography variant='h5' component='h1'>
            <b>{caseNumber}</b> {!antemortem && `(${donorLastName}, ${donorFirstName})`}
          </Typography>

          {/* Show CMS line only if `antemortem` is false */}
          {!antemortem && (
            <Typography variant='subtitle1' color='gray'>
              CMS: {mdxLabCaseId}
            </Typography>
          )}
        </Stack>

        <Box sx={{ ml: 'auto', mr: 2, mt: 1 }}>
          <Hidden mdUp>
            <Tooltip title='Show Case Menu'>
              <IconButton
                size={isMediumScreen ? 'medium' : 'large'}
                sx={{ width: 'max-content', height: 'max-content' }}
                onClick={handleMenuClick}
              >
                <MenuIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Box>
      </Box>
      <Divider sx={{ borderColor: '#555', my: 3, pb: 0 }} />
    </Box>
  );
}
