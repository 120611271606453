import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';
import ELabMROReviewFiles from './ELabMROReviewFiles';
import { Grid, Divider } from '@mui/material';

export default function ElabMROReview() {
  const mroReviewColumns = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 125,
      maxWidth: 125,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'toxLimsNumber',
      headerName: 'Tox LIMS Number',
      minWidth: 175,
      maxWidth: 200,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleELabCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'issued',
      headerName: 'Issued',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onPoliceHold',
      headerName: 'On Police Hold',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'pending',
      headerName: 'Pending',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'certifyingReviewer',
      headerName: 'Certifying Reviewer',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'toxReviewDate',
      headerName: 'Tox Review Date',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'amendedDate',
      headerName: 'Amended Date',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'amended',
      headerName: 'Amended',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'ledtApproved',
      headerName: 'LEDT/MRO approved',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReportDataGrid
          idcolumn='id'
          //   loading={loading}
          listresults={[]}
          columnsInput={mroReviewColumns}
          title=''
          customButtons={[<GridToolbarQuickFilter key='filter' />]}
        />
      </Grid>
      <Grid item xs={12}>
        <br />
        <Divider sx={{ borderColor: '#555', m: 0, pb: 0 }} />
        <ELabMROReviewFiles />
      </Grid>
    </Grid>
  );
}
