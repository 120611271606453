// import { Autocomplete, Button, Checkbox, Grid, Stack, TextField } from '@mui/material';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { useEffect, useState } from 'react';
// import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
// import { useNavigate } from 'react-router-dom';
// import InfoCard from '../../../../components/LMS/ELabReports/InfoCard';
// import ReportCard from '../../../../components/Reports/ReportCard';
// import { useAuth } from '../../../../utils/auth/AuthService';
// import { dateRangeShortcuts } from '../../../../utils/constants/DateRangeShortcuts';
// import { ELabTATFormValues } from './ELabAMTurnaroundTime';

// export default function ELabPMTurnaroundTimeView() {
//   const { user } = useAuth();
//   const navigate = useNavigate();
//   const [avgTurnaround, setAvgTurnaround] = useState<string>('0');
//   const [yearOptions, setYearOptions] = useState<number[]>([]);
//   const [totalCases, setTotalCases] = useState<number>(0);

//   useEffect(() => {
//     if (user) {
//       const currentYear = new Date().getFullYear();
//       setYearOptions([...Array(16).keys()].map(i => currentYear - i));
//     }
//   }, [user]);

//   const initialValues: ELabTATFormValues = {
//     reportedDateRange: [null, null],
//     selectedYear: user ? new Date().getFullYear() : null,
//   };

//   const onSubmit = async (values: ELabTATFormValues, formData: FormData) => {
//     formData.append(
//       'SEARCHFROMDATE',
//       values.selectedYear ? '' : values.reportedDateRange[0]?.toISOString() ?? ''
//     );
//     formData.append(
//       'SEARCHTODATE',
//       values.selectedYear ? '' : values.reportedDateRange[1]?.toISOString() ?? ''
//     );
//     formData.append('CASEYEAR', values.selectedYear?.toString() || '');
//     formData.append('CASETYPE', 'pm');

//     if (!user?.token) {
//       throw new Error('User token unavailable');
//     }
//     const response = await fetch(
//       `${import.meta.env.VITE_FASTAPI_URL}elab/reports/turnaround-time`,
//       {
//         method: 'POST',
//         headers: { Authorization: 'Bearer ' + user.token },
//         body: formData,
//       }
//     );
//     if (response.status === 401) throw new Error('You are unauthorized to use this tool');
//     if (response.status >= 400) throw new Error('An error occurred');
//     const jsonData = await response.json();

//     const transformedData = jsonData.map((item: any) => ({
//       ELABCASENUMBER: item.ELABCASENUMBER,
//       MDXLABCASEID: item.MDXLABCASEID,
//       DonorFirstName: item.DonorFirstName,
//       DonorLastName: item.DonorLastName,
//       DECEDENTNAME: item.DECEDENTNAME,
//       AGENCYNAME: item.AGENCYNAME,
//       CreatedDt: item.CreatedDt,
//       Status: item.Status,
//       NOTES: item.NOTES,
//       TurnaroundDays: item.TurnaroundDays,
//       results: item.results || [],
//     }));

//     setTotalCases(transformedData.length);

//     const validTurnaroundTimes = transformedData
//       .filter((item: any) => item.TurnaroundDays)
//       .map((item: any) => parseFloat(item.TurnaroundDays));
//     const avg =
//       validTurnaroundTimes.length > 0
//         ? validTurnaroundTimes.reduce((a: number, b: number) => a + b, 0) /
//           validTurnaroundTimes.length
//         : 0;
//     setAvgTurnaround(avg.toFixed(2));

//     return transformedData;
//   };

//   const renderForm = (formik: any) => (
//     <Grid container spacing={2}>
//       <Grid item xs={12} md={4}>
//         <Autocomplete
//           id='selectedYear'
//           options={yearOptions}
//           value={formik.values.selectedYear}
//           size='small'
//           getOptionLabel={(option: number) => option.toString()}
//           onChange={(event, data) => formik.setFieldValue('selectedYear', data)}
//           renderOption={(props, option, { selected }) => (
//             <li {...props}>
//               <Checkbox
//                 icon={<MdCheckBoxOutlineBlank />}
//                 checkedIcon={<MdOutlineCheckBox />}
//                 style={{ marginRight: 8 }}
//                 checked={selected}
//               />
//               {option.toString()}
//             </li>
//           )}
//           fullWidth
//           renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
//         />
//       </Grid>
//       <Grid item xs={12} md={8}>
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <DateRangePicker
//             name='reportedDateRange'
//             value={formik.values?.reportedDateRange}
//             onChange={data => formik.setFieldValue('reportedDateRange', data)}
//             localeText={{ start: 'Reported From', end: 'Reported To' }}
//             slotProps={{
//               shortcuts: { items: dateRangeShortcuts },
//             }}
//           />
//         </LocalizationProvider>
//       </Grid>
//     </Grid>
//   );

//   const columns = [
//     {
//       field: 'ELABCASENUMBER',
//       headerName: 'eLab No',
//       width: 105,
//       editable: false,
//       renderCell: (params: any) => (
//         <Button
//           color='primary'
//           onClick={event => {
//             handleELabCaseClick(event, params);
//           }}
//         >
//           <b>{params.formattedValue}</b>
//         </Button>
//       ),
//       align: 'center',
//       headerAlign: 'center',
//       headerClassName: 'header-theme',
//     },
//     {
//       field: 'CreatedDt',
//       headerName: 'Created',
//       flex: 1,
//       minWidth: 150,
//       editable: false,
//       align: 'center',
//       headerAlign: 'center',
//       headerClassName: 'header-theme',
//       renderCell: (params: any) => new Date(params.row.CreatedDt).toLocaleString(),
//     },
//     {
//       field: 'Completed',
//       headerName: 'Completed',
//       flex: 1,
//       minWidth: 150,
//       editable: false,
//       align: 'center',
//       headerAlign: 'center',
//       headerClassName: 'header-theme',
//       valueFormatter: (value: any) => (value ? new Date(value).toLocaleString() : '---'),
//       valueGetter: (_: any, value: any) => value?.results[0]?.VALIDATIONDATE,
//     },
//     {
//       field: 'TurnaroundDays',
//       headerName: 'Turnaround Time (Days)',
//       flex: 1,
//       minWidth: 80,
//       editable: false,
//       align: 'center',
//       headerAlign: 'center',
//       headerClassName: 'header-theme',
//       renderCell: (params: any) => {
//         const value = Number(params?.row?.TurnaroundDays);
//         return isNaN(value) ? '---' : Math.round(value).toString();
//       },
//     },
//   ];

//   const handleELabCaseClick = (event: any, data: any) => {
//     navigate('/elabpmcaseview', {
//       state: {
//         elabcasenumber: data.formattedValue,
//         previousPage: window.location.pathname,
//         showBackButton: true,
//       },
//     });
//   };

//   const chatContent = (
//     <Stack direction='row' spacing={2}>
//       <InfoCard title={totalCases.toString()} subtitle='Total Cases' />
//       <InfoCard title={`${avgTurnaround} days`} subtitle='Avg. Turnaround Time' />
//     </Stack>
//   );

//   return (
//     <ReportCard<ELabTATFormValues>
//       fullWidth
//       title='eLab Turnaround Time Report'
//       description='This report shows the turnaround time for eLab cases. You can filter by year or date range and select jurisdictions.'
//       columns={columns}
//       chatContent={chatContent}
//       idColumn='ELABCASENUMBER'
//       initialValues={initialValues}
//       renderForm={renderForm}
//       onSubmit={onSubmit}
//     />
//   );
// }

import { Autocomplete, Button, Checkbox, Grid, Stack, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import InfoCard from '../../../../components/LMS/ELabReports/InfoCard';
import ReportCard from '../../../../components/Reports/ReportCard';
import { useAuth } from '../../../../utils/auth/AuthService';
import { dateRangeShortcuts } from '../../../../utils/constants/DateRangeShortcuts';
import { ELabTATFormValues } from './ELabAMTurnaroundTime';

export default function ELabPMTurnaroundTimeView() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [avgTurnaround, setAvgTurnaround] = useState<string>('0');
  const [yearOptions, setYearOptions] = useState<number[]>([]);
  const [totalCases, setTotalCases] = useState<number>(0);

  useEffect(() => {
    if (user) {
      const currentYear = new Date().getFullYear();
      setYearOptions([...Array(16).keys()].map(i => currentYear - i));
    }
  }, [user]);

  const initialValues: ELabTATFormValues = {
    reportedDateRange: [null, null],
    selectedYear: user ? new Date().getFullYear() : null,
  };

  const onSubmit = async (values: ELabTATFormValues, formData: FormData) => {
    formData.append(
      'SEARCHFROMDATE',
      values.selectedYear ? '' : values.reportedDateRange[0]?.toISOString() ?? ''
    );
    formData.append(
      'SEARCHTODATE',
      values.selectedYear ? '' : values.reportedDateRange[1]?.toISOString() ?? ''
    );
    formData.append('CASEYEAR', values.selectedYear?.toString() || '');
    formData.append('CASETYPE', 'pm');

    if (!user?.token) {
      throw new Error('User token unavailable');
    }
    const response = await fetch(
      `${import.meta.env.VITE_FASTAPI_URL}elab/reports/turnaround-time`,
      {
        method: 'POST',
        headers: { Authorization: 'Bearer ' + user.token },
        body: formData,
      }
    );
    if (response.status === 401) throw new Error('You are unauthorized to use this tool');
    if (response.status >= 400) throw new Error('An error occurred');
    const jsonData = await response.json();

    // Transform the API response to match our grid column definitions.
    const transformedData = jsonData.map((item: any) => ({
      CASENUMBER: item.CASENUMBER,
      CREATEDDT: item.CASERECEIVEDDT,
      MatchedEditedDt: item.REVIEWDT,
      TurnaroundDays: item.TurnaroundDays,
      Status: item.Status,
      MatchedDocument: item.MatchedDocument,
      ID: item.ID,
    }));

    setTotalCases(transformedData.length);

    const validTurnaroundTimes = transformedData
      .filter((item: any) => item.TurnaroundDays)
      .map((item: any) => parseFloat(item.TurnaroundDays));
    const avg =
      validTurnaroundTimes.length > 0
        ? validTurnaroundTimes.reduce((a: number, b: number) => a + b, 0) /
          validTurnaroundTimes.length
        : 0;
    setAvgTurnaround(avg.toFixed(2));

    return transformedData;
  };

  const renderForm = (formik: any) => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Autocomplete
          id='selectedYear'
          options={yearOptions}
          value={formik.values.selectedYear}
          size='small'
          getOptionLabel={(option: number) => option.toString()}
          onChange={(event, data) => formik.setFieldValue('selectedYear', data)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<MdCheckBoxOutlineBlank />}
                checkedIcon={<MdOutlineCheckBox />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.toString()}
            </li>
          )}
          fullWidth
          renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            name='reportedDateRange'
            value={formik.values?.reportedDateRange}
            onChange={data => formik.setFieldValue('reportedDateRange', data)}
            localeText={{ start: 'Reported From', end: 'Reported To' }}
            slotProps={{
              shortcuts: { items: dateRangeShortcuts },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      field: 'CASENUMBER',
      headerName: 'eLab No',
      width: 105,
      editable: false,
      renderCell: (params: any) => (
        <Button
          color='primary'
          onClick={event => {
            handleELabCaseClick(event, params);
          }}
        >
          <b>{params.formattedValue}</b>
        </Button>
      ),
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'CREATEDDT',
      headerName: 'Created',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: (params: any) => new Date(params.row.CREATEDDT).toLocaleString(),
    },
    {
      field: 'MatchedEditedDt',
      headerName: 'Completed',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: (params: any) =>
        params.row.MatchedEditedDt ? new Date(params.row.MatchedEditedDt).toLocaleString() : '---',
    },
    {
      field: 'TurnaroundDays',
      headerName: 'Turnaround Time (Days)',
      flex: 1,
      minWidth: 80,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: (params: any) => {
        const value = Number(params?.row?.TurnaroundDays);
        return isNaN(value) ? '---' : Math.round(value).toString();
      },
    },
  ];

  const handleELabCaseClick = (event: any, data: any) => {
    navigate('/elabpmcaseview', {
      state: {
        elabcasenumber: data.formattedValue,
        previousPage: window.location.pathname,
        showBackButton: true,
      },
    });
  };

  const chatContent = (
    <Stack direction='row' spacing={2}>
      <InfoCard title={totalCases.toString()} subtitle='Total Cases' />
      <InfoCard title={`${avgTurnaround} days`} subtitle='Avg. Turnaround Time' />
    </Stack>
  );

  return (
    <ReportCard<ELabTATFormValues>
      fullWidth
      title='eLab PM Turnaround Time Report'
      description='This report shows the turnaround time for eLab PM cases. You can filter by year or date range and select jurisdictions.'
      columns={columns}
      chatContent={chatContent}
      idColumn='CASENUMBER'
      initialValues={initialValues}
      renderForm={renderForm}
      onSubmit={onSubmit}
    />
  );
}
