
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ReferenceData from './ReferenceDataModule/ReferenceDataView';
import AccountRequestView from './AccountRequestView';
import MDXUserAccounts from './MDXUserAccounts';

export const SUPPORT_MENU_ICON = { 
  'user-accounts': {
    icon: null,
    component: <MDXUserAccounts />,
  },

  'account-requests': {
    icon: null,
    component: <AccountRequestView />,
  },

  'Reference Data': {
    icon: <MenuBookIcon />,
    component: <ReferenceData />,
  }, 
};

export const CASE_ADMIN = {
  'Reference Data': true,
  'Lab List': true,
  POC: true,
  'Location List': true,
  'Case Visit': true,
  'Report Header Details': true,
  'Generate Blank RIME': true,
  'Livery Services': true,
};

export const SYSTEMS_ADMIN = {
  'Workstation Setup': true,
};

export const SPECIAL_PRIVILEGE = [
  { name: 'Hide in Drop-Downs/Pop-Ups', value: 'isHideDropdownPopUp' },
  { name: 'Bypass 2FA Authentication', value: 'enable2FA' },
];

export const PHYSICIAN_CERTIFICATION_PATHOLOGY = [
  { name: 'AP (Anatomic Pathology)', value: 'isAnatomicPathologyCert' },
  { name: 'CP (Clinical Pathology)', value: 'isClinicalPathologyCert' },
  { name: 'FP (Forensic Pathology)', value: 'isForensicPathologyCert' },
];


export const OCSME_CERTIFICATION = [
  { name: 'A (Autopsy)', value: 'isAutopsyCertCert' },
  { name: 'DI (Death Investigation)', value: 'isDeathInvestigationCert' },
  { name: 'E (External Exam)', value: 'isExternalExamCert' },
];
