import { Close, GroupAddOutlined, GroupRemoveOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { forwardRef, useRef, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { AddParticipantsMenu } from './AddParticipantsMenu';
import { useConversation } from './ConversationsContext';
import { ChatParticipant } from './conversation.types';

type ModifyChatParticipantMenuProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: HTMLElement | null;
};

export const ModifyChatParticipantMenu = forwardRef<HTMLDivElement, ModifyChatParticipantMenuProps>(
  ({ open, setOpen, anchorEl }, ref) => {
    const { user } = useAuth();
    const { selectedChat, removeParticipantFromChat } = useConversation();
    const [loading, setLoading] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [leaveConfirmOpen, setLeaveConfirmOpen] = useState(false);
    const [participantToRemove, setParticipantToRemove] = useState<Partial<ChatParticipant> | null>(
      null
    );

    const [addOpen, setAddOpen] = useState(false);
    const addButtonRef = useRef<HTMLDivElement>(null);

    const isCreator = (participantUserSeq: string) =>
      selectedChat?.creator?.userSeq?.toLowerCase() === participantUserSeq.toLowerCase();

    const isSupportAgent = user?.roleCheck(['451', '8f7']) || false;
    const isAuthorizedToRemoveUsers = (participantUserSeq: string) =>
      isSupportAgent || isCreator(participantUserSeq);

    const sortedParticipants = [...(selectedChat?.participants || [])]
      .filter(p => p.isActive)
      .sort((a, b) => {
        if (isCreator(a?.userSeq || '')) return -1;
        if (isCreator(b?.userSeq || '')) return 1;

        const aName = `${a.personFirstName} ${a.personLastName}`.toLowerCase();
        const bName = `${b.personFirstName} ${b.personLastName}`.toLowerCase();
        return aName.localeCompare(bName);
      });

    const onClose = () => {
      if (!addOpen) {
        setOpen(false);
      }
    };

    const handleRemoveClick = (participant: Partial<ChatParticipant>) => {
      setParticipantToRemove(participant);
      setConfirmOpen(true);
    };

    const handleConfirmRemove = async () => {
      if (!participantToRemove || !selectedChat) return;

      setLoading(true);
      try {
        await removeParticipantFromChat({
          conversationSeq: selectedChat.conversationSeq,
          participantUserSeq: participantToRemove?.userSeq || '',
          silent: false,
        });
        setConfirmOpen(false);
        setParticipantToRemove(null);
      } catch (error) {
        console.error('Failed to remove participant:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleLeaveClick = () => {
      setLeaveConfirmOpen(true);
    };

    const handleConfirmLeave = async () => {
      if (!selectedChat || !user) return;

      setLoading(true);
      try {
        await removeParticipantFromChat({
          conversationSeq: selectedChat.conversationSeq,
          participantUserSeq: user.userSeq,
          silent: false,
        });
        setLeaveConfirmOpen(false);
        setOpen(false); // Close the menu after leaving
      } catch (error) {
        console.error('Failed to leave chat:', error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
          <Stack spacing={0}>
            <Typography variant='body2' sx={{ px: 1, pt: 1 }}>
              Participants ({sortedParticipants.length || 0})
            </Typography>

            <List dense>
              {sortedParticipants.map((p, index) => (
                <div key={p.userSeq}>
                  {index === 0 && <Divider component='li' />}
                  <ListItem
                    alignItems='flex-start'
                    sx={{
                      '&:hover': {
                        backgroundColor: 'action.hover',
                        cursor: 'default',
                      },
                      borderRadius: 1,
                    }}
                    secondaryAction={
                      isAuthorizedToRemoveUsers(p?.userSeq || '') &&
                      !isCreator(p?.userSeq || '') && (
                        <Tooltip title='Remove' arrow>
                          <IconButton
                            edge='end'
                            aria-label='remove'
                            onClick={() => handleRemoveClick(p)}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  >
                    <ListItemText
                      primary={`${p.personFirstName} ${p.personLastName} (${p.userName})`}
                      secondary={isCreator(p?.userSeq || '') && <>Creator</>}
                    />
                  </ListItem>
                  <Divider component='li' />
                </div>
              ))}
            </List>
          </Stack>

          <MenuList dense sx={{ py: 0, my: 0 }}>
            <div ref={addButtonRef}>
              <MenuItem
                onClick={() => setAddOpen(true)}
                sx={{ color: 'primary.main' }}
                disabled={!isAuthorizedToRemoveUsers(user?.userSeq || '')}
              >
                <ListItemIcon>
                  <GroupAddOutlined sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                Add people
              </MenuItem>
            </div>
            <MenuItem
              onClick={handleLeaveClick}
              sx={{ color: 'error.main' }}
              disabled={isCreator(user?.userSeq || '')}
            >
              <ListItemIcon>
                <GroupRemoveOutlined sx={{ color: 'error.main' }} />
              </ListItemIcon>
              Leave chat
            </MenuItem>
          </MenuList>
        </Menu>

        <AddParticipantsMenu open={addOpen} setOpen={setAddOpen} anchorEl={addButtonRef.current} />

        <Dialog
          open={confirmOpen}
          onClose={() => !loading && setConfirmOpen(false)}
          aria-labelledby='remove-participant-dialog-title'
        >
          <DialogTitle id='remove-participant-dialog-title'>
            Remove "{participantToRemove?.personFirstName} {participantToRemove?.personLastName} (
            {participantToRemove?.userName})"?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              They will no longer have access to this chat or its message history
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmOpen(false)} disabled={loading} color='inherit'>
              Cancel
            </Button>
            <Button
              onClick={handleConfirmRemove}
              disabled={loading}
              color='error'
              variant='contained'
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={leaveConfirmOpen}
          onClose={() => !loading && setLeaveConfirmOpen(false)}
          aria-labelledby='leave-chat-dialog-title'
        >
          <DialogTitle id='leave-chat-dialog-title'>Leave "{selectedChat?.topic}"?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You will no longer have access to this chat or its message history
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLeaveConfirmOpen(false)} disabled={loading} color='inherit'>
              Cancel
            </Button>
            <Button
              onClick={handleConfirmLeave}
              disabled={loading}
              color='error'
              variant='contained'
              autoFocus
            >
              Leave
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);
