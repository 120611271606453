import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormHelperText,
  FormControl,
  Autocomplete,
} from '@mui/material';
import { TextField } from '@mui/material';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import { getAdminData, saveAdminData } from '../../../../../services/utility-api';
import * as Yup from 'yup';
import ApiNotification from '../../../../../components/DialogBox';

interface AddEditProps {
  editObj: any;
  referenceMasterTableName: string;
  setIsAddEditOn: React.Dispatch<React.SetStateAction<boolean>>;
  getAllData: () => void;
}

const AddEdit: React.FC<AddEditProps> = ({
  editObj,
  referenceMasterTableName,
  setIsAddEditOn,
  getAllData,
}) => {
  const [liveryServiceOptions, setLiveryServiceOptions] = useState([]);
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { VITE_API_URL } = import.meta.env;

  const initialValues = {
    ...editObj,
    personFirstName: editObj?.personFirstName || '',
    personMiddleName: editObj?.personMiddleName || '',
    personLastName: editObj?.personLastName || '',
    personSuffix: editObj?.personSuffix || '',
    liveryService: editObj?.liveryService || null,
  };

  const validationSchema = Yup.object({
    personLastName: Yup.string().required('Last Name is required').nullable(),
    liveryService: Yup.object()
      .shape({
        optionSeq: Yup.string().required('Livery Service is required'),
      })
      .nullable()
      .required('Livery Service is required'),
  });

  const handleClose = () => {
    setIsAddEditOn(false);
  };

  const closeDialogBox = () => {
    setOpenApiDialogBox(false);
  };

  useEffect(() => {
    const fetchLiveryServices = async () => {
      const response = await fetch(`${VITE_API_URL}getliveryserviceoptions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error(`${response.status}: ${response.statusText}`);
      const options = await response.json();
      setLiveryServiceOptions(options);
    };
    fetchLiveryServices();
  }, []);

  const onSave = async (values: any) => {
    if (!values.personLastName || !values.liveryService) return;
    setIsLoading(true);
    const id = '00000000-0000-0000-0000-000000000000';
    const liveryPersonPayload = {
      liveryPersonSeq: values.id ?? id,
      liverySeq: values.liveryService.optionSeq ?? id,
      personSeq: values.personSeq ?? id,
      person: {
        personFirstName: values.personFirstName,
        personLastName: values.personLastName ?? '',
        personMiddleName: values.personMiddleName,
        personSeq: values.personSeq ?? id,
        personSuffix: values.personSuffix,
      },
    };

    try {
      const response = await saveAdminData({
        values: liveryPersonPayload,
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
        {({ handleSubmit, values, setFieldValue, handleChange }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                    <strong>
                      {Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Livery Person
                    </strong>
                  </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={6} lg={3}>
                  <FormControl fullWidth error required component='fieldset'>
                    <Autocomplete
                      id='liveryService'
                      options={liveryServiceOptions || []}
                      value={values?.liveryService || null}
                      isOptionEqualToValue={(option, value) =>
                        option?.optionSeq?.toLowerCase() === value?.optionSeq?.toLowerCase()
                      }
                      size='small'
                      getOptionLabel={option => option?.optionName || ''}
                      onChange={(e, newValue) => setFieldValue('liveryService', newValue)}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Livery Service'
                          id='liveryService'
                          name='liveryService'
                          placeholder='Livery Service'
                          required
                        />
                      )}
                    />
                    <ErrorMessage
                      name='liveryService'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      required
                      name='personFirstName'
                      label='First Name'
                      value={values.personFirstName}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name='personFirstName'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      required
                      label='Last Name'
                      name='personLastName'
                      value={values.personLastName}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name='personLastName'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label='Middle Name'
                      name='personMiddleName'
                      value={values.personMiddleName}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name='personMiddleName'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label='Suffix'
                      name='personSuffix'
                      value={values.personSuffix}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name='personSuffix'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent='flex-start'>
                  <Box component={'div'}>
                    <Button
                      variant='contained'
                      sx={{ minWidth: '100px', mt: '16px' }}
                      onClick={e => handleSubmit()}
                      disabled={isLoading}
                    >
                      {Object.keys(editObj).length ? 'Update' : 'Save'}
                    </Button>

                    <Button
                      color='error'
                      variant='outlined'
                      onClick={handleClose}
                      sx={{ minWidth: '100px', marginTop: '16px', marginLeft: '1rem' }}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <ApiNotification
        openApiDialogBox={openApiDialogBox}
        closeDialogBox={closeDialogBox}
        dialogContents={dialogContents}
      />
    </>
  );
};
export default AddEdit;
