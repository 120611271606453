import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root.success': {
    '& fieldset': {
      borderColor: theme.palette.success.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.success.dark,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.success.main,
    },
  },
  '& .success-label': {
    color: theme.palette.success.main,
  },
}));

interface UsernameCheckerProps {
  initialUsername: string;
  accessToken: string;
  apiUrl: string;
  onChange: (username: string, isAvailable: boolean) => void;
  disabled?: boolean;
}

const UsernameChecker: React.FC<UsernameCheckerProps> = ({
  initialUsername,
  accessToken,
  apiUrl,
  onChange,
  disabled = false,
}) => {
  const [suggestedUsername, setSuggestedUsername] = useState<string>(initialUsername);
  const [baseUsername, setBaseUsername] = useState<string>(initialUsername);
  const [usernameAvailable, setUsernameAvailable] = useState<boolean | null>(null);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [isManualInput, setIsManualInput] = useState(false);
  const firstRenderRef = useRef(true);

  const MAX_ATTEMPTS = 10;

  const checkUsernameAvailability = async (username: string): Promise<boolean> => {
    try {
      const response = await fetch(
        `${apiUrl}account-requests/check-userid-availability?userid=${encodeURIComponent(
          username
        )}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to check username availability');
      }

      const data = await response.json();
      return data.is_available;
    } catch (error) {
      console.error('Error checking username availability:', error);
      return false;
    }
  };

  const findAvailableUsername = async (username: string) => {
    if (!username) {
      setUsernameAvailable(null);
      onChange('', false);
      return;
    }

    setCheckingUsername(true);
    try {
      // First try the base username
      let isAvailable = await checkUsernameAvailability(username);
      if (isAvailable) {
        setSuggestedUsername(username);
        setUsernameAvailable(true);
        onChange(username, true);
        return;
      }

      // Try adding numbers starting at 2, max 10 attempts
      for (let counter = 2; counter <= MAX_ATTEMPTS + 1; counter++) {
        const usernameToTry = `${username}${counter}`;
        isAvailable = await checkUsernameAvailability(usernameToTry);

        if (isAvailable) {
          setSuggestedUsername(usernameToTry);
          setUsernameAvailable(true);
          onChange(usernameToTry, true);
          return;
        }
      }

      // If we get here, we couldn't find an available username
      setUsernameAvailable(false);
      setSuggestedUsername(username);
      onChange(username, false);
    } finally {
      setCheckingUsername(false);
    }
  };

  const handleManualCheck = async (username: string) => {
    if (!username) {
      setUsernameAvailable(null);
      onChange('', false);
      return;
    }

    setCheckingUsername(true);
    try {
      const isAvailable = await checkUsernameAvailability(username);
      setUsernameAvailable(isAvailable);
      onChange(username, isAvailable);
    } finally {
      setCheckingUsername(false);
    }
  };

  // Handle initial username and changes to initialUsername
  useEffect(() => {
    // Always check on first render if there's an initialUsername
    if (firstRenderRef.current && initialUsername) {
      firstRenderRef.current = false;
      setBaseUsername(initialUsername);
      setSuggestedUsername(initialUsername);
      findAvailableUsername(initialUsername);
    } else if (!isManualInput && initialUsername && initialUsername !== suggestedUsername) {
      // Handle subsequent changes to initialUsername
      setBaseUsername(initialUsername);
      setSuggestedUsername(initialUsername);
      findAvailableUsername(initialUsername);
    }
  }, [initialUsername, suggestedUsername]);

  const handleInputChange = (newUsername: string) => {
    setIsManualInput(true);
    setSuggestedUsername(newUsername);
    handleManualCheck(newUsername);
  };

  const handleTryAnother = () => {
    setIsManualInput(false);
    findAvailableUsername(baseUsername);
  };

  return (
    <StyledTextField
      label='UserID'
      fullWidth
      value={suggestedUsername}
      onChange={e => handleInputChange(e.target.value)}
      margin='normal'
      disabled={disabled}
      error={usernameAvailable === false}
      InputProps={{
        className: usernameAvailable ? 'success' : '',
        endAdornment: checkingUsername ? (
          <CircularProgress size={20} />
        ) : usernameAvailable ? (
          <Box component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CheckCircle color='success' />
            {suggestedUsername !== baseUsername && (
              <Button size='small' onClick={handleTryAnother} sx={{ minWidth: 'auto' }}>
                Try Another
              </Button>
            )}
          </Box>
        ) : usernameAvailable === false ? (
          <Box component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Cancel color='error' />
            <Button size='small' onClick={handleTryAnother} sx={{ minWidth: 'auto' }}>
              Try Another
            </Button>
          </Box>
        ) : null,
      }}
      InputLabelProps={{
        className: usernameAvailable ? 'success-label' : '',
      }}
      helperText={
        checkingUsername
          ? 'Checking availability...'
          : usernameAvailable === false
          ? 'UserID is already taken'
          : usernameAvailable === true
          ? 'UserID is available'
          : ''
      }
      FormHelperTextProps={{
        className: usernameAvailable ? 'success-label' : '',
      }}
    />
  );
};

export default UsernameChecker;
