import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import CharacterLimitTextField from '../../../../utils/components/CharacterLimitTextField';
import DialogCrudDataGrid, {
  handleTextFieldChange,
} from '../../../../utils/components/DialogCrudDataGrid';

export default function ElabInstrumentsManagement() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;

  const labInstrumentColumns = [
    {
      field: 'InstrumentSeq', // the id of the columns
      headerName: '',
      flex: 1,
      minWidth: 125,
      maxWidth: 125,
      editable: false,
    },
    {
      field: 'instrumentName',
      headerName: 'Instrument Name',
      minWidth: 175,
      maxWidth: 200,
      flex: 1,
      editable: false,
      //   renderCell: params => {
      //     return (
      //       <NavLink
      //         //variant="contained"
      //         color='primary'
      //         onClick={event => {
      //           handleELabCaseClick(event, params);
      //         }}
      //       >
      //         {params.formattedValue}
      //       </NavLink>
      //     );
      //   },
    },
    {
      field: 'storageLocationName',
      headerName: 'Location',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    // {
    //   field: 'instrumentType',
    //   headerName: 'Instrument Type',
    //   flex: 1,
    //   // minWidth: 150,
    //   // maxWidth: 170,
    //   editable: false,
    // },
    {
      field: 'instrumentManufacturer',
      headerName: 'Manufacturer',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    {
      field: 'instrumentIpAddress',
      headerName: 'IP Address',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    {
      field: 'instrumentSoftware',
      headerName: 'Software',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    {
      field: 'instrumentSerialNumber',
      headerName: 'Serial Number',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    {
      field: 'calibrationInterval',
      headerName: 'Calibration Interval',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    {
      field: 'maintenanceInterval',
      headerName: 'Maintenance Interval',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
    },
  ];

  useEffect(() => {
    if (user === null) return;

    setLoading(true);

    if (user && user.token) {
      fetchLabInstruments();
    }
  }, [user]);

  const fetchLabInstruments = async () => {
    try {
      const response = await fetch(`${VITE_FASTAPI_URL}elab/lab_instruments`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch inventory');
      const data = await response.json();
      const formattedData = data.map(item => ({
        ...item,
        id: item.InstrumentSeq,
      }));
      setlistresults(formattedData);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const createLabInstrument = async newItem => {
    try {
      const response = await fetch(`${VITE_FASTAPI_URL}elab/lab_instruments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) throw new Error('Failed to create inventory item');
      await fetchLabInstruments(); // Refresh data after creation
    } catch (error) {
      console.error(error);
    }
  };

  const updateLabInstrument = async updatedItem => {
    try {
      const response = await fetch(`${VITE_FASTAPI_URL}elab/lab_instruments/${updatedItem.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(updatedItem),
      });

      if (!response.ok) throw new Error('Failed to update inventory item');
      fetchLabInstruments(); // Refresh data
    } catch (error) {
      console.error(error);
    }
  };

  const deleteLabInstrument = async id => {
    try {
      const response = await fetch(`${VITE_FASTAPI_URL}elab/lab_instruments/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (!response.ok) throw new Error('Failed to delete inventory item');
      fetchLabInstruments();
    } catch (error) {
      console.error(error);
    }
  };

  const LabInstrumentDialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(() => {
      if (mode === 'add') {
        return {
          ...data,
          createdByUserSeq: user?.userSeq || null,
          updatedByUserSeq: user?.userSeq || null,
        };
      } else {
        // mode === 'edit'
        return {
          ...data,
          updatedByUserSeq: user?.userSeq || data.updatedByUserSeq,
        };
      }
    });

    useEffect(() => {
      setLocalData(prev => {
        if (mode === 'add') {
          return {
            ...data,
            createdByUserSeq: user?.userSeq || prev.createdByUserSeq,
            updatedByUserSeq: user?.userSeq || prev.updatedByUserSeq,
          };
        } else {
          return {
            ...prev,
            ...data,
            updatedByUserSeq: user?.userSeq || prev.updatedByUserSeq,
          };
        }
      });
    }, [data, mode, user]);

    return (
      <Grid container spacing={1}>
        {/* Instrument Name */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.instrumentName}
            onChange={event =>
              handleTextFieldChange(event, 'instrumentName', localData, setLocalData, onChange)
            }
            label='Instrument Name'
            fullWidth
            margin='dense'
            characterLimit={50}
            TextFieldProps={{ required: true }}
          />
        </Grid>

        {/* Location */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.storageLocationName}
            onChange={event =>
              handleTextFieldChange(event, 'storageLocationName', localData, setLocalData, onChange)
            }
            label='Location'
            fullWidth
            margin='dense'
            characterLimit={50}
          />
        </Grid>

        {/* Instrument Ip address */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.instrumentIpAddress}
            onChange={event =>
              handleTextFieldChange(event, 'instrumentIpAddress', localData, setLocalData, onChange)
            }
            label='IP Address'
            fullWidth
            margin='dense'
            characterLimit={50}
          />
        </Grid>

        {/* Instrument Software */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.instrumentSoftware}
            onChange={event =>
              handleTextFieldChange(event, 'instrumentSoftware', localData, setLocalData, onChange)
            }
            label='Software'
            fullWidth
            margin='dense'
            characterLimit={50}
          />
        </Grid>
        {/* Manufacturer */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.instrumentManufacturer}
            onChange={event =>
              handleTextFieldChange(
                event,
                'instrumentManufacturer',
                localData,
                setLocalData,
                onChange
              )
            }
            label='Manufacturer'
            fullWidth
            margin='dense'
            characterLimit={50}
          />
        </Grid>

        {/* Serial Number */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.instrumentSerialNumber}
            onChange={event =>
              handleTextFieldChange(
                event,
                'instrumentSerialNumber',
                localData,
                setLocalData,
                onChange
              )
            }
            label='Serial Number'
            fullWidth
            margin='dense'
            characterLimit={100}
          />
        </Grid>

        {/* Calibration Interval */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.calibrationInterval}
            onChange={event =>
              handleTextFieldChange(event, 'calibrationInterval', localData, setLocalData, onChange)
            }
            label='Calibration Interval'
            fullWidth
            margin='dense'
            characterLimit={50}
          />
        </Grid>

        {/* Maintanence Interval */}
        <Grid item xs={12} md={6} xl={3}>
          <CharacterLimitTextField
            value={localData?.maintenanceInterval}
            onChange={event =>
              handleTextFieldChange(event, 'maintenanceInterval', localData, setLocalData, onChange)
            }
            label='Maintenance Interval'
            fullWidth
            margin='dense'
            characterLimit={50}
          />
        </Grid>

        {/* Details */}
        <Grid item xs={12}>
          <CharacterLimitTextField
            value={localData?.details}
            onChange={event =>
              handleTextFieldChange(event, 'details', localData, setLocalData, onChange)
            }
            label='Details'
            fullWidth
            multiline
            rows={4}
            margin='dense'
            characterLimit={500}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogCrudDataGrid
      idColumn='InstrumentSeq'
      columns={labInstrumentColumns}
      loading={loading}
      rows={listresults}
      title='Lab Instrument'
      rowsperpage={20}
      createFunction={createLabInstrument}
      updateFunction={updateLabInstrument}
      deleteFunction={deleteLabInstrument}
      dialogContent={LabInstrumentDialogContent}
      initialState={{
        columns: {
          columnVisibilityModel: {
            InstrumentSeq: false,
          },
        },
      }}
    />
  );
}
