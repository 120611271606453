import React, { useContext, useMemo, useState } from 'react';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Alert,
  Autocomplete,
  TextField,
} from '@mui/material';
import axios from 'axios';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useDropzone } from 'react-dropzone';
import { TailSpin } from 'react-loader-spinner';
import {
  AttachFile,
  Description,
  PictureAsPdf,
  InsertDriveFile,
  Image,
  ErrorOutline,
} from '@mui/icons-material';
import { GlobalContext } from '../../Context/GlobalContext';
import DeleteIcon from '@mui/icons-material/Delete';
import authHeader from '../../services/auth-header';
import { useAuth } from '../auth/AuthService';
import { AddRounded } from '@mui/icons-material';

const guid = '00000000-0000-0000-0000-000000000000';

const baseStyle = {
  minWidth: 550,
  minHeight: 400,
  border: '3px dashed #ccc',
  borderColor: '#ccc',
  borderRadius: 1,
  padding: 30,
  backgroundColor: '#eee',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface FileWithMeta extends File {
  status?: 'pending' | 'uploading' | 'success' | 'error';
  rejectReason?: string;
}

type UploadResult = {
  fileName: string;
  success: boolean;
  message?: string;
};

type FileUploadDropzoneProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  fileTypeRequired: boolean;
  fileTypeOptions: Option[];
  moduleName?: string;
  handleSaveFiles: (files: File[], fileType?: Option) => Promise<UploadResult[]>;
  acceptedExtensions?: string[];
};

const FileUploadDropzone: React.FC<FileUploadDropzoneProps> = ({
  isOpen,
  setIsOpen,
  fileTypeRequired = true,
  fileTypeOptions,
  moduleName,
  handleSaveFiles,
  acceptedExtensions = ['pdf', 'png', 'jpg', 'jpeg'],
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithMeta[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileWithMeta[]>([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [fileType, setFileType] = useState<Option | null>(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handlePreviewIcon = (file: FileWithMeta) => {
    const iconProps: React.CSSProperties = {};

    if (file.status === 'error') {
      iconProps.border = '2px solid red';
    }

    const mimeType = file?.file?.type || '';

    // Images: handle jpg, png, gif, etc.
    if (mimeType.startsWith('image/')) {
      return <Image style={{ fontSize: 50, ...iconProps }} />;
    }

    switch (mimeType) {
      case 'application/pdf':
        return <PictureAsPdf style={{ fontSize: 50, ...iconProps }} />;

      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description style={{ fontSize: 50, ...iconProps }} />;

      default:
        // return file.status === 'error' ? (
        //   <ErrorOutline style={{ fontSize: 50, color: 'red' }} />
        // ) : (
        <InsertDriveFile style={{ fontSize: 50, ...iconProps }} />;
      // );
    }
  };

  const dismissAlert = () => {
    setShowErrorAlert(false);
    setRejectedFiles([]);
    setErrorMessage('');
  };

  const handleDrop = (newAccepted: File[], fileRejections: any[]) => {
    const updatedAccepted: FileWithMeta[] = [];
    const updatedRejected: FileWithMeta[] = [];

    newAccepted.forEach(file => {
      const extension = file.name.split('.').pop()?.toLowerCase();
      if (!extension || !acceptedExtensions.includes(extension || '')) {
        updatedRejected.push({
          file,
          status: 'error',
          rejectReason: 'This file type is not allowed.',
        });
      } else {
        updatedAccepted.push({ file, status: 'pending' });
      }
    });

    setAcceptedFiles(prev => [...prev, ...updatedAccepted]);
    setRejectedFiles(updatedRejected);

    if (updatedRejected.length > 0) {
      setShowErrorAlert(true);
    }
  };

  const onSaveFiles = async () => {
    if (uploadInProgress || acceptedFiles.length === 0) return;

    setUploadInProgress(true);

    // Set all files to uploading state first
    const filesUploading: FileWithMeta[] = acceptedFiles.map(file => ({
      ...file,
      status: 'uploading',
    }));
    setAcceptedFiles(filesUploading);

    try {
      const fileObjects = acceptedFiles.map(fileWithMeta => fileWithMeta.file);

      const uploadResults = await handleSaveFiles(fileObjects, fileType || undefined);

      const updatedFiles: FileWithMeta[] = filesUploading.map(file => {
        const result = uploadResults.find(res => res.fileName === file.file.name);
        return result
          ? {
              ...file,
              status: result.success ? 'success' : 'error',
              rejectReason: result.message || '',
            }
          : { ...file };
      });

      const failedFiles = updatedFiles?.filter(file => file.status === 'error');
      setAcceptedFiles(failedFiles);
      setUploadInProgress(false);

      if (failedFiles.length === 0) {
        handleClose(); 
      } else if (failedFiles.length > 0) {
        setErrorMessage('There has been an error during the upload.');
        setShowErrorAlert(true);
      }
    } catch (error) {
      console.error('Unexpected error during upload:', error);
      setUploadInProgress(false);
    }
  };

  const handleClose = () => {
    if (uploadInProgress) return;
    setIsOpen(false);
    setAcceptedFiles([]);
    setRejectedFiles([]);
    setFileType(null);
    setShowErrorAlert(false);
    setErrorMessage('');
  };

  var { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxFiles: 50,
    maxSize: 2000000000,
    onDrop: handleDrop,
    disabled: uploadInProgress,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const dialogTitle = () => (
    <>
      <div style={{ paddingTop: '1rem' }}>
        <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>File Upload</strong>
        </Typography>
        <Divider sx={{ borderColor: '#555', m: 0, pb: 0 }} />
      </div>
      <div style={{ marginTop: '8px' }}>
        {showErrorAlert && (
          <Alert severity='error' onClose={dismissAlert}>
            {errorMessage ? (
              <div style={{ fontSize: '14px' }}>{errorMessage}</div>
            ) : (
              rejectedFiles.map((file, index) => (
                <div style={{ fontSize: '14px' }} key={index}>
                  {`${index + 1}. ${file.file.name.split('.')[0]} : ${file.rejectReason}`}
                </div>
              ))
            )}
          </Alert>
        )}
      </div>
    </>
  );

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{dialogTitle()}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {fileTypeRequired && (
            <Grid item xs={12}>
              <Autocomplete
                id='fileType'
                size='small'
                options={fileTypeOptions ?? []}
                value={fileType}
                getOptionLabel={option => option.optionName ?? ''}
                onChange={(event, data) => setFileType(data)}
                fullWidth
                sx={{ maxWidth: '25rem' }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option.optionName}</li>
                )}
                renderInput={params => (
                  <TextField {...params} label='File Type' placeholder='File Type' />
                )}
              />
              {!fileType && (
                <Typography variant='body2' color='error' style={{ marginTop: 8 }}>
                  Please select a file type.
                </Typography>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <Box component={'div'} {...getRootProps({ style: { ...style, minHeight: '100px' } })}>
              <Box component={'div'} sx={{ display: 'block', textAlign: 'center' }}>
                <DriveFolderUploadIcon
                  fontSize='large'
                  sx={{ color: '#aaa', height: 50, width: 50, marginBottom: '1rem' }}
                />
                <input {...getInputProps()} />
                <Typography variant='h6' sx={{ color: '#aaa' }}>
                  Drag & drop files here, or click to select files
                </Typography>
                <Typography variant='subtitle1' color='gray'>
                {`(Only ${acceptedExtensions.map(ext => `*.${ext}`).join(', ')} files will be accepted)`}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {acceptedFiles.length > 0 && (
            <Grid item xs={12}>
              <List>
                {acceptedFiles.map(file => (
                  <ListItem key={file.file.path}>
                    {file.status === 'uploading' ? (
                      <TailSpin height={20} width={20} color='#00BFFF' />
                    ) : (
                      handlePreviewIcon(file)
                    )}
                    <ListItemText
                      primary={file.file.path}
                      secondary={file.status === 'error' ? file.rejectReason : undefined}
                      sx={{ ml: 2 }}
                    />
                    {!uploadInProgress && (
                      <DeleteIcon
                        color='secondary'
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          setAcceptedFiles(prev => prev.filter(f => f.file.path !== file.file.path))
                        }
                      />
                    )}
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'right', margin: '1rem' }}>
        <Button
          variant='contained'
          onClick={onSaveFiles}
          autoFocus
          disabled={
            uploadInProgress ||
            acceptedFiles.length === 0 ||
            (fileTypeRequired && !fileType)
          }
        >
          {uploadInProgress ? 'Uploading...' : 'Upload'}
        </Button>
        <Button onClick={handleClose} color='error'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDropzone;
