import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Slide,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import React, { useState } from 'react';
import ReportDataGrid from '../../utils/components/ReportDataGrid/ReportDataGrid';

interface ReportCardProps<T> {
  title: string;
  description: string;
  columns: any[];
  chatContent: React.ReactNode;
  fullWidth?: boolean;
  idColumn?: string;
  initialValues: T;
  validate?: (values: T) => Partial<Record<keyof T, string>>;
  renderForm: (formik: FormikProps<T>) => React.ReactNode;
  onSubmit: (values: T, formData: FormData) => Promise<any[]>;
}

export default function ReportCard<T extends {}>({
  title,
  description,
  columns,
  chatContent,
  fullWidth = false,
  idColumn = 'id',
  initialValues,
  validate,
  renderForm,
  onSubmit,
}: ReportCardProps<T>) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [showResults, setShowResults] = useState(false);

  const formik = useFormik<T>({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setShowResults(true);
      try {
        const formData = new FormData();
        // Append all form values to the FormData.
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
        });
        const data = await onSubmit(values, formData);
        setResults(data);
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const handleClear = () => {
    formik.resetForm();
    setShowResults(false);
  };

  return (
    <Card raised sx={{ my: 3, mx: 'auto', width: fullWidth ? '100%' : { xs: '95%', lg: '75%' } }}>
      <CardHeader
        title={title}
        sx={{ mx: 'auto', textAlign: 'center', color: theme.palette.primary.main }}
        action={
          <Tooltip
            title={description}
            placement='right-end'
            sx={{ color: theme.palette.text.primary }}
          >
            <InfoIcon />
          </Tooltip>
        }
      />
      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
            <Stack spacing={1} sx={{ mb: 2 }}>
              {Object.values(formik.errors).map((error, index) => (
                <Slide key={index} direction='right' in={true} mountOnEnter unmountOnExit>
                  <Alert severity='warning' sx={{ mb: 2 }}>
                    {error as string}
                  </Alert>
                </Slide>
              ))}
            </Stack>
          )}

          {renderForm(formik)}

          <Divider sx={{ my: 2, opacity: 1 }} />
          <Stack direction='row' spacing={2} sx={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={formik.isSubmitting}
            >
              Calculate
            </Button>
            <Button type='button' color='secondary' variant='outlined' onClick={handleClear}>
              Clear
            </Button>
          </Stack>
        </form>
        <Collapse in={showResults} sx={{ mt: showResults ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={results}
            idcolumn={idColumn}
            columnsInput={columns}
            rowsperpage={20}
            title={title}
            casecount
            chatcontent={chatContent}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
