import { CancelRounded, CheckBox, CheckBoxOutlineBlankOutlined, Info } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Grid,
  Snackbar,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useOptionsAPI } from '../../utils/api/useOptions.hook';
import { useAuth } from '../../utils/auth/AuthService';
import { Option } from '../Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { useConversation } from '../SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';
import { ConversationUser } from '../SysAdmin/SysAdminModules/UserFeedback/Conversations/Dialogs/NewConversationDialog';
import { ConversationTypes } from '../SysAdmin/SysAdminModules/UserFeedback/Conversations/conversation.types';

export interface UserAddress {
  addressSeq: string;
  contactInfoSeq: string | null;
  addressTypeSeq: string;
  otherAddressType: string;
  placeSeq: string | null;
  addressLine1: string;
  addressLine2: string;
  apartmentNo: string;
  crossStreet: string;
  city: string;
  stateSeq: string;
  otherState: string;
  zip: string;
  countrySeq: string;
  isPrimary: boolean;
  creationDateTime: string;
  isActive: boolean | null;
  floor: string;
  isCopyAddress: boolean;
  geoX: number | null;
  geoY: number | null;
  isWithinServiceArea: boolean;
  countySeq: string | null;
  nursingHomeSeq: string | null;
  funeralHomeSeq: string | null;
  lawEnforcementSeq: string | null;
  status: string;
}

export interface UserContact {
  contactItemSeq: string;
  contactItemDetails: string;
  contactItemTypeSeq: string;
  contactOrder: string;
  isActive: boolean;
  contactItemIsPrimary: boolean;
}

export interface UserProfile {
  userSeq: string;
  roleseq: string[];
  countySeqandDashboard: string[];
  userTitle: string;
  userName: string;
  primaryEmail: string;
  userFirstName: string;
  userMiddleName: string;
  userLastName: string;
  userSuffix: string;
  primaryMobileNo: string;
  initials: string;
  barcode: string;
  departmentSeq: string;
  organizationseq: string;
  operationMode: string;
  srtCaseEditable?: boolean;
  xRayEmailEnabled?: boolean;
  holdable?: boolean;
  isHighProfile: boolean;
  isExamCompleteDateEditable: boolean;
  isUnconfirmCaseRelease: boolean;
  isVoidTissueNumber: boolean;
  isDeleteTissueNumber: boolean;
  isHideDropdownPopUp: boolean;
  isModifyIdUser: boolean;
  isAutopsyReportFinal: boolean;
  enable2FA: boolean;
  eSignature: string | null;
  isAnatomicPathologyCert: boolean;
  isClinicalPathologyCert: boolean;
  isForensicPathologyCert: boolean;
  mdLicenseNo: string | null;
  mdLicenseExpiryDate: string | null;
  isDeathInvestigationCert: boolean;
  isAutopsyCertCert: boolean;
  isExternalExamCert: boolean;
  ocsmeCertificateExpiryDate: string | null;
  abmdiCertificateNO: string;
  abmdiCertificateExpiryDate: string | null;
  boardcertificatefellowNo: string;
  userAddress: UserAddress[];
  userContacts: UserContact[];
  securityQuestion: string | null;
  selectedSupervisor: ConversationUser | null;
  additionalNotes?: string;
}

interface NewAccountRequestDialog {
  initialData?: UserProfile;
  open?: boolean;
  onClose?: () => void;
  stateOptions?: Option[];
  countryOptions?: Option[];
  addressTypeOptions?: Option[];
}

const isOptionEqualToValue = (option: Option, value: Option) =>
  option.optionSeq.toLowerCase() === value.optionSeq?.toLowerCase();

const getOptionLabel = (option: Option) => option.optionName;

const multiRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Option,
  { selected }: { selected: boolean }
) => (
  <li {...props}>
    <Checkbox
      icon={<CheckBoxOutlineBlankOutlined fontSize='small' />}
      checkedIcon={<CheckBox fontSize='small' />}
      style={{ marginRight: 8 }}
      checked={selected}
    />
    {option.optionName}
  </li>
);

const multiRenderOptionPermissions = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: RoleOption,
  { selected }: { selected: boolean }
) => (
  <li {...props}>
    <Checkbox
      icon={<CheckBoxOutlineBlankOutlined fontSize='small' />}
      checkedIcon={<CheckBox fontSize='small' />}
      style={{ marginRight: 8 }}
      checked={selected}
    />
    <Stack>
      <>{option.optionName}</>

      <Typography variant='button' color='text.secondary'>
        <i>{option.description}</i>
      </Typography>
    </Stack>
  </li>
);

const multiRenderTags = (values: Option[], getTagProps: any) =>
  values.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      sx={{
        height: 'auto',
        '& .MuiChip-label': { display: 'flex', alignItems: 'center' },
        '& .MuiChip-deleteIcon': { fontSize: '16px' },
      }}
      label={option.optionName}
      deleteIcon={<CancelRounded />}
    />
  ));

const { VITE_FASTAPI_URL } = import.meta.env;

export function NewAccountRequestDialog({
  initialData,
  open = false,
  onClose = () => {},
  stateOptions = [],
  countryOptions = [],
  addressTypeOptions = [],
}: NewAccountRequestDialog) {
  // State for snackbar notifications
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'info' | 'warning'
  >('info');

  const handleDialogClose = (status?: { success: boolean; message: string }) => {
    if (status) {
      setSnackbarMessage(status.message);
      setSnackbarSeverity(status.success ? 'success' : 'error');
      setSnackbarOpen(true);
    }
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <UserForm
        initialData={initialData}
        onClose={onClose}
        addressTypeOptions={addressTypeOptions}
        countryOptions={countryOptions}
        stateOptions={stateOptions}
      />
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

interface UserFormProps {
  initialData?: UserProfile;
  onClose: (status?: { success: boolean; message: string }) => void;
  stateOptions?: Option[];
  countryOptions?: Option[];
  addressTypeOptions: Option[];
}

interface RoleOption extends Option {
  isPermission: boolean;
  isVisibleWhenRequestingNewAccount: boolean;
  description: string;
}

const UserForm: React.FC<UserFormProps> = ({ initialData, onClose }) => {
  const { user } = useAuth();
  const { createChat, availableUsers: conversationUsers } = useConversation();
  const availableUsers: ConversationUser[] = [
    ...conversationUsers.filter(u => !u.userName.includes('Support')),
    {
      userName: user?.userName || '',
      userSeq: user?.userSeq || '',
      personFirstName: user?.userFirstName || '',
      personLastName: user?.userLastName || '',
    },
  ];
  const { options: stateOptions } = useOptionsAPI('getstateoptions');
  const { options: addressTypeOptions } = useOptionsAPI('getaddresstypeoptions');
  const { options: countryOptions } = useOptionsAPI('getcountryoptions');
  const { options: unfilteredRoleOptions } = useOptionsAPI<RoleOption>('getroleoptions');
  const roleOptions = unfilteredRoleOptions.filter(
    r => r.isActive && r.isVisibleWhenRequestingNewAccount && !r.isPermission
  );

  const permissionOptions = unfilteredRoleOptions.filter(
    r => r.isActive && r.isVisibleWhenRequestingNewAccount && r.isPermission
  );

  const jurisdictionOptions: Option[] =
    user?.jdxAccessList.map(jdx => ({ optionName: jdx.jdxName, optionSeq: jdx.jdxSeq })) || [];

  const defaultNewUserProfile: UserProfile = {
    // Basic defaults
    abmdiCertificateExpiryDate: null,
    abmdiCertificateNO: '',
    barcode: '',
    boardcertificatefellowNo: '',
    countySeqandDashboard: [],
    departmentSeq: '4f66a4b5-858e-459a-8f37-33e224a45c99',
    eSignature: null,
    enable2FA: false,
    initials: '',
    mdLicenseExpiryDate: null,
    mdLicenseNo: null,
    ocsmeCertificateExpiryDate: null,
    operationMode: '',
    organizationseq: 'a6dbc293-7045-4cc1-bc79-36d772a57743',
    primaryEmail: '',
    primaryMobileNo: '',
    roleseq: ['A62EB010-88D5-440A-BE2C-E94BCEC73502 || True'], // MEDEXLAB-ACCESS
    securityQuestion: null,
    userAddress: [
      {
        addressSeq: '00000000-0000-0000-0000-000000000000',
        addressTypeSeq: '',
        addressLine1: '',
        city: '',
        placeSeq: null,
        stateSeq: '',
        countrySeq: '',
        countySeq: null,
        nursingHomeSeq: null,
        funeralHomeSeq: null,
        lawEnforcementSeq: null,
        isWithinServiceArea: true,
        creationDateTime: new Date().toISOString(),
        status: 'Active',
        geoX: null,
        geoY: null,
        isCopyAddress: false,
        floor: '',
        isPrimary: false,
        otherState: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
        contactInfoSeq: '00000000-0000-0000-0000-000000000000',
        isActive: true,
      },
    ],
    userContacts: [
      {
        contactItemSeq: '00000000-0000-0000-0000-000000000000',
        contactItemTypeSeq: '176240f7-30a9-40dd-af0c-304226e1bfea',
        contactItemDetails: '',
        contactOrder: '1',
        isActive: true,
        contactItemIsPrimary: false,
      },
    ],
    userFirstName: '',
    userLastName: '',
    userMiddleName: '',
    userName: '',
    userSeq: '00000000-0000-0000-0000-000000000000',
    userSuffix: '',
    userTitle: '',
    // @ts-ignore
    IsWithinServiceArea: true,
    isAnatomicPathologyCert: false,
    isAutopsyCertCert: false,
    isAutopsyReportFinal: false,
    isClinicalPathologyCert: false,
    isDeathInvestigationCert: false,
    isDeleteTissueNumber: false,
    isExamCompleteDateEditable: false,
    isExternalExamCert: false,
    isForensicPathologyCert: false,
    isHideDropdownPopUp: false,
    isHighProfile: false,
    isModifyIdUser: false,
    isUnconfirmCaseRelease: false,
    isVoidTissueNumber: false,
    selectedSupervisor: null,
    additionalNotes: '',
  };

  const [formData, setFormData] = useState<UserProfile>(initialData || defaultNewUserProfile);
  const primaryAddress = formData.userAddress[0] || {
    addressTypeSeq: '',
    stateSeq: '',
    countrySeq: '',
    addressLine1: '',
    city: '',
    zip: '',
  };

  const selectedAddressType = useMemo(() => {
    return primaryAddress.addressTypeSeq
      ? addressTypeOptions.find(
          opt => opt.optionSeq.toLowerCase() === primaryAddress.addressTypeSeq.toLowerCase()
        ) || null
      : null;
  }, [addressTypeOptions, primaryAddress]);

  const [selectedRoles, setSelectedRoles] = useState<Option[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<Option[]>([]);
  const [selectedJurisdictions, setSelectedJurisdictions] = useState<Option[]>([]);

  const selectedSupervisor = useMemo(() => {
    return formData.selectedSupervisor
      ? availableUsers.find(
          opt => opt.userSeq.toLowerCase() === formData.selectedSupervisor?.userSeq?.toLowerCase()
        ) || null
      : null;
  }, [availableUsers, formData]);

  const selectedAddressState = useMemo(() => {
    return primaryAddress.stateSeq
      ? stateOptions.find(
          opt => opt.optionSeq.toLowerCase() === primaryAddress.stateSeq.toLowerCase()
        ) || null
      : null;
  }, [stateOptions, primaryAddress]);

  const selectedAddressCountry = useMemo(() => {
    return primaryAddress.countrySeq
      ? countryOptions.find(
          opt => opt.optionSeq.toLowerCase() === primaryAddress.countrySeq.toLowerCase()
        ) || null
      : null;
  }, [countryOptions, primaryAddress]);

  // We'll track form errors in a single object: field -> error message
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setErrors(prev => ({ ...prev, [name]: '' })); // Clear errors as user types

    if (name.startsWith('address.')) {
      const field = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        userAddress: [
          {
            ...prev.userAddress[0],
            creationDateTime: new Date().toISOString(),
            [field]: value,
          },
        ],
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  /**
   * Basic validation to ensure all required fields are present before sending.
   */
  // First, let's modify the validateForm function to include the new requirements
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    // Required: first name, last name, email, phone
    if (!formData.userFirstName.trim()) {
      newErrors['userFirstName'] = 'First name is required.';
    }
    if (!formData.userLastName.trim()) {
      newErrors['userLastName'] = 'Last name is required.';
    }
    if (!formData.primaryEmail.trim()) {
      newErrors['primaryEmail'] = 'Email is required.';
    }
    if (!formData.primaryMobileNo.trim()) {
      newErrors['primaryMobileNo'] = 'Phone is required.';
    }

    // Required: Supervisor
    if (!selectedSupervisor) {
      newErrors['selectedSupervisor'] = 'Supervisor is required.';
    }

    // Required address fields: addressLine1, city, stateSeq, countrySeq
    if (!primaryAddress.addressLine1.trim()) {
      newErrors['address.addressLine1'] = 'Address line 1 is required.';
    }
    if (!primaryAddress.city.trim()) {
      newErrors['address.city'] = 'City is required.';
    }
    if (!primaryAddress.stateSeq.trim()) {
      newErrors['address.stateSeq'] = 'State is required.';
    }
    if (!primaryAddress.countrySeq.trim()) {
      newErrors['address.countrySeq'] = 'Country is required.';
    }

    // NEW - Required: Address Type
    if (!primaryAddress.addressTypeSeq.trim()) {
      newErrors['address.addressTypeSeq'] = 'Address type is required.';
    }

    // NEW - Required: At least one role
    if (selectedRoles.length === 0) {
      newErrors['roles'] = 'At least one role is required.';
    }

    // NEW - Required: At least one permission
    if (selectedPermissions.length === 0) {
      newErrors['permissions'] = 'At least one permission is required.';
    }

    // NEW - Required: At least one jurisdiction
    if (selectedJurisdictions.length === 0) {
      newErrors['jurisdictions'] = 'At least one jurisdiction is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    // Halt if any required fields are missing
    if (!validateForm()) return;

    try {
      const payload = {
        requestedOnDateTime: new Date().toISOString(),
        requestedByUserSeq: user?.userSeq,
        existingEmployeeUserSeq: null,
        accountRequestTypeSeq: '197a8561-f36f-4314-a3ab-3df95415ccbd',
        employee: {
          firstName: formData.userFirstName,
          lastName: formData.userLastName,
          middleName: formData.userMiddleName,
          phoneNumber: formData.primaryMobileNo,
          email: formData.primaryEmail,
          supervisorUserSeq: selectedSupervisor?.userSeq || null,
          address: {
            addressTypeSeq: selectedAddressType?.optionSeq,
            addressLine1: primaryAddress.addressLine1,
            addressLine2: primaryAddress.addressLine2,
            addressAptSuite: primaryAddress.apartmentNo,
            addressFloor: primaryAddress.floor,
            addressCity: primaryAddress.city,
            addressZip: primaryAddress.zip,
            addressStateSeq: selectedAddressState?.optionSeq,
            addressCountrySeq: selectedAddressCountry?.optionSeq,
          },
        },
        roles: [
          ...selectedRoles.map(jdx => jdx.optionSeq),
          ...selectedPermissions.map(jdx => jdx.optionSeq),
        ],
        jurisdictions: selectedJurisdictions.map(jdx => jdx.optionSeq),
        notes: formData.additionalNotes || null,
      };

      // Always grant MEDEXLAB access
      payload.roles = [...payload.roles, 'A62EB010-88D5-440A-BE2C-E94BCEC73502'];

      const resp = await fetch(`${VITE_FASTAPI_URL}account-requests`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!resp.ok) throw new Error('Failed to create account request');
      const accountRequestResponse = await resp.json();

      const newChat = await createChat({
        topic: `New account request: ${payload.employee.firstName} ${payload.employee.lastName}`,
        messages: [
          {
            messageContent: `
              <strong>New Account Request</strong><br/>
              <table>
                <tr><td colspan="2"><strong>Personal Information</strong></td></tr>
                <tr><td>First Name:</td><td>${payload.employee.firstName}</td></tr>
                <tr><td>Middle Name:</td><td>${payload.employee.middleName}</td></tr>
                <tr><td>Last Name:</td><td>${payload.employee.lastName}</td></tr>
                <tr><td>Email:</td><td>${payload.employee.email}</td></tr>
                <tr><td>Phone:</td><td>${payload.employee.phoneNumber}</td></tr>
                <tr><td>Supervisor:</td><td>${selectedSupervisor?.personFirstName} ${
              selectedSupervisor?.personLastName
            } (${selectedSupervisor?.userName})</td></tr>
              </table>
              <br/>
              <table>
                <tr><td colspan="2"><strong>Address</strong></td></tr>
                <tr><td>Line 1:</td><td>${payload.employee.address.addressLine1}</td></tr>
                ${
                  payload.employee.address.addressLine2
                    ? `<tr><td>Line 2:</td><td>${payload.employee.address.addressLine2}</td></tr>`
                    : ''
                }
                <tr><td>City:</td><td>${payload.employee.address.addressCity}</td></tr>
                <tr><td>ZIP:</td><td>${payload.employee.address.addressZip}</td></tr>
                <tr><td>State:</td><td>${selectedAddressState?.optionName}</td></tr>
                <tr><td>Country:</td><td>${selectedAddressCountry?.optionName}</td></tr>
              </table>
              <br/>
              <table>
                <tr><td colspan="2"><strong>System Access</strong></td></tr>
                <tr><td>Roles:</td><td>${selectedRoles
                  .map(role => role.optionName)
                  .join(', ')}</td></tr>
                <tr><td>Jurisdictions:</td><td>${selectedJurisdictions
                  .map(jdx => jdx.optionName)
                  .join(', ')}</td></tr>
              </table>
              ${payload.notes ? `<br/><strong>Additional Notes:</strong><br/>${payload.notes}` : ''}
            `,
            sender: { userSeq: user?.userSeq },
            readBy: [{ userSeq: user?.userSeq }],
            attachments: [],
          },
        ],
        creator: { userSeq: user?.userSeq },
        type: { conversationTypeSeq: ConversationTypes.New_Account_Request },
        isOpen: true,
        isActive: true,
        participants:
          user?.userSeq?.toLowerCase() === selectedSupervisor?.userSeq?.toLowerCase()
            ? [{ userSeq: user?.userSeq }]
            : [{ userSeq: user?.userSeq }, { userSeq: selectedSupervisor?.userSeq }],
      });

      const linked_response = await fetch(
        `${VITE_FASTAPI_URL}account-requests/link-to-conversation`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            accountRequestSeq: accountRequestResponse.accountRequestSeq,
            conversationSeq: newChat.conversationSeq,
          }),
        }
      );
      if (!linked_response.ok) throw new Error('Failed to link conversation to account request');

      onClose({ success: true, message: 'New account request submitted!' });
    } catch (error: any) {
      console.error(error);
      onClose({ success: false, message: error.message || 'Error creating account request' });
    }
  };

  return (
    <Box component='div' sx={{ p: 4, width: '600px' }}>
      <Typography variant='h5' sx={{ mb: 4 }}>
        Request New Account
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='overline' sx={{ mb: 2 }}>
            Employee Details
          </Typography>
        </Grid>

        {/* First Name */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            label='First Name'
            name='userFirstName'
            value={formData.userFirstName}
            onChange={handleChange}
            variant='outlined'
            error={!!errors['userFirstName']}
            helperText={errors['userFirstName']}
            autoComplete='off'
          />
        </Grid>

        {/* Middle Name */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label='Middle Name'
            name='userMiddleName'
            value={formData.userMiddleName}
            onChange={handleChange}
            variant='outlined'
            autoComplete='off'
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            label='Last Name'
            name='userLastName'
            value={formData.userLastName}
            onChange={handleChange}
            variant='outlined'
            error={!!errors['userLastName']}
            helperText={errors['userLastName']}
            autoComplete='off'
          />
        </Grid>

        {/* Email */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            label='Email'
            name='primaryEmail'
            type='email'
            value={formData.primaryEmail}
            onChange={handleChange}
            variant='outlined'
            error={!!errors['primaryEmail']}
            helperText={errors['primaryEmail']}
            autoComplete='off'
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={6}>
          <PhoneInput
            fullWidth
            required
            label='Phone'
            name='primaryMobileNo'
            value={formData.primaryMobileNo}
            onChange={e => {
              setErrors(prev => ({ ...prev, primaryMobileNo: '' }));
              setFormData(prev => ({
                ...prev,
                primaryMobileNo: e.target.value,
                userContacts: [
                  {
                    contactItemSeq: '00000000-0000-0000-0000-000000000000',
                    contactItemTypeSeq: '176240f7-30a9-40dd-af0c-304226e1bfea',
                    contactItemDetails: e.target.value,
                    contactOrder: '1',
                    isActive: true,
                    contactItemIsPrimary: false,
                  },
                ],
              }));
            }}
            error={!!errors['primaryMobileNo']}
            helperText={errors['primaryMobileNo']}
            variant='outlined'
            autoComplete='off'
          />
        </Grid>

        {/* Supervisor */}
        <Grid item xs={12}>
          <Autocomplete
            options={availableUsers}
            value={selectedSupervisor}
            onChange={(_, newValue) => {
              setErrors(prev => ({ ...prev, selectedSupervisor: '' }));
              setFormData(prev => ({ ...prev, selectedSupervisor: newValue }));
            }}
            getOptionLabel={(option: ConversationUser) =>
              `${option.personFirstName} ${option.personLastName} (${option.userName})`
            }
            isOptionEqualToValue={(option: ConversationUser, value: ConversationUser) =>
              option.userSeq?.toLowerCase() === value.userSeq?.toLowerCase()
            }
            renderInput={params => (
              <TextField
                {...params}
                required
                label='Employee Supervisor'
                variant='outlined'
                error={!!errors['selectedSupervisor']}
                helperText={errors['selectedSupervisor']}
                autoComplete='off'
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='overline' sx={{ mb: 2 }}>
            Employee Address
          </Typography>
        </Grid>

        {/* Address Type */}
        <Grid item xs={12}>
          <Autocomplete
            options={addressTypeOptions}
            value={selectedAddressType}
            onChange={(_, newValue) => {
              setErrors(prev => ({ ...prev, 'address.addressTypeSeq': '' }));
              setFormData(prev => ({
                ...prev,
                userAddress: [
                  { ...prev.userAddress[0], addressTypeSeq: newValue?.optionSeq || '' },
                ],
              }));
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={params => (
              <TextField
                {...params}
                required
                label='Address Type'
                variant='outlined'
                error={!!errors['address.addressTypeSeq']}
                helperText={errors['address.addressTypeSeq']}
                autoComplete='off'
              />
            )}
          />
        </Grid>

        {/* Address Line 1 */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label='Address Line 1'
            name='address.addressLine1'
            value={primaryAddress.addressLine1}
            onChange={handleChange}
            variant='outlined'
            error={!!errors['address.addressLine1']}
            helperText={errors['address.addressLine1']}
            autoComplete='off'
          />
        </Grid>

        {/* Address Line 2 */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Address Line 2'
            name='address.addressLine2'
            value={primaryAddress.addressLine2}
            onChange={handleChange}
            variant='outlined'
            autoComplete='off'
          />
        </Grid>

        {/* Apt / Suite */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Apt/Suite'
            name='address.apartmentNo'
            value={primaryAddress.apartmentNo}
            onChange={handleChange}
            variant='outlined'
            autoComplete='off'
          />
        </Grid>

        {/* Floor */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Floor'
            name='address.floor'
            value={primaryAddress.floor}
            onChange={handleChange}
            variant='outlined'
            autoComplete='off'
          />
        </Grid>

        {/* City */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            label='City'
            name='address.city'
            value={primaryAddress.city}
            onChange={handleChange}
            variant='outlined'
            error={!!errors['address.city']}
            helperText={errors['address.city']}
            autoComplete='off'
          />
        </Grid>

        {/* State */}
        <Grid item xs={6}>
          <Autocomplete
            options={stateOptions}
            value={selectedAddressState}
            onChange={(_, newValue) => {
              setErrors(prev => ({ ...prev, 'address.stateSeq': '' }));
              setFormData(prev => ({
                ...prev,
                userAddress: [{ ...prev.userAddress[0], stateSeq: newValue?.optionSeq || '' }],
              }));
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, option, state) => multiRenderOption(props, option, state)}
            renderInput={params => (
              <TextField
                {...params}
                required
                label='State'
                variant='outlined'
                error={!!errors['address.stateSeq']}
                helperText={errors['address.stateSeq']}
                autoComplete='off'
              />
            )}
          />
        </Grid>

        {/* Zip */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='ZIP Code'
            name='address.zip'
            value={primaryAddress.zip}
            onChange={handleChange}
            variant='outlined'
            autoComplete='off'
          />
        </Grid>

        {/* Country */}
        <Grid item xs={6}>
          <Autocomplete
            options={countryOptions}
            value={selectedAddressCountry}
            onChange={(_, newValue) => {
              setErrors(prev => ({ ...prev, 'address.countrySeq': '' }));
              setFormData(prev => ({
                ...prev,
                userAddress: [{ ...prev.userAddress[0], countrySeq: newValue?.optionSeq || '' }],
              }));
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, option, state) => multiRenderOption(props, option, state)}
            renderInput={params => (
              <TextField
                {...params}
                required
                label='Country'
                variant='outlined'
                error={!!errors['address.countrySeq']}
                helperText={errors['address.countrySeq']}
                autoComplete='off'
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='overline' sx={{ mb: 2 }}>
            Employee Access
          </Typography>
        </Grid>

        {/* Roles */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={roleOptions}
            value={selectedRoles}
            onChange={(_, newValue) => {
              setSelectedRoles(newValue as Option[]);
              setErrors(prev => ({ ...prev, roles: '' }));
            }}
            limitTags={2}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, option, state) => multiRenderOption(props, option, state)}
            renderTags={(v, getTagProps) => multiRenderTags(v, getTagProps)}
            renderInput={params => (
              <TextField
                {...params}
                required
                label='Roles'
                variant='outlined'
                error={!!errors['roles']}
                helperText={errors['roles']}
                autoComplete='off'
              />
            )}
            disableCloseOnSelect
          />
        </Grid>

        {/* Permissions */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={permissionOptions}
            value={selectedPermissions}
            onChange={(_, newValue) => {
              setSelectedPermissions(newValue as Option[]);
              setErrors(prev => ({ ...prev, permissions: '' }));
            }}
            limitTags={2}
            getOptionLabel={option => option.optionName}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, option, state) =>
              multiRenderOptionPermissions(props, option as RoleOption, state)
            }
            renderTags={(v, getTagProps) => multiRenderTags(v, getTagProps)}
            renderInput={params => (
              <TextField
                {...params}
                required
                label='Permissions'
                variant='outlined'
                error={!!errors['permissions']}
                helperText={errors['permissions']}
                autoComplete='off'
              />
            )}
            disableCloseOnSelect
          />
        </Grid>

        {/* Jurisdictions */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={jurisdictionOptions}
            value={selectedJurisdictions}
            limitTags={1}
            onChange={(_, newValue) => {
              setSelectedJurisdictions(newValue as Option[]);
              setErrors(prev => ({ ...prev, jurisdictions: '' }));
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, option, state) => multiRenderOption(props, option, state)}
            renderTags={(v, getTagProps) => multiRenderTags(v, getTagProps)}
            renderInput={params => (
              <TextField
                {...params}
                required
                label='Jurisdictions'
                variant='outlined'
                error={!!errors['jurisdictions']}
                helperText={errors['jurisdictions']}
                autoComplete='off'
              />
            )}
            disableCloseOnSelect
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label='Notes'
            name='additionalNotes'
            value={formData.additionalNotes || ''}
            onChange={handleChange}
            variant='outlined'
            placeholder='Enter any additional information about this account request...'
            autoComplete='off'
          />
        </Grid>

        {/* Info Alert */}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert icon={<Info />} severity='info' variant='filled'>
            Once approved, the user will receive an email containing login instructions.
          </Alert>
        </Grid>

        {/* Action Buttons */}
        <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button variant='outlined' color='error' onClick={() => onClose()}>
            Cancel
          </Button>
          <Button variant='contained' color='primary' onClick={handleSave}>
            Submit Request
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

interface PhoneInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (e: { target: { name: string; value: string } }) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value, onChange, ...props }) => {
  const formatPhoneNumber = (input: string): string => {
    const cleaned = input.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (!match) return '';
    const parts = [match[1], match[2], match[3]].filter(Boolean);
    return parts.join('-');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    onChange({ target: { name: props.name || '', value: formatted } });
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      placeholder='123-456-7890'
      inputProps={{ maxLength: 12, ...props.inputProps }}
      autoComplete='off'
    />
  );
};
