import { ChevronLeft, GroupAddOutlined } from '@mui/icons-material';
import { Box, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { ChatParticipantChip } from './ChatParticipantChip';
import { Conversation, ConversationTypes } from './conversation.types';
import { formatMessageDateAlwaysIncludeTime } from './ConversationMessages';
import { useConversation } from './ConversationsContext';
import { ModifyChatParticipantMenu } from './ModifyChatParticipantMenu';

export const ConversationHeader = ({
  onBackClick,
  isMobile,
  isDarkMode = false,
  onConversationUpdated = (conversation: Conversation) => {},
  supportAgentName = 'Support',
}: {
  onBackClick: () => void;
  isMobile: boolean;
  isDarkMode?: boolean;
  onConversationUpdated?: (conversation: Conversation) => void;
  supportAgentName?: string;
}) => {
  const [addOpen, setAddOpen] = useState(false);
  const [editTopicAnchorEl, setEditTopicAnchorEl] = useState<null | HTMLElement>(null);
  const [newTopic, setNewTopic] = useState('');
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const { selectedChat, updateConversationOpenStatus } = useConversation();
  const creator = selectedChat?.creator;

  const { user } = useAuth();
  const isSystemAdmin = user?.roleCheck(['451', '8f7']) || false;

  const hideParticipantList =
    !isSystemAdmin &&
    [
      ConversationTypes.Feedback,
      ConversationTypes.Error_Report,
      ConversationTypes.New_Account_Request,
    ].includes(selectedChat?.type?.conversationTypeSeq || '');

  const handleToggleStatus = async () => {
    if (!selectedChat) return;

    updateConversationOpenStatus({
      conversationSeq: selectedChat.conversationSeq,
      isOpen: !selectedChat.isOpen,
    });
  };

  const handleTopicUpdate = () => {
    if (!selectedChat || !newTopic.trim()) return;

    // onConversationUpdated({
    //   ...selectedChat,
    //   topic: newTopic.trim(),
    // });

    setEditTopicAnchorEl(null);
    setNewTopic('');
  };

  const handleCancelEdit = () => {
    setEditTopicAnchorEl(null);
    setNewTopic('');
  };

  // Check if the current user can modify participants
  const canModifyParticipants =
    // For feedback type, only users with specific roles can modify
    selectedChat?.type.conversationTypeSeq?.toLowerCase() ===
    ConversationTypes.Feedback.toLowerCase()
      ? user?.roleCheck(['451', '8f7'])
      : true; // For other types, anyone can modify

  return (
    <Box
      component='div'
      sx={{
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        flexShrink: 0,
        position: 'sticky',
        top: -1,
        backdropFilter: 'blur(32px)',
        backgroundColor: user?.isDarkMode ? 'rgba(18, 18, 18, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        color: isDarkMode ? '#fff' : 'inherit',
        zIndex: 10,
      }}
    >
      <Stack direction='row' spacing={2}>
        {isMobile && (
          <IconButton onClick={onBackClick}>
            <ChevronLeft />
          </IconButton>
        )}
        <Box component='div' sx={{ width: '100%' }}>
          <Box
            component='div'
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 0,
              gap: 2,
            }}
          >
            <Box component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant='h6' sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {selectedChat?.topic}
              </Typography>
              {isSystemAdmin && (
                <>
                  {/* <Tooltip title='Name chat' arrow>
                    <IconButton
                      size='small'
                      onClick={event => {
                        setEditTopicAnchorEl(event.currentTarget);
                        setNewTopic(selectedChat?.topic || '');
                      }}
                    >
                      <Edit fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={editTopicAnchorEl}
                    open={Boolean(editTopicAnchorEl)}
                    onClose={handleCancelEdit}
                  >
                    <Stack sx={{ width: 300, px: 1 }} spacing={1}>
                      <Typography variant='body1' sx={{ pt: 1.5 }}>
                        Chat name
                      </Typography>
                      <TextField
                        fullWidth
                        size='small'
                        value={newTopic}
                        onChange={e => setNewTopic(e.target.value)}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            handleTopicUpdate();
                          }
                        }}
                        autoFocus
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          justifyContent: 'flex-end',
                          width: '100%',
                          mt: 1,
                        }}
                      >
                        <Button
                          onClick={handleCancelEdit}
                          size='large'
                          sx={{ textTransform: 'none' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size='large'
                          onClick={handleTopicUpdate}
                          variant='contained'
                          disabled={!newTopic.trim()}
                          sx={{ textTransform: 'none' }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Stack>
                  </Menu> */}
                </>
              )}
            </Box>

            <Stack
              direction='row'
              spacing={1}
              sx={{
                flexShrink: 0,
                alignItems: 'center',
              }}
            >
              {isSystemAdmin && (
                <Chip
                  label={selectedChat?.type.conversationTypeName}
                  size='small'
                  variant='outlined'
                />
              )}

              <Chip
                label={selectedChat?.isOpen ? 'Open' : 'Closed'}
                color={selectedChat?.isOpen ? 'success' : 'default'}
                onClick={isSystemAdmin ? handleToggleStatus : undefined}
                size='small'
                sx={{ cursor: 'default' }}
              />
            </Stack>
          </Box>

          <Box component='div' sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant='body2' color={isDarkMode ? 'text.secondary' : 'text.secondary'}>
              Started by {creator?.userName} on{' '}
              {formatMessageDateAlwaysIncludeTime(selectedChat?.createdAtTimestamp || '')}
            </Typography>

            <Box
              component='div'
              sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1, alignItems: 'center' }}
            >
              <>
                {/* {String(hideParticipantList)}
                <pre> {JSON.stringify(selectedChat?.participants, null, 2)}</pre> */}
                {hideParticipantList ? (
                  <>
                    <Chip
                      key='convo-creator'
                      label={`${creator?.personFirstName} ${creator?.personLastName} (${creator?.userName})`}
                      size='small'
                      variant='outlined'
                    />
                    <Chip
                      key='support-agent-name'
                      label={supportAgentName}
                      size='small'
                      variant='outlined'
                    />
                  </>
                ) : (
                  selectedChat?.participants.map(
                    participant =>
                      participant?.isActive && <ChatParticipantChip participant={participant} />
                  )
                )}

                {canModifyParticipants && (
                  <Tooltip arrow title='View or add participants'>
                    <IconButton
                      size='small'
                      ref={addButtonRef}
                      onClick={() => setAddOpen(true)}
                      id='add-participant-button'
                    >
                      <GroupAddOutlined />
                    </IconButton>
                  </Tooltip>
                )}
                <ModifyChatParticipantMenu
                  open={addOpen}
                  setOpen={setAddOpen}
                  anchorEl={addButtonRef.current}
                />
              </>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
