export function formatPhoneNumber(phoneNumber?: string, silent: boolean = false): string {
  if (!phoneNumber) return '';

  // Remove all non-digits except for a leading '+'
  let cleaned = phoneNumber.replace(/[^\d+]/g, '');

  // Check if the first character is '1', if it is then remove it for processing
  if (cleaned.startsWith('+1')) {
    cleaned = cleaned.substring(2);
  }

  // Check the length of the cleaned string, it should be 10, otherwise the phone number is invalid
  if (cleaned.length !== 10) {
    return silent ? phoneNumber : (() => { throw new Error('Invalid phone number. The number must have 10 digits, not including the country code.'); })();
  }


  let area = cleaned.substring(0, 3);
  let central = cleaned.substring(3, 6);
  let end = cleaned.substring(6, 10);

  return '+1 (' + area + ') ' + central + '-' + end;
}
