import React, { useState, useCallback, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import {
  fetchFormattedDate,
  fetchLoginHistory,
  findDayDifference,
} from '../../../services/utility-api';
import dayjs, { Dayjs } from 'dayjs';
import DataGridComponent from '../../../components/AdministrationModules/DataGridComponent';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ActionList } from '../../../components/CaseView/CaseViewDateTimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';

interface LoginHistoryProps {
  userSeq: string;
}

const LoginHistory: React.FC<LoginHistoryProps> = ({ userSeq }) => {
  const [loginHistoryData, setLoginHistoryData] = useState<[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      field: 'loginDateTime',
      headerName: '	Log In Date Time',
      minWidth: 150,
      flex: 1,
      align: 'left',
      valueFormatter: (value : any) => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      field: 'logOutDateTime',
      headerName: '	Log Out Date Time',
      minWidth: 150,
      flex: 1,
      align: 'left',
      valueFormatter: (value : any) => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      field: 'userIPAddress',
      headerName: 'User IP Address',
      type: 'text',
      width: 100,
      align: 'left',
      flex: 1,
    },
  ];

  const validateInputs = () => {
    let isValid = true;

    if (!startDate) {
      setStartDateError('Start Date is required');
      isValid = false;
    } else {
      setStartDateError('');
    }

    if (!endDate) {
      setEndDateError('End Date is required');
      isValid = false;
    } else if (endDate.isBefore(startDate)) {
      setEndDateError('End Date cannot be before Start Date');
      isValid = false;
    } else {
      setEndDateError('');
    }

    return isValid;
  };

  const handleSearch = useCallback(async () => {
    if (!validateInputs()) return;
    setIsLoading(true);
    try {
      const dayDifference = findDayDifference(startDate, endDate);
      const formattedDate = fetchFormattedDate(startDate);
      const history = await fetchLoginHistory(userSeq, dayDifference, formattedDate);
      setLoginHistoryData(history);
    } catch (error) {
      console.error('Error fetching login history:', error);
    } finally {
      setIsLoading(false);
    }
  }, [startDate, endDate, userSeq]);

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name='startDate'
            value={startDate}
            onChange={data => {
              setStartDate(data);
              validateInputs();
            }}
            label='Start Date *'
            slots={{
              actionBar: ActionList,
            }}
            slotProps={{
              textField: {
                error: !!startDateError,
                helperText: startDateError,
              },
            }}
            sx={{ width: '100%' }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name='endDate'
            value={endDate}
            onChange={data => {
              setEndDate(data);
              validateInputs();
            }}
            label='End Date *'
            slots={{
              actionBar: ActionList,
            }}
            slotProps={{
              textField: {
                error: Boolean(endDateError),
                helperText: endDateError,
              },
            }}
            sx={{ width: '100%' }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button fullWidth variant='contained' size='large' onClick={handleSearch}>
          Search
        </Button>
      </Grid>

      <Grid item xs={12}>
        <DataGridComponent
          loading={isLoading}
          listresults={loginHistoryData}
          columnsInput={columns}
          rowsperpage={20}
          gridheight={loginHistoryData?.length > 0 ? 'auto' : 100}
          idcolumn={'loginDateTime'}
        />
      </Grid>
    </Grid>
  );
};

export default LoginHistory;
