import React, { useState } from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@mui/material';
import axios from 'axios';
import authHeader from '../../services/auth-header';

export const ClearUserSessionButton = ({ userid }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleClearSession = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}user/clearusersession`,
        { username: userid },
        { headers: authHeader() }
      );

      setResponseMessage(response.data.message || 'User session cleared successfully');
      setResponseModal(true);
    } catch (error) {
      setResponseMessage(error.message || 'Failed to clear user session');
      setResponseModal(true);
    }
  };

  return (
    <>
      <Button
        primary
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => setConfirmationOpen(true)}
        disabled={!userid}
      >
        Clear User Session
      </Button>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
        <DialogTitle>Confirm Session Clear</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to clear all active sessions for this user? 
            This will force the user to log in again on all devices.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
          <Button 
            onClick={() => {
              setConfirmationOpen(false);
              handleClearSession();
            }}
          >
            Yes, Clear Sessions
          </Button>
        </DialogActions>
      </Dialog>

      {/* Response Dialog */}
      <Dialog open={responseModal} onClose={() => setResponseModal(false)}>
        <DialogTitle>Session Clear Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {responseMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResponseModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};