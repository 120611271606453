import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../../utils/auth/AuthService';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ELabAMContacts() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const { VITE_FASTAPI_URL } = import.meta.env;

  const columns = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 150,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'contactfirstname',
      headerName: 'Contact First Name',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      // renderCell: params => {
      //   return (
      //     <NavLink
      //       //variant="contained"
      //       color='primary'
      //       onClick={event => {
      //         handleBatchClick(event, params);
      //       }}
      //     >
      //       {params.formattedValue}
      //     </NavLink>
      //   );
      // },
    },

    {
      field: 'contactlastname',
      headerName: 'Contact Last Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'contactphone',
      headerName: 'Phone Number',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'contactemail',
      headerName: 'Email',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  useEffect(() => {
    if (user === null) return;

    setLoading(true);

    if (user && user.token) {
      fetch(VITE_FASTAPI_URL + 'elab/am-contacts', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setLoading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }
  }, [user]);

  return (
    <ReportDataGrid
      idcolumn='id'
      loading={loading}
      listresults={listresults}
      columnsInput={columns}
      title='AM Contacts'
    />
  );
}
