import axios from 'axios';
import dayjs from 'dayjs';
import {
  OCSME_CERTIFICATION_OBJECT,
  PHYSICIAN_CERTIFICATION_PATHOLOGY_OBJECT,
  SPECIAL_PRIVILEGE_OBJECT,
} from '../views/CMSAdmin/Menu/constants';
import authHeader from './auth-header';

let counties = [];
let roleSequenceName = {};
let countySequenceName = {};
let countyNameFromSequence = {};

export const fetchRoles = async (module) => {
  return axios
    .get(`${import.meta.env.VITE_API_URL}GetAllRole`, {
      headers: authHeader(),
    })
    .then((response) => {
      const roles = response?.data?.data?.map((role) => {
        roleSequenceName = {
          ...roleSequenceName,
          [role?.roleSeq]: role?.roleName,
        };
        if (module === 'UserAccount') {
          return { roleName: role?.roleName, roleSeq: role?.roleSeq };
        }
        else if (role?.status === 'Active' && module !== 'Roles') {
          return { name: role?.roleName, value: role?.roleSeq };
        } 
        else {
          return { name: role?.roleName, value: role?.roleSeq };
        }
      });
      return roles;
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const fetchCounty = async () => {
  return axios
    .get(`${import.meta.env.VITE_API_URL}GetAllCounty`, {
      headers: authHeader(),
    })
    .then((response) => {
      counties = response?.data?.data?.[0]?.children;
      return response?.data?.data?.[0]?.children?.map((county) => {
        countySequenceName = {
          ...countySequenceName,
          [county?.countyName]: county?.countySeq,
        };
        countyNameFromSequence = {
          ...countyNameFromSequence,
          [county?.countySeq]: county?.countyName,
        };
        return { name: county?.countyName, value: county?.countySeq };
      });
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const fetchUserCounty = async () => {
  return axios
    .get(`${import.meta.env.VITE_API_URL}GetUserCountyDetails`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const getNameFromSequence = (sequenceArray) => {
  return sequenceArray?.roleseq?.map(
    (sequence) => roleSequenceName?.[sequence]
  );
};

export const getNameFromCountySequence = (sequenceArray) => {
  return sequenceArray.map((sequence) => countyNameFromSequence?.[sequence]);
};

export const getNameFromRolesSequence = (sequenceArray) => {
  return sequenceArray.map((sequence) => roleSequenceName?.[sequence]);
};

export const getNameAndSequenceForCounty = (sequenceArray) => {
  return sequenceArray?.map((sequence) => {
    return { name: countyNameFromSequence?.[sequence], value: sequence };
  });
};

export const fetchDepartment = async () => {
  return axios
    .get(
      `${import.meta.env.VITE_API_URL}GetMasterData?tableName=AssignmentType`,
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response?.data?.data?.map((dept) => {
        return {
          name: dept?.assignmentTypeName,
          value: dept?.assignmentTypeSeq,
        };
      });
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const fetchOperationMode = async () => {
  return axios
    .get(
      `${import.meta.env.VITE_API_URL}GetMasterData?tableName=OperationMode`,
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      console.log('response11', response.data.data);
      return response?.data?.data?.map((operationMode) => {
        return {
          name: operationMode?.operationModeName,
          value: operationMode?.operationModeSeq,
        };
      });
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const fetchOrigin = async () => {
  return axios
    .get(`${import.meta.env.VITE_API_URL}GetMasterData?tableName=Origin`, {
      headers: authHeader(),
    })
    .then((response) => {
      console.log('response11', response.data.data);
      return response?.data?.data?.map((origin) => {
        return { name: origin?.originName, value: origin?.originSeq };
      });
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const getNameFromSpecialPrivilege = (privilegeArray) => {
  return privilegeArray?.map(
    (privilege) => SPECIAL_PRIVILEGE_OBJECT?.[privilege]
  );
};

export const fetchLoginHistory = async (userSeq, dayDiff, formattedDate) => {
  return axios
    .get(
      `${import.meta.env.VITE_API_URL}GetUserLoginHistory?userSeq=${userSeq}&dayDiff=${dayDiff}&stDate=${formattedDate}`,
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const fetchUserCertificateType = async (userSeq, isDropDown) => {
  const dropDown = [{ name: 'All', value: 'all' }];
  return axios
    .get(
      `${import.meta.env.VITE_API_URL}GetCertificateType?userSeq=${userSeq}`,
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      if (isDropDown) {
        return response.data.data;
      } else {
        response.data.data?.map((documents) => {
          dropDown?.push({
            name: documents?.photoTypeName,
            value: documents?.photoTypeSeq,
          });
        });
        return dropDown;
      }
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const findDayDifference = (startDate, endDate) => {
  const NewstartDate = new Date(startDate?.$d);
  const NewendDate = new Date(endDate?.$d);

  const timeDiff = Math.abs(NewendDate.getTime() - NewstartDate.getTime());
  const dayDiffs = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return dayDiffs;
};

export const calculateDateDifference = (toDate, fromDate) => {
  const differenceInTime = toDate.getTime() - fromDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.abs(Math.round(differenceInDays));
};

export const fetchFormattedDate = (date) => {
  return new Intl.DateTimeFormat('en-IN', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(dayjs(date));
};

export const fetchFormat = (date) => {
  if (!date) return null;
  const dateSplit = date.split('-');
  return dayjs(dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0]);
};

export const fetchUserProfileInformation = async (
  userSeq,
  isIncludeSecurityQuestion
) => {
  return axios
    .post(
      `${import.meta.env.VITE_API_URL}GetUserProfiles?userSeq=${userSeq}&isIncludeSecurityQuestion=${isIncludeSecurityQuestion}`,
      '',
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response?.data?.data;
    })
    .catch((err) => {
      console.log('error', err);
      return;
    });
};

export const getNameFromPathology = (options) => {
  return options?.map(
    (option) => PHYSICIAN_CERTIFICATION_PATHOLOGY_OBJECT?.[option]
  );
};

export const getNameFromOCSME = (options) => {
  console.log('options', options);
  return options?.map((option) => OCSME_CERTIFICATION_OBJECT?.[option]);
};

export const getNameFromPhotoTypePermissions = (options) => {
  console.log('options', options);
  return options?.map((option) => OCSME_CERTIFICATION_OBJECT?.[option]);
};

export const getNameFromDocumentTypePermissions = (options) => {
  console.log('options', options);
  return options?.map((option) => OCSME_CERTIFICATION_OBJECT?.[option]);
};

export const getNameFromReportTypePermissions = (options) => {
  console.log('options', options);
  return options?.map((option) => OCSME_CERTIFICATION_OBJECT?.[option]);
};

export const fetchCountyCode = async (countySequence) => {
  return counties?.filter((county) => county?.countySeq === countySequence);
};
const { VITE_API_URL } = import.meta.env;

export const getAllCounty = async () => {
  const response = await axios.get(VITE_API_URL + 'GetAllCounty', {
    headers: authHeader(),
  });
  const countyNames = {};
  response.data.data.forEach((county) => {
    countyNames[county.countySeq] = {
      countySeq: county.countySeq,
      countyName: county.countyName,
      code: county.code,
      order: county.order,
    };
    if (county.hasOwnProperty('children')) {
      county.children.forEach((county) => {
        countyNames[county.countySeq] = {
          countySeq: county.countySeq,
          countyName: county.countyName,
          code: county.code,
          order: county.order,
        };
      });
    }
  });
  return countyNames;
};


export const getAllJurisdiction = async () => {
  const response = await axios.get(VITE_API_URL + 'getjurisdictionoptions', {
    headers: authHeader(),
  });
  return response.data;
};

export const getAdminData = async (tableName) => {
  const response = await axios.get(
    VITE_API_URL + `GetMasterData?tableName=${tableName}`,
    {
      headers: authHeader(),
    }
  );
  return response;
};

export const deleteData = async (params) => {
  const response = await axios.post(
    VITE_API_URL +
      `InactivateMasterData?seq=${params.seq}&tableName=${params.tableName}`,
    null,
    {
      headers: authHeader(),
    }
  );
  if (response.data.status == 1 && response.data.message.includes('REFERENCE'))
    return "This Record is being used by other Module(s)-Record can't be Inactivated.";
  return response.data.message;
};

export const activeData = async (params) => {
  const response = await axios.post(
    VITE_API_URL +
      `ActivateMasterData?seq=${params.seq}&tableName=${params.tableName}`,
    null,
    {
      headers: authHeader(),
    }
  );
  if (response.data.status == 1 && response.data.message.includes('REFERENCE'))
    return "This Record is being used by other Module(s)-Record can't be Inactivated.";
  return response.data.message;
};

export const saveAdminData = async (params) => {
  const response = await axios.post(
    VITE_API_URL + `SaveMasterData?tableName=${params.tableName}`,
    [params.values],
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

export const MMDDYYYY = (dateParam) => {
  const dt = new Date(dateParam);
  const date = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
  const month =
    dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1) : dt.getMonth() + 1;
  return `${month}/${date}/${dt.getFullYear()}`;
};

export const getSecurityQuestions = async (userName) => {
  const requestBody = {
    userName,
  };
  try {
    let res = await axios.post(
      VITE_API_URL + 'UserCredentials/forgotpassword',
      requestBody,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const getDynamicMenu = async (userSequence) => {
  try {
    const dynamicMenu = await axios.get(
      `${import.meta.env.VITE_API_URL}user/getdynamicmenu?userSeq=${userSequence}`
    );
    return dynamicMenu;
    setMenu(dynamicMenu?.data?.data?.dynamicMenu?.[0]?.children);
  } catch (error) {
    console.log('error', error);
  }
};
