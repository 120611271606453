import React, { useMemo } from 'react';
import { Autocomplete, Button, Checkbox, TextField, Typography, useTheme } from '@mui/material';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useAuth } from '../utils/auth/AuthService';

export interface Jurisdiction {
  jdxName: string;
  isSelectAll?: boolean;
}

type SetSelectedJurisdictions =
  | ((newValue: Jurisdiction[]) => void)
  | ((event: React.ChangeEvent<any> | null, data: Jurisdiction[]) => void);

interface JurisdictionAutocompleteProps {
  selectedJurisdictions: Jurisdiction[];
  setSelectedJurisdictions: SetSelectedJurisdictions;
}

export default function JurisdictionAutocomplete({
  selectedJurisdictions,
  setSelectedJurisdictions,
}: JurisdictionAutocompleteProps) {
  const { user } = useAuth();
  const theme = useTheme();

  // Memoize and sort the jurisdictions from the auth service (if available)
  const sortedJurisdictions = useMemo<Jurisdiction[]>(() => {
    return user?.jdxAccessList && Array.isArray(user.jdxAccessList)
      ? [...(user.jdxAccessList as Jurisdiction[])].sort((a, b) =>
          a.jdxName.localeCompare(b.jdxName)
        )
      : [];
  }, [user?.jdxAccessList]);

  // Add a dummy option for "Select All" at the top of the options list.
  const options = useMemo<Jurisdiction[]>(() => {
    return [{ jdxName: 'Select All', isSelectAll: true }, ...sortedJurisdictions];
  }, [sortedJurisdictions]);

  // Helper to call the setter with the correct signature.
  const updateSelectedJurisdictions = (newSelection: Jurisdiction[]) => {
    if ((setSelectedJurisdictions as Function).length === 1) {
      (setSelectedJurisdictions as (newValue: Jurisdiction[]) => void)(newSelection);
    } else {
      (
        setSelectedJurisdictions as (
          event: React.ChangeEvent<any> | null,
          data: Jurisdiction[]
        ) => void
      )(null, newSelection);
    }
  };

  const handleSelectAndDeselectAllJurisdictions = () => {
    if (selectedJurisdictions.length === sortedJurisdictions.length) {
      updateSelectedJurisdictions([]);
    } else {
      updateSelectedJurisdictions(sortedJurisdictions);
    }
  };

  const handleChange = (
    event: React.SyntheticEvent,
    value: Jurisdiction[],
    reason: string,
    details?: { option: Jurisdiction }
  ) => {
    // Check if the dummy "Select All" option was clicked.
    if (details?.option?.isSelectAll) {
      handleSelectAndDeselectAllJurisdictions();
      return;
    }
    // Filter out any dummy option (if present) from the selection.
    const filteredValue = value.filter(option => !option.isSelectAll);
    updateSelectedJurisdictions(filteredValue);
  };

  return (
    <Autocomplete<Jurisdiction>
      multiple
      options={options}
      value={selectedJurisdictions}
      disableCloseOnSelect
      size='small'
      getOptionLabel={option => (option.isSelectAll ? '' : option.jdxName)}
      onChange={handleChange}
      renderOption={(props, option, { selected }) => {
        if (option.isSelectAll) {
          return (
            <li {...props}>
              <Button
                fullWidth
                sx={{
                  backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#cccccc',
                  color: theme.palette.text.primary,
                  p: 1,
                  boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)',
                }}
                onClick={e => {
                  e.stopPropagation();
                  handleSelectAndDeselectAllJurisdictions();
                }}
              >
                {selectedJurisdictions.length === sortedJurisdictions.length ? (
                  <Typography variant='button'>Deselect All</Typography>
                ) : (
                  <Typography variant='button'>Select All</Typography>
                )}
              </Button>
            </li>
          );
        }
        return (
          <li {...props}>
            <Checkbox
              icon={<MdCheckBoxOutlineBlank />}
              checkedIcon={<MdOutlineCheckBox />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.jdxName}
          </li>
        );
      }}
      renderInput={params => (
        <TextField {...params} label='Jurisdictions' placeholder='Jurisdictions' />
      )}
      fullWidth
    />
  );
}
