import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import ReportDataGrid from '../../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ELabDonorSamplesView() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext();

  const columns = [
    {
      field: 'samplenumber',
      // field: 'SAMPLENUMBER',
      headerName: 'Sample Number',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'sampledescription',
      headerName: 'Sample Description',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'samplecomment',
      headerName: 'Comment',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'samplecollectiondate',
      headerName: 'Collected On',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: params => {
        if (!params.value) {
          return <span></span>;
        }
        const dateObj = new Date(params.value);
        const formattedDate = dateObj.toLocaleDateString(); // formats date based on locale, adjust format as needed
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: 'labduedate',
      headerName: 'Lab Due Date',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      // renderCell: (params) => {
      //   const dateObj = new Date(params.value);
      //   const formattedDate = dateObj.toLocaleDateString(); // formats date based on locale, adjust format as needed
      //   return <span>{formattedDate}</span>;
      // }
    },
    {
      field: 'containerid',
      headerName: 'Container ID',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    // {
    //   field: 'evidencequantity',
    //   headerName: 'Quantity',
    //   flex: 1,
    //   minWidth: 150,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    // {
    //     field: 'evidencequantityunits',
    //     headerName: 'Units',
    //     flex: 1,
    //     minWidth: 150,
    //     editable: false,
    //     align: 'center',
    //     headerAlign: 'center',
    //     headerClassName: 'header-theme',
    // },
  ];

  useEffect(() => {
    if (formik.values != null) {
      console.log('the formik?, ', formik.values.samplelist);
      setlistresults(formik.values.samplelist);
      console.log('list results: ', listresults);
    }
    console.log(formik.values);
  }, [formik.values]);

  return (
    <ReportDataGrid
      loading={loading}
      idcolumn='samplenumber'
      listresults={listresults ?? []}
      columnsInput={columns}
      title='Specimens / Samples'
    />
  );
}
