import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import '../../../App.css';
import CaseNotes from '../../../components/CaseNotes';
import CaseDocuments from '../../../components/CaseRecords/CaseDocuments';
import CondensedDataGrid from '../../../components/CondensedDataGrid';
import authHeader from '../../../services/auth-header';
import { useAuth } from '../../../utils/auth/AuthService';
import { HL7_FHIR_Export_Button } from '../../../utils/components/HL7_FHIR_Export_Button';
import { MEAction } from '../../../utils/constants/MEAction';
import options from '../../../utils/constants/options';

const MyTableRow = styled(TableRow)(({ theme }) => ({
  height: 'auto',
  padding: '2px 5px',
}));

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '2px 5px',
}));

export default function SummaryView() {
  const [caseVisitHistory, setCaseVisitHistory] = useState([]);
  const [caseUpdatedInfo, setCaseUpdatedInfo] = useState([]);
  const [caseTransferInfo, setCaseTransferInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const { VITE_API_URL } = import.meta.env;
  const { user } = useAuth();
  const formik = useFormikContext();
  const theme = useTheme();
  const hasSecAdminRole = user.roleCheck(['SEC']);
  const [stateNames, setStateNames] = useState([]);
  console.log(formik.values);
  const bodyLocationStateSeq = formik.values?.caseInvestigations?.bodyLocation?.[0]?.stateSeq;

  const getStateNameBySeq = stateSeq => {
    const state = stateNames.find(item => item.stateSeq === stateSeq);

    return state ? state.stateName : '';
  };

  const nokInformants =
    formik.values?.caseDemographics?.informantList?.filter(
      informant => informant.isActive === 1 && informant.nextOfKin === 1
    ) || [];

  const requestedDocuments = formik.values?.caseRecords?.chargeableRequests;
  const requester = requestedDocuments?.requester;

  const caseVisitHistoryColumns = [
    {
      field: 'userName',
      headerName: 'User',
      flex: 1,
      editable: false,
      align: 'Left',
      headerAlign: 'Left',
    },
    {
      field: 'ip',
      headerName: 'IP',
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'Left',
    },
    {
      field: 'visitTime',
      headerName: 'Visit Time',
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'Left',
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : '';
      },
    },
  ];

  const selectedMeAction = formik.values.caseInvestigations?.meAction?.optionSeq?.toUpperCase();
  const isReadyForCheckOut = () => {
    const hasAcceptedIdentification = formik.values?.caseDemographics?.identifications?.some(
      identification => identification.isAccept === true
    );
    const isExamCompleted =
      selectedMeAction === MEAction.Constants.Disposition.Accepted &&
      formik.values?.caseMortuary?.examEndDateTime !== null;
    const isStorageOnly =
      selectedMeAction === MEAction.Constants.Disposition.StorageOnly &&
      formik.values?.caseMortuary?.checkInDate !== null;
    const isNotOnHold = formik.values?.caseMortuary?.isHold !== true;

    return hasAcceptedIdentification && (isExamCompleted || isStorageOnly) && isNotOnHold;
  };

  const fetchCaseVisitHistory = async () => {
    setLoading(true);
    await fetch(
      VITE_API_URL + 'GetCaseVisitHistory?CaseSeq=' + formik.values?.caseSummary?.caseSeq,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      }
    )
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        if (data.message != null) {
          alert(data.message);
        } else {
          setCaseVisitHistory(data);
        }
      })
      .catch(e => {
        alert(e);
      });

    setLoading(false);
  };

  const AgeBirthDate = () => {
    if (
      !formik.values?.caseSummary?.decedentInfo?.age &&
      !formik.values?.caseSummary?.decedentInfo?.birthTime
    ) {
      return null;
    } else if (!formik.values?.caseSummary?.decedentInfo?.age) {
      return new Date(formik.values?.caseSummary?.decedentInfo?.birthTime).toLocaleDateString();
    } else if (!formik.values?.caseSummary?.decedentInfo?.birthTime) {
      return (
        formik.values?.caseSummary?.decedentInfo?.age +
        ' ' +
        formik.values?.caseSummary?.decedentInfo?.ageUnit
      );
    } else {
      return (
        formik.values?.caseSummary?.decedentInfo?.age +
        ' ' +
        formik.values?.caseSummary?.decedentInfo?.ageUnit +
        ' - (' +
        new Date(formik.values?.caseSummary?.decedentInfo?.birthTime).toLocaleDateString() +
        ')'
      );
    }
  };

  const fetchCaseUpdatedInfo = async () => {
    await fetch(
      VITE_API_URL + 'getcaseupdatedinfo?CaseSeq=' + formik.values?.caseSummary?.caseSeq,
      {
        method: 'GET',
      }
    )
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        if (data.message != null) {
          alert(data.message);
        } else {
          setCaseUpdatedInfo(data);
        }
      })
      .catch(e => {
        alert(e);
      });
  };

  function formatDateTime(dateTimeString, includeTime = true) {
    // console.log(dateTimeString);
    // console.log(includeTime);
    // Check if dateTimeString is null, undefined, or empty
    if (!dateTimeString) {
      return '--'; // Return an empty string or a placeholder text
    }

    let options;
    if (includeTime) {
      options = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        // hour12: 'False', // remove this and replace with hour cycle for 24 hr display
        hourCycle: 'h23',
      };
    } else {
      options = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      };
    }
    return new Date(dateTimeString).toLocaleString('en-US', options);
  }

  const fetchCaseTransferInfo = async () => {
    await fetch(
      VITE_API_URL + 'GetCaseTransferInfo?CaseSeq=' + formik.values?.caseSummary?.caseSeq,
      {
        method: 'GET',
      }
    )
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        if (data.message != null) {
          alert(data.message);
        } else {
          setCaseTransferInfo(data);
        }
      })
      .catch(e => {
        // alert(e);
      });
  };

  const fetchStateNames = async () => {
    axios
      .get(VITE_API_URL + 'GetMasterData?tableName=state', {
        headers: authHeader(),
      })
      .then(response => {
        setStateNames(response?.data?.data || []);
      });
  };

  useEffect(() => {
    fetchCaseVisitHistory();
    fetchCaseUpdatedInfo();
    fetchCaseTransferInfo();
    fetchStateNames();
  }, []);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2} sx={{ mb: 2, width: '100%' }}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Table>
                <TableHead>
                  <MyTableRow>
                    <MyTableCell
                      sx={{ fontSize: '1.25rem', pb: 1, color: theme.palette.primary.light }}
                    >
                      DEMOGRAPHICS
                    </MyTableCell>
                    <MyTableCell></MyTableCell>
                  </MyTableRow>
                </TableHead>
                <TableBody>
                  <MyTableRow>
                    <MyTableCell>Name:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.decedentName}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Birthdate</MyTableCell>
                    <MyTableCell>
                      {formatDateTime(formik.values?.caseSummary?.decedentInfo?.birthDate, false)} (
                      {formik.values?.caseSummary?.decedentInfo?.age}{' '}
                      {formik.values?.caseSummary?.decedentInfo?.ageUnit}){/* <AgeBirthDate /> */}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Gender:</MyTableCell>
                    <MyTableCell>{formik.values?.caseSummary?.decedentInfo?.gender} </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Race:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.ethnicityRace}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Address:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.residence?.addressLine1 || ''}
                      {formik.values?.caseSummary?.decedentInfo?.residence?.addressLine1
                        ? formik.values?.caseSummary?.decedentInfo?.residence?.cityTown
                          ? ', '
                          : ''
                        : ''}
                      {formik.values?.caseSummary?.decedentInfo?.residence?.cityTown || ''}{' '}
                      {formik.values?.caseSummary?.decedentInfo?.residence?.state}{' '}
                      {formik.values?.caseSummary?.decedentInfo?.residence?.zipCode}
                    </MyTableCell>
                  </MyTableRow>

                  {/* <MyTableRow>
                    <MyTableCell>City:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.residence?.cityTown}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>State:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.residence?.state}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Zipcode:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.residence?.zipCode}
                    </MyTableCell>
                  </MyTableRow> */}

                  <MyTableRow>
                    <MyTableCell>Marital Status:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.maritalStatus}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Pregnancy Status:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.pregnancyStatus}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Veteran Status:</MyTableCell>
                    <MyTableCell>
                      {options.find(
                        option =>
                          option.value === formik.values?.caseSummary?.decedentInfo?.veteranStatus
                      )?.optionName || null}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Occupation:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.decedentInfo?.occupation}
                    </MyTableCell>
                  </MyTableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Table sx={{ mb: 6 }}>
                <TableHead>
                  <MyTableRow>
                    <MyTableCell
                      sx={{ fontSize: '1.25rem', pb: 1, color: theme.palette.primary.light }}
                    >
                      STATUS
                    </MyTableCell>
                    <MyTableCell></MyTableCell>
                  </MyTableRow>
                </TableHead>
                <MyTableRow>
                  <MyTableCell>Case Status:</MyTableCell>
                  <MyTableCell>
                    {formik.values?.caseCertification?.caseStatus === 'O'
                      ? 'Open'
                      : formik.values?.caseCertification?.caseStatus === 'C'
                      ? 'Closed'
                      : formik.values?.caseCertification?.caseStatus === 'V'
                      ? 'Void'
                      : 'Unknown'}
                  </MyTableCell>
                </MyTableRow>
                <MyTableRow>
                  <MyTableCell>Jurisdiction</MyTableCell>
                  <MyTableCell>{formik.values?.caseSummary?.caseJdx}</MyTableCell>
                </MyTableRow>
                <MyTableRow>
                  <MyTableCell>Reported (ME Notified):</MyTableCell>
                  <MyTableCell>
                    {formik.values?.caseSummary?.caseInformation?.meNotifiedDateTime}
                  </MyTableCell>
                </MyTableRow>
                <MyTableRow>
                  <MyTableCell>ME Action:</MyTableCell>
                  <MyTableCell>
                    {' '}
                    {formik.values?.caseSummary?.caseInformation?.meAction}
                  </MyTableCell>
                </MyTableRow>
                <MyTableRow>
                  <MyTableCell>Identifications:</MyTableCell>

                  <MyTableCell>{formik.values?.caseSummary?.caseInformation?.idStatus}</MyTableCell>
                </MyTableRow>

                {(selectedMeAction === MEAction.Constants.Disposition.Accepted ||
                  selectedMeAction === MEAction.Constants.Disposition.StorageOnly) && (
                  <MyTableRow>
                    <MyTableCell>Postmortem Exam:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseMortuary?.funeralHome &&
                      formik.values?.caseMortuary?.funeralHome?.optionSeq !== null
                        ? 'Checked out'
                        : isReadyForCheckOut()
                        ? `Ready for Checkout - ${formik.values?.caseMortuary?.locationID || ''}`
                        : formik.values?.caseMortuary?.checkInDate
                        ? 'Checked-in'
                        : 'Not checked-in'}
                    </MyTableCell>
                  </MyTableRow>
                )}

                {(caseTransferInfo?.toCaseID || caseTransferInfo?.fromCaseID) && (
                  <>
                    {caseTransferInfo?.toCaseID && (
                      <MyTableRow>
                        <MyTableCell>Transferred to Jurisdiction:</MyTableCell>
                        <MyTableCell>
                          {caseTransferInfo?.toJurisdiction} {'('}
                          {caseTransferInfo?.toCaseID}
                          {')'}
                        </MyTableCell>
                      </MyTableRow>
                    )}

                    {caseTransferInfo?.fromCaseID && (
                      <MyTableRow>
                        <MyTableCell>Transferred from Jurisdiction:</MyTableCell>
                        <MyTableCell>
                          {caseTransferInfo?.fromJurisdiction} {'('}
                          {caseTransferInfo?.fromCaseID}
                          {')'}
                        </MyTableCell>
                      </MyTableRow>
                    )}

                    <MyTableRow>
                      <MyTableCell>Transferred Date:</MyTableCell>
                      <MyTableCell>
                        {' '}
                        {formatDateTime(caseTransferInfo?.transferDate, true)}
                      </MyTableCell>
                    </MyTableRow>

                    <MyTableRow>
                      <MyTableCell>Original Investigator:</MyTableCell>
                      <MyTableCell> {caseTransferInfo?.originalInvestigator}</MyTableCell>
                    </MyTableRow>

                    <MyTableRow>
                      <MyTableCell>Transfer Accepted By:</MyTableCell>
                      <MyTableCell> {caseTransferInfo?.transferAcceptedBy}</MyTableCell>
                    </MyTableRow>
                  </>
                )}
                <TableBody>
                  {/* <MyTableRow>
                    <MyTableCell>Toxicology:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.toxicologyStatus}
                    </MyTableCell>
                  </MyTableRow> */}

                  {/* 
        <MyTableRow>
            <MyTableCell>Case Intake:</MyTableCell>
            <MyTableCell>{formik.values?.caseSummary?.caseStatus}</MyTableCell>
        </MyTableRow>
        <MyTableRow>
            <MyTableCell>Daily Case List:</MyTableCell>
            <MyTableCell>{formik.values?.caseSummary?.caseInformation.dailyCaseListStatus}</MyTableCell>
        </MyTableRow> 
        */}
                  {/* <MyTableRow>
                    <MyTableCell>Investigations:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.investigationStatus}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Identifications:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.identificationStatus}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Livery:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.liveryStatus}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Histology:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.histologyStatus}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Lab:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.labStatus}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Pathology:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.pathologyStatus}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Mortuary:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.morgueStatus}
                    </MyTableCell>
                  </MyTableRow> */}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Table>
                <TableHead>
                  <MyTableRow>
                    <MyTableCell
                      sx={{
                        fontSize: '1.25rem',
                        pb: 1,
                        color: theme.palette.primary.light,
                        width: 'max-content',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      CASE DETAILS
                    </MyTableCell>
                    <MyTableCell></MyTableCell>
                  </MyTableRow>
                </TableHead>

                <TableBody>
                  <MyTableRow>
                    <MyTableCell>MLDI Synopsis</MyTableCell>
                    <MyTableCell>{formik.values?.caseSummary?.caseSynopsis}</MyTableCell>
                  </MyTableRow>
                  <MyTableRow>
                    <MyTableCell>Investigator:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.assignedInvestigator}
                    </MyTableCell>
                  </MyTableRow>
                  <MyTableRow>
                    <MyTableCell>Reason for Investigation:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseInvestigations?.investigationReason?.optionName}
                    </MyTableCell>
                  </MyTableRow>

                  {selectedMeAction === MEAction.Constants.Disposition.Release && (
                    <MyTableRow>
                      <MyTableCell>Reason for Release:</MyTableCell>
                      <MyTableCell>
                        {formik.values?.caseInvestigations?.releaseReason?.optionName}
                      </MyTableCell>
                    </MyTableRow>
                  )}

                  {selectedMeAction === MEAction.Constants.Disposition.Facilitated_Release && (
                    <MyTableRow>
                      <MyTableCell>Reason for Facilitated:</MyTableCell>
                      <MyTableCell>
                        {formik.values?.caseInvestigations?.facilitatedReason?.optionName}
                      </MyTableCell>
                    </MyTableRow>
                  )}

                  {selectedMeAction === MEAction.Constants.Disposition.Transfer && (
                    <MyTableRow>
                      <MyTableCell>Reason for Transfer:</MyTableCell>
                      <MyTableCell>
                        {formik.values?.caseInvestigations?.transferReason?.optionSeq?.toUpperCase() ===
                        MEAction.Constants.TransferReason.Other
                          ? formik.values?.caseInvestigations?.otherTransferReason
                          : formik.values?.caseInvestigations?.transferReason?.optionName}
                      </MyTableCell>
                    </MyTableRow>
                  )}

                  <MyTableRow>
                    <MyTableCell>Pathologist:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.physician}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Exam Type:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.typeOfExam}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Exam Date:</MyTableCell>
                    <MyTableCell>
                      {formatDateTime(formik.values?.caseSummary?.caseInformation?.examEndDateTime)}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Manner of Death:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.mannerOfDeath}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Cause of Death:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.causeOfDeath}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Contributory:</MyTableCell>
                    <MyTableCell>{formik.values?.casePathology.contributory}</MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Drug Toxicity:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.casePathology?.drugToxicityConfirmed ? 'Yes' : 'No'}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Facility Type:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.facilityType}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Facility Name:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.facilityName}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Location of Body:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.blAddress}
                      {formik.values?.caseSummary?.caseInformation?.blAddress
                        ? formik.values?.caseInvestigations?.bodyLocation?.[0]?.city
                          ? ', '
                          : ''
                        : ''}
                      {formik.values?.caseInvestigations?.bodyLocation?.[0]?.city || ''}{' '}
                      {formik.values?.caseInvestigations?.bodyLocation?.[0]?.stateSeq
                        ? getStateNameBySeq(
                            formik.values?.caseInvestigations?.bodyLocation?.[0]?.stateSeq
                          )
                        : ' '}{' '}
                      {formik.values?.caseSummary?.caseInformation?.blZip}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Last Known to Be Alive:</MyTableCell>
                    <MyTableCell>
                      {formatDateTime(
                        formik.values?.caseInvestigations?.discovery?.lastKnownToBeAlive?.dateTime
                      )}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Place of Onset/Injury:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessAddress?.[0]
                        ?.addressLine1 || ''}
                      {formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessAddress?.[0]
                        ?.addressLine1
                        ? formik.values?.caseInvestigations?.discovery
                            ?.foundOnsetIllnessAddress?.[0]?.city
                          ? ', '
                          : ''
                        : ''}
                      {formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessAddress?.[0]
                        ?.city || ''}{' '}
                      {formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessAddress?.[0]
                        ?.stateSeq
                        ? getStateNameBySeq(
                            formik.values?.caseInvestigations?.discovery
                              ?.foundOnsetIllnessAddress?.[0]?.stateSeq
                          )
                        : ' '}{' '}
                      {
                        formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessAddress?.[0]
                          ?.zip
                      }
                    </MyTableCell>
                  </MyTableRow>

                  {/* <MyTableRow>
                    <MyTableCell>Discovery:</MyTableCell>
                    <MyTableCell>
                    {formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessByType === 'F'
                      ? 'Found'
                      : formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessByType === 'O'
                      ? 'Injury Observed'
                      : formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessByType === 'I'
                      ? 'Onset of Illness'
                      : 'Category Not Selected'}
                      {' '}
                      {formatDateTime(
                        formik.values?.caseInvestigations?.discovery?.foundOnsetIllnessBy?.dateTime
                      )}
                    </MyTableCell>
                  </MyTableRow> */}

                  <MyTableRow>
                    <MyTableCell>Law Enforcement Notified:</MyTableCell>
                    <MyTableCell>
                      {formatDateTime(
                        formik.values?.caseInvestigations?.discovery?.policeNotifiedBy?.dateTime
                      )}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Law Enforcement Case No:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseInvestigations?.discovery?.policeCaseNumber}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Death Certificate No.</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.edrsCaseNumber}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Lab Number:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation.labNumber}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Pronouncing Physician:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.pronouncingPhysician}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Pronouncement Date</MyTableCell>
                    <MyTableCell>
                      {formatDateTime(
                        formik.values?.caseSummary?.caseInformation?.pronouncedDateTime
                      )}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Checked out to:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.checkoutTo}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Checkout Authorized By:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseSummary?.caseInformation?.checkoutAuthorizedBy}
                    </MyTableCell>
                  </MyTableRow>

                  <MyTableRow>
                    <MyTableCell>Case Released to Physician:</MyTableCell>
                    <MyTableCell>
                      {formik.values?.caseInvestigations?.physician?.lastName || ''}
                      {formik.values?.caseInvestigations?.physician?.lastName
                        ? formik.values?.caseInvestigations?.physician?.firstName
                          ? ', '
                          : ''
                        : ''}
                      {formik.values?.caseInvestigations?.physician?.firstName || ''}{' '}
                      {
                        formik.values?.caseInvestigations?.physician?.contactItem?.[0]
                          ?.contactItemDetails
                      }
                    </MyTableCell>
                  </MyTableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Table>
                <TableHead>
                  <MyTableRow>
                    <MyTableCell
                      sx={{ fontSize: '1.35rem', pb: 1, color: theme.palette.primary.light }}
                    >
                      NEXT OF KIN
                    </MyTableCell>
                    <MyTableCell></MyTableCell>
                    <MyTableCell></MyTableCell>
                  </MyTableRow>
                </TableHead>

                <TableBody>
                  {nokInformants.length > 0 ? (
                    <>
                      {/* <MyTableRow>
                              <MyTableCell component="th">Name</MyTableCell>
                              <MyTableCell component="th">Relationship</MyTableCell>
                              <MyTableCell component="th">Contact Details</MyTableCell>
                            </MyTableRow> */}
                      {nokInformants.map((informant, informant_index) => (
                        <MyTableRow key={informant_index}>
                          <MyTableCell>
                            {informant?.personLastName || ''}
                            {informant?.personLastName
                              ? informant?.personFirstName
                                ? ', '
                                : ''
                              : ''}
                            {informant?.personFirstName || ''}
                          </MyTableCell>
                          <MyTableCell>{informant?.relship?.optionName}</MyTableCell>
                          <MyTableCell>
                            {informant.informantAddress?.contactItem?.map(
                              (contact, contact_index) => (
                                <div key={contact_index}>{contact?.contactItemDetails}</div>
                              )
                            )}
                          </MyTableCell>
                        </MyTableRow>
                      ))}
                    </>
                  ) : (
                    <MyTableRow>
                      <MyTableCell>No NOK</MyTableCell>
                      <MyTableCell></MyTableCell>
                      <MyTableCell></MyTableCell>
                    </MyTableRow>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <br />
          {requestedDocuments?.length > 0 && (
            <Card>
              <CardContent>
                <Table>
                  <TableHead>
                    <MyTableRow>
                      <MyTableCell
                        sx={{ fontSize: '.7rem', pb: 1, color: theme.palette.primary.light }}
                      >
                        REQUESTED FILES
                      </MyTableCell>
                      <MyTableCell></MyTableCell>
                      <MyTableCell></MyTableCell>
                      <MyTableCell></MyTableCell>
                    </MyTableRow>
                  </TableHead>

                  <TableBody>
                    {requestedDocuments?.map((request, request_index) => (
                      <MyTableRow key={request_index}>
                        <MyTableCell>
                          {request?.requester?.[0]?.personLastName || ''}
                          {request?.requester?.[0]?.personLastName
                            ? request?.requester?.[0]?.personFirstName
                              ? ', '
                              : ''
                            : ''}
                          {request?.requester?.[0]?.personFirstName || ''}
                        </MyTableCell>

                        <MyTableCell>
                          {request?.requestedDocuments?.map((document, document_index) => (
                            <div key={document_index}>{document?.itemRequested?.optionName}</div>
                          ))}
                        </MyTableCell>

                        <MyTableCell>
                          {request?.requestedDocuments?.map((date_request, date_request_index) => (
                            <div key={date_request_index}>
                              Requested: {formatDateTime(date_request?.dateRequested, false)}
                            </div>
                          ))}
                        </MyTableCell>

                        <MyTableCell>
                          {request?.requestedDocuments?.map((date_sent, date_sent_index) => (
                            <div key={date_sent_index}>
                              {date_sent?.dateSent ? (
                                <>Sent: {formatDateTime(date_sent?.dateSent, false)}</>
                              ) : (
                                <>Not Sent</>
                              )}
                            </div>
                          ))}
                        </MyTableCell>
                      </MyTableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Typography variant='subtitle1' color='gray'>
          <strong>CASE NOTES </strong>
        </Typography>
        <br />
      </Grid>

      <Grid item xs={12}>
        <CaseNotes />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Typography variant='subtitle1' color='gray'>
          <strong>DOCUMENTS </strong>
        </Typography>
        <br />
      </Grid>
      <Grid item xs={12}>
        <>
          <CaseDocuments caseinfo={formik.values?.caseSummary} />
        </>
      </Grid>
      {/* todo: restrictt to the CMS-ADMIN role */}
      {/* <CaseViewFieldsContainer>
        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
          <Typography variant='subtitle1' color='gray'>
            <strong>CASE ACTIVITY LOG </strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TransportLogDataGrid />
        </Grid>
      </CaseViewFieldsContainer> */}
      {user.roleCheck(['2d0']) && (
        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3 }} />
          <HL7_FHIR_Export_Button />
        </Grid>
      )}
      {/* SECURITY-ADMIN ROLE */}
      {hasSecAdminRole && (
        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Case Viewed By</strong>
          </Typography>
          <br />
          <CondensedDataGrid
            loading={loading}
            listresults={caseVisitHistory}
            columnsInput={caseVisitHistoryColumns}
            idcolumn='caseVisitHistorySeq'
            gridheight={caseVisitHistory?.length > 0 ? 'auto' : 80}
            gridcolor='#006400'
            title='Case Viewed By'
            tooltip='Case Visit History'
            rowsperpage={10}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: 'row', marginTop: '1rem', gap: '1rem' }}
      >
        <Typography
          variant='body1'
          noWrap
          sx={{ fontSize: '0.8rem' }} // Customizable font size
        >
          Created: {caseUpdatedInfo?.createdOn}
        </Typography>
        <Typography
          variant='body1'
          noWrap
          sx={{ fontSize: '0.8rem' }} // Customizable font size
        >
          By: {caseUpdatedInfo?.createdBy}
        </Typography>
        <Typography
          variant='body1'
          noWrap
          sx={{ fontSize: '0.8rem' }} // Customizable font size
        >
          Updated: {caseUpdatedInfo?.lastUpdatedOn}
        </Typography>
        <Typography
          variant='body1'
          noWrap
          sx={{ fontSize: '0.8rem' }} // Customizable font size
        >
          By: {caseUpdatedInfo?.lastUpdatedBy}
        </Typography>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: formik.values?.caseSummary.jdxLogo }}
            style={{
              width: '100%',
              height: '100%',
              maxWidth: '250px',
              maxHeight: '150px',
            }}
          />
        </Box>
      </Grid>
    </Box>
  );
}
