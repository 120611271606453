import { LocalShippingRounded, People } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import CustomDrawer from '../../utils/components/CustomDrawer';
import CustomHeader from '../../utils/components/CustomHeader';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import { AllRoles } from '../../utils/constants/AllRoles';
import { LiveryCheckinView } from '../Livery/LiveryCheckInView';
import YourPreferencesView from './YourProfileModules/PreferencesView/YourPreferencesView';
import UserProfileDetailsView from './YourProfileModules/UserProfileDetailsView';
import YourCasesView from './YourProfileModules/YourCasesView';
import YourReportsView from './YourProfileModules/YourReportsView';

export default function YourProfileView() {
  const auth = useAuth();
  const hasAccessToLivery = auth.user?.roleCheck([AllRoles.Livery]) ?? false;

  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleTabChange = (event: any, newTab: any) => {
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const drawerContents = [
    { text: 'Your Profile', icon: <PersonIcon /> },
    { text: 'Your Cases', icon: <People /> },
    ...(hasAccessToLivery ? [{ text: 'Your Transport', icon: <LocalShippingRounded /> }] : []),
    { text: 'Reports', icon: <AssessmentIcon /> },
    { text: 'Preferences', icon: <SettingsIcon /> },
  ];

  return (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {/* Mobile and Desktop Drawer*/}
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      {/* Page Contents */}
      <Box
        component='section'
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {/* @ts-ignore */}
        <CustomTabPanel value={currentTab} index={0} sx={{ margin: 0 }}>
          {/* @ts-ignore */}
          <CustomHeader
            title='User Profile Details'
            description='Configure your user information, documents and security questions'
            handleMenuClick={handleMenuClick}
          />
          <UserProfileDetailsView />
        </CustomTabPanel>

        {/* @ts-ignore */}
        <CustomTabPanel value={currentTab} index={1} sx={{ margin: 0 }}>
          {/* @ts-ignore */}
          <CustomHeader
            title='Your Case History'
            description='View all your cases, details and statistics'
            handleMenuClick={handleMenuClick}
          />
          <YourCasesView />
        </CustomTabPanel>
        {/* @ts-ignore */}
        {hasAccessToLivery && (
          //  @ts-ignore
          <CustomTabPanel value={currentTab} index={2} sx={{ margin: 0 }}>
            {/* @ts-ignore */}
            <CustomHeader
              title='Your Transport'
              description='Manage transport assignments (under construction)'
              handleMenuClick={handleMenuClick}
            />
            <LiveryCheckinView />
          </CustomTabPanel>
        )}
        {/* @ts-ignore */}
        <CustomTabPanel value={currentTab} index={hasAccessToLivery ? 3 : 2} sx={{ margin: 0 }}>
          {/* @ts-ignore */}
          <CustomHeader
            title='Reports'
            description='Create quick assessments based on your User data'
            handleMenuClick={handleMenuClick}
          />
          <YourReportsView />
        </CustomTabPanel>
        {/* @ts-ignore */}
        <CustomTabPanel value={currentTab} index={hasAccessToLivery ? 4 : 3} sx={{ margin: 0 }}>
          {/* @ts-ignore */}
          <CustomHeader
            title='Your Preferences'
            description='Set your app preferences here'
            handleMenuClick={handleMenuClick}
          />
          <YourPreferencesView />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
