import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../Context/GlobalContext';
import authHeader from '../../services/auth-header';
import { fetchDepartment, fetchOperationMode } from '../../services/utility-api';
import { useAuth } from '../../utils/auth/AuthService';
import { SPECIAL_PRIVILEGE, TITLE } from '../../views/CMSAdmin/Menu/constants';
import ControlComponents from '../../views/SysAdmin/SysAdminModules/UserAdminModules/ControlComponents';
import ApiNotification from '../DialogBox';
import Signature from './ESignature';
import Takephoto from './TakePhotoModules/Takephoto';
import { UnlockAccountButton } from './UnlockAccountButton';
import { ClearUserSessionButton } from './ClearUserSessionButton';

const UserForm = ({
  title,
  userName,
  firstName,
  lastName,
  middleName,
  suffix,
  primaryEmail,
  primaryCellularNumber,
  initials,
  department,
  operationMode,
  organization,
  userBarCode,
  specialPrivilege,
  handlePrimaryMobileNumber,
  primaryMobileNo,
  userid = '',
}) => {
  const [departmentDropDown, setDepartmentDropDown] = useState([]);
  const [operationModeDropDown, setOperationModeDropDown] = useState([]);
  const [organizationDropDown, setOrganizationDropDown] = useState([]);
  const [photoModal, setPhotoModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  // const [primaryMobileNo, setPrimaryMobileNo] = useState(primaryCellularNumber);
  const { profileInformation, sendPhotoToViewModal, signature } = useContext(GlobalContext);
  const [viewPhotoModal, setViewPhotoModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState('');

  const [securityQuestionModal, setSecurityQuestionModal] = useState(false);
  const [securityMessage, setSecurityMessage] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState(false);
  const [confirmationSecurityQuestion, setConfirmationSecurityQuestion] = useState(false);
  const [confirmChangePass, setConfirmChangePass] = useState(false);
  const auth = useAuth();
  const { user } = useAuth();
  const path = window.location.pathname.includes('yourprofile');
  console.log('auth', auth);
  const navigate = useNavigate();
  const getActiveOrg = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}GetMasterData?tableName=Origin`,
        {
          headers: authHeader(),
        }
      );
      const roles = response?.data?.data
        ?.map(role => ({
          status: role?.status,
          name: role?.originName,
          value: role?.originSeq,
        }))
        .filter(role => role?.status === 'Active');
      setOrganizationDropDown(roles);
    } catch (error) {}
  };
  useEffect(() => {
    fetchDepartment().then(response => setDepartmentDropDown(response));
    fetchOperationMode().then(response => setOperationModeDropDown(response));
    getActiveOrg();
    // fetchOrigin().then((response) => setOrganizationDropDown(response));
  }, []);

  const handleClose = () => {
    setPhotoModal(false);
  };

  const closeSignature = () => {
    setSignatureModal(false);
  };

  const showSignature = () => {
    if (signature) {
      console.log('signature', signature);
      return (
        <Grid item lg={6} xs={12} md={6} sm={12}>
          <div>
            <div>
              <img
                src={URL.createObjectURL(signature)}
                alt='Signature'
                style={{ height: '40px' }}
              />
            </div>
          </div>
        </Grid>
      );
    } else if (profileInformation?.eSignature) {
      const image = new Image();
      image.src = 'data:image/png;base64,' + profileInformation?.eSignature;
      return (
        <Grid item lg={6} xs={12} md={6} sm={12}>
          <div>
            <div>
              <img src={image.src} alt='Signature' style={{ height: '40px' }} />
            </div>
          </div>
        </Grid>
      );
    }
  };

  const handleMobile = (e, index) => {
    const input = e.target.value;
    let formattedNumber = '';
    const digitsOnly = input.replace(/\D/g, '');
    if (digitsOnly.length == 0) formattedNumber = '';
    else if (digitsOnly.length < 4) formattedNumber = `(${digitsOnly.slice(0, digitsOnly.length)}`;
    else if (digitsOnly.length < 7)
      formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, digitsOnly.length)}`;
    else
      formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(
        6,
        10
      )}`;
    setPrimaryMobileNo(formattedNumber);
    handlePrimaryMobileNumber(formattedNumber);
  };

  const handlePassword = async () => {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}user/generatetemporarypassword`,
      {
        userSeq: profileInformation?.userSeq,
        securityQuestionSeq: '00000000-0000-0000-0000-000000000000',
        securityAnswer: '',
      },
      { headers: authHeader() }
    );

    if (response.data.status === 0) {
      setPasswordModal(true);
      setPasswordMessage(response.data.message);
    }
  };

  const handleSecurityQuestion = async () => {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}ResetSecurityQuestion`,
      { userSeq: profileInformation?.userSeq },
      { headers: authHeader() }
    );
    if (response.data.status === 0) {
      setSecurityQuestionModal(true);
      setSecurityMessage(response.data.message);
    }
  };
  const handlePassChange = () => {
    const userDetails = { ...user };
    const AUTH_TOKEN = localStorage.getItem('auth_token');
    const ACCESS_TOKEN = localStorage.getItem('access_token');
    auth.authDispatch({ type: 'RESET_AUTH' });
    // auth.userDispatch({ type: 'CLEAR_USER' });
    auth.userDispatch({
      type: 'SET_USER',
      payload: {
        userName: userDetails?.userName,
        userSeq: userDetails?.userSeq,
        token: ACCESS_TOKEN,
        authToken: AUTH_TOKEN,
        accessToken: null,
        refreshToken: null,
      },
    });
    navigate('/login/forgot-password/change');
  };

  useEffect(() => {
    console.log('userName', userName);
  }, [userName]);
  return (
    <Box>
      <h5>User</h5>
      <Grid container spacing={2}>
        {/* <Grid item lg={12} xs={12} md={12} sm={12}>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
            <UserAvatar sx={{ height: 60, width: 60 }} />
            <Stack spacing={0.5} justifyContent='center'>
              <Typography variant='h3' fontWeight='bold'>
                {auth.user.userFirstName} {auth.user.userLastName}
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                {auth.user.userName}
              </Typography>
            </Stack>
          </Stack>
        </Grid> */}
        <Grid item lg={4} xs={12} md={12} sm={12}>
          <ControlComponents control='input' name={firstName} label='First name*' type='text' />
        </Grid>
        <Grid item lg={4} xs={12} md={12} sm={12}>
          <ControlComponents control='input' name={middleName} label='Middle name' type='text' />
        </Grid>
        <Grid item lg={4} xs={12} md={12} sm={12}>
          <ControlComponents
            control='input'
            name={lastName}
            label='Last name'
            type='text'
            required={true}
          />
        </Grid>
        <Grid item lg={4} xs={12} md={12} sm={12}>
          <ControlComponents
            control='input'
            name={userName}
            label='UserID'
            type='text'
            disabled={path}
          />
        </Grid>

        <Grid item lg={4} xs={12} md={12} sm={12}>
          <ControlComponents
            control='input'
            name={primaryEmail}
            label='Email'
            type='email'
            required={true}
          />
        </Grid>
        <Grid item lg={4} xs={12} md={12} sm={12}>
          <FormControl component='fieldset' variant='standard' fullWidth>
            {/* <TextField
              // focused
              type='text'
              id='outlined-basic'
              label='Primary Mobile Number'
              variant='outlined'
              value={primaryMobileNo}
              onChange={handleMobile}
              size='large'
                          /> */}
            <ControlComponents
              control='input'
              name={primaryMobileNo}
              label='Phone number'
              type='tel'
            />
          </FormControl>
        </Grid>

        <Grid item lg={3} xs={12} md={12} sm={12}>
          <ControlComponents
            control='select'
            name={title}
            label='Title'
            options={TITLE}
            type='select'
          />
        </Grid>
        <Grid item lg={3} xs={12} md={12} sm={12}>
          <ControlComponents control='input' name={suffix} label='Suffix' type='text' />
        </Grid>
        <Grid item lg={3} xs={12} md={12} sm={12}>
          <ControlComponents
            control='input'
            name={initials}
            label='Initials*'
            type='text'
            disabled={path}
          />
        </Grid>
        <Grid item lg={3} xs={12} md={12} sm={12}>
          <ControlComponents
            control='input'
            name={userBarCode}
            label='User Barcode'
            disabled={path}
          />
        </Grid>
        <Grid item lg={3} xs={12} md={12} sm={12}>
          <ControlComponents
            control='select'
            name={organization}
            label='Organization*'
            options={organizationDropDown}
            disabled={path}
          />
        </Grid>
        <Grid item lg={3} xs={12} md={12} sm={12}>
          <ControlComponents
            control='select'
            name={department}
            label='Department*'
            options={departmentDropDown}
            disabled={path}
          />
        </Grid>
        <Grid item lg={3} xs={12} md={12} sm={12}>
          <ControlComponents
            control='select'
            name={operationMode}
            label='Operation Mode'
            options={operationModeDropDown}
            disabled={path}
          />
        </Grid>
        <Grid item lg={3} xs={12} md={12} sm={12}>
          <ControlComponents
            control='multiSelect'
            name={specialPrivilege}
            label='Special Privilege'
            options={SPECIAL_PRIVILEGE}
            module='Special Privilege'
            disabled={path}
          />
        </Grid>

        {!window?.location?.pathname.includes('yourprofile') ? (
          <>
            <Grid item lg={6} xs={12} md={12} sm={12}>
              <Button
                primary
                fullWidth
                variant='contained'
                onClick={() => setConfirmationSecurityQuestion(true)}
                disabled={!!window.location.pathname.includes('add-user')}
              >
                Reset Security Questions
              </Button>
            </Grid>
            <Grid item lg={6} xs={12} md={12} sm={12}>
              <Button
                primary
                fullWidth
                variant='contained'
                onClick={() => setConfirmationPassword(true)}
                disabled={!!window.location.pathname.includes('add-user')}
              >
                Generate Temporary Password
              </Button>
            </Grid>
            {window?.location?.pathname.includes('edit-user') && (
              <>
                <Grid item lg={6} xs={12} md={12} sm={12}>
                  <UnlockAccountButton userid={userid} />
                </Grid>
                <Grid item lg={6} xs={12} md={12} sm={12}>
                  <ClearUserSessionButton userid={userid} />
                </Grid>
              </>
            )}
          </>
        ) : null}

        <Grid item lg={3} xs={12} md={12} sm={12}>
          <Button
            primary
            fullWidth
            variant='contained'
            onClick={() => setPhotoModal(true)}
            disabled={!!window.location.pathname.includes('add-user')}
          >
            Update Profile Picture
          </Button>
        </Grid>
        {photoModal && (
          <Takephoto open={photoModal} setModal={setPhotoModal} handleClose={handleClose} />
        )}
        {/* <Grid item lg={3} xs={12} md={12} sm={12}>
                    <Button
                        primary
                        fullWidth
                        variant='contained'
                        disabled={!!window.location.pathname.includes('add-user')}
                        onClick={async () => {
                            const response = await axios.get(
                                `${import.meta.env.VITE_API_URL}GetUserPicture?userSeq=${profileInformation?.userSeq}`,
                                { headers: authHeader() }
                            );
                            if (response.data.data.length > 0) {
                                const image = new Image();
                                image.src = 'data:image/png;base64,' + response?.data?.data?.[0]?.photo;
                                sendPhotoToViewModal(image.src);
                            }
                            setViewPhotoModal(true);
                        }}
                    >
                        View Photo
                    </Button>
                    {viewPhotoModal ? (
                        <Viewphoto closeModal={() => setViewPhotoModal(false)} open={viewPhotoModal} />
                    ) : null}
                </Grid> */}
        <Grid item lg={3} xs={12} md={12} sm={12}>
          <Button
            primary
            fullWidth
            variant='contained'
            onClick={() => setSignatureModal(true)}
            disabled={!!window.location.pathname.includes('add-user')}
          >
            E-Signature
          </Button>
          {signatureModal ? (
            <Signature open={signatureModal} closeSignature={closeSignature} />
          ) : null}
          {showSignature()}
        </Grid>
        {window?.location?.pathname.includes('yourprofile') ? (
          <Grid item lg={3} xs={12} md={12} sm={12}>
            <Button
              primary
              fullWidth
              variant='contained'
              onClick={() => setConfirmChangePass(true)}
              // disabled={!!window.location.pathname.includes('add-user')}
            >
              Change Password
            </Button>
          </Grid>
        ) : null}
        {securityQuestionModal ? (
          <ApiNotification
            openApiDialogBox={securityQuestionModal}
            closeDialogBox={() => setSecurityQuestionModal(false)}
            dialogContents={securityMessage || ''}
          />
        ) : null}
        {passwordModal ? (
          <ApiNotification
            openApiDialogBox={passwordModal}
            closeDialogBox={() => setPasswordModal(false)}
            dialogContents={passwordMessage || ''}
          />
        ) : null}
      </Grid>
      {confirmationPassword ? (
        <div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to generate temporary password?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmationPassword(false)}>No</Button>
              <Button
                onClick={() => {
                  setConfirmationPassword(false);
                  handlePassword();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {confirmationSecurityQuestion ? (
        <div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to reset security question?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmationSecurityQuestion(false)}>No</Button>
              <Button
                onClick={() => {
                  setConfirmationSecurityQuestion(false);
                  handleSecurityQuestion();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {confirmChangePass ? (
        <div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm?</DialogTitle>
            <DialogContent>
              <DialogContentText>Do you want to change your password ?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmChangePass(false)}>No</Button>
              <Button
                onClick={() => {
                  setConfirmChangePass(false);
                  handlePassChange();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    </Box>
  );
};

export default UserForm;
