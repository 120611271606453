import { Box } from '@mui/material';
import { useState } from 'react';
import BulkFileUpload from '../../components/CaseRecords/BulkFileUploadModule';
import CustomHeader from '../../utils/components/CustomHeader';
import TreeDrawer from '../../utils/components/TreeDrawer';
import TreeTabPanel from '../../utils/components/TreeTabPanel';
import { HL7Uploader } from '../Resources/ResourcesModules/Tools/ToolsModules';
import BeastLEDTCasesView from './LMSModules/BeastModules/BeastLEDTCasesView';
import ELabAMCaseListView from './LMSModules/eLabModules/CaseModules/ElabAMCaseListView';
import ELabPMCaseListView from './LMSModules/eLabModules/CaseModules/ELabPMCaseListView';
import ELabAMClients from './LMSModules/eLabModules/ELabAMClients';
import ELabAMContacts from './LMSModules/eLabModules/ElabAMContacts';
import ELabAMTurnaroundTimeView from './LMSModules/eLabModules/ELabAMTurnaroundTime';
import ElabFinalCaseReview from './LMSModules/eLabModules/ElabAMReadyForReview';
import ElabInstrumentsManagement from './LMSModules/eLabModules/ElabInstrumentsManagement';
import ElabInventoryManagement from './LMSModules/eLabModules/ElabInventoryManagement';
import ELabLEDTCasesView from './LMSModules/eLabModules/ELabLEDTCasesView';
import ELabLEDTInvoicing from './LMSModules/eLabModules/ElabLEDTInvoicing';
import ElabMROReview from './LMSModules/eLabModules/ELabMROReview';
import ELabPMAgenciesView from './LMSModules/eLabModules/ElabPMAgenciesView';
import ELabPMBatchesView from './LMSModules/eLabModules/ELabPMBatchesView';
import ELabPMContactsView from './LMSModules/eLabModules/ELabPMContactsView';
import ELabPMTurnaroundTimeView from './LMSModules/eLabModules/ELabPMTurnaroundTime';
import ElabSampleEvidenceInvocing from './LMSModules/eLabModules/ElabSampleEvidenceInvoicing';
import BeastInvoicingView from './LMSModules/InvoicingModules/BeastInvoicingView';
import ElabPMReadyForReview from './LMSModules/eLabModules/ElabPMReadyForReview';
import ElabAMReadyForReview from './LMSModules/eLabModules/ElabAMReadyForReview';
import { useAuth } from '../../utils/auth/AuthService';
import CatalogView from './LMSModules/LMSCatalogView';

export default function LMSView() {
  const [currentTab, setCurrentTab] = useState('elab_pm_cases');
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useAuth();

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  // commented out items that are under construction
  const drawerContents = [
    {
      id: 'elab',
      label: 'ELAB',
      children: [
        { id: 'elab_pm_cases', label: 'PM CASES' },
        { id: 'elab_am_cases', label: 'AM CASES' },
        // { id: 'elab_ledt_cases', label: 'LEDT CASES' },
        { id: 'elab_batches', label: 'ELAB BATCHES' },
      ],
    },
    {
      id: 'clients',
      label: 'CLIENTS',
      children: [
        { id: 'elab_pm_agencies', label: 'PM AGENCIES' },
        { id: 'elab_pm_contacts', label: 'PM CONTACTS' },
        { id: 'elab_am_clients', label: 'AM CLIENTS' },
        { id: 'elab_am_contacts', label: 'AM CONTACTS' },
      ],
    },
    // {
    //   id: 'beast',
    //   label: 'BEAST',
    //   children: [{ id: 'beast_ledt_cases', label: 'LEDT CASES' }],
    // },
    {
      id: 'bulk_uploads',
      label: 'BULK UPLOAD',
    },
    {
      id: 'invoicing',
      label: 'INVOICING',
      children: [
        { id: 'elab_ledt_invoicing', label: 'ELAB LEDT INVOICING' },
        { id: 'sample_evidence_invoicing', label: 'SAMPLE EVIDENCE' },
        { id: 'beast_invoicing', label: 'BEAST INVOICING' },
      ],
    },
    ...(user?.roleCheck('MRO')
    ? [
        {
          id: 'mro_review',
          label: 'MRO REVIEW',
        },
      ]
    : []), 

    {
      id: 'final_case_review',
      label: 'FINAL CASE REVIEW',
      children: [
        { id: 'pm_final_case_review', label: 'PM FINAL CASE REVIEW' },
        { id: 'am_final_case_review', label: 'AM FINAL CASE REVIEW' },
      ],
    },
    {
      id: 'lms_admin',
      label: 'LMS ADMIN',
      children: [
        { id: 'elab_am_tat', label: 'AM TAT' },
        { id: 'elab_pm_tat', label: 'PM TAT' },
        { id: 'inventory_management', label: 'INVENTORY' },
        { id: 'lab_instrument_management', label: 'LAB INSTRUMENTS' },
        { id: 'lms_catalog', label: 'TEST CATALOG' }, 
      ],
    },
    {
      id: 'it_tools',
      label: 'IT HL-7 TOOLS',
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <TreeDrawer
        drawerContents={drawerContents}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <TreeTabPanel value={currentTab} index='elab_pm_cases' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab PM Case List'
            description='View all current eLab post-mortem cases'
            handleMenuClick={handleMenuClick}
          />
          <ELabPMCaseListView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_am_cases' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab AM Case List'
            description='View all current eLab antemortem cases'
            handleMenuClick={handleMenuClick}
          />
          <ELabAMCaseListView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_pm_agencies' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab PM Agencies'
            description='View all current Post Mortem Agencies'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ELabPMAgenciesView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_pm_contacts' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab PM Contacts'
            description='View all current PM Contacts'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ELabPMContactsView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_am_clients' sx={{ margin: 0 }}>
          <CustomHeader
            title='Antemortem Clients'
            description='View all current eLab antemortem clients'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ELabAMClients />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_am_contacts' sx={{ margin: 0 }}>
          <CustomHeader
            title='Antemortem Contacts'
            description='View all current eLab antemortem contacts'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ELabAMContacts />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_ledt_cases' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab LEDT Case List'
            description='View all current eLab LEDT cases'
            handleMenuClick={handleMenuClick}
          />
          <ELabLEDTCasesView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_batches' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab PM Specimen Batches'
            description='View all batches of eLab specimes for testing'
            handleMenuClick={handleMenuClick}
          />
          <ELabPMBatchesView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='beast_ledt_cases' sx={{ margin: 0 }}>
          <CustomHeader
            title='BEAST LEDT Cases'
            description='View and manage all BEAST LEDT cases'
            handleMenuClick={handleMenuClick}
          />
          <BeastLEDTCasesView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='beast_invoicing' sx={{ margin: 0 }}>
          <CustomHeader
            title='BEAST Invoicing'
            description='Manage invoicing for BEAST'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <BeastInvoicingView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_ledt_invoicing' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab LEDT Invoicing'
            description='Manage invoicing for eLab LEDT'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ELabLEDTInvoicing />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='sample_evidence_invoicing' sx={{ margin: 0 }}>
          <CustomHeader
            title='Sample Evidence To be Invoiced List'
            description='Manage invoicing for sample evidence'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ElabSampleEvidenceInvocing />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='pm_final_case_review' sx={{ margin: 0 }}>
          <CustomHeader
            title='Postmortem Ready For Review'
            description='Manage final postmortem (PM) cases'
            handleMenuClick={handleMenuClick}
          />
          <ElabPMReadyForReview />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='am_final_case_review' sx={{ margin: 0 }}>
          <CustomHeader
            title='Antemortem Ready For Review'
            description='Manage final antemortem (AM) cases'
            handleMenuClick={handleMenuClick}
          />
          <ElabAMReadyForReview />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='mro_review' sx={{ margin: 0 }}>
          <CustomHeader
            title='Medical Review Officer Review'
            description='Manage reviews'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ElabMROReview />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='inventory_management' sx={{ margin: 0 }}>
          <CustomHeader
            title='Inventory Management'
            description='Manage inventory'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ElabInventoryManagement />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='lab_instrument_management' sx={{ margin: 0 }}>
          <CustomHeader
            title='Lab Instrument Management'
            description='Manage lab instruments'
            handleMenuClick={handleMenuClick}
            inDevelopment
          />
          <ElabInstrumentsManagement />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='bulk_uploads' sx={{ margin: 0 }}>
          <CustomHeader
            // title='Bulk Upload Tox to MedexLab'
            // description='Upload multiple final tox reports (PDF) to MedexLab at once'
            handleMenuClick={handleMenuClick}
          />
          <BulkFileUpload isLab={true} />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='it_tools' sx={{ margin: 0 }}>
          <CustomHeader
            // title='Bulk Upload Tox to MedexLab'
            // description='Upload multiple final tox reports (PDF) to MedexLab at once'
            handleMenuClick={handleMenuClick}
          />
          <HL7Uploader />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_am_tat' sx={{ margin: 0 }}>
          <CustomHeader
            title='AM Turnaround Time Report'
            description='This report measures how long it takes on average for an eLab AM case to complete'
            handleMenuClick={handleMenuClick}
          />
          <ELabAMTurnaroundTimeView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_pm_tat' sx={{ margin: 0 }}>
          <CustomHeader
            title='PM Turnaround Time Report'
            description='This report measures how long it takes on average for an eLab PM case to complete.'
            handleMenuClick={handleMenuClick}
          />
          <ELabPMTurnaroundTimeView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='lms_catalog' sx={{ margin: 0 }}>
          <CustomHeader
            title='Test Catalog'
            description='Manage Lab Test and Specimen Catalogs'
            handleMenuClick={handleMenuClick}
          />
          <CatalogView />
        </TreeTabPanel>
      </Box>
    </Box>
  );
}
