import {
  Box,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import { Role } from './UserAccount.type';
import { useFormikContext } from 'formik';


interface UserAccountRolesProps {
  roleOptions: Role[];
}

const UserAccountRoles: React.FC<UserAccountRolesProps> = ({ roleOptions }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<{
    roles: Role[];
  }>();

  const handleCheckboxChange = (selectedRole: Role) => {
    const isSelected = values.roles.some(
      role => role.roleSeq?.toLowerCase() === selectedRole.roleSeq?.toLowerCase()
    );

    setFieldValue(
      'roles',
      isSelected
        ? values.roles.filter(
            role => role.roleSeq?.toLowerCase() !== selectedRole.roleSeq?.toLowerCase()
          )
        : [...values.roles, selectedRole]
    );
  };

  const handleSelectAll = () => {
    setFieldValue('roles', values.roles.length === roleOptions.length ? [] : [...roleOptions]);
  };

  return (
    <Box component={'div'}>
      <Grid container spacing={2}>
        {/* Permissions Card */}
        <Grid item xs={12} md={12}>
          <Card sx={{ p: '16px', height: '100%' }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                  <strong>Permissions</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ borderColor: '#555', m: 0, pb: 0 }} />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Button variant='contained' onClick={handleSelectAll} sx={{ mb: 2 }}>
                  {values.roles.length === roleOptions.length ? 'Deselect All' : 'Select All'}
                </Button>
              </Grid>

              {roleOptions
                ?.filter(role => role.isPermission)
                ?.map((role, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={`permission-${index}`}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.roles.some(
                            selectedRole =>
                              selectedRole.roleSeq?.toLowerCase() === role.roleSeq?.toLowerCase()
                          )}
                          onChange={() => handleCheckboxChange(role)}
                        />
                      }
                      label={role.roleName}
                    />
                  </Grid>
                ))}
            </Grid>
          </Card>
        </Grid>

        {/* Roles Card */}
        <Grid item xs={12} md={12}>
          <Card sx={{ p: '16px', height: '100%' }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                  <strong>Roles</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ borderColor: '#555', m: 0, pb: 0 }} />
                <br />
              </Grid>

              <Grid item xs={12}>
                <Button variant='contained' onClick={handleSelectAll} sx={{ mb: 2 }}>
                  {values.roles.length === roleOptions.length ? 'Deselect All' : 'Select All'}
                </Button>
              </Grid>

              {roleOptions
                ?.filter(role => !role.isPermission)
                ?.map((role, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={`role-${index}`}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.roles.some(
                            selectedRole =>
                              selectedRole.roleSeq?.toLowerCase() === role.roleSeq?.toLowerCase()
                          )}
                          onChange={() => handleCheckboxChange(role)}
                        />
                      }
                      label={role.roleName}
                    />
                  </Grid>
                ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserAccountRoles;
