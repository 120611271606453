import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  Tooltip,
  Alert,
  Snackbar,
  AlertColor,
} from '@mui/material';
import { useGridApiContext, GridToolbarContainer } from '@mui/x-data-grid-premium';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import authHeader from '../../../../services/auth-header.jsx';
import FileUploadDropzone from '../../../../utils/components/FileUploadDropzone.js';
import { createSvgIcon } from '@mui/material/utils';
import { TailSpin } from 'react-loader-spinner';
import CondensedDataGrid from '../../../../components/CondensedDataGrid.jsx';
import { useAuth } from '../../../../utils/auth/AuthService.js';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { PrintFileButton } from '../../../../utils/functions/triggerPrintDialog.js';
import { LMS_FileTypes } from '../../../../utils/constants/constants.jsx';

const ELabMROReviewFiles = () => {
  const { user } = useAuth();
  const [fileTypeOptions, setFileTypeOptions] = useState<Option[] | []>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [mroFiles, setMROFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const { VITE_API_URL } = import.meta.env;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [clickTimeout, setClickTimeout] = useState(null);
  const [snackbarAlert, setSnackbarAlert] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const ExportIcon = createSvgIcon(
    <path d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z' />,
    'SaveAlt'
  );
  const [showSelectionAlert, setShowSelectionAlert] = useState(false);
  const guid = '00000000-0000-0000-0000-000000000000';

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  const columns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      minWidth: 300,
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        const { fileName, fileSeq } = params.row;
        return (
          <PrintFileButton
            filename={fileName}
            fileSeq={fileSeq}
            caseid={''}
            endpoint='downloadlmsfiles'
            buttonSize='small'
            buttonColor='primary'
            buttonSx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'normal',
              display: 'flex',
              textAlign: 'left',
              wordWrap: 'break-word',
            }}
          />
        );
      },
    },
    {
      field: 'fileLabel',
      headerName: 'File Type',
      minWidth: 180,
      flex: 1,
      editable: false,
    },

    {
      field: 'uploadedBy',
      headerName: 'Uploaded By',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: value => {
        return value ? value.optionName : '';
      },
    },
    {
      field: 'uploadedOn',
      headerName: 'Uploaded On',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 100,
      renderCell: params => {
        const onDeleteClick = (e, row) => {
          e.stopPropagation();
          setShowConfirmation(true);
          setFileToDelete(row);
        };

        return (
          <IconButton
            onClick={e => onDeleteClick(e, params.row)}
            style={{
              justifyContent: 'left',
              marginRight: '1rem',
              visibility:
                String(params.row?.uploadedBy?.optionSeq)?.toLowerCase() ===
                  String(user?.userSeq)?.toLowerCase() || user?.roleCheck(['451', 'MRO'])
                  ? 'visible'
                  : 'hidden',
            }}
            size='small'
            color='error'
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const downloadLMSFiles = async (selectedRows: any) => {
    const fileSeqs = [];
    if (!selectedRows || selectedRows.size === 0) {
      setShowSelectionAlert(true);
      return;
    }
    for (var row of selectedRows) {
      fileSeqs.push(Array.isArray(selectedRows) ? row['key'] : row[0]);
    }

    let formData = new FormData();
    formData.append('fileSeqsString', JSON.stringify(fileSeqs));

    setDownloading(true);

    try {
      const response = await fetch(`${VITE_API_URL}downloadlmsfiles`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: formData,
      });

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      }

      if (response.status >= 400) {
        const errorText = await response.text();
        throw new Error(errorText || 'Download failed');
      }

      const blob = await response.blob();

      const isSingleFile = selectedRows.length === 1 || selectedRows.size === 1;

      let filename = 'MROFiles.zip';

      if (isSingleFile) {
        const disposition = response.headers.get('content-disposition');
        const filenameMatch = disposition?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        filename = filenameMatch ? filenameMatch[1].replace(/['"]/g, '') : 'file';
      }

      saveAs(blob, filename);
    } catch (error) {
      console.error('Download error:', error);
      alert(error || 'An unexpected error occurred');
    }

    setDownloading(false);
  };

  const CustomExportButton = (props: any) => {
    const apiRef = useGridApiContext();
    const { hideMenu } = props;

    return downloading ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TailSpin color='#00BFFF' height={20} width={20} />
        <i style={{ color: '#00BFFF', paddingLeft: 10 }}>
          I'm preparing the download for you, this may take a bit of time....
        </i>
      </div>
    ) : (
      <Button
        {...buttonBaseProps}
        onClick={() => {
          const selectedRows = apiRef.current.getSelectedRows();
          downloadLMSFiles(selectedRows);
          hideMenu?.();
        }}
      >
        Download Selection
      </Button>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <CustomExportButton />
        <Button color='primary' startIcon={<AddIcon />} onClick={() => setIsOpen(true)}>
          Upload New File
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleConfirmDelete = () => {
    if (!fileToDelete) return;
    setShowConfirmation(false);

    fetch(VITE_API_URL + `VoidLMSFile?fileSeq=${fileToDelete.fileSeq}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setSnackbarAlert({
          open: true,
          severity: 'success',
          message: `File voided successfully.`,
        });
        fetchMROFiles();
      })
      .catch(e => {
        setSnackbarAlert({
          open: true,
          severity: 'error',
          message: `An error occured, Fail to void the file.`,
        });
      });
  };

  const handleRowClick = (params: any) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleRowDoubleClick(params);
    } else {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setClickTimeout(null);
      }, 250); // 250ms to differentiate between single and double click
      setClickTimeout(timer);
    }
  };

  const handleRowDoubleClick = (selectedRow: any) => {
    // Extract rows that are currently selected
    if (selectedRow) {
      downloadLMSFiles([{ key: selectedRow.id, value: selectedRow.row }]);
    }
  };

  const handleCloseUserDocumntSnackbar = () => {
    setSnackbarAlert(prev => ({ ...prev, open: false }));
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleSaveFiles = async (files: File[], fileType?: Option) => {
    if (!files.length) return [];

    try {
      const formData = new FormData();

      files.forEach(file => {
        formData.append('files', file);
      });

      const defaultOption = fileTypeOptions?.find(
        (option: Option) => option?.optionSeq?.toUpperCase() === LMS_FileTypes.Medical_Review
      );

      if (defaultOption) {
        formData.append('fileType.optionSeq', defaultOption.optionSeq ?? '');
        formData.append('fileType.optionName', defaultOption.optionName ?? '');
      }

      const saveEndpoint = 'lmsfileuploads';

      const response = await axios.post(`${VITE_API_URL}${saveEndpoint}`, formData, {
        headers: {
          ...authHeader(),
          'Content-Type': 'multipart/form-data',
        },
      });

      const resultData = response?.data;

      if (!resultData) {
        throw new Error('No response from server.');
      }

      const fileResults = resultData?.data || [];

      const anySuccessful = fileResults.some((res: any) => res.success === true);
      const allSuccessful = fileResults.every((res: any) => res.success === true);

      if (anySuccessful) {
        fetchMROFiles();
      }

      if (allSuccessful) {
        setIsOpen(false);
        setSnackbarAlert({
          open: true,
          severity: 'success',
          message: `files uploaded successfully.`,
        });
      } else {
        console.warn(
          'Some files failed:',
          fileResults.filter((res: any) => !res.success)
        );
      }

      return fileResults;
    } catch (error) {
      console.error('Unexpected error during upload:', error);
      return files.map(file => ({
        fileName: file.name,
        success: false,
        message: 'Unexpected error during upload:' + error?.message,
      }));
    }
  };

  const fetchMROFiles = async () => {
    setLoadingFiles(true);

    try {
      const response = await fetch(
        VITE_API_URL +
          `getlmsfiles?fileTypeSeq=${encodeURIComponent(LMS_FileTypes.Medical_Review)}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + user?.token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      }

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();

      if (data) {
        setMROFiles(data);
      } else {
        console.error('Invalid response structure:', data);
      }
    } catch (error) {
      console.error('Error fetching mro files:', error);
    } finally {
      setLoadingFiles(false);
    }
  };

  const fetchFileTypeOptions = () => {
    fetch(VITE_API_URL + 'getlmsfiletypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setFileTypeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    fetchMROFiles();
    fetchFileTypeOptions();
  }, []);

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Confirm Void File'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to void this file?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container spacing={2}>

      <Snackbar
          open={snackbarAlert.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ position: 'absolute', top: 5 }}
          autoHideDuration={5000}
          onClose={handleCloseUserDocumntSnackbar}
        >
          <Alert
            onClose={handleCloseUserDocumntSnackbar}
            severity={snackbarAlert.severity as AlertColor}
            variant='filled'
            sx={{ width: '100%' }}
          >
            <Typography variant='body1' fontWeight={500}>
              {snackbarAlert.message}
            </Typography>
          </Alert>
        </Snackbar>

        <Grid item xs={12}>
          <Typography variant='subtitle1' color='gray'>
            <strong>REVIEW FILES </strong>
          </Typography>
        </Grid>

        {showSelectionAlert && (
          <Grid item xs={12}>
            <Alert
              severity='warning'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => setShowSelectionAlert(false)}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              Please select at least one files to download.
            </Alert>
          </Grid>
        )}
        
        <Grid item xs={12}>
          <CondensedDataGrid
            columnsVisibility={{
              photoSeq: false,
            }}
            loading={loadingFiles}
            listresults={mroFiles}
            columnsInput={columns}
            gridheight={mroFiles?.length > 0 ? 'auto' : 200}
            idcolumn='fileSeq'
            rowsperpage={10}
            gridcolor='#4b21a6'
            tooltip='A list of all files associated with MRO Review'
            title=''
            checkboxSelection={true}
            toolbarComponent={CustomToolbar}
            onRowClick={(params: any) => handleRowClick(params)}
            disableRowSelectionOnClick={false}
          />
        </Grid>
      </Grid>

      {showConfirmation && <ConfirmationModal />}

      <FileUploadDropzone
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fileTypeRequired={false}
        fileTypeOptions={fileTypeOptions}
        handleSaveFiles={handleSaveFiles}
        acceptedExtensions={['pdf']}
      />
    </>
  );
};

export default ELabMROReviewFiles;
