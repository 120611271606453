import { AddRounded, DeleteOutlineRounded, EditRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { User } from '../../../../types/User';
import { useOptions, useOptionsAPI } from '../../../../utils/api/useOptions.hook';
import { useAuth } from '../../../../utils/auth/AuthService';
import { CreateXrayRequestFormModal } from './XrayRequestForm/CreateXrayRequestModal';
import { EditXrayRequestFormModal } from './XrayRequestForm/EditXrayRequestModal';
import {
  BodyCategoryDto,
  DecedentType,
  ExtremityTypeDto,
  TypeOfCaseDto,
  XrayRequestDto,
  deleteXrayRequest,
  fetchAllPersonsInCurrentUserJdx,
  fetchAllXrayRequestsForCase,
  fetchXrayBodyCategoryTypes,
  fetchXrayTypeOfCase,
  getDecedentTypes,
  getExtremityTypes,
} from './XrayRequestForm/xrayrequestform.constants';

const { VITE_API_URL } = import.meta.env;

export function XrayRequestFormView({ caseSeq = null }) {
  const formik = useFormikContext<any>();
  const [caseID, setCaseID] = useState<string | undefined>(undefined);
  const [jdxSeq, setJdxSeq] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    const fetchCaseIdAndJdxSeq = async () => {
      await fetch(VITE_API_URL + `getcaseid?caseSeq=${caseSeq}`, {
        method: 'GET',
      })
        .then(res => {
          return res.text();
        })
        .then(data => {
          setCaseID(data);
        })
        .catch(e => {
          //alert(e);
        });

      await fetch(VITE_API_URL + `getjdxseq?caseSeq=${caseSeq}`, {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setJdxSeq(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    if (formik?.values?.caseSummary) {
      setCaseID(formik?.values?.caseSummary?.caseInformation?.caseID);
      setJdxSeq(formik?.values?.caseSummary?.jdxSeq);
    } else {
      fetchCaseIdAndJdxSeq();
    }
  }, [formik?.values]);

  const [selectedRequest, setSelectedRequest] = useState<XrayRequestDto | null>(null);
  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const auth = useAuth();
  const [caseTypes, setCaseTypes] = useState<TypeOfCaseDto[]>([]);

  const [bodyCategories, setBodyCategories] = useState<BodyCategoryDto[]>([]);
  const [extremityTypes, setExtremityTypes] = useState<ExtremityTypeDto[]>([]);
  // const [users, setUsers] = useState<Partial<User>[]>([]);
  const { options: users } = useOptionsAPI<Partial<User>>(
    `user/getAllUsersInCurrentUserJdx?jdxSeq=${formik?.values?.caseSummary?.jdxSeq}`
  );
  const [decedentTypes, setDecedentTypes] = useState<DecedentType[]>([]);

  const [allXrayRequests, setAllXrayRequests] = useState<XrayRequestDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown | null>(null);

  const { options: medicalExaminerOptions } = useOptions('pathologist options', {
    jdxSeq: jdxSeq,
  });

  const fetchData = async (caseID: string | undefined) => {
    if (!caseID) return;
    if (!auth.user?.accessToken) return;
    setError(null);

    try {
      const bodyCategoriesResponse = await fetchXrayBodyCategoryTypes(auth.user.accessToken);
      const extremityTypesResponse = await getExtremityTypes(auth.user.accessToken);
      const decentTypesResponse = await getDecedentTypes(auth.user.accessToken);
      const typesOfCaseResponse = await fetchXrayTypeOfCase(auth.user.accessToken);
      const userOptionsResponse = await fetchAllPersonsInCurrentUserJdx(auth.user.accessToken);

      const allXrayRequestsResponse = await fetchAllXrayRequestsForCase(
        auth.user.accessToken,
        caseID
      );

      setDecedentTypes(await decentTypesResponse.json());
      // setUsers(await userOptionsResponse.json());
      setBodyCategories(await bodyCategoriesResponse.json());
      setExtremityTypes(await extremityTypesResponse.json());
      setCaseTypes(await typesOfCaseResponse.json());
      setAllXrayRequests(await allXrayRequestsResponse.json());
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const refetchXrays = async () => {
    setLoading(true);
    const allXrayRequestsResponse = await fetchAllXrayRequestsForCase(
      auth.user?.accessToken!,
      caseID
    );
    setAllXrayRequests(await allXrayRequestsResponse.json());
    setLoading(false);
  };

  useEffect(() => {
    fetchData(caseID);
  }, [caseID]);

  const renderCaseTypeCell = (params: GridRenderCellParams<XrayRequestDto['caseTypes']>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const caseTypes: XrayRequestDto['caseTypes'] = params.value || [];
    const checkedTypes = caseTypes.filter(t => t.isChecked === true);

    if (!checkedTypes.length) return <span>None</span>;

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        {checkedTypes.length === 0 && <span>None</span>}
        {checkedTypes.length === 1 && <Chip size='small' label={checkedTypes[0].typeOfCaseName} />}

        {checkedTypes.length > 1 && (
          <React.Fragment>
            <Stack direction='row' alignItems='center' spacing={1} onClick={handleClick}>
              <Chip size='small' label={checkedTypes[0].typeOfCaseName} />
              <Typography variant='caption'>+{checkedTypes.length - 1}</Typography>
            </Stack>
          </React.Fragment>
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <Box component='div' p={1}>
            <ul>
              {checkedTypes.map(a => (
                <li>{a.typeOfCaseName}</li>
              ))}
            </ul>
          </Box>
        </Menu>
      </div>
    );
  };

  const renderAreasRequestedCell = (params: GridRenderCellParams<XrayRequestDto>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const areasRequested: XrayRequestDto['bodyCategories'] = params.value.bodyCategories || [];
    const checkedAreas = areasRequested.filter(a => a.isChecked === true);

    if (!checkedAreas.length) return <span>None</span>;

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const renderLabel = (bc: BodyCategoryDto) => {
      if (bc.subCategories && bc.subCategories.length > 0) {
        const subCategoryLabel = bc.subCategories.map(s => s.bodySubCategoryName).join(', ');
        return `${bc.bodyCategoryName} - ${subCategoryLabel}`;
      }

      return bc.bodyCategoryName ?? 'Unknown';
    };

    return (
      <div>
        {checkedAreas.length === 0 && 'None'}
        {checkedAreas.length === 1 && <Chip size='small' label={renderLabel(checkedAreas[0])} />}

        {checkedAreas.length > 1 && (
          <React.Fragment>
            <Stack direction='row' alignItems='center' spacing={1} onClick={handleClick}>
              <Chip size='small' label={renderLabel(checkedAreas[0])} />
              <Typography variant='caption'>+{checkedAreas.length - 1}</Typography>
            </Stack>
          </React.Fragment>
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <Box component='div' p={1}>
            <ul>
              {checkedAreas.map(a => (
                <li>{renderLabel(a)}</li>
              ))}
            </ul>
          </Box>
        </Menu>
      </div>
    );
  };

  const renderExtremitiesRequestedCell = (params: GridRenderCellParams<XrayRequestDto>) => {
    const request: XrayRequestDto = params.value || [];
    return <span>{request.extremitiesRequested.filter(e => e.isChecked).length}</span>;
  };

  const renderStatusCell = (params: GridRenderCellParams<XrayRequestDto>) => {
    const request: XrayRequestDto = params.value || [];
    const completedOn = request.completedOn;
    const completedBy = request.completedByUser;

    if (completedOn === null && completedBy === null) {
      return <Chip label='Pending' size='small' />;
    }

    const completedOnDateLocal = new Date(completedOn).toLocaleDateString();
    const completedOnTimeLocal = new Date(completedOn).toLocaleTimeString();

    if (completedOn && completedBy) {
      return (
        <Tooltip
          title={`Completed by ${completedBy.personFirstName} ${completedBy.personLastName} on ${completedOnDateLocal} at ${completedOnTimeLocal}}`}
          arrow
        >
          <Chip
            label={`Completed ${completedOnDateLocal} at ${completedOnTimeLocal}`}
            size='small'
          />
        </Tooltip>
      );
    }

    if (completedOn == null && completedBy !== null) {
      return (
        <Tooltip
          title={`Completed by ${completedBy.personFirstName} ${completedBy.personLastName} at an unspecified time`}
          arrow
        >
          <Chip label='Completed' size='small' />
        </Tooltip>
      );
    }

    if (completedOn !== null) {
      return (
        <Tooltip
          title={`Completed by an unknown user on ${completedOnDateLocal} at ${completedOnTimeLocal}`}
          arrow
        >
          <Chip
            label={`Completed ${completedOnDateLocal} at ${completedOnTimeLocal}`}
            size='small'
          />
        </Tooltip>
      );
    }

    return <Chip label='Unknown' size='small' />;
  };

  const renderActions = (params: GridRenderCellParams) => {
    const handleEditClick = () => {
      const xrayRequest = allXrayRequests[params.id as number];
      setSelectedRequest(xrayRequest);
      setEditOpen(true);
    };

    const handleDeleteClick = () => {
      const xrayRequest = allXrayRequests[params.id as number];
      setSelectedRequest(xrayRequest);
      setDeleteOpen(true);
    };

    return (
      <Stack direction='row'>
        <IconButton color='default' onClick={handleEditClick}>
          <EditRounded />
        </IconButton>
        <IconButton color='default' onClick={handleDeleteClick}>
          <DeleteOutlineRounded />
        </IconButton>
      </Stack>
    );
  };

  const handleDeleteConfirmation = async () => {
    if (!selectedRequest) return;

    try {
      await deleteXrayRequest(auth.user?.accessToken!, {
        requestSeq: selectedRequest.xRayRequestSeq,
      });
    } catch (error) {
      console.error('Error deleting request:', error);
    } finally {
      refetchXrays();
    }

    setDeleteOpen(false);
    setSelectedRequest(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'requestedBy',
      headerName: 'Requested By',
      width: 150,
      valueGetter: (value: XrayRequestDto['requedstedByUser']) => {
        if (!value) return 'Unknown';
        return `${value.personLastName}, ${value.personFirstName}`;
      },
    },
    {
      field: 'requestedOn',
      headerName: 'Requested On',
      width: 200,
      type: 'dateTime',
      valueGetter: value => new Date(value),
      valueFormatter: value => format(new Date(value), 'MM/dd/yyyy, HH:mm'),
    },
    {
      field: 'assignedME',
      headerName: 'Assigned ME',
      width: 150,
      valueGetter: (value: XrayRequestDto['assignedMedicalExaminerUser']) => {
        if (!value) return `None`;
        return `${value.personLastName}, ${value.personFirstName}`;
      },
    },
    {
      field: 'areasRequested',
      headerName: 'Areas Requested',
      width: 250,
      valueGetter: (value: XrayRequestDto) => {
        if (!value) return 'Unknown';
        return value;
      },
      renderCell: renderAreasRequestedCell,
    },
    {
      field: 'extremitiesRequested',
      headerName: 'Extremities Requested',
      width: 200,
      valueGetter: (value: XrayRequestDto) => {
        if (!value) return 'Unknown';
        return value;
      },
      renderCell: renderExtremitiesRequestedCell,
    },

    {
      field: 'caseType',
      headerName: 'Case Type',
      width: 180,
      editable: false,
      valueGetter: (value: XrayRequestDto['caseTypes']) => {
        if (!value) {
          return 'Unknown';
        }
        return value;
      },
      renderCell: renderCaseTypeCell,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      width: 100,
      editable: false,
      renderCell: renderStatusCell,
    },
    {
      field: 'edit',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: renderActions,
    },
  ];

  const rows: GridRowsProp = allXrayRequests?.map((request, index) => ({
    id: index,
    areasRequested: request,
    extremitiesRequested: request,
    requestedOn: request.requestedOn,
    requestedBy: request.requedstedByUser,
    assignedME: request.assignedMedicalExaminerUser,
    caseType: request.caseTypes ?? [],
    status: request,
  }));

  const isMobileScreen = useMediaQuery('(max-width: 600px)');

  return (
    <Box component='div'>
      <DataGridPremium
        pagination
        paginationModel={{
          pageSize: isMobileScreen ? 5 : 10,
          page: 0,
        }}
        pageSizeOptions={[5, 10, 25]}
        autoHeight={true}
        rows={rows}
        columns={columns}
        loading={loading}
        slots={{
          toolbar: () => (
            <CustomToolbar
              loading={loading}
              createOpen={createOpen}
              setCreateOpen={setCreateOpen}
            />
          ),
          noRowsOverlay: () => <></>,
        }}
        localeText={{
          noRowsLabel: '',
        }}
        onCellDoubleClick={params => {
          const xrayRequest = allXrayRequests[params.id as number];
          setSelectedRequest(xrayRequest);
          setEditOpen(true);
        }}
      />
      {selectedRequest && (
        <EditXrayRequestFormModal
          open={editOpen}
          bodyCategories={bodyCategories}
          extremities={extremityTypes}
          decedentTypes={decedentTypes}
          handleClose={(shouldRefetchData: boolean) => {
            if (shouldRefetchData) {
              refetchXrays();
            }
            setEditOpen(false);
          }}
          medicalExaminerOptions={medicalExaminerOptions}
          users={users}
          caseTypes={caseTypes}
          xrayRequest={selectedRequest}
        />
      )}

      {selectedRequest && (
        <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
          <DialogTitle>Delete X-Ray Request?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>This cannot be undone.</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteOpen(false)}>No</Button>
            <Button onClick={handleDeleteConfirmation} color='error'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <CreateXrayRequestFormModal
        caseId={caseID}
        users={users}
        open={createOpen}
        loading={loading}
        bodyCategories={bodyCategories}
        extremities={extremityTypes}
        handleClose={(shouldRefetchData: boolean) => {
          if (shouldRefetchData) {
            refetchXrays();
          }
          setCreateOpen(false);
        }}
        decedentTypes={decedentTypes}
        medicalExaminerOptions={medicalExaminerOptions}
        caseTypes={caseTypes}
      />
    </Box>
  );
}

function CustomToolbar({
  loading,
  createOpen,
  setCreateOpen,
}: {
  loading: boolean;
  createOpen: boolean;
  setCreateOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <GridToolbarContainer>
      <Button
        disabled={loading}
        color='primary'
        startIcon={<AddRounded />}
        onClick={() => setCreateOpen(true)}
      >
        Add X-Ray Request
      </Button>
    </GridToolbarContainer>
  );
}
