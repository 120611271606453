import { Settings } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {
  Checkbox,
  CircularProgress,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  DataGridPremium,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import '../../../App.css';
import { useAuth } from '../../auth/AuthService';

function CustomPagination({ paginationModel, setPaginationModel, pageCount }) {
  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={paginationModel.page + 1}
      count={pageCount}
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => setPaginationModel(prev => ({ ...prev, page: value - 1 }))}
      sx={{ mr: 3 }}
    />
  );
}

export const ReportDataGridToolbar = ({
  title,
  casecount,
  count,
  expanded,
  setExpanded,
  customButtons = [],
  tooltip,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const showInfo = Boolean(anchorEl);

  const toggleExpandDataGrid = event => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const onInfoClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onInfoClose = () => {
    setTimeout(() => setAnchorEl(null), 100);
  };

  const getTitleAndCount = () => {
    var titleAndCount = '';
    if (title != null && casecount === true) titleAndCount = title + ': ' + count;
    else if (title != null && casecount != null) titleAndCount = title + ': ' + casecount;
    else if (title != null && casecount == null) titleAndCount = title;
    else if (title == null && casecount === true) titleAndCount += 'Cases Found: ' + count;
    else if (title == null && casecount != null) titleAndCount += 'Cases Found: ' + casecount;
    return titleAndCount;
  };

  const apiRef = useGridApiContext();
  const { user } = useAuth();
  const authorizedToView = user?.roleCheck(['451', '8f7']) || false;

  return (
    <GridToolbarContainer>
      <Stack sx={{ width: '100%' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ p: 2 }}>
          <Typography variant='h5'>{getTitleAndCount()}</Typography>
          <Stack direction='row' spacing={2}>
            {customButtons.map((ButtonComponent, index) => (
              <React.Fragment key={`custom-button-${index}`}>{ButtonComponent}</React.Fragment>
            ))}
            {tooltip && (
              <Tooltip title={'More information about open cases'} arrow>
                <IconButton
                  onClick={onInfoClick}
                  size='small'
                  aria-describedby={showInfo ? 'simple-popover' : undefined}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            <Popover
              id={showInfo ? 'simple-popover' : undefined}
              open={showInfo}
              anchorEl={anchorEl}
              onClose={onInfoClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ marginRight: '2rem' }}
              disableAutoFocus
              disableEnforceFocus
            >
              <div style={{ padding: '10px' }}>
                {<Typography component='span' dangerouslySetInnerHTML={{ __html: tooltip }} />}
              </div>
            </Popover>
            <GridToolbarExportContainer>
              <GridExcelExportMenuItem />
              <GridCsvExportMenuItem />
              {authorizedToView && <FHIRJsonExportMenuItem apiRef={apiRef} />}

              <GridPrintExportMenuItem />
            </GridToolbarExportContainer>
            <IconButton
              sx={{
                color: theme.palette.text.primary,
                opacity: 0.8,
              }}
              size='small'
              onClick={toggleExpandDataGrid}
            >
              <OpenInFullIcon fontSize='small' />
            </IconButton>
          </Stack>
        </Stack>
        <Divider sx={{ opacity: 0.6 }} />
      </Stack>
    </GridToolbarContainer>
  );
};

export default function CondensedReportDataGrid({
  loading,
  rows,
  columns,
  chatcontent = null,
  title,
  idcolumn,
  casecount = null,
  rowsperpage = 5,
  expanded,
  setExpanded,
  customButtons = [],
  ...props
}) {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsperpage,
    page: 0,
  });

  const apiRef = useGridApiRef();

  return (
    <Stack spacing={2}>
      {chatcontent && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <Skeleton
              variant='rounded'
              animation='wave'
              height={50}
              sx={{
                borderRadius: 20,
                width: '80%',
              }}
            />
          ) : (
            chatcontent
          )}
        </Box>
      )}
      <DataGridPremium
        {...props}
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        loading={loading}
        getRowId={row => row[idcolumn]}
        pagination
        autoHeight
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              ID: false,
              resultseq: false,
              caseseq: false,
            },
          },
        }}
        slots={{
          // pagination: CustomGridFooter,
          toolbar: ReportDataGridToolbar,
          footer: CustomGridFooter,
        }}
        slotProps={{
          toolbar: {
            title: title,
            casecount: casecount,
            count: rows.length,
            expanded: expanded,
            setExpanded: setExpanded,
            customButtons: customButtons,
            tooltip: props?.tooltip || '',
          },
          footer: {
            paginationModel,
            setPaginationModel,
            pageCount: Math.ceil(rows.length / paginationModel.pageSize),
          },
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            fontSize: '0.775rem',
            borderRight: '1px solid rgba(200, 200, 200, 0.6)',
          },
          '& .MuiDataGrid-row': {
            borderBottom: '1px solid rgba(200, 200, 200, 0.6)',
          },
        }}
      />
    </Stack>
  );
}

const toCamelCase = str => {
  if (!str) return str;
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '');
};

const CustomGridFooter = ({ paginationModel, setPaginationModel, pageCount }) => {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [camelCaseEnabled, setCamelCaseEnabled] = useState(false);
  const [originalHeaders, setOriginalHeaders] = useState({});

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCamelCaseToggle = () => {
    const newEnabled = !camelCaseEnabled;
    const allColumns = apiRef.current.getAllColumns();

    if (newEnabled) {
      const headers = {};
      allColumns.forEach(col => {
        headers[col.field] = col.headerName;
      });
      setOriginalHeaders(headers);
    }

    const columnUpdates = allColumns.map(column => ({
      ...column,
      headerName: newEnabled
        ? toCamelCase(column.headerName || '')
        : originalHeaders[column.field] || column.headerName,
    }));

    apiRef.current.updateColumns(columnUpdates);
    setCamelCaseEnabled(newEnabled);
  };

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderTop: theme => `1px solid ${theme.palette.divider}`,
        p: 1,
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1} sx={{ mx: 1 }}>
        <Tooltip title='Settings' arrow>
          <IconButton
            onClick={handleClick}
            size='small'
            aria-controls={Boolean(anchorEl) ? 'footer-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
          >
            <Settings fontSize='small' />
          </IconButton>
        </Tooltip>
        <Menu
          id='footer-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleCamelCaseToggle} dense>
            <Checkbox checked={camelCaseEnabled} size='small' />
            <ListItemText primary='Use Technical Column Names' />
          </MenuItem>
        </Menu>
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <CustomPagination
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        pageCount={pageCount}
      />
    </Box>
  );
};

const { VITE_API_URL } = import.meta.env;

const FHIRJsonExportMenuItem = ({ apiRef }) => {
  const [loading, setLoading] = useState(false);

  const handleFHIRExport = async () => {
    if (loading) return;

    try {
      setLoading(true);

      // Get all selected rows or all rows if none selected
      const selectedRows = apiRef.current.getSelectedRows();
      const rows =
        selectedRows.size > 0
          ? Array.from(selectedRows.values())
          : Array.from(apiRef.current.getRowModels().values());

      // Extract case IDs and filter out undefined/null values
      const caseIds = rows.map(row => row.caseID).filter(id => id != null);

      // Call FHIR endpoint with POST request
      const response = await fetch(`${VITE_API_URL}getcmscase/fhir/batch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(caseIds),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Download JSON
      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'fhir-bundles.json';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting FHIR bundles:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MenuItem onClick={handleFHIRExport} disabled={loading}>
      <Stack>
        <Typography>Download as JSON</Typography>
        <Typography variant='caption' color='text.secondary'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <SubdirectoryArrowRightIcon sx={{ fontSize: 14 }} />
            <span>HL7© FHIR Document Bundle</span>
            {loading && <CircularProgress size={14} />}
          </Stack>
        </Typography>
      </Stack>
    </MenuItem>
  );
};
