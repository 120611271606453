import dayjs from 'dayjs';

export const dateRangeShortcuts = [
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs();
      const endOfLastMonth = today.startOf('month').subtract(1, 'day');
      return [endOfLastMonth.startOf('month'), endOfLastMonth];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, 'day'), today];
    },
  },
  {
    label: 'Last 6 Months',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(6, 'month'), today];
    },
  },
  {
    label: 'Last 3 Years',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(3, 'year'), today];
    },
  },
  {
    label: 'Last 5 Years',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(5, 'year'), today];
    },
  },
  {
    label: 'Reset',
    getValue: () => [null, null],
  },
];
