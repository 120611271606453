import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, Close, Send, TextFields } from '@mui/icons-material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from '../../../../../../utils/auth/AuthService';
import { useConversation } from '../ConversationsContext';
import { ConversationTypes } from '../conversation.types';
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from 'mui-tiptap';
import type { EditorOptions } from '@tiptap/core';
import EditorMenuControls from '../../../../../Home/EditorMenuControls';
import useExtensions from '../../../../../Home/useExtensions';

// Function to convert File to base64
async function convertToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

// Function to resize image
async function resizeImage(file: File, maxWidth = 600, maxHeight = 600): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }

      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        blob => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Could not create blob'));
          }
        },
        file.type,
        0.85 // compression quality
      );
    };
    img.onerror = () => reject(new Error('Could not load image'));
  });
}

export type ConversationUser = {
  userSeq: string;
  userName: string;
  personFirstName: string;
  personLastName: string;
};

interface NewConversationProps {
  open?: boolean;
  onClose?: () => void;
  isDialog?: boolean;
}

export function NewConversation({ open = true, onClose, isDialog = true }: NewConversationProps) {
  const { user } = useAuth();
  const location = useLocation();
  const {
    createChat,
    creatingConversation,
    supportAgentName,
    availableUsers,
    emitTypingIndicator,
  } = useConversation();
  const [topic, setTopic] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');
  const [participants, setParticipants] = useState<ConversationUser[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMenuBar, setShowMenuBar] = useState<boolean>(false);

  const rteRef = useRef<RichTextEditorRef>(null);
  const extensions = useExtensions({ placeholder: 'Type your message here...' });

  const handleNewImageFiles = useCallback(async (files: File[], insertPosition?: number) => {
    if (!rteRef.current?.editor) {
      return;
    }

    try {
      const processedImages = await Promise.all(
        files.map(async file => {
          const resizedBlob = await resizeImage(file);
          const resizedFile = new File([resizedBlob], file.name, { type: file.type });
          const base64 = await convertToBase64(resizedFile);
          return { src: base64, alt: file.name };
        })
      );

      insertImages({
        images: processedImages,
        editor: rteRef.current.editor,
        position: insertPosition,
      });
    } catch (error) {
      console.error('Image processing error:', error);
    }
  }, []);

  const handleDrop: NonNullable<EditorOptions['editorProps']['handleDrop']> = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = Array.from(event.dataTransfer.files).filter(file =>
        file.type.startsWith('image/')
      );

      if (imageFiles.length > 0) {
        const position = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, position);

        // Also add non-image files to attachments
        const nonImageFiles = Array.from(event.dataTransfer.files).filter(
          file => !file.type.startsWith('image/')
        );

        if (nonImageFiles.length > 0) {
          setAttachments(prev => [...prev, ...nonImageFiles]);
        }

        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handlePaste: NonNullable<EditorOptions['editorProps']['handlePaste']> = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = Array.from(event.clipboardData.files).filter(file =>
        file.type.startsWith('image/')
      );

      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);

        // Also add non-image files to attachments
        const nonImageFiles = Array.from(event.clipboardData.files).filter(
          file => !file.type.startsWith('image/')
        );

        if (nonImageFiles.length > 0) {
          setAttachments(prev => [...prev, ...nonImageFiles]);
        }

        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const removeAttachment = (fileToRemove: File) => {
    setAttachments(prev => prev.filter(file => file !== fileToRemove));
  };

  // Function to remove an image from the editor
  const removeImage = useCallback((imagePos: number) => {
    if (rteRef.current?.editor) {
      // Delete the image node at the given position
      rteRef.current.editor.commands.deleteRange({
        from: imagePos,
        to: imagePos + 1,
      });
    }
  }, []);

  const isFeedbackChat = (p: ConversationUser[]): boolean => {
    if (p.findIndex(u => u.userSeq === 'Support') > -1) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!topic.trim() || participants.length <= 0) return;

    try {
      setIsLoading(true);
      await createChat(
        {
          topic,
          messages: [
            {
              messageContent,
              sender: { userSeq: user?.userSeq },
              readBy: [{ userSeq: user?.userSeq }],
              attachments: attachments || [],
            },
          ],
          creator: {
            userSeq: user?.userSeq,
          },
          type: {
            conversationTypeSeq: isFeedbackChat(participants)
              ? ConversationTypes.Feedback
              : ConversationTypes.Chat,
          },
          isOpen: isFeedbackChat(participants) ? true : undefined,
          isActive: true,
          participants: [
            ...participants.filter(p => p.userSeq !== 'Support').map(p => ({ userSeq: p.userSeq })),
            { userSeq: user?.userSeq },
          ],
        },
        {
          caseId: location.state?.cmscaseid || null,
          pathname: location.pathname,
        }
      );

      // Clean up
      setTopic('');
      setMessageContent('');
      setParticipants([]);
      setAttachments([]);

      if (rteRef.current?.editor) {
        rteRef.current.editor.commands.setContent('');
      }

      if (onClose && isDialog) {
        onClose();
      }
    } catch (error) {
      console.error('Error creating chat:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortAutoCompleteOptions = (options: ConversationUser[]): ConversationUser[] => {
    return options.sort((a, b) => {
      // Support agent always comes first
      if (a.userSeq === 'Support') return -1;
      if (b.userSeq === 'Support') return 1;
      // For other users, sort by name
      return `${a.personFirstName} ${a.personLastName}`.localeCompare(
        `${b.personFirstName} ${b.personLastName}`
      );
    });
  };

  // Handle participant selection logic
  const handleParticipantChange = (_, newValue: ConversationUser[]) => {
    // Find if there's a support user in the new selection
    const supportUser = newValue.find(user => user.userSeq === 'Support');

    if (supportUser) {
      // If Support is selected, only allow Support
      setParticipants([supportUser]);
    } else {
      // No Support user, allow all selected participants
      setParticipants(newValue);
    }
  };

  // Check if a specific option is disabled (used in renderOption)
  const isOptionDisabled = (option: ConversationUser) => {
    // If we have Support agent selected and this is not Support, disable it
    return participants.some(p => p.userSeq === 'Support') && option.userSeq !== 'Support';
  };

  // Effect for editor updates
  useEffect(() => {
    const editor = rteRef.current?.editor;
    if (!editor) return;

    const updateHandler = () => {
      setMessageContent(editor.getHTML());
    };

    editor.on('update', updateHandler);
    return () => {
      editor.off('update', updateHandler);
    };
  }, [rteRef.current]);

  // Add styles for image hover controls to the document
  useEffect(() => {
    // Add our custom styles for image handling
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      .ProseMirror img {
        border-radius: 4px;
        transition: all 0.2s;
        position: relative;
        margin: 0.5em 0;
        max-width: 100%;
      }
      
      .ProseMirror img.ProseMirror-selectednode {
        outline: none !important;
        border: 2px solid #2196f3 !important;
        border-radius: 4px;
      }
      
      .ProseMirror img {
        position: relative;
      }
      
      .ProseMirror img::before {
        content: "×";
        position: absolute;
        top: 4px;
        right: 4px;
        width: 24px;
        height: 24px;
        background-color: rgba(255, 255, 255, 0.8);
        color: #f44336;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        opacity: 0;
        transition: opacity 0.2s;
        cursor: pointer;
        z-index: 10;
        line-height: 1;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12);
      }
      
      .ProseMirror img::after {
        content: "⤡";
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 24px;
        height: 24px;
        background-color: rgba(255, 255, 255, 0.8);
        color: #2196f3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        opacity: 0;
        transition: opacity 0.2s;
        cursor: nwse-resize;
        z-index: 10;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12);
      }
      
      .ProseMirror-focused img:hover::before,
      .ProseMirror-focused img:hover::after {
        opacity: 1;
      }
    `;
    document.head.appendChild(styleElement);

    // Add event listener for the custom delete button
    const handleImageClick = event => {
      const target = event.target;
      // Check if we clicked on an image inside the editor
      if (target.tagName === 'IMG' && target.closest('.ProseMirror')) {
        // Get the position of the click relative to the image
        const rect = target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // Check if the click was in the top-right corner (where our X button is)
        if (x >= rect.width - 28 && x <= rect.width - 4 && y >= 4 && y <= 28) {
          // Delete the image
          if (rteRef.current?.editor) {
            const { state } = rteRef.current.editor;
            const { from } = state.selection;

            // Find the image node
            const resolvedPos = state.doc.resolve(from);
            const node = resolvedPos.parent.child(resolvedPos.index());

            // Check if it's an image and delete it
            if (node && node.type.name === 'image') {
              rteRef.current.editor.commands.deleteSelection();
              event.preventDefault();
              event.stopPropagation();
            }
          }
        }
      }
    };

    // Add the event listener to the document
    document.addEventListener('click', handleImageClick, true);

    return () => {
      document.head.removeChild(styleElement);
      document.removeEventListener('click', handleImageClick, true);
    };
  }, []);

  const renderAttachments = () => (
    <Box component='div' sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 2 }}>
      {attachments.map((file, index) => (
        <Chip key={index} label={file.name} onDelete={() => removeAttachment(file)} />
      ))}
    </Box>
  );

  const content = (
    <Box component='div' sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box component='div' sx={{ position: 'relative', mb: 2 }}>
        <Typography variant='h6' component='h2'>
          New Chat
        </Typography>

        <Typography variant='subtitle1' component='h2' sx={{ mt: 1, color: 'text.secondary' }}>
          <strong>Need help? </strong>Start a chat with {supportAgentName}
        </Typography>
      </Box>

      <Autocomplete
        multiple
        options={sortAutoCompleteOptions(availableUsers)}
        getOptionLabel={(option: ConversationUser) => {
          if (option.userSeq === 'Support') {
            return supportAgentName || '';
          }
          return `${option.personFirstName} ${option.personLastName} (${option.userName})`;
        }}
        value={participants}
        onChange={handleParticipantChange}
        renderInput={params => (
          <TextField {...params} label='To' placeholder='Select participants' />
        )}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => {
          const isDisabled = isOptionDisabled(option);
          const label =
            option.userSeq === 'Support'
              ? supportAgentName
              : `${option.personFirstName} ${option.personLastName} (${option.userName})`;

          return (
            <li {...props} style={{ opacity: isDisabled ? 0.5 : 1 }}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize='small' />}
                checkedIcon={<CheckBox fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected}
                disabled={isDisabled}
              />
              {label}
            </li>
          );
        }}
        renderTags={(value: ConversationUser[], getTagProps) =>
          value.map((option, index) => {
            if (option.userSeq === 'Support') {
              return <Chip label={supportAgentName} {...getTagProps({ index })} />;
            }

            return (
              <Chip
                label={`${option.personFirstName} ${option.personLastName}`}
                {...getTagProps({ index })}
              />
            );
          })
        }
        disabled={creatingConversation || isLoading}
        getOptionDisabled={isOptionDisabled}
      />

      <TextField
        label='Subject'
        value={topic}
        onChange={e => setTopic(e.target.value)}
        fullWidth
        required
        disabled={creatingConversation || isLoading}
      />

      {/* Display non-image attachments */}
      {attachments.length > 0 && renderAttachments()}

      {/* Drag and drop instruction with NEW chip */}
      <Box component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0 }}>
        <Chip label='TIP' color='primary' size='small' sx={{ fontWeight: 'bold' }} />
        <Typography variant='body2' color='text.secondary'>
          You can drag and drop images directly into the message box, or paste them from your
          clipboard
        </Typography>
      </Box>

      {/* Rich Text Editor */}
      <Box
        component='div'
        sx={{
          '& .ProseMirror': {
            '& h1, & h2, & h3, & h4, & h5, & h6': {
              scrollMarginTop: showMenuBar ? 50 : 0,
            },
            '& img.ProseMirror-selectednode': {
              outline: 'none !important',
              border: '2px solid #2196f3 !important',
            },
            '& figure': {
              position: 'relative',
              display: 'inline-block',
              margin: '0',
            },
          },
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          content={messageContent}
          editable={!creatingConversation && !isLoading}
          editorProps={{
            handleDrop,
            handlePaste,
          }}
          renderControls={() => <EditorMenuControls />}
          RichTextFieldProps={{
            variant: 'outlined',
            MenuBarProps: { hide: !showMenuBar },
            footer: (
              <Stack
                direction='row'
                spacing={2}
                sx={{
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  borderTopColor: theme => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
                <Box component='div' sx={{ flexGrow: 1 }}>
                  <MenuButton
                    value='formatting'
                    tooltipLabel={showMenuBar ? 'Hide formatting' : 'Show formatting'}
                    size='small'
                    onClick={() => setShowMenuBar(prev => !prev)}
                    selected={showMenuBar}
                    IconComponent={TextFields}
                  />
                </Box>
              </Stack>
            ),
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>

      <Box component='div' sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
        {onClose && isDialog && (
          <Button onClick={onClose} disabled={creatingConversation || isLoading}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          variant='contained'
          disabled={!topic.trim() || isLoading || creatingConversation || participants.length <= 0}
          endIcon={<Send />}
        >
          Start Chat
        </Button>
      </Box>
    </Box>
  );

  if (isDialog) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }

  return (
    <Paper
      variant='outlined'
      elevation={0}
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        mx: { xs: 2, sm: 4 },
        mb: 2,
        maxWidth: '100%',
        margin: '0 auto',
        p: 2,
        mt: 2,
      }}
    >
      {content}
    </Paper>
  );
}
