import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, IconButton, Tooltip, Divider } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AddEdit from './AddEdit';
import { activeData, deleteData, getAdminData } from '../../../../../services/utility-api';
import AccessDenied from '../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../components/AdministrationModules/Breadcrumb';
import DataGridComponent from '../../../../../components/AdministrationModules/DataGridComponent';
import ReferenceMasterTableFilter from '../ReferenceMasterTableFilter';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { SlideProps } from '@mui/material/Slide';

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const LiveryPerson = () => {
  const { user } = useAuth();
  const authorizedToEdit = user?.roleCheck(['8f7']) || false;
  const [rows, setRows] = useState<any[]>([]);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] = useState(false);
  const [isAddEditOn, setIsAddEditOn] = useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState<{
    icon?: string;
    seq?: string;
    status?: string;
  }>({});
  const [editObj, setEditObj] = useState({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [rowsToShow, setRowsToShow] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const MENU_OBJECT = {
    Home: '/support',
    'Reference Data': '/support',
    'Livery Person': '',
  };
  const referenceMasterTableName = 'LiveryPerson';

  const [columns, setColumns] = useState([
    {
      field: 'liveryPersonName',
      headerName: 'Person Name',
      minWidth: 200,
      align: 'left',
      flex: 2,
    },
    {
      field: 'liveryService',
      headerName: 'Livery Services',
      minWidth: 200,
      align: 'left',
      flex: 2,
      valueFormatter: (value: any) => {
        return value ? value.optionName : '';
      },
    },
  ]);

  let isStatusColumnAdded = false;
  columns.forEach(column => {
    if (column.field == 'status') {
      isStatusColumnAdded = true;
    }
  });

  if (!isStatusColumnAdded) {
    setColumns(prev => [
      ...prev,
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        align: 'left',
        flex: 1,
        renderCell: (cellValues: any) => {
          const handleClick = async (row: any) => {
            setOpenActiveInActiveDialogBox(true);
            const newIconData = row.status === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,
              seq: row?.id,
              status: row.status,
            });
          };

          return Number(
            cellValues.row[
              referenceMasterTableName[0].toLowerCase() +
                referenceMasterTableName.slice(1) +
                'Order'
            ]
          ) > 90000 ? (
            <></>
          ) : (
            <Button
              onClick={e => {
                e.preventDefault();
                handleClick(cellValues.row);
              }}
            >
              {cellValues.row.status === 'Active' ? (
                <Tooltip title='Active'>
                  <LockIcon />
                </Tooltip>
              ) : (
                <Tooltip title='InActive'>
                  <DoneIcon />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ]);
  }

  const getAllData = async () => {
    setIsLoading(true);
    const response = await getAdminData('LiveryPerson');
    const data = response?.data?.data.map((liveryRow: Record<string, any>) => {
      const {
        personFirstName,
        personMiddleName,
        personLastName,
        personSalutationSeq,
        personSuffix,
        personSeq,
      } = liveryRow?.person ?? {};
      const { liverySeq: optionSeq, liveryName: optionName } = liveryRow?.livery ?? {};
      const livery = {
        id: liveryRow?.liveryPersonSeq,
        liveryPersonName: personMiddleName
          ? personFirstName + ' ' + personMiddleName + ' ' + personLastName
          : personFirstName + ' ' + personLastName,
        liveryService: { optionSeq, optionName },
        status: liveryRow?.status,
        personSeq: personSeq,
        personFirstName: personFirstName,
        personSuffix: personSuffix,
        personMiddleName: personMiddleName,
        personLastName: personLastName,
        personSalutationSeq: personSalutationSeq,
      };

      return livery;
    });
    setRows(data);
    setRowsToShow(data.filter((row: any) => row['status'].toLowerCase() === 'active'));
    setIsLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const editRow = (params: any) => {
    if (authorizedToEdit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: 'LiveryPerson',
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: 'LiveryPerson',
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      setRows([]);
      setRowsToShow([]);
      getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };

  if (!authorizedToEdit) {
    <Grid container spacing={2} sx={{ mt: '10px', padding: '16px' }}>
      <Grid item xs={12} md={12}>
        <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
      </Grid>
      <Grid item xs={12} md={12}>
        <AccessDenied />
      </Grid>
    </Grid>;
  } else {
    return (
      <Grid container spacing={2} sx={{ padding: '16px' }}>
        <Grid item xs={12}>
          <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3 }} />
        </Grid>

        <Grid item xs={12}>
          <Box component={'div'} sx={{ tr: 'hover', cursor: 'pointer' }}>
            <ReferenceMasterTableFilter
              rows={rows}
              isJdx={false}
              jurisdictionOptions={[]}
              setRowsToShow={setRowsToShow}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <DataGridComponent
            sortModel={[
              {
                field: 'liveryPersonName',
                sort: 'asc',
              },
            ]}
            loading={isLoading}
            listresults={rowsToShow}
            columnsInput={columns}
            rowsperpage={10}
            onRowClick={editRow}
            gridheight={rowsToShow.length > 0 ? 'auto' : 200}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant='contained'
            sx={{ minWidth: '125px' }}
            onClick={() => {
              setIsAddEditOn(false);
              setTimeout(() => {
                setEditObj({});
                setIsAddEditOn(true);
              }, 200);
            }}
          >
            Add Livery Person
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
          {isAddEditOn && editObj && (
            <Box component={'div'}>
              <AddEdit
                editObj={editObj}
                referenceMasterTableName='LiveryPerson'
                setIsAddEditOn={setIsAddEditOn}
                getAllData={getAllData}
              />
            </Box>
          )}
        </Grid>

        <div className='modal'>
          <Dialog
            open={openActiveInActiveDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenActiveInActiveDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                Are you sure you want to{' '}
                {activeInactiveClickData?.status === 'Active' ? 'InActive ' : 'Active'} this livery
                person?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>No</Button>
              <Button onClick={changeActiveInActiveClick}>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>

        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    );
  }
};

export default LiveryPerson;
