import { Card, CardContent, Typography } from '@mui/material';

export default function InfoCard({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          {title}
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}
