import { useState } from 'react';
import { OrbitControls, useTexture } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { XR, createXRStore } from '@react-three/xr';
import { Fab, IconButton } from '@mui/material';
import { CloseFullscreen, Maximize, Minimize, OpenInFull } from '@mui/icons-material';

const store = createXRStore({
  emulate: true,
});

const SphereWithTexture = ({ image }: { image: string }) => {
  const texture = useTexture(image);

  return (
    <mesh>
      <sphereGeometry args={[5, 64, 64]} />
      <meshBasicMaterial map={texture} side={2} />
    </mesh>
  );
};

const Viewer360 = ({ image }: { image: string }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <>
      {import.meta.env.DEV && <button onClick={() => store.enterAR()}>Enter VR</button>}
      <Fab
        size='small'
        color='primary'
        onClick={() => setIsFullscreen(!isFullscreen)}
        sx={{
          position: 'absolute',
          top: isFullscreen ? 10 : 100,
          right: isFullscreen ? 10 : 50,
          zIndex: 1000,
        }}
      >
        {isFullscreen ? <CloseFullscreen /> : <OpenInFull />}
      </Fab>
      <div
        style={{
          position: isFullscreen ? 'fixed' : 'relative',
          top: isFullscreen ? 0 : 'auto',
          left: isFullscreen ? 0 : 'auto',
          width: isFullscreen ? '100vw' : '100%',
          height: isFullscreen ? '100vh' : 650,
          background: 'black',
          zIndex: isFullscreen ? 999 : 'auto',
        }}
      >
        <Canvas camera={{ position: [0, 0, 0.1] }} style={{ width: '100%', height: '100%' }}>
          <XR store={store}>
            <OrbitControls enableZoom={false} />
            <SphereWithTexture image={image} />
          </XR>
        </Canvas>
      </div>
    </>
  );
};

export default Viewer360;
