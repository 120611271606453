import { DeleteRounded, Message, RefreshRounded, Send, TextFields } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from '../utils/auth/AuthService';
import { useConversation } from '../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';
import { ConversationTypes } from '../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/conversation.types';
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from 'mui-tiptap';
import type { EditorOptions } from '@tiptap/core';
import EditorMenuControls from '../views/Home/EditorMenuControls';
import useExtensions from '../views/Home/useExtensions';

// Function to convert File to base64
async function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

// Function to resize image
async function resizeImage(file, maxWidth = 600, maxHeight = 600) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }

      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        blob => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Could not create blob'));
          }
        },
        file.type,
        0.85 // compression quality
      );
    };
    img.onerror = () => reject(new Error('Could not load image'));
  });
}

const { VITE_API_URL } = import.meta.env;

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  onMessagesClick: () => void;
  dialogProps?: DialogProps;
}

export function FeedbackDialog({
  open,
  onClose,
  dialogProps,
  onMessagesClick,
}: FeedbackDialogProps) {
  const auth = useAuth();
  const { createConversation, createChat } = useConversation();
  const location = useLocation();
  const [messageContent, setMessageContent] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [sending, setSending] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [error, setError] = useState(null);
  const [showMenuBar, setShowMenuBar] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const rteRef = useRef(null);
  const extensions = useExtensions({
    placeholder: "What's going on? We review and respond to all feedback.",
  });

  const handleClose = () => {
    onClose();
  };

  const handleNewImageFiles = useCallback(async (files, insertPosition) => {
    if (!rteRef.current?.editor) {
      return;
    }

    try {
      const processedImages = await Promise.all(
        files.map(async file => {
          const resizedBlob = await resizeImage(file);
          const resizedFile = new File([resizedBlob], file.name, { type: file.type });
          const base64 = await convertToBase64(resizedFile);
          return { src: base64, alt: file.name };
        })
      );

      insertImages({
        images: processedImages,
        editor: rteRef.current.editor,
        position: insertPosition,
      });
    } catch (error) {
      console.error('Image processing error:', error);
    }
  }, []);

  const handleDrop = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = Array.from(event.dataTransfer.files).filter(file =>
        file.type.startsWith('image/')
      );

      if (imageFiles.length > 0) {
        const position = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, position);
        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handlePaste = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = Array.from(event.clipboardData.files).filter(file =>
        file.type.startsWith('image/')
      );

      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const removeAttachment = fileToRemove => {
    setAttachments(prev => prev.filter(file => file !== fileToRemove));
  };

  // Effect for editor updates
  useEffect(() => {
    const editor = rteRef.current?.editor;
    if (!editor) return;

    const updateHandler = () => {
      setMessageContent(editor.getHTML());
    };

    editor.on('update', updateHandler);
    return () => {
      editor.off('update', updateHandler);
    };
  }, [rteRef.current]);

  // Add styles for image hover controls to the document
  useEffect(() => {
    // Add our custom styles for image handling
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      .ProseMirror img {
        border-radius: 4px;
        transition: all 0.2s;
        position: relative;
        margin: 0.5em 0;
        max-width: 100%;
      }
      
      .ProseMirror img.ProseMirror-selectednode {
        outline: none !important;
        border: 2px solid #2196f3 !important;
        border-radius: 4px;
      }
      
      .ProseMirror img {
        position: relative;
      }
      
      .ProseMirror img::before {
        content: "×";
        position: absolute;
        top: 4px;
        right: 4px;
        width: 24px;
        height: 24px;
        background-color: rgba(255, 255, 255, 0.8);
        color: #f44336;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        opacity: 0;
        transition: opacity 0.2s;
        cursor: pointer;
        z-index: 10;
        line-height: 1;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12);
      }
      
      .ProseMirror img::after {
        content: "⤡";
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 24px;
        height: 24px;
        background-color: rgba(255, 255, 255, 0.8);
        color: #2196f3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        opacity: 0;
        transition: opacity 0.2s;
        cursor: nwse-resize;
        z-index: 10;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12);
      }
      
      .ProseMirror-focused img:hover::before,
      .ProseMirror-focused img:hover::after {
        opacity: 1;
      }
    `;
    document.head.appendChild(styleElement);

    // Add event listener for the custom delete button
    const handleImageClick = event => {
      const target = event.target;
      // Check if we clicked on an image inside the editor
      if (target.tagName === 'IMG' && target.closest('.ProseMirror')) {
        // Get the position of the click relative to the image
        const rect = target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // Check if the click was in the top-right corner (where our X button is)
        if (x >= rect.width - 28 && x <= rect.width - 4 && y >= 4 && y <= 28) {
          // Delete the image
          if (rteRef.current?.editor) {
            const { state } = rteRef.current.editor;
            const { from } = state.selection;

            // Find the image node
            const resolvedPos = state.doc.resolve(from);
            const node = resolvedPos.parent.child(resolvedPos.index());

            // Check if it's an image and delete it
            if (node && node.type.name === 'image') {
              rteRef.current.editor.commands.deleteSelection();
              event.preventDefault();
              event.stopPropagation();
            }
          }
        }
      }
    };

    // Add the event listener to the document
    document.addEventListener('click', handleImageClick, true);

    return () => {
      document.head.removeChild(styleElement);
      document.removeEventListener('click', handleImageClick, true);
    };
  }, []);

  const renderAttachments = () => (
    <Box component='div' sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 2 }}>
      {attachments.map((file, index) => (
        <Chip key={index} label={file.name} onDelete={() => removeAttachment(file)} />
      ))}
    </Box>
  );

  const resetForm = () => {
    setMessageContent('');
    setAttachments([]);
    setSending(false);
    setError(null);

    if (rteRef.current?.editor) {
      rteRef.current.editor.commands.setContent('');
    }
  };

  const sendFeedback = async () => {
    if (!messageContent.trim()) return;

    try {
      setSending(true);
      setError(null);

      // Extract any non-embedded images from the editor (for example, if they were directly pasted)
      // These will be handled by the editor itself and embedded directly

      await createChat(
        {
          topic: `Feedback from ${auth.user?.userFirstName} ${auth?.user?.userLastName}`,
          messages: [
            {
              messageContent,
              sender: { userSeq: auth.user?.userSeq },
              readBy: [{ userSeq: auth.user?.userSeq }],
              attachments: attachments || [],
            },
          ],
          creator: {
            userSeq: auth.user?.userSeq,
          },
          type: {
            conversationTypeSeq: ConversationTypes.Feedback,
          },
          isOpen: true,
          isActive: true,
          participants: [{ userSeq: auth.user?.userSeq }],
        },
        {
          caseId: location.state?.cmscaseid || null,
          pathname: location.pathname,
        }
      );

      resetForm();
      setFeedbackSent(true);
      onClose();
    } catch (e) {
      setError(e instanceof Error ? e.message : 'An unknown error occurred');
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        maxWidth='md'
        fullWidth
        onClose={handleClose}
        {...dialogProps}
      >
        <DialogTitle>Send feedback</DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ width: '100%' }}>
            <InputLabel htmlFor='feedback-body'>
              What's going on? We review and respond to all feedback.
            </InputLabel>
            <InputLabel sx={{ mt: 1 }}>
              <Typography variant='subtitle1'>
                Check for responses to your feedback in{' '}
                <Button variant='text' startIcon={<Message />} onClick={() => onMessagesClick()}>
                  Messages
                </Button>
              </Typography>
            </InputLabel>

            {/* Drag and drop instruction with TIP chip */}
            <Box
              component='div'
              sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2, mb: 2 }}
            >
              <Chip label='TIP' color='primary' size='small' sx={{ fontWeight: 'bold' }} />
              <Typography variant='body2' color='text.secondary'>
                You can drag and drop images directly into the message box, or paste them from your
                clipboard
              </Typography>
            </Box>

            {/* Rich Text Editor */}
            <Box
              component='div'
              sx={{
                '& .ProseMirror': {
                  '& h1, & h2, & h3, & h4, & h5, & h6': {
                    scrollMarginTop: showMenuBar ? 50 : 0,
                  },
                  '& img.ProseMirror-selectednode': {
                    outline: 'none !important',
                    border: '2px solid #2196f3 !important',
                  },
                  '& figure': {
                    position: 'relative',
                    display: 'inline-block',
                    margin: '0',
                  },
                  minHeight: '200px',
                },
              }}
            >
              <RichTextEditor
                ref={rteRef}
                extensions={extensions}
                content={messageContent}
                editable={!sending}
                editorProps={{
                  handleDrop,
                  handlePaste,
                }}
                renderControls={() => <EditorMenuControls />}
                RichTextFieldProps={{
                  variant: 'outlined',
                  MenuBarProps: { hide: !showMenuBar },
                  footer: (
                    <Stack
                      direction='row'
                      spacing={2}
                      sx={{
                        borderTopStyle: 'solid',
                        borderTopWidth: 1,
                        borderTopColor: theme => theme.palette.divider,
                        py: 1,
                        px: 1.5,
                      }}
                    >
                      <Box component='div' sx={{ flexGrow: 1 }}>
                        <MenuButton
                          value='formatting'
                          tooltipLabel={showMenuBar ? 'Hide formatting' : 'Show formatting'}
                          size='small'
                          onClick={() => setShowMenuBar(prev => !prev)}
                          selected={showMenuBar}
                          IconComponent={TextFields}
                        />
                      </Box>
                    </Stack>
                  ),
                }}
              >
                {() => (
                  <>
                    <LinkBubbleMenu />
                    <TableBubbleMenu />
                  </>
                )}
              </RichTextEditor>
            </Box>

            {/* Display warning about PII in attachments */}
            <Typography variant='subtitle1' color='error' fontWeight='bold' sx={{ mt: 2 }}>
              Ensure all personally identifiable information is redacted from your attachments or
              screenshots
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button
            onClick={resetForm}
            variant='text'
            color='inherit'
            size='small'
            startIcon={<RefreshRounded />}
          >
            Reset
          </Button>
          <Box component='div'>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='error'
              size='large'
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={sendFeedback}
              variant='contained'
              color='primary'
              size='large'
              disabled={sending || !messageContent.trim()}
              startIcon={<Send />}
            >
              {sending ? 'Sending...' : 'Send'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={feedbackSent}
        autoHideDuration={5000}
        onClose={() => setFeedbackSent(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant='filled' severity='success'>
          <AlertTitle>Thank you for your feedback</AlertTitle>
          It will be reviewed soon
        </Alert>
      </Snackbar>

      <Snackbar
        open={error !== null}
        autoHideDuration={5000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant='filled' severity='error'>
          <AlertTitle>Error while sending feedback</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}
