import { Download, Email, ListRounded } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import { FormikTextField } from '../../utils/components/FormikTextField';
import { convertSvgToPng } from '../../utils/functions/convertSvgToPng';
import { validEmail } from '../../utils/functions/useInputValidation';

const BodyReleaseForm = ({ casedata, isEditable }) => {
  const auth = useAuth();
  const formik = useFormikContext();
  const { VITE_API_URL } = import.meta.env;
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [instanceUrl, setInstanceURL] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [email, setEmail] = useState(formik.values?.caseMortuary?.fhrEmail || '');

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleEmailConfirm = async () => {
    try {
      setSendingEmail(true);

      const response = await fetch(instanceUrl);
      const pdfBlob = await response.blob();
      const pdfFile = new File([pdfBlob], 'BodyReleaseForm.pdf', { type: 'application/pdf' });
      const formData = new FormData();

      formData.append('RecipientEmails', casedata.caseMortuary.fhrEmail);

      formData.append(
        'Subject',
        `Body Release Form — ${formik.values?.caseSummary?.decedentInfo?.decedentName} `
      );

      const emailBody = `As requested, please refer to the attached Release Document: <br/>
Decedent Name: ${casedata?.caseSummary?.decedentInfo?.decedentName}<br/>
Sent by: ${auth.user?.userFirstName} ${auth?.user?.userLastName}<br/>
<br/>
If you believe you received this email in error, please disregard and delete it.<br/><br/>`;
      formData.append('Body', emailBody);
      formData.append('Attachments', pdfFile);

      const emailResponse = await fetch(`${VITE_API_URL}sendemail`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: formData,
      });

      if (!emailResponse.ok) {
        const errorData = await emailResponse.text();
        console.error('Email API Error:', {
          status: emailResponse.status,
          statusText: emailResponse.statusText,
          errorData,
        });
        throw new Error(errorData || 'Failed to send email');
      }

      if (!emailResponse.ok) {
        throw new Error('Failed to send email');
      }

      setSnackbar({
        open: true,
        message: 'Email sent successfully!',
        severity: 'success',
      });

      setEmailDialogOpen(false);
    } catch (error) {
      console.error('Error sending email:', error);
      setSnackbar({
        open: true,
        message: 'Failed to send email. Please try again later.',
        severity: 'error',
      });
    } finally {
      setSendingEmail(false);
    }
  };

  const handleSaveToDocuments = async () => {
    try {
      setUploading(true);

      const isSigned = Boolean(formik.values?.caseMortuary?.fhrSign);
      const response = await fetch(instanceUrl);
      const pdfBlob = await response.blob();
      const fileName = isSigned
        ? `${casedata?.caseSummary?.caseNumber}_BodyReleaseForm_Signed`
        : `${casedata?.caseSummary?.caseNumber}_BodyReleaseForm`;

      const pdfFile = new File([pdfBlob], `${fileName}.pdf`, { type: 'application/pdf' });
      const formDataUploadFiles = new FormData();

      formDataUploadFiles.append('FILEPARAMS[0].CASENO', casedata?.caseSummary?.caseNumber);
      formDataUploadFiles.append('FILEPARAMS[0].CASESEQ', casedata?.caseSummary?.caseSeq);
      formDataUploadFiles.append(
        'FILEPARAMS[0].FILERELATEDTO',
        isSigned ? '4e2379bd-136f-4cf7-89e7-fb54296f8ecf' : '4c62e883-69a0-49f3-9624-a1f9b5c3f7ff'
      );
      formDataUploadFiles.append('FILEPARAMS[0].FILERELATEDTONAME', fileName);
      formDataUploadFiles.append('FILEPARAMS[0].FILE', pdfFile);
      formDataUploadFiles.append('USERSEQ', auth.user?.userSeq);

      const uploadResponse = await fetch(`${VITE_API_URL}fileuploads`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: formDataUploadFiles,
      });

      const data = await uploadResponse.json();

      if (uploadResponse.ok && data.status === 0) {
        //setUploadSuccess(true);
        setSnackbar({
          open: true,
          message: 'File successfully saved to documents',
          severity: 'success',
        });
        setDialogOpen(false);
      } else {
        throw new Error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error saving to documents:', error);
      setSnackbar({
        open: true,
        message: 'Failed to save file to documents. Please try again later',
        severity: 'error',
      });
    } finally {
      setUploading(false);
    }
  };

  const fetchReportHeader = async () => {
    const qryParams = {
      JdxSeq: casedata.caseSummary.jdxSeq,
    };

    const url = `${VITE_API_URL}getreportheader?${new URLSearchParams(qryParams)}`;
    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (res.status >= 400) {
        throw new Error('An error occurred');
      }

      const data = await res.json();
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStateOptions = async () => {
    try {
      const res = await fetch(VITE_API_URL + 'getstateoptions', {
        method: 'GET',
      });

      const data = await res.json();
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchFuneralHomeInfo = async () => {
    const qryParams = {
      FuneralHomeSeq: casedata?.caseMortuary?.funeralHome?.optionSeq,
    };

    const url = `${VITE_API_URL}getfuneralhomeaddress?${new URLSearchParams(qryParams)}`;

    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (res.status >= 400) {
        throw new Error('An error occurred');
      }

      const data = await res.json();
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const generateAndOpenPDF = async (funeralHomeInfo, stateOptions, reportHeader) => {
    try {
      const pdfDoc = new jsPDF();

      // Add content to the PDF using jsPDF methods
      pdfDoc.setFont('Arial', 'bold');
      pdfDoc.setFontSize(10);
      pdfDoc.setLineWidth(0.5);

      // Define the header text
      const reportHeaderName = reportHeader[0]?.reportHeaderName || ''; 
      const reportAddress = reportHeader[0]?.reportAddress || ''; 

      let headerText = '';

      if (reportHeaderName) {
        headerText += reportHeaderName; 
      }

      if (reportAddress) {
        headerText += (headerText ? '\n\n' : '') + reportAddress;
      }

      const headerLines = headerText
        .split('\n')
        .flatMap(line => pdfDoc.splitTextToSize(line, pdfDoc.internal.pageSize.getWidth() - 20));

      // Calculate the starting position for the header text
      const center = pdfDoc.internal.pageSize.getWidth() / 2;

      const lineHeight = 4; // Space between lines
      const headerPadding = 4; // Padding above and below text
      let headerHeight = headerLines.length * lineHeight + headerPadding;
      headerHeight = Math.max(headerHeight, 30);

      // Set header background color
      pdfDoc.setFillColor('#f2f2f2');
      pdfDoc.rect(0, 0, pdfDoc.internal.pageSize.getWidth(), headerHeight + 2, 'F');

      // **Draw the header text dynamically**
      headerLines.forEach((line, index) => {
        const textWidth = pdfDoc.getTextWidth(line);
        const textX = center - textWidth / 2;
        const textY = headerPadding + index * lineHeight + 2; // Position text inside header
        pdfDoc.text(line, textX, textY);
      });

      // Add the logo in the top-left corner
      if (casedata?.caseSummary.jdxLogo) {
        const logoImage = await convertSvgToPng(casedata?.caseSummary.jdxLogo);
        const logoWidth = 40; // Adjust the logo width
        const logoHeight = 40; // Adjust the logo height
        const logoStartX = 10; // Position from left
        const logoStartY = (headerHeight - logoHeight) / 2; // Position from top
        pdfDoc.addImage(logoImage, 'PNG', logoStartX, logoStartY, logoWidth, logoHeight);
      }

      let marginLeft = 60;
      const padding = 2;

      // Calculate the starting position for the form elements
      const formX = 10;
      const formY = 10 + headerHeight; // Start the form elements below the header

      //function to draw underlined value with fixed length and padding
      const drawField = (header, value, x, y, underlineLength) => {
        pdfDoc.setFont('Arial', 'normal');
        // Draw the header and value with padding
        pdfDoc.text(header, x + marginLeft, y, { align: 'right', direction: 'rtl' }); // Set text direction to "rtl"
        pdfDoc.setFont('Arial', 'bold');
        pdfDoc.text(value, x + marginLeft + padding, y); // Use the maximum header width here

        // Draw the underline only under the value
        pdfDoc.line(
          x + marginLeft + padding,
          y + 2,
          x + marginLeft + padding + underlineLength,
          y + 2
        );
      };

      const drawField2 = (header, value, x, y, underlineLength) => {
        // Draw the header and value with padding, starting at the position of the first field
        const fieldMarginLeft = 80;
        pdfDoc.setFont('Arial', 'normal');
        pdfDoc.text(header, x + marginLeft + fieldMarginLeft, y, {
          align: 'right',
          direction: 'rtl',
        }); // Set text direction to "rtl"
        pdfDoc.setFont('Arial', 'bold');
        pdfDoc.text(value, x + marginLeft + fieldMarginLeft + padding, y);

        // Draw the underline only under the value
        pdfDoc.line(
          x + marginLeft + fieldMarginLeft + padding,
          y + 2,
          x + marginLeft + fieldMarginLeft + padding + underlineLength,
          y + 2
        );
      };

      // Draw the main header text
      pdfDoc.setFontSize(14); // Increase the font size for main header
      const documentName = 'BODY RELEASE FORM';
      const documentNameWidth = pdfDoc.getTextWidth(documentName);
      const documentNameX = pdfDoc.internal.pageSize.getWidth() / 2 - documentNameWidth / 2;
      pdfDoc.text(documentName, documentNameX, formY);
      pdfDoc.line(documentNameX, formY + 3, documentNameX + documentNameWidth, formY + 3);
      pdfDoc.setFontSize(10); // Reset font size to normal size

      // If they are next of kin and are the person authorizing the release
      const authorizingReleasePerson = casedata?.caseDemographics?.informantList?.find(
        informant => {
          const informantSeq = informant?.personSeq?.toLowerCase() || '';
          const authorizingReleaseSeq = (
            casedata?.caseMortuary?.authorizingRelease || ''
          ).toLowerCase();

          return informantSeq === authorizingReleaseSeq;
        }
      );

      const formattedName = authorizingReleasePerson
        ? `${authorizingReleasePerson.personLastName}, ${authorizingReleasePerson.personFirstName}`
        : null;

      // console.log('the auth guy: ', authorizingReleasePerson);
      // console.log('the state options: ', stateOptions);

      const matchState = stateseq => {
        return stateOptions.find(option => option.optionSeq === stateseq);
      };

      const stateseqToFind = authorizingReleasePerson?.informantAddress?.address?.[0]?.stateSeq
        ? authorizingReleasePerson.informantAddress.address[0].stateSeq.toUpperCase()
        : '';

      const kinState = matchState(stateseqToFind);

      // Draw the fields with the desired format
      drawField(
        'Name of deceased:',
        casedata.caseSummary?.decedentInfo?.decedentName,
        formX,
        formY + 20,
        60
      );
      drawField2('Case No:', casedata.caseSummary.caseNumber, formX, formY + 20, 40);
      drawField(
        'Address:',
        casedata.caseSummary?.decedentInfo?.residence?.addressLine1 +
          ' ' +
          casedata.caseSummary?.decedentInfo?.residence?.addressLine2,
        formX,
        formY + 30,
        120
      );
      drawField(
        'City:',
        casedata.caseSummary?.decedentInfo?.residence?.cityTown || '',
        formX,
        formY + 40,
        40
      );
      drawField2(
        'State:',
        casedata.caseSummary?.decedentInfo?.residence?.state || '',
        formX,
        formY + 40,
        40
      );
      drawField(
        'This is to certify that I:',
        casedata.caseMortuary?.signedBy ?? '',
        formX,
        formY + 50,
        60
      );
      drawField(
        'Representing the funeral home of:',
        funeralHomeInfo[0]?.funeralHomeName ?? '',
        formX,
        formY + 60,
        60
      );
      drawField(
        'Address:',
        (funeralHomeInfo[0]?.addressLine1 ?? '') + ' ' + (funeralHomeInfo[0]?.addressLine2 ?? ''),
        formX,
        formY + 70,
        120
      );
      drawField('City:', funeralHomeInfo[0]?.city ?? '', formX, formY + 80, 40);
      drawField2('State:', funeralHomeInfo[0]?.stateName ?? '', formX, formY + 80, 40);
      drawField('Zip code:', funeralHomeInfo[0]?.zip ?? '', formX, formY + 90, 40);
      drawField2('Contact Info:', '', formX, formY + 90, 40);
      drawField('Has been authorized by: (Name)', formattedName ?? '', formX, formY + 100, 60);
      drawField(
        'Address:',
        authorizingReleasePerson?.informantAddress?.address?.[0]?.addressLine1 ?? '',
        formX,
        formY + 110,
        120
      );
      drawField(
        'City:',
        authorizingReleasePerson?.informantAddress?.address?.[0]?.city ?? '',
        formX,
        formY + 120,
        40
      );
      drawField2('State:', kinState?.optionName ?? '', formX, formY + 120, 40);
      drawField(
        'Zip code:',
        authorizingReleasePerson?.informantAddress?.address?.[0]?.zip ?? '',
        formX,
        formY + 130,
        40
      );
      drawField2(
        'Contact Info:',
        authorizingReleasePerson?.informantAddress?.contactItem?.[0]?.contactItemDetails ?? '',
        formX,
        formY + 130,
        40
      );
      drawField(
        'Having the relationship of:',
        authorizingReleasePerson?.relship?.optionName ?? '',
        formX,
        formY + 140,
        60
      );
      pdfDoc.setFont('Arial', 'normal');
      pdfDoc.text('to the deceased,', formX + marginLeft + 70 + padding, formY + 140);
      drawField(
        'To remove and to care for the body of:',
        casedata.caseSummary?.decedentInfo?.decedentName,
        formX,
        formY + 150,
        60
      );

      const paragraphText =
        'From the Office of the Medical Examiner for the purpose of funeral arrangements, embalming, shipping, cremation, burial, or other final means of disposition or personal postmortem care.';
      const paragraphLines = pdfDoc.splitTextToSize(paragraphText, 180);
      const paragraphX = formX + 5;
      const paragraphY = formY + 160;

      let maxLineWidth = 0;
      paragraphLines.forEach(line => {
        const lineWidth = pdfDoc.getTextWidth(line);
        if (lineWidth > maxLineWidth) {
          maxLineWidth = lineWidth;
        }
      });

      // Calculate the right boundary position
      const rightBoundaryX = paragraphX + maxLineWidth;

      // Check if the text exceeds the right boundary
      if (rightBoundaryX > formX + 200) {
        // Wrap the text to the next line if it exceeds the boundary
        paragraphLines.pop();
        pdfDoc.text('...continued on next line...', paragraphX, paragraphY + 3 * 6 + 2);
      }

      paragraphLines.forEach((line, index) => {
        if (index < 3) {
          // Limit the number of lines to 3, adjust as needed
          pdfDoc.text(line, paragraphX, paragraphY + index * 6);
        }
      });

      drawField('Signature of Funeral Home Representative:', '', formX, formY + 190, 60);

      // Add the signature image to the PDF
      const signatureX = formX + marginLeft + padding + 20;
      const signatureY = formY + 190 - 15;
      const signatureWidth = 30;
      const signatureHeight = 15;
      const signature = casedata.caseMortuary?.fhrSign;
      if (signature) {
        pdfDoc.addImage(signature, 'PNG', signatureX, signatureY, signatureWidth, signatureHeight);
      }

      drawField('Released by:', casedata.caseMortuary?.releasedBy || '', formX, formY + 200, 60);
      drawField('Witnessed by:', casedata.caseMortuary?.witnessedBy || '', formX, formY + 210, 60);
      drawField('Date:', new Date().toLocaleDateString(), formX, formY + 220, 40);
      drawField2('Time:', new Date().toLocaleTimeString(), formX, formY + 220, 40);
      drawField(
        'Death Certificate No:',
        casedata?.caseCertification?.edrsNumber || '',
        formX,
        formY + 230,
        40
      );

      const pdfBlob = pdfDoc.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);
      // window.open(pdfUrl, '_blank');
      setInstanceURL(pdfUrl);
    } catch (error) {
      console.error('Error while generating or downloading PDF:', error);
    }
  };

  const handlePrintBodyReleaseForm = async () => {
    try {
      setLoading(true);
      const [funeralHomeInfo, stateOptions, reportHeader] = await Promise.all([
        fetchFuneralHomeInfo(),
        fetchStateOptions(),
        fetchReportHeader(),
      ]);
      generateAndOpenPDF(funeralHomeInfo, stateOptions, reportHeader);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error in handlePrintBodyReleaseForm:', error);
      setSnackbar({
        open: true,
        message: 'Failed to generate form. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const isSigned = Boolean(formik.values?.caseMortuary?.fhrSign);
    const fileName = isSigned
      ? `${casedata?.caseSummary?.caseNumber}_BodyReleaseForm_Signed`
      : `${casedata?.caseSummary?.caseNumber}_BodyReleaseForm`;

    const a = document.createElement('a');
    a.href = instanceUrl;
    a.download = `${fileName}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Button
        variant='contained'
        color='info'
        style={{ width: '100%' }}
        onClick={handlePrintBodyReleaseForm}
        startIcon={<LaunchIcon />}
        disabled={loading || !isEditable}
      >
        BODY RELEASE FORM
      </Button>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth='lg'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Box display='flex' alignItems='center' justifyContent='space-between' padding='16px'>
          <DialogTitle style={{ margin: 0, padding: 0 }}>Body Release Form Preview</DialogTitle>
          <Box>
            <Button
              startIcon={<Download />}
              onClick={() => handleDownload()}
              color='primary'
              style={{ marginRight: 8 }}
            >
              Download PDF
            </Button>

            <Button
              startIcon={<Email />}
              onClick={() => setEmailDialogOpen(true)}
              color='primary'
              style={{ marginRight: 8 }}
            >
              Email PDF
            </Button>

            <Button
              startIcon={<ListRounded />}
              onClick={handleSaveToDocuments}
              disabled={uploading}
              color='secondary'
              style={{ marginRight: 8 }}
            >
              Save to Documents
            </Button>

            <Button onClick={() => setDialogOpen(false)} color='error'>
              Close
            </Button>
          </Box>
        </Box>
        <DialogContent
          dividers
          sx={{
            flex: 1,
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <iframe
            title='PDF Preview'
            src={`${instanceUrl}#zoom=page-fit&view=FitH&toolbar=0`}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              flexGrow: 1,
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
        aria-labelledby='email-dialog-title'
        aria-describedby='email-dialog-description'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='email-dialog-title'>Email "Body Release Form"?</DialogTitle>
        <DialogContent id='email-dialog-description'>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <FormikTextField
              gridProps={{ xs: 12, md: 12 }}
              disabled={true}
              id='caseMortuary.fhrEmail'
              name='caseMortuary.fhrEmail'
              placeholder='Recipient Email'
              label='Recipient Email'
              type='text'
              fullWidth
              variant='outlined'
              authorizedToEdit={false}
              formikField='caseMortuary.fhrEmail'
              error={
                formik.values?.caseMortuary?.fhrEmail &&
                Boolean(!validEmail(formik.values?.caseMortuary?.fhrEmail))
              }
              helperText={
                formik.values?.caseMortuary?.fhrEmail &&
                !validEmail(formik.values?.caseMortuary?.fhrEmail) &&
                'Please enter a valid email address'
              }
            />
            <Grid item xs={12}>
              <Typography variant='h6' color='error' fontWeight='bold'>
                This cannot be undone.{' '}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEmailDialogOpen(false)} color='error' autoFocus>
            Cancel
          </Button>
          <Button
            onClick={handleEmailConfirm}
            color='primary'
            variant='contained'
            disabled={!validEmail(formik.values?.caseMortuary?.fhrEmail) || sendingEmail}
          >
            {sendingEmail ? 'Sending...' : 'Send'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant='standard'
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BodyReleaseForm;
